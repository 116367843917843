import {Injectable} from '@angular/core';
import {catchError, Observable, of, Subject, zipWith} from 'rxjs';
import {GatewayApi} from '@hrs/providers';
import {EducationFile} from '../../education';
import {Quiz} from '../../education/quizzes/education-quiz.model';
import {HRSSecureCache} from '../storage/cache';
import {EducationRailTabs, EducationRailTabTypes} from '../../education/education-rail-tabs.enum';
import {getLogger} from '@hrs/logging';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EducationService {
    private readonly logger = getLogger('EducationService');
    // array of condition strings, ['COPD', 'CHF', 'Default']
    public quizConditions: string[] = [];
    public fileConditions: string[] = [];
    public videoConditions: string[] = [];
    public fileCollection: EducationFile[] = [];
    public videoCollection: EducationFile[] = [];
    public quizCollection: Quiz[] = [];
    public educationDataLoaded: Subject<any> = new Subject();
    public educationRailTab: EducationRailTabTypes;

    constructor(
        private cache: HRSSecureCache,
        private gatewayApi: GatewayApi,
    ) {}

    public setActiveRailTab(): void {
        if (this.hasVideos()) {
            this.educationRailTab = EducationRailTabs.VIDEOS;
        } else if (this.hasQuizzes()) {
            this.educationRailTab = EducationRailTabs.QUIZZES;
        } else if (this.hasFiles()) {
            this.educationRailTab = EducationRailTabs.FILES;
        }
    }

    public hasEducationContent(): boolean {
        return this.hasVideos() || this.hasFiles() || this.hasQuizzes();
    }

    public hasFiles(): boolean {
        return this.fileCollection.length > 0;
    }

    public hasVideos(): boolean {
        return this.videoCollection.length > 0;
    }

    public hasQuizzes(): boolean {
        return this.quizCollection.length > 0;
    }

    public getQuizzesByCondition(condition: string): Quiz[] {
        return this.quizCollection.filter((quiz) => {
            if (quiz.questions.length > 0 && quiz.conditions.includes(condition)) {
                return quiz;
            }
        });
    }

    public getVideosByCondition(condition: string): EducationFile[] {
        return this.videoCollection.filter(function(video) {
            return video.attributes.conditions.includes(condition);
        });
    }

    public getFilesByCondition(condition: string): EducationFile[] {
        return this.fileCollection.filter((file) => {
            return file.attributes.conditions.includes(condition);
        });
    }

    public getVideoById(id: string): EducationFile[] {
        return this.videoCollection.filter((video) => {
            return video.id === id;
        });
    }

    public getFileById(id: string): EducationFile[] {
        return this.fileCollection.filter((file) => {
            return file.id === id;
        });
    }

    public getQuizById(id: string): Quiz[] {
        return this.quizCollection.filter((quiz) => {
            return quiz.id === id;
        });
    }

    public load(bypassCache: boolean = false): Observable<any> {
        return this.loadEducationFiles(bypassCache).pipe(zipWith(this.loadEducationQuizzes(bypassCache)));
    }

    private loadEducationQuizzes(bypassCache: boolean = false): any {
        return this.cache.loadFromDelayedObservable(
            HRSSecureCache.EDUCATION_QUIZ_CACHE_KEY + this.cache.userID,
            this.gatewayApi.get('quizzes?filter[hrsid]=' + this.cache.userID),
            undefined,
            undefined,
            bypassCache ? 'all' : undefined,
            'meta'
        ).pipe(
            tap((res:any) => {
                this.quizCollection = res.data;
                this.quizConditions = [];
                for (let quiz of this.quizCollection) this.quizConditions = [...this.quizConditions, ...quiz.conditions];
                // filter duplicates
                this.quizConditions = this.quizConditions.filter((ele, i, a) => i === a.indexOf(ele));
                this.setActiveRailTab();
                this.educationDataLoaded.next(true);
            }),
            catchError((error) => {
                this.logger.phic.error('Failed to load education quizzes', error);
                return of({error});
            })
        );
    }

    private loadEducationFiles(bypassCache: boolean = false): any {
        return this.cache.loadFromDelayedObservable(
            HRSSecureCache.EDUCATION_FILES_CACHE_KEY + this.cache.userID,
            this.gatewayApi.get('v1/files?filter[hrsid]=' + this.cache.userID),
            undefined,
            undefined,
            bypassCache ? 'all' : undefined,
            'meta'
        ).pipe(
            tap((res: any) => {
                let fileObj = {};
                let videoObj = {};
                this.fileConditions = [];
                this.videoConditions = [];
                for (let file of res.data) {
                    if (file.attributes.type.toLowerCase() === 'custom') {
                        if (!fileObj[file.id]) fileObj[file.id] = file;
                        this.fileConditions = [...this.fileConditions, ...file.attributes.conditions];
                    } else if (file.attributes.type.toLowerCase() === 'video') {
                        if (!videoObj[file.id]) videoObj[file.id] = file;
                        this.videoConditions = [...this.videoConditions, ...file.attributes.conditions];
                    }
                }
                this.fileCollection = [];
                this.videoCollection = [];
                for (let file in fileObj) {
                    if (file) {
                        this.fileCollection.push(fileObj[file]);
                    }
                }
                for (let video in videoObj) {
                    if (video) {
                        this.videoCollection.push(videoObj[video]);
                    }
                }
                this.videoConditions = this.videoConditions.filter((element, i, a) => i === a.indexOf(element));
                this.fileConditions = this.fileConditions.filter((element, i, a) => i === a.indexOf(element));
                this.setActiveRailTab();
                this.educationDataLoaded.next(true);
            }),
            catchError((error) => {
                this.logger.phic.error('Failed to load education files', error);
                return of({error});
            })
        );
    }
}
