import {Injectable} from '@angular/core';
import {CommunicationService, VideoCallType} from '@hrs/providers';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';
import {Platform} from '@ionic/angular';
import {Observable, Subject} from 'rxjs';
import {getLogger} from '@hrs/logging';

declare let OT: any;

export enum OpentokEventScope {
    SESSION_EVENTS = 'sessionEvents',
    PUBLISHER_EVENTS = 'publisherEvents',
    SUBSCRIBER_EVENTS = 'subscriberEvents'
}

export enum OpentokEventType {
    SESSION_CONNECTED = 'sessionConnected',
    SESSION_DISCONNECTED = 'sessionDisconnected',
    SESSION_RECONNECTED = 'sessionReconnected',
    SESSION_RECONNECTING = 'sessionReconnecting',
    SESSION_ERROR = 'sessionError',
    CONNECTION_CREATED = 'connectionCreated',
    CONNECTION_DESTROYED = 'connectionDestroyed',
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    SUBSCRIBED_TO_STREAM = 'subscribedToStream',
    VIDEO_DATA_RECEIVED = 'videoDataReceived',
    AUDIO_LEVEL_UPDATED = 'audioLevelUpdated',
    VIDEO_DISABLED = 'videoDisabled',
    VIDEO_DISABLE_WARNING = 'videoDisableWarning',
    VIDEO_DISABLE_WARNING_LIFTED = 'videoDisableWarningLifted',
    VIDEO_ENABLED = 'videoEnabled',
    SIGNAL_RECEIVED = 'signalReceived',
    ARCHIVE_STARTED = 'archiveStarted',
    ARCHIVE_STOPPED = 'archiveStopped',
    STREAM_PROPERTY_CHANGED = 'streamPropertyChanged',
    STREAM_CREATED = 'streamCreated',
    STREAM_DESTROYED = 'streamDestroyed'
}

export enum OpentokVideoType {
    CUSTOM = 'custom',
    CAMERA = 'camera',
    SCREEN = 'screen'
}

export interface OpentokError {
    message?: string;
    errorDomain?: string;
    errorName?: string;
    errorCode?: number;
    systemMessage?: string;
}

export interface OpentokConnection {
    connectionId?: string;
    creationTime?: string;
    data?: string;
}

export interface OpentokVideoDimensions {
    width: number;
    height: number;
}

export interface OpentokStream {
    connectionId?: string;
    connection?: OpentokConnection;
    creationTime?: string;
    fps?: number;
    hasAudio?: boolean;
    hasVideo?: boolean;
    videoDimensions?: OpentokVideoDimensions;
    videoType?: OpentokVideoType;
    name?: string;
    streamId?: string;
}

export interface OpentokEventData {
    opentokError?: OpentokError;
    connection?: OpentokConnection;
    stream?: OpentokStream;
    changedProperty?: string;
    newValue?: any;
    oldValue?: any;
    id?: any;
    audioLevel?: number;
    reason?: string;
    status?: string;
    name?: string;
    [key: string]: any;
}

export interface OpentokEvent {
    scope: OpentokEventScope;
    type: OpentokEventType;
    data: OpentokEventData;
}

const HIGH_PRIORITY_EVENTS: Set<OpentokEventType> = new Set([
    OpentokEventType.SESSION_CONNECTED,
    OpentokEventType.SESSION_DISCONNECTED,
    OpentokEventType.SESSION_RECONNECTED,
    OpentokEventType.SESSION_RECONNECTING,
    OpentokEventType.SESSION_ERROR,
    OpentokEventType.CONNECTION_CREATED,
    OpentokEventType.CONNECTION_DESTROYED,
    OpentokEventType.CONNECTED,
    OpentokEventType.DISCONNECTED,
    OpentokEventType.SUBSCRIBED_TO_STREAM,
    OpentokEventType.VIDEO_DISABLED,
    OpentokEventType.VIDEO_DISABLE_WARNING,
    OpentokEventType.VIDEO_DISABLE_WARNING_LIFTED,
    OpentokEventType.VIDEO_ENABLED,
    OpentokEventType.SIGNAL_RECEIVED,
    OpentokEventType.ARCHIVE_STARTED,
    OpentokEventType.ARCHIVE_STOPPED,
    OpentokEventType.STREAM_CREATED,
    OpentokEventType.STREAM_DESTROYED
]);

// events that emit continuously, and should be filtered from logs
const LOG_FILTER_EVENTS: Set<OpentokEventType> = new Set([
    OpentokEventType.AUDIO_LEVEL_UPDATED
]);

@Injectable({
    providedIn: 'root',
})
export class OpenTokService {
    private readonly logger = getLogger('OpenTokService');

    private readonly nativeEventSubject = new Subject<OpentokEvent>();
    private readonly nativeEventDelegateProxy: (ev: OpentokEvent) => void;
    private readonly nativeEventDelegateErrorProxy: (error: any) => void;

    private OTsession: any;
    private OTpublisher: any;
    private OTsubscriber: any;
    private delegateInitializeRetryCount: number = 0;

    public readonly nativeEvents$: Observable<OpentokEvent>;

    constructor(
        private communication: CommunicationService,
        private insomnia: Insomnia,
        private platform: Platform
    ) {
        this.nativeEvents$ = this.nativeEventSubject.asObservable();
        this.nativeEventDelegateProxy = this.onNativeEvent.bind(this);
        this.nativeEventDelegateErrorProxy = this.onNativeEventDelegateError.bind(this);
        this.initNotificationListeners();
    }

    private onNativeEvent(ev: OpentokEvent): void {
        const message = `onNativeEvent`;
        if (HIGH_PRIORITY_EVENTS.has(ev?.type)) {
            this.logger.debug(message, ev);
        } else if (!LOG_FILTER_EVENTS.has(ev?.type)) {
            this.logger.trace(message, ev);
        }
        this.nativeEventSubject.next(ev);
    }

    private onNativeEventDelegateError(error: any): void {
        this.logger.error(`onNativeEventDelegateError -> ${error}`);
        const maxAttempts = 3;

        // prevent infinite loops from potential exceptions
        if (this.delegateInitializeRetryCount > maxAttempts) {
            this.logger.warn(`max retry attempts exceeded! (${maxAttempts})`);
            return;
        }

        this.delegateInitializeRetryCount++;
        this.logger.error(`retry = ${this.delegateInitializeRetryCount} out of ${maxAttempts})`);
        this.initializeNativeEventDelegateAsync();
    }

    private initNotificationListeners() {
        this.logger.debug(`initNotificationListeners()`);
        this.initializeNativeEventDelegateAsync();
        // End current call and join incoming call
        // event listener, call in progress, call pending in background
        this.communication.exitVideoCallEnterNew$.subscribe(() => {
            this.logger.debug(`initNotificationListeners() End current call and join incoming call`);
            // need to tell the current call to end
            if (this.hasActiveSession()) { // OpenTok call in progress
                this.closeVideoCall();
            }
        });
    }

    private initializeNativeEventDelegateAsync(): void {
        this.logger.trace(`initializeNativeEventDelegateAsync()`);
        this.initializeNativeEventDelegate().catch((err) => {
            this.logger.error(`initializeNativeEventDelegate() failed! -> ${err}`);
        });
    }

    private async initializeNativeEventDelegate(): Promise<void> {
        this.logger.trace(`initializeNativeEventDelegate()`);
        await this.platform.ready();

        if (!this.platform.is('android')) {
            return;
        }

        this.logger.trace(`initializeNativeEventDelegate() registering shared event listener...`);
        OT.setSharedEventListener(
            this.nativeEventDelegateProxy,
            this.nativeEventDelegateErrorProxy
        );
    }

    public hasActiveSession(): boolean {
        const activeOTSession: boolean = !!this.OTsession;
        this.logger.debug(`hasActiveSession(): ${activeOTSession}`);
        return activeOTSession;
    }

    public getPublisher(): any {
        this.logger.debug(`getPublisher()`);
        return this.OTpublisher;
    }

    public updateViews(): void {
        OT.updateViews();
    }

    public initVideoCall(apiKey: string, sessionId: string, callToken: string, domPublisherElId: string, domSubscriberElId: string) {
        this.logger.debug(`initVideoCall`);
        this.OTsession = OT.initSession(apiKey, sessionId);
        this.OTpublisher = OT.initPublisher(domPublisherElId);
        this.initOTEventListeners(domSubscriberElId);
        this.connectSession(callToken);
        this.communication.setVideoCallActive(VideoCallType.OPENTOK);
        this.keepAwake();
    }

    private initOTEventListeners(domSubscriberElId: string): void {
        this.logger.debug(`initEventListeners()`);
        this.OTsession.on({
            streamCreated: (event) => {
                this.logger.debug(`initOTEventListeners() -> streamCreated`, event);
                // callee answered
                this.communication.notifyVideoCallConnected({
                    callId: this.communication.videoCallId,
                    id: this.communication.videoParticipantId
                });
                // displays callees stream
                if (this.OTsubscriber == null) {
                    this.logger.phic.debug(`subscribing to created stream...`);
                    this.OTsubscriber = this.OTsession.subscribe(event.stream, domSubscriberElId, {width: '100%', height: '81vh'});
                } else {
                    this.logger.phic.warn('initOTEventListeners() Session was already publishing so did not subscribe again');
                }
                OT.updateViews();
            },
            streamDestroyed: (event) => {
                // the call was ended by the other party
                this.logger.debug(`initOTEventListeners() -> streamDestroyed`, event);
                // notify VideoPage - needed for iOS
                this.communication.notifyCallerLeft({
                    callId: this.communication.videoCallId,
                    id: this.communication.videoParticipantId
                });
                this.closeVideoCall();
            },
            sessionConnected: (event) => {
                this.logger.debug(`initOTEventListeners() -> sessionConnected`, event);
                this.OTsession.publish(this.OTpublisher);
                this.communication.updateParticipantStatus('active');
            },
            sessionDisconnected: (event) => {
                // the call was ended by either party - needed to complete cleanup when patient disconnects
                this.logger.debug(`initOTEventListeners() -> sessionDisconnected`, event);
                // video call type is set to NONE if streamDestroyed is called first
                if (this.communication.videoCallType() === VideoCallType.OPENTOK) {
                    // notify VideoPage - needed for iOS
                    this.communication.notifyCallerLeft({
                        callId: this.communication.videoCallId,
                        id: this.communication.videoParticipantId
                    });
                    this.closeVideoCall();
                }
            }
        });
    }

    private connectSession(token: string): void {
        this.logger.debug(`connectSession()`);
        this.OTsession.connect(token, (error) => {
            this.logger.debug(`connectSession() -> session.connect`, {error});
            if (error) {
                this.logger.phic.error('Error connecting opentok session', error);
            }
        });
    }

    public closeVideoCall(): void {
        this.logger.debug(`closeVideoCall`);
        if (this.hasActiveSession()) {
            this.communication.setVideoCallInactive();
            this.logger.debug(`disconnecting current OpenTok session`);
            this.OTsession.disconnect();
            this.OTpublisher.destroy();
            this.OTsession = null;
            this.OTpublisher = null;
            this.OTsubscriber = null;
            this.communication.updateParticipantStatus('left');
            this.allowSleep();
            OT.updateViews();
        }
    }

    // Used to keep the users screen on during opentok calls
    private keepAwake(): void {
        this.logger.debug(`keepAwake()`);
        this.insomnia.keepAwake().then(() => {
            this.logger.log('keepAwake() Keeping device display awake');
        }, (err) => {
            this.logger.phic.error('keepAwake() Failed to keep device display awake: ' + err);
        });
    }

    // allow the device to sleep after an opentok call ends
    private allowSleep(): void {
        this.logger.debug(`allowSleep()`);
        this.insomnia.allowSleepAgain().then(() => {
            this.logger.log('allowSleep() Allowing device sleep');
        }, (err) => {
            this.logger.phic.error('allowSleep() Failed to allow device display sleep: ' + err);
        });
    }
}
