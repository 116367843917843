<hrs-content>
    <ng-container *ngIf="downloading">
        <div class="ion-margin-top"></div>
        <div class="ion-margin-top"></div>
        <app-skeleton-content class="ion-margin-top"></app-skeleton-content>
    </ng-container>
    <pdf-viewer [src]="src"
                [page]="1"
                [render-text]="true"
                [original-size]="false"
                [zoom]="zoomState"
                [zoom-scale]="zoomScale"
                (after-load-complete)="afterLoadComplete($event)"
                (error)="onError($event)"
    ></pdf-viewer>
</hrs-content>
<hrs-footer variant="two-button">
    <hrs-button
        icon="zoom-out"
        variant="primary"
        [disabled]="this.zoomState <= 0.5"
        [text]="'PDF.ZOOM_OUT' | translate"
        (click)="zoomOut()"
    ></hrs-button>
    <hrs-button
        icon="zoom-in"
        variant="primary"
        [text]="'PDF.ZOOM_IN' | translate"
        (click)="zoomIn()"
    ></hrs-button>
</hrs-footer>
