import {Injectable, NgZone} from '@angular/core';
import {HRSStorage} from '../../storage/storage';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {AnDClassicService} from './andclassic.service';
import {ModalService} from '@hrs/providers';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {HRSFirebaseAnalytics} from '../../analytics/firebaseanalytics.service';
import {HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

/**
 * This class is the service class for AnD Classic BP Monitor 767. It inherits AnD Classic base service for all the
 * common features and specializes some behavior related to bp monitor.
 */
@Injectable({
    providedIn: 'root',
})
export class AnDClassicBPService extends AnDClassicService {
    private readonly logger = getLogger('AnDClassicBPService');

    constructor(
        bluetoothSerial: BluetoothSerial,
        storage: HRSStorage,
        ngZone: NgZone,
        fbanalytics: HRSFirebaseAnalytics,
        modalService: ModalService,
    ) {
        super(
            bluetoothSerial,
            storage,
            ngZone,
            fbanalytics,
            modalService
        );
    }

    /**
     * Returns the reading data size for AnD classic BP monitor 767
     * @returns readingData size
     */
    public getDataSize(): number {
        return 70;
    }

    /**
     * Process the data obtained from AnD Device
     * @param rxPacket
     * @param data
     * @param peripheral
     */
    public processData(rxPacket: Uint8Array, peripheral: any): void {
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'bloodpressure');
        // Check whether it is a recent reading or an old one
        this.isRecentReading = this.isRecentReadingRecieved(rxPacket, peripheral);

        // Process data header section
        this.processDataHeaderSection(rxPacket);

        // Process data reading section
        let bpMonitorData = BluetoothUtils.getStringValueFromByteArray(rxPacket, 60, 71); // from 60th byte we get the bp monitor data. Data is of lenght 10

        let measurementStatus = bpMonitorData.substring(0, 2);
        this.logger.phic.debug('AnD 767 BP: Measurement status ' + measurementStatus);

        let sysdiffstr = bpMonitorData.substring(2, 4);
        let diastr = bpMonitorData.substring(4, 6);
        let hrstr = bpMonitorData.substring(6, 8);
        let sysdiff = parseInt(sysdiffstr, 16);
        let dia = parseInt(diastr, 16);
        let sys = dia + sysdiff;
        let hr = parseInt(hrstr, 16);

        if (sys == null || dia == null || hr == null || sys == 0 || dia == 0 || hr == 0) {
            this.logger.phic.debug('AnD 767 BP: Bad/ERR reading: No values recieved');
            this.readingErrorOccurred = true;
            this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.INCORRECT_DEVICE_DATA});
        } else {
            if (this.isRecentReading) {
                this.logger.phic.debug('AnD 767 BP Measurement Recieved: Systolic ' + sys + ' Diastolic ' + dia + ' Heart Rate ' + hr);
                this.metricUpdateCallback(sys, dia, hr, peripheral);
                this.gotRecentReading = true;
            } else {
                this.logger.phic.debug('AnD 767 BP: Discarding old reading');
                this.historicalReadingObtained = true;
            }
        }
    }

    /**
    * Callback function when metrics are updated
    */
    public metricUpdateCallback: (sys: number, dias: number, hr: number, peripheral: any) => void;

    public onConnected(peripheral: any, onMetricUpdateCallback: (sys: number, dias: number, hr: number, peripheral: any) => void): void {
        this.metricUpdateCallback = onMetricUpdateCallback;
        this.onPeripheralConnected(peripheral);
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        // cleanup
        this.gotRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }
}
