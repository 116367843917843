import {isInteger} from 'lodash';
import {Task} from './task.model';
import {TaskType} from './task-type.enum';

export class ActivityTask extends Task {
    public type = TaskType.Activity;
    public goal: number;

    public duration: number = 0;

    public set(data: Partial<ActivityTask>): this {
        Object.assign(this, data);
        return this;
    }

    public hasMetGoal(): boolean {
        return !(isInteger(this.goal)) || (isInteger(this.duration) && this.duration >= this.goal);
    }

    public isCompleted(checkGoal? : boolean): boolean {
        const hasMetGoal = checkGoal ? this.hasMetGoal() : true;
        return super.isCompleted() && hasMetGoal;
    }
}
