
import {BuildUtility} from '@hrs/utility';
import {Injectable} from '@angular/core';

@Injectable()
export class TabletGuard {
    constructor() {}

    canActivate(): boolean {
        return BuildUtility.isHRSTab();
    }
}
