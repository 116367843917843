import {AdminService} from './admin/admin.service';
import {AudioService} from './audio/audio.service';
import {BloodPressureDeviceService} from './device/bloodpressure.service';
import {CareplanChangeService} from './care-plan-change/care-plan-change.service';
import {ConsentFormService} from './consent-form';
import {DeviceService} from './device/device.service';
import {DomService} from './overlay/dom.service';
import {EducationFileService} from './education/education-file.service';
import {EducationService} from './education/education.service';
import {EducationTrackingService} from './education/education-tracking.service';
import {EnvironmentService} from './environment/environment.service';
import {EventService} from './events/event.service';
import {FirebaseNotifications} from './firebase/firebase';
import {FitbitService} from './fitbit/fitbit.service';
import {GlucoseDeviceService} from './device/glucose.service';
import {HRSSecureCache} from './storage/cache';
import {HRSStorage} from './storage/storage';
import {LanguageService} from './language/language.service';
import {LogUploadService} from './logs/log-upload.service';
import {LogUploadViewControllerService} from './logs/log-upload-view-controller.service';
import {MenuService} from './menu/menu.service';
import {NativeLoggerService} from './logs/native-logger.service';
import {OfflineTaskService} from './tasks/task-offline.service';
import {OpenTokService} from './opentok/opentok.service';
import {OVERLAY_DURATION_INFINITE, OverlayService} from './overlay/overlay.service';
import {PulseOxDeviceService} from './device/pulseox.service';
import {Settings} from './settings/settings.service';
import {SignalStrengthService} from './signal-strength/signal-strength.service';
import {SupportCallService} from './support-call/support-call.service';
import {TabletSettingsService} from './tablet-settings/tablet-settings.service';
import {TaskService} from './tasks/task.service';
import {TaskTrackingService} from './task-tracking/task-tracking.service';
import {TemperatureDeviceService} from './device/temperature.service';
import {TextToSpeechService} from './text-to-speech/text-to-speech.service';
import {User} from './user/user.service';
import {WeightDeviceService} from './device/weight.service';
import {WelchBPService} from './device/welch/welch.bloodpressure.service';
import {ZoomService} from './zoom/zoom.service';
import {UserAgentProvider} from './user-agent/user-agent';

export {
    AudioService,
    AdminService,
    BloodPressureDeviceService,
    CareplanChangeService,
    ConsentFormService,
    DeviceService,
    DomService,
    EducationFileService,
    EducationService,
    EducationTrackingService,
    EnvironmentService,
    EventService,
    FirebaseNotifications,
    FitbitService,
    GlucoseDeviceService,
    HRSSecureCache,
    HRSStorage,
    LanguageService,
    LogUploadService,
    LogUploadViewControllerService,
    MenuService,
    NativeLoggerService,
    OfflineTaskService,
    OpenTokService,
    OVERLAY_DURATION_INFINITE,
    OverlayService,
    PulseOxDeviceService,
    Settings,
    SignalStrengthService,
    SupportCallService,
    TabletSettingsService,
    TaskService,
    TaskTrackingService,
    TemperatureDeviceService,
    TextToSpeechService,
    User,
    UserAgentProvider,
    WeightDeviceService,
    WelchBPService,
    ZoomService
};
export * from './consent-form';
export * from './schedule';
// export * from './tasks';
