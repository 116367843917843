import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KioskIntentService} from '../knox-service-intents/kiosk-intent.service';
import {OverlayService} from '@patient/providers';
import {OverlayRef} from '../../hrs-overlay';

@Component({
    selector: 'kiosk-settings',
    templateUrl: './kiosk-settings.component.html',
    styleUrls: ['./kiosk-settings.component.scss'],
})
export class KioskSettingsComponent implements OnInit {
    constructor(
        private kiosk: KioskIntentService,
        private overlay: OverlayService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    // lock and unlock the tablet when in kiosk-mode
    public toggleLock(event: any): void | Error {
        if (event && event.detail && event.detail.value) {
            const lockStatus = event.detail.value;
            if (lockStatus === 'locked') {
                this.kiosk.enableKioskMode().then(() => {
                    this.presentToast(lockStatus);
                });
            } else if (lockStatus === 'unlocked') {
                this.kiosk.disableKioskMode().then(() => {
                    this.presentToast(lockStatus);
                });
            }
        } else {
            throw new Error('Error during toggleLock');
        }
    }

    // locked / unlocked toast messages
    private async presentToast(lockStatus: 'locked' | 'unlocked'): Promise<OverlayRef> {
        let lockStatusText = this.translate.instant(lockStatus === 'locked' ? 'ADMIN_KIOSK_LOCKED' : 'ADMIN_KIOSK_UNLOCKED');
        return await this.overlay.openToast({
            header: lockStatusText,
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'kiosk_toast'
        });
    }
}
