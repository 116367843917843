import {Injectable, OnDestroy} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {Subscription} from 'rxjs';
import {isFunction, isNumber, isObject, isString, pick} from 'lodash';
import {CommunicationService, VideoCallType, VideoCallStatus, VoiceCallStatus, CallActionOrigin} from '@hrs/providers';
import {HRSFirebaseAnalytics, HRSFirebaseEventData, HRSFirebaseEvents, HRSFirebaseParams} from './firebaseanalytics.service';
import {OpenTokService, OpentokEvent, OpentokEventType} from '../opentok/opentok.service';
import {ZoomService, ZoomEvent, ZoomEventType} from '../zoom/zoom.service';
import {TwilioService, TwilioEvent, TwilioEventType} from '../twilio/twilio.service';
import {DeviceProfileService} from '../../hrs-tablet/device-profile/device-profile.service';

const MAX_CALL_DURATION_MS = 2 * 24 * 60 * 60 * 1000; // 2 days

export enum CommunicationAnalyticsProvider {
    ZOOM = 'zoom',
    OPENTOK = 'opentok',
    TWILIO = 'twilio'
}

type EventHandler<T> = (data: T) => void;

interface CallMap<T> {
  [key: string]: EventHandler<T>;
}

interface PluginEventCacheData<T> {
    capturedEvent: T;
    timestamp: number;
}

interface PluginEventCache<T> {
    [eventName: string]: PluginEventCacheData<T>;
}

function routeEvent<T>(callMap: CallMap<T>, eventName: string, data: T): void {
    if (isFunction(callMap[eventName])) {
        callMap[eventName](data);
    }
}

function cacheEvent<T>(cache: PluginEventCache<T>, eventName: string, data: T): void {
    let cacheData = cache[eventName];
    if (!cacheData) {
        cacheData = cache[eventName] = {} as any;
    }
    cacheData.capturedEvent = data;
    cacheData.timestamp = Date.now();
}

function handleNativeEvent<T>(callMap: CallMap<T>, cache: PluginEventCache<T>, eventName: string, data: T): void {
    routeEvent(callMap, eventName, data);
    cacheEvent(cache, eventName, data);
}

function serializeCallEventData(ev: any): string {
    return JSON.stringify(pick(ev, ['type', 'action']));
}

function millisecondsToSeconds(millis: number): number {
    return Math.ceil(millis / 1000);
}

function extractCallId(data: any): any {
    if (!data) return '';
    if (isString(data.callid) || isNumber(data.callid)) return data.callid + '';
    if (isString(data.callId) || isNumber(data.callId)) return data.callId + '';
    if (isObject(data.data)) return extractCallId(data.data);
    return '';
}

function extractVideoProvider(currentProvider: VideoCallType, data: any): VideoCallType {
    let provider = currentProvider;
    if (provider === VideoCallType.NONE) {
        const isZoomCall = !!(data && typeof(data.type) === 'string' && data.type.includes('zoom'));
        provider = isZoomCall ? VideoCallType.ZOOM : VideoCallType.OPENTOK;
    }
    return provider;
}

function sanitizeZoomVideoQuality(quality: string): string {
    return quality.replace('VideoQuality_', ''); // strip prefix
}

@Injectable({
    providedIn: 'root'
})
export class CommunicationAnalyticsService implements OnDestroy {
    private readonly logger = getLogger('CommunicationAnalyticsService');

    private readonly opentokEventCache: PluginEventCache<OpentokEvent>;
    private readonly opentokCallMap: CallMap<OpentokEvent>;

    private readonly zoomEventCache: PluginEventCache<ZoomEvent>;
    private readonly zoomCallMap: CallMap<ZoomEvent>;

    private readonly twilioEventCache: PluginEventCache<TwilioEvent>;
    private readonly twilioCallMap: CallMap<TwilioEvent>;

    private initialized: boolean = false;
    private activeSubscriptions: Subscription[] = [];
    private lastCallInitiationType: CallActionOrigin = CallActionOrigin.NONE;

    // video call tracking properties
    private lastVideoCallType: VideoCallType = VideoCallType.NONE;
    private lastVideoCallStatus: VideoCallStatus = VideoCallStatus.NONE;
    private lastVideoCallId: any = null;
    private videoCallStartTime: number = -1;

    // voice call tracking properties
    private lastVoiceCallStatus: VoiceCallStatus = VoiceCallStatus.NONE;
    private lastVoiceCallId: any = null;
    private voiceCallStartTime: number = -1;

    constructor(
    private readonly communicationService: CommunicationService,
    private readonly analytics: HRSFirebaseAnalytics,
    private readonly opentok: OpenTokService,
    private readonly zoom: ZoomService,
    private readonly twilio: TwilioService,
    private readonly deviceProfileService: DeviceProfileService
    ) {
        this.opentokEventCache = {};
        this.opentokCallMap = {
            [OpentokEventType.VIDEO_DISABLED]: (ev) => this.onOpentokProgress(ev),
            [OpentokEventType.VIDEO_DISABLE_WARNING]: (ev) => this.onOpentokProgress(ev),
            [OpentokEventType.VIDEO_DISABLE_WARNING_LIFTED]: (ev) => this.onOpentokProgress(ev),
            [OpentokEventType.SESSION_ERROR]: (ev) => this.onOpentokError(ev),
        };
        this.zoomEventCache = {};
        this.zoomCallMap = {
            [ZoomEventType.USER_NETWORK_QUALITY_CHANGED]: (ev) => this.onZoomProgress(ev),
            [ZoomEventType.SINK_MEETING_VIDEO_QUALITY_CHANGED]: (ev) => this.onZoomProgress(ev),
            [ZoomEventType.MEETING_LEAVE_COMPLETE]: (ev) => this.onZoomCallComplete(ev),
            [ZoomEventType.MICROPHONE_STATUS_ERROR]: (ev) => this.onZoomError(ev),
            [ZoomEventType.MEETING_FAIL]: (ev) => this.onZoomError(ev),
            [ZoomEventType.AUTH_IDENTITY_EXPIRED]: (ev) => this.onZoomError(ev),
            [ZoomEventType.IDENTITY_EXPIRED]: (ev) => this.onZoomError(ev),
            [ZoomEventType.INVALID_RECLAIM_HOST_KEY]: (ev) => this.onZoomError(ev),
            [ZoomEventType.SDK_INITIALIZE_AUTH_IDENTITY_EXPIRED]: (ev) => this.onZoomError(ev),
        };
        this.twilioEventCache = {};
        this.twilioCallMap = {
            [TwilioEventType.CLIENT_REGISTER_ERROR]: (ev) => this.onTwilioError(ev),
            [TwilioEventType.CALL_CONNECT_FAILURE]: (ev) => this.onTwilioError(ev),
        };
    }

    public get activeSubscriptionCount(): number {
        return this.activeSubscriptions.length;
    }

    public initialize(): void {
        this.logger.debug(`initialize()`);

        if (this.initialized) {
            this.logger.info(`skipping initialization (already initialized)`);
            return;
        }

        this.initialized = true;

        this.activeSubscriptions = [
            this.opentok.nativeEvents$.subscribe((ev) => handleNativeEvent(this.opentokCallMap, this.opentokEventCache, ev.type, ev)),
            this.zoom.nativeEvents$.subscribe((ev) => handleNativeEvent(this.zoomCallMap, this.zoomEventCache, ev.type, ev)),
            this.twilio.nativeEvents$.subscribe((ev) => handleNativeEvent(this.twilioCallMap, this.twilioEventCache, ev.type, ev)),
            this.communicationService.activeVideoCallChange$.subscribe((ev) => this.onAppVideoCallTypeChange(ev)),
            this.communicationService.activeVideoCallStatusChange$.subscribe((ev) => this.onAppVideoCallStatusChange(ev)),
            this.communicationService.activeVoiceCallChange$.subscribe((ev) => this.onAppVoiceCallStatusChange(ev)),
            this.communicationService.activeCallInitiationType$.subscribe((ev) => this.onAppCallInitiationTypeChange(ev)),
            this.communicationService.incomingVideoCall$.subscribe((ev) => this.onAppIncomingVideoCall(ev)),
            this.communicationService.videoCallConnected$.subscribe((ev) => this.onAppVideoCallConnected(ev)),
            this.communicationService.videoCallDeclinedLocally$.subscribe((ev) => this.onAppVideoCallDeclinedLocally(ev)),
            this.communicationService.remoteVideoCalleeDidNotRespond$.subscribe((ev) => this.onAppRemoteVideoCallNotAnswered(ev)),
            this.communicationService.videoCallEndedLocally$.subscribe((ev) => this.onAppVideoCallEndedLocally(ev)),
            this.communicationService.remoteVideoCalleeLeft$.subscribe((ev) => this.onAppVideoCallEndedRemotely(ev)),
            this.communicationService.incomingVoiceCall$.subscribe((ev) => this.onAppIncomingVoiceCall(ev)),
            this.communicationService.voiceCallDeclinedLocally$.subscribe((ev) => this.onAppVoiceCallDeclinedLocally(ev)),
            this.communicationService.voiceCallAnsweredLocally$.subscribe((ev) => this.onAppVoiceCallAnsweredLocally(ev)),
            this.communicationService.voiceCallStartedLocally$.subscribe((ev) => this.onAppVoiceCallStartedLocally(ev)),
            this.communicationService.remoteVoiceCalleeDidNotRespond$.subscribe((ev) => this.onAppRemoteVoiceCallNotAnswered(ev)),
            this.communicationService.voiceCallEndedLocally$.subscribe((ev) => this.onAppVoiceCallEndedLocally(ev)),
            this.communicationService.remoteVoiceCalleeLeft$.subscribe((ev) => this.onAppVoiceCallEndedRemotely(ev)),
            this.communicationService.newChatMessage$.subscribe((ev) => this.onAppNewChatMessage(ev)),
            this.communicationService.chatMessageSent$.subscribe((ev) => this.onAppChatMessageSent(ev)),
            this.communicationService.chatMessageSendError$.subscribe((err) => this.onAppChatMessageSendError(err)),
        ];
    }

    public ngOnDestroy(): void {
        for (const sub of this.activeSubscriptions) {
            try {
                sub?.unsubscribe();
            } catch {}
        }

        this.activeSubscriptions = [];
        this.initialized = false;
    }

    private logEvent(name: string, params: HRSFirebaseEventData): void {
        this.normalizeParams(name, params)
            .then((normalizedParams: HRSFirebaseEventData) => {
                this.logger.debug(`LOG EVENT = "${name}" with params =`, normalizedParams);
                this.analytics.logEvent(name, normalizedParams);
            })
            .catch((err) => {
                this.logger.error(`failed to post log event`, err);
            });
    }

    private async normalizeParams(name: string, params: HRSFirebaseEventData): Promise<HRSFirebaseEventData> {
        switch (name) {
            case HRSFirebaseEvents.CALL_START:
                await this.injectNetworkInfoParams(params);
                break;
            case HRSFirebaseEvents.CALL_PROGRESS:
            case HRSFirebaseEvents.CALL_END:
            case HRSFirebaseEvents.CALL_ERROR:
                await this.injectNetworkInfoParams(params);
                this.injectCallDurationParam(params);
                break;
            default:
                break;
        }

        return params;
    }

    private async injectNetworkInfoParams(params: HRSFirebaseEventData): Promise<void> {
        const networkInfo = await this.deviceProfileService.getNetworkData().catch((err) => {
            this.logger.warn(`injectNetworkInfoParams() failed to load network data`, err);
            return {} as any;
        });

        const {strength, type, level} = networkInfo;

        if (type) {
            params[HRSFirebaseParams.NETWORK_TYPE] = type;
        }

        if (isNumber(strength)) {
            params[HRSFirebaseParams.SIGNAL_STRENGTH] = strength;
        }

        if (isNumber(level)) {
            params[HRSFirebaseParams.SIGNAL_LEVEL] = level;
        }
    }

    private injectCallDurationParam(params: HRSFirebaseEventData): void {
        const provider = params[HRSFirebaseParams.PROVIDER];
        let duration = 0;

        switch (provider) {
            case CommunicationAnalyticsProvider.OPENTOK:
            case CommunicationAnalyticsProvider.ZOOM:
                duration = Date.now() - this.videoCallStartTime;
                break;
            case CommunicationAnalyticsProvider.TWILIO:
                duration = Date.now() - this.voiceCallStartTime;
                break;
        }

        // If the evaluated duration is ridiculously long, it most likely indicates a
        // CALL_END event that does not have a matching CALL_START event.
        // In that case, clamp it back to zero.
        if (duration > MAX_CALL_DURATION_MS) {
            duration = 0;
        }

        params[HRSFirebaseParams.DURATION] = millisecondsToSeconds(duration);
    }

    // //////////////////////////////////////////////////////
    // Custom App Event Callbacks
    // //////////////////////////////////////////////////////

    private onAppCallInitiationTypeChange(type: CallActionOrigin): void {
        this.logger.trace(`onAppCallInitiationTypeChange() ${type}`);
        if (type !== CallActionOrigin.NONE) {
            this.lastCallInitiationType = type;
        }
    }

    private onAppVideoCallTypeChange(type: VideoCallType): void {
        this.logger.trace(`onAppVideoCallTypeChange() ${type}`);
        if (type !== VideoCallType.NONE) {
            this.lastVideoCallType = type;
        }
    }

    private onAppVideoCallStatusChange(status: VideoCallStatus): void {
        this.logger.trace(`onAppVideoCallStatusChange() ${status}`);
        if (status !== VideoCallStatus.NONE) {
            this.lastVideoCallStatus = status;
        }
    }

    private onAppIncomingVideoCall(data: any): void {
        this.logger.trace(`onAppIncomingVideoCall()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_PENDING, {
            [HRSFirebaseParams.PROVIDER]: extractVideoProvider(this.lastVideoCallType, data),
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.META_DATA]: serializeCallEventData(data),
        });
    }

    private onAppVideoCallConnected(data: any): void {
        this.logger.trace(`onAppVideoCallConnected()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.videoCallStartTime = Date.now();
        this.logEvent(HRSFirebaseEvents.CALL_START, {
            [HRSFirebaseParams.PROVIDER]: this.lastVideoCallType,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.STATE]: this.lastVideoCallStatus,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
        });
    }

    private onAppVideoCallDeclinedLocally(data: any): void {
        this.logger.trace(`onAppVideoCallDeclinedLocally()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_DECLINED, {
            [HRSFirebaseParams.PROVIDER]: this.lastVideoCallType,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onAppRemoteVideoCallNotAnswered(data: any): void {
        this.logger.trace(`onAppRemoteVideoCallNotAnswered()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_DECLINED, {
            [HRSFirebaseParams.PROVIDER]: this.lastVideoCallType,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.REMOTE,
            [HRSFirebaseParams.REASON]: data?.action,
            [HRSFirebaseParams.META_DATA]: serializeCallEventData(data),
        });
    }

    private onAppVideoCallEndedLocally(data: any): void {
        this.logger.trace(`onAppVideoCallEndedLocally()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_END, {
            [HRSFirebaseParams.PROVIDER]: this.lastVideoCallType,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onAppVideoCallEndedRemotely(data: any): void {
        this.logger.trace(`onAppVideoCallEndedRemotely()`, data);
        this.lastVideoCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_END, {
            [HRSFirebaseParams.PROVIDER]: this.lastVideoCallType,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.REMOTE,
        });
    }

    private onAppVoiceCallStatusChange(status: VoiceCallStatus): void {
        this.logger.trace(`onAppVoiceCallStatusChange() ${status}`);
        if (status !== VoiceCallStatus.NONE) {
            this.lastVoiceCallStatus = status;
        }
    }

    private onAppIncomingVoiceCall(data: any): void {
        this.logger.trace(`onAppIncomingVoiceCall()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_PENDING, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.META_DATA]: serializeCallEventData(data),
        });
    }

    private onAppVoiceCallDeclinedLocally(data: any): void {
        this.logger.trace(`onAppVoiceCallDeclinedLocally()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_DECLINED, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.STATE]: this.lastVoiceCallStatus,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onAppVoiceCallAnsweredLocally(data: any): void {
        this.logger.trace(`onAppVoiceCallAnsweredLocally()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.voiceCallStartTime = Date.now();
        this.logEvent(HRSFirebaseEvents.CALL_START, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.STATE]: this.lastVoiceCallStatus,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
        });
    }

    private onAppVoiceCallStartedLocally(data: any): void {
        this.logger.trace(`onAppVoiceCallStartedLocally()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.voiceCallStartTime = Date.now();
        this.logEvent(HRSFirebaseEvents.CALL_START, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.STATE]: this.lastVoiceCallStatus,
            [HRSFirebaseParams.INITIATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onAppRemoteVoiceCallNotAnswered(data: any): void {
        this.logger.trace(`onAppRemoteVoiceCallNotAnswered()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_DECLINED, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.REMOTE,
            [HRSFirebaseParams.REASON]: data?.action,
            [HRSFirebaseParams.META_DATA]: serializeCallEventData(data),
        });
    }

    private onAppVoiceCallEndedLocally(data: any): void {
        this.logger.trace(`onAppVoiceCallEndedLocally()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_END, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onAppVoiceCallEndedRemotely(data: any): void {
        this.logger.trace(`onAppVoiceCallEndedRemotely()`, data);
        this.lastVoiceCallId = extractCallId(data);
        this.logEvent(HRSFirebaseEvents.CALL_END, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.REMOTE,
        });
    }

    private onAppNewChatMessage(data: any): void {
        this.logger.trace(`onAppNewChatMessage()`, data);
        this.logEvent(HRSFirebaseEvents.CHAT_MESSAGE_RECEIVED, {});
    }

    private onAppChatMessageSent(data: any): void {
        this.logger.trace(`onAppChatMessageSent()`, data);
        this.logEvent(HRSFirebaseEvents.CHAT_MESSAGE_SENT, {});
    }

    private onAppChatMessageSendError(data: any): void {
        this.logger.trace(`onAppChatMessageSendError()`, data);
        this.logEvent(HRSFirebaseEvents.CHAT_MESSAGE_SEND_ERROR, {
            [HRSFirebaseParams.META_DATA]: JSON.stringify(data)
        });
    }

    // //////////////////////////////////////////////////////
    // OPENTOK Callbacks
    // //////////////////////////////////////////////////////

    private onOpentokProgress(ev: OpentokEvent): void {
        this.logger.trace(`onOpentokProgress()`, ev);
        this.logEvent(HRSFirebaseEvents.CALL_PROGRESS, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.OPENTOK,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.META_DATA]: JSON.stringify(ev)
        });
    }

    private onOpentokError(ev: OpentokEvent): void {
        this.logger.trace(`onOpentokError()`, ev);
        this.logEvent(HRSFirebaseEvents.CALL_ERROR, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.OPENTOK,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.REASON]: ev.type,
            [HRSFirebaseParams.META_DATA]: JSON.stringify(ev)
        });
    }

    // //////////////////////////////////////////////////////
    // ZOOM Callbacks
    // //////////////////////////////////////////////////////

    private onZoomProgress(ev: ZoomEvent): void {
        this.logger.trace(`onZoomProgress()`, ev);
        const eventData: HRSFirebaseEventData = {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.ZOOM,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.META_DATA]: JSON.stringify(ev)
        };
        if (ev?.data?.videoQuality) {
            eventData[HRSFirebaseParams.CALL_QUALITY] = sanitizeZoomVideoQuality(ev.data.videoQuality);
        }
        this.logEvent(HRSFirebaseEvents.CALL_PROGRESS, eventData);
    }

    private onZoomCallComplete(ev: ZoomEvent): void {
        this.logger.trace(`onZoomCallComplete()`, ev);
        this.logEvent(HRSFirebaseEvents.CALL_END, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.ZOOM,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.TERMINATOR]: CallActionOrigin.LOCAL,
        });
    }

    private onZoomError(ev: ZoomEvent): void {
        this.logger.trace(`onZoomError()`, ev);
        this.logEvent(HRSFirebaseEvents.CALL_ERROR, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.ZOOM,
            [HRSFirebaseParams.ID]: this.lastVideoCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.REASON]: ev.type,
            [HRSFirebaseParams.META_DATA]: JSON.stringify(ev)
        });
    }

    // //////////////////////////////////////////////////////
    // TWILIO Callbacks
    // //////////////////////////////////////////////////////

    private onTwilioError(ev: TwilioEvent): void {
        this.logger.trace(`onTwilioError()`, ev);
        this.logEvent(HRSFirebaseEvents.CALL_ERROR, {
            [HRSFirebaseParams.PROVIDER]: CommunicationAnalyticsProvider.TWILIO,
            [HRSFirebaseParams.ID]: this.lastVoiceCallId,
            [HRSFirebaseParams.INITIATOR]: this.lastCallInitiationType,
            [HRSFirebaseParams.REASON]: ev.type,
            [HRSFirebaseParams.META_DATA]: JSON.stringify(ev)
        });
    }
}
