import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, Observable, Subscription, catchError, defer, finalize, first, switchMap, timer} from 'rxjs';
import {getLogger} from '@hrs/logging';
import {LogUploadService} from './log-upload.service';
import {spinnerAction} from 'src/app/utility/loading-spinner-action';
import {OverlayService} from '../overlay/overlay.service';
import {EventService} from '../events/event.service';
import {retryBackoff} from 'backoff-rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogUploadViewControllerService {
    private readonly logger = getLogger('LogUploadViewControllerService');
    private mRemoteTriggerSubscription: Subscription | null = null;
    private mAutoRetrySubscription: Subscription | null = null;

    constructor(
        private readonly loadingController: LoadingController,
        private readonly translateService: TranslateService,
        private readonly logUploadService: LogUploadService,
        private readonly overlayService: OverlayService,
        private readonly eventService: EventService
    ) {
        this.initialize();
    }

    private clearRemoteTriggerSubscription(): void {
        if (this.mRemoteTriggerSubscription) {
            this.mRemoteTriggerSubscription.unsubscribe();
            this.mRemoteTriggerSubscription = null;
        }
    }

    private clearAutoRetrySubscription(): void {
        if (this.mAutoRetrySubscription) {
            this.mAutoRetrySubscription.unsubscribe();
            this.mAutoRetrySubscription = null;
        }
    }

    private initialize(): void {
        this.logger.trace(`initialize()`);

        this.clearAutoRetrySubscription();
        this.clearRemoteTriggerSubscription();

        this.mRemoteTriggerSubscription = this.eventService.remoteLogDumpTriggered.subscribe(
            () => this.handleRemoteUploadTrigger()
        );
    }

    private beginAutoRetryDelayed(): void {
        this.logger.trace(`beginAutoRetryDelayed()`);
        this.clearAutoRetrySubscription();
        this.mAutoRetrySubscription = this.getUploadWithRetryDelayedStream().subscribe();
    }

    private handleRemoteUploadTrigger(): void {
        this.logger.trace(`handleRemoteUploadTrigger()`);
        this.logUploadService.uploadLogs().catch((e) => {
            this.logger.error(`failed to upload logs from remote trigger! -> ${e}`, e);
        });
    }

    private async retryUploadLogs(): Promise<void> {
        this.logger.trace(`retryUploadLogs()`);
        return this.logUploadService.uploadLogs();
    }

    private async uploadLogsWithSpinner(): Promise<void> {
        this.logger.trace(`uploadLogsWithSpinner()`);
        const uploadAction = () => this.logUploadService.uploadLogs();
        const spinnerOptions = {message: this.translateService.instant('TABLET_SETTINGS.UPLOADING_LOGS')};
        await spinnerAction(this.loadingController, uploadAction, spinnerOptions);
    }

    public getUploadWithRetryDelayedStream(): Observable<any> {
        // delays retry with backoff stream by 10 seconds, so that
        // we don't try again immediately after initial failure
        return timer(10000).pipe(
            switchMap(() => this.getUploadWithRetryStream())
        );
    }

    public getUploadWithRetryStream(): Observable<any> {
        return defer(() => this.retryUploadLogs()).pipe(
            first(),
            retryBackoff({
                initialInterval: 5 * 60 * 1000, // 5 minutes
                maxInterval: 20 * 60 * 1000, // 20 minutes
                maxRetries: 20, // gives us about 6 hours worth of retries
            }),
            catchError((error) => {
                this.logger.error(`uploadWithRetry$ backoff attempts ran out! -> ${error}`, error);
                return EMPTY;
            }),
            finalize(() => this.mAutoRetrySubscription = null)
        );
    }

    public async submitAppLogs(): Promise<void> {
        this.logger.trace(`submitAppLogs()`);
        try {
            this.clearAutoRetrySubscription();
            await this.uploadLogsWithSpinner();
            await this.showLogUploadSuccessDialog();
        } catch (e) {
            await this.showLogUploadErrorDialog(e);
            this.beginAutoRetryDelayed();
        }
    }

    public async showLogUploadSuccessDialog(): Promise<void> {
        this.logger.trace(`showLogUploadSuccessDialog()`);
        await this.overlayService.openToast({
            header: this.translateService.instant('SUBMISSION_SUCCESSFUL'),
            message: [
                this.translateService.instant('LOG_UPLOAD_SUCCESS_MESSAGE')
            ],
            qa: 'log_upload_success_overlay'
        });
    }

    public async showLogUploadErrorDialog(error: any): Promise<void> {
        this.logger.error(`showLogUploadErrorDialog() for error -> ${error}`, error);
        await this.overlayService.openAlert({
            header: this.translateService.instant('SUBMISSION_UNSUCCESSFUL'),
            message: [
                this.translateService.instant('LOG_UPLOAD_ERROR_MESSAGE_PART_1'),
                this.translateService.instant('LOG_UPLOAD_ERROR_MESSAGE_PART_2'),
            ],
            buttons: [
                {text: this.translateService.instant('OK_BUTTON')}
            ],
            qa: 'log_upload_error_overlay'
        });
    }
}
