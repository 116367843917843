import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    Type,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

import {OverlayConfig, OverlayRef, overlayType} from './overlay';
import {Observable, Subject} from 'rxjs';
import {InsertionDirective} from '../directives/dom-insertion.directive';
import {DomService} from '../services/overlay/dom.service';
import {HrsPropClickHandler} from '@hrsui/core/dist/types/utils/_interfaces';
import {
    HrsAlert,
    HrsToast
} from '@hrsui/angular';

@Component({
    selector: 'hrs-overlay',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class OverlayComponent implements OnInit, OnDestroy {
    @ViewChild(InsertionDirective, {static: true}) insertionPoint: InsertionDirective;
    @ViewChild('hrsAlert', {static: true}) alertEl: HrsAlert;
    @ViewChild('hrsToast', {static: true}) toastEl: HrsToast;

    @HostListener('hrsUserSelection', ['$event'])
    async handleOverlayButtonClick({detail: isAffirmativeChoice}) {
        // `hrs-alert` buttons return `true` for primary/affirmative or `false` for secondary/dismissal
        if (isAffirmativeChoice) {
            if (this.alertEl) {
                // @ts-ignore
                await this.alertEl['nativeElement'].dismiss();
            }
            this.ref.close({isAffirmativeChoice});
        } else {
            await this.close();
        }
    }
    private readonly _onClose = new Subject<any>();
    private readonly element: ElementRef;
    private clickListenerRelease: () => void;
    private escListenerRelease: () => void;
    public _active: boolean;
    public childComponentRef: ComponentRef<any>;
    public childComponentType: Type<any>;
    public handler: HrsPropClickHandler;
    public onClose$: Observable<any>;
    public overlayType: overlayType;
    public title: string;

    /**
     * @param config - added dynamically with OverlayInjector
     * @param ref - added dynamically with OverlayInjector
     * @param cd
     *      @see: https://angular.io/api/core/ChangeDetectorRef
     * @param el
     *      @see: https://angular.io/api/core/ElementRef
     * @param dom - HRS DOM Service
     * @param renderer
     *      @see: https://angular.io/api/core/Renderer2
     */
    constructor(
        private cd: ChangeDetectorRef,
        private dom: DomService,
        el: ElementRef,
        private renderer: Renderer2,
        public config: OverlayConfig,
        public ref: OverlayRef,
    ) {
        this._active = true;
        this.onClose$ = this._onClose.asObservable();
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        this.clickListenerRelease = this.renderer.listen(this.element, 'click', (el) => {
            if (el?.target?.parentElement?.isModal) {
                this.dismiss();
            }
        });
        // modals & wizards should close when user presses ESC key
        if (/modal|wizard/.test(this.overlayType)) {
            this.escListenerRelease = this.renderer.listen('document', 'keyup.esc', (el) => {
                this.close();
                return false; // prevent default behavior
            });
        }
        this.renderer.addClass(document.body, 'hrs-overlay-open');
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'hrs-overlay-open');
        if (this.clickListenerRelease) this.clickListenerRelease();
        if (this.escListenerRelease) this.escListenerRelease();
        this._onClose.next(this.element);
    }

    /**
     * Dynamically load ViewChild from child Component Type
     * @param componentType - child component to display within overlay
     */
    public async loadChildComponent(componentType: Type<any>): Promise<void> {
        this.childComponentType = componentType;
        const childComponent = await this.dom.createComponent(componentType, this);
        await this.applyOverlayOptions(this.config.inputs, childComponent);
        this.childComponentRef = childComponent;
    }

    /**
     * Add custom configuration settings from parent Injector and OverlayService to instance
     */
    public async applyOverlayOptions(inputsConfig?, targetComponentRef?: ComponentRef<any>): Promise<void> {
        const config = inputsConfig ? inputsConfig : this.config;
        const targetComponent = targetComponentRef ? targetComponentRef.instance : this;
        for (let key in config) {
            if ({}.hasOwnProperty.call(config, key)) {
                targetComponent[key] = config[key] && config[key];
            }
        }
        this.cd.detectChanges();
    }

    public overlayBody(): HTMLElement {
        const queryString = {
            modal: 'hrs-modal',
            alert: 'hrs-alert',
            toast: 'hrs-toast',
            wizard: 'hrs-wizard'
        }[this.overlayType];
        return (this.element as unknown as HTMLElement).querySelector(queryString) as HTMLElement;
    }

    /**
     * Called when overlay or header close button are clicked or when external process needs to close an overlay
     */
    public async close(): Promise<void> {
        /* in order for a child component to intercept the close button in a modal's header and perform
            additional checking before closing the modal, it needs to provide a handleModalClose() method
            NOTE:  the handleModalClose() method will have the responsibility of calling OverlayRef.dismiss(), as appropriate
        */
        if (/modal|wizard/.test(this.overlayType) && typeof this.childComponentRef.instance.handleModalClose === 'function') {
            this.childComponentRef.instance.handleModalClose();
        } else {
            await this.dismiss();
        }
    }

    /**
     * Called when overlay or header close button are clicked, or when no custom callbacks are added to footer buttons
     */
    public async dismiss(): Promise<void> {
        if (this.alertEl) await this.alertEl['nativeElement'].dismiss();
        this.ref.dismiss({message: 'Overlay or header close button clicked'});
    }
}
