<hrs-content background="white">
    <div class="c_usage_instructions--wrapper">
        <hrs-image variant="large-thumbnail" [imageUrl]="imageUrl"></hrs-image>
        <span class="device-details">
            <hrs-text font="h1-bold">{{device.name}}</hrs-text>
            <hrs-text font="h2-regular">{{device.id}}</hrs-text>
            <hrs-chip variant="success" [toggle]="true">{{'BLUETOOTH.DEVICE_PAIRED' | translate}}</hrs-chip>
        </span>
        <div class="c_usage_instructions--list">
            <hrs-text font="b1-semibold">{{'BLUETOOTH.DEVICE_INSTRUCTIONS_INTRO' | translate}}</hrs-text>
            <div *ngFor="let instruction of instructions">
                <hrs-text font="b1-regular">{{instruction}}</hrs-text>
            </div>
        </div>
    </div>
</hrs-content>
<hrs-footer *ngIf="isModal" variant="two-button">
    <hrs-button
        variant="secondary"
        (click)="readContent()"
        icon="volume"
        qa="qa-usage_instructions--btn_read_content"
    ></hrs-button>
    <hrs-button
        variant="primary"
        text="{{'CLOSE_BUTTON' | translate}}"
        (click)="close()"
        qa="qa-usage_instructions--btn_close"
    ></hrs-button>
</hrs-footer>

