////////////////////////////////////////////////////////////////
// Generic Cordova Utilities
////////////////////////////////////////////////////////////////
function noop() {
    return;
}
function cordovaExec(plugin, method, successCallback = noop, errorCallback = noop, args = []) {
    if (window.cordova) {
        window.cordova.exec(successCallback, errorCallback, plugin, method, args);
    }
    else {
        console.warn(`${plugin}.${method}(...) :: cordova not available`);
        errorCallback && errorCallback(`cordova_not_available`);
    }
}
function cordovaExecPromise(plugin, method, args) {
    return new Promise((resolve, reject) => {
        cordovaExec(plugin, method, resolve, reject, args);
    });
}
////////////////////////////////////////////////////////////////
// Plugin Interface
////////////////////////////////////////////////////////////////
const PLUGIN_NAME = 'NativeAudio';
function invoke(method, ...args) {
    return cordovaExecPromise(PLUGIN_NAME, method, args);
}
export var NativeAudioEventType;
(function (NativeAudioEventType) {
    NativeAudioEventType["EVENT_TYPE_PLAYBACK_LOOP_ENDED"] = "playbackLoopEnded";
})(NativeAudioEventType || (NativeAudioEventType = {}));
export class NativeAudioCordovaInterface {
    constructor() {
    }
    setOptions(options) {
        return invoke('setOptions', options);
    }
    /**
     * Loads an audio file into memory. Optimized for short clips / single shots (up to five seconds). Cannot be stopped / looped.
     *
     * @param id {string} unique ID for the audio file
     * @param assetPath {string}  the relative path or absolute URL (inluding http://) to the audio asset.
     * @returns {Promise<any>}
    */
    preloadSimple(id, assetPath) {
        return invoke('preloadSimple', id, assetPath);
    }
    /**
     * Loads an audio file into memory. Optimized for background music / ambient sound. Uses highlevel native APIs with a larger footprint. (iOS: AVAudioPlayer). Can be stopped / looped and used with multiple voices. Can be faded in and out using the delay parameter.
     *
     * @param id {string} unique ID for the audio file
     * @param assetPath {string}  the relative path or absolute URL (inluding http://) to the audio asset.
     * @param volume {number} the volume of the preloaded sound (0.1 to 1.0)
     * @param voices {number} the number of multichannel voices available
     * @param delay {number}
     * @returns {Promise<any>}
     */
    preloadComplex(id, assetPath, volume, voices, delay) {
        return invoke('preloadComplex', id, assetPath, parseFloat(volume), voices, parseFloat(delay));
    }
    /**
     * Plays an audio asset
     *
     * @param id {string} unique ID for the audio file
     * @param completeCallback {SuccessCallback} optional. Callback to be invoked when audio is done playing
     * @returns {Promise<any>}
     */
    play(id, completionCallback) {
        if (typeof completionCallback === 'function') {
            this.addCompleteListener(id, completionCallback);
        }
        return invoke('play', id);
    }
    /**
     * Plays an audio asset and does not resolve until asset has finished playing
     *
     * @param id {string} unique ID for the audio file
     * @returns {Promise<any>}
     */
    addCompleteListener(id, successCallback) {
        cordovaExec(PLUGIN_NAME, 'addCompleteListener', successCallback, undefined, [id]);
    }
    /**
     * Stops playing an audio
     *
     * @param id {string} unique ID for the audio file
     * @returns {Promise<any>}
     */
    stop(id) {
        return invoke('stop', id);
    }
    /**
     * Loops an audio asset for a specified duration, this only works for complex assets
     *
     * @param id {string} unique ID for the audio file
     * @param duration {number} how long to play the loop in ms (-1 will loop infinitely)
     * @returns {Promise<any>}
     */
    loop(id, duration) {
        return invoke('loop', id, duration ? duration : -1);
    }
    /**
     * Unloads an audio file from memory
     *
     * @param id {string} unique ID for the audio file
     * @returns {Promise<any>}
     */
    unload(id) {
        return invoke('unload', id);
    }
    /**
     * Changes the volume for preloaded complex assets.
     *
     * @param id {string} unique ID for the audio file
     * @param volume {number} the volume of the audio asset (0.1 to 1.0)
     * @returns {Promise<any>}
     */
    setVolumeForComplexAsset(id, volume) {
        return invoke('setVolumeForComplexAsset', id, parseFloat(volume));
    }
    setSharedEventDelegate(success, error) {
        cordovaExec(PLUGIN_NAME, 'setSharedEventDelegate', success, error, [false]);
    }
    removeSharedEventDelegate() {
        cordovaExec(PLUGIN_NAME, 'setSharedEventDelegate', undefined, undefined, [true]);
    }
}
/**
 * Singleton reference to interact with this cordova plugin
 */
export const NativeAudio = new NativeAudioCordovaInterface();
