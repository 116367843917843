import {Component, ElementRef, OnInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import SignaturePad from 'signature_pad';

/**
 * Component derived from this module:
 * https://www.npmjs.com/package/angular2-signaturepad
 *
 * Simplified variant that fixes misc rendering issues with angular 16+,
 * as well as API compatibility issues with latest signature_pad module.
 */
@Component({
    selector: 'app-signature-pad',
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {
    @Output() public beginStroke: EventEmitter<any> = new EventEmitter();
    @Output() public endStroke: EventEmitter<any> = new EventEmitter();

    @ViewChild('canvas', {static: true})
    private canvasRef: ElementRef<HTMLCanvasElement>;

    private readonly beginStrokeCallback: () => void;
    private readonly endStrokeCallback: () => void;
    private signaturePad: SignaturePad = null;

    constructor() {
        this.beginStrokeCallback = this.notifyStrokeBegin.bind(this);
        this.endStrokeCallback = this.notifyStrokeEnd.bind(this);
    }

    private get canvas(): HTMLCanvasElement {
        return this.canvasRef?.nativeElement;
    }

    @Input()
    public get height(): number {
        return this.canvas.height;
    }

    public set height(value: number) {
        this.canvas.height = value;
        this.resizeCanvas();
    }

    public ngOnInit(): void {
        this.signaturePad = new SignaturePad(this.canvas);
        this.signaturePad.addEventListener('beginStroke', this.beginStrokeCallback);
        this.signaturePad.addEventListener('endStroke', this.endStrokeCallback);
        this.resizeCanvas();
    }

    public toDataURL(imageType?: string, quality?: number): string {
        return this.signaturePad.toDataURL(imageType, quality);
    }

    private notifyStrokeBegin(ev?: any): void {
        this.beginStroke.emit(ev);
    }

    private notifyStrokeEnd(ev?: any): void {
        this.endStroke.emit(ev);
    }

    private resizeCanvas(): void {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
        const canvas: HTMLCanvasElement = this.canvas;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
    }
}
