import {Component, Optional} from '@angular/core';
import {ModalService} from '@hrs/providers';
import {Task} from '../../services/tasks';
import moment from 'moment';
import {
    DeviceService,
    OverlayService,
    TaskService,
    TextToSpeechService,
} from '@patient/providers';
import {MetricEntryTypeEnum} from '../../services/device/metric-entry-type.enum';
import {BuildUtility} from '@hrs/utility';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {ActivatedRoute} from '@angular/router';
import {FooterClickDetails, FooterEvent, MainPageTypes} from '../../main/main-config';
import {Subscription} from 'rxjs';
import {OverlayRef} from '../../hrs-overlay';

@Component({
    selector: 'app-devices-instructions-usage',
    templateUrl: './device-instructions-usage.page.html',
    styleUrls: ['./device-instructions-usage.page.scss']
})

export class DeviceInstructionsUsagePage {
    public device: any;
    public imageUrl: string;
    public instructions: string[] = [];
    public peripheralType: string;

    private isHRSTab: boolean = BuildUtility.isHRSTab();
    public isModal: boolean = true;
    private manualEntrySubscription: Subscription;
    private tasks: Task[];
    private timeAndDate: moment.Moment;

    constructor(
        @Optional() private overlayRef: OverlayRef,
        private activatedRoute: ActivatedRoute,
        private deviceService: DeviceService,
        private overlay: OverlayService,
        private modalService: ModalService,
        private taskService: TaskService,
        private taskTrackingService: TaskTrackingService,
        private textToSpeechService: TextToSpeechService,
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.peripheralType) {
                this.isModal = false;
                this.device = params.device;
                this.imageUrl = params.imageUrl;
                this.instructions = params.instructions;
                this.peripheralType = params.peripheralType;
            }
        });
        this.tasks = this.taskService.tasks;
    }

    ngOnInit() {
        if (this.isHRSTab) {
            this.deviceService.subscribePollingForBluetoothDevices();
        }

        this.taskTrackingService.startTracking('bluetooth-device-usage-instructions', 'Opened bluetooth device usage page for ' + this.peripheralType);
        // Handle the footer Manual Entry button.
        this.manualEntrySubscription = FooterEvent.clicked.subscribe((details: FooterClickDetails) => {
            if (details.page === MainPageTypes['USAGE_INSTRUCTIONS']) {
                if (this.isHRSTab && !details.buttonIndex) {
                    this.readContent();
                } else {
                    this.metricEntry('manual');
                }
            }
        });
    }

    ngOnDestroy() {
        this.taskTrackingService.stopTracking();
        this.textToSpeechService.stop();
        if (this.manualEntrySubscription) this.manualEntrySubscription.unsubscribe();
    }

    /**
     * Links to generic metric on click for manual entry as well as if bluetooth reading
     */
    private metricEntry(entryPoint: string): void {
        const metricPageOpen = this.modalService.getModalStatus('GenericMetricPage');
        let task = this.tasks.find((metric) => metric.type === this.peripheralType);
        let currentMoment = moment();

        // Need to check time and date so it doesn't double launch the generic metrics while ngZone is running
        if (this.deviceService.timeAndDate && entryPoint != MetricEntryTypeEnum.ManualEntry) {
            this.timeAndDate = this.deviceService.timeAndDate;
            if (!metricPageOpen && currentMoment.isAfter(moment(this.timeAndDate).add(30, 'seconds')) && this.peripheralType != 'glucose') {
                this.launchGenericMetricModal(task, entryPoint, this.device);
            } else if (!metricPageOpen && currentMoment.isAfter(moment(this.timeAndDate).add(2, 'minutes'))) {
                this.launchGenericMetricModal(task, entryPoint, this.device);
            }
        } else if (!metricPageOpen && entryPoint != MetricEntryTypeEnum.ManualEntry) {
            this.timeAndDate = moment();
            this.deviceService.timeAndDate = this.timeAndDate;
            if (!metricPageOpen) {
                this.launchGenericMetricModal(task, entryPoint, this.device);
            }
        } else if (!metricPageOpen && entryPoint === MetricEntryTypeEnum.ManualEntry) {
            if (!metricPageOpen) {
                this.launchGenericMetricModal(task, entryPoint, this.device);
            }
        }
    }

    private async launchGenericMetricModal(task: Task, entryPoint: string, peripheral?: any): Promise<OverlayRef> {
        return await this.overlay.openModal({
            component: await import('../../generic-metric/generic-metric.module').then((m) => m.GenericMetricPage),
            title: task.title,
            inputs: {
                task: task,
                entryPoint: entryPoint,
                peripheral: peripheral
            },
            qa: 'generic_metric_modal'
        });
    }

    public readContent(): void {
        this.textToSpeechService.speak('BLUETOOTH.DEVICE_INSTRUCTIONS_TITLE').then(() => {
            const text = this.instructions.join(' ');
            this.textToSpeechService.speak(text);
        });
    }

    public close(): void {
        this.overlayRef.dismiss();
    }
}
