import {Component, HostListener, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavController, MenuController} from '@ionic/angular';
import {Validators, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {
    AdminService,
    EnvironmentService,
    OverlayService,
    SupportCallService,
    User
} from '@patient/providers';
import {HrsInput, HrsMenu} from '@hrsui/angular';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {OverlayRef} from '../hrs-overlay';

@Component({
    selector: 'app-login',
    templateUrl: 'login.page.html'
})
export class LoginPage {
    public account: UntypedFormGroup;
    public loggingIn: boolean = false;

    private adminClicks: number = 0;
    private queryParamsSubscription: Subscription;
    private toast: OverlayRef;

    @ViewChild('hrsMenu', {}) menuEl: HrsMenu;
    @ViewChild('sign_in_name', {}) signInNameEl: HrsInput;
    @ViewChild('sign_in_code', {}) signInCodeEl: HrsInput;

    // listen for change on inputs and if input was previously invalid
    // set to valid when input value changes
    @HostListener('hrsChange', ['$event'])
    handleInputChange({detail: {value, el}}) {
        if (el) {
            const elFormName: string = el.getAttribute('formControlName');

            if ((!this.signInNameEl.isValid && elFormName == 'username') ||
               (!this.signInCodeEl.isValid && elFormName == 'loginCode')) {
                el.setIsValid(true);
            }
        }
    }

    constructor(
        private adminService: AdminService,
        private environment: EnvironmentService,
        private formBuilder: UntypedFormBuilder,
        private menuCtrl: MenuController,
        private navCtrl: NavController,
        private overlay: OverlayService,
        private route: ActivatedRoute,
        private supportCallService: SupportCallService,
        private translate: TranslateService,
        private user: User
    ) {}

    ngOnInit(): void {
        this.menuCtrl.enable(false);
        // The account fields for the login form.
        this.account = this.formBuilder.group({
            username: new UntypedFormControl('', Validators.required),
            loginCode: new UntypedFormControl('', Validators.required),
        });

        this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
            if (params?.autoLogin) {
                this.decryptAndLogin(params?.credentials);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
            this.queryParamsSubscription = null;
        }
    }

    private decryptAndLogin(credentials: string): Observable<void> {
        // always login - let error message display as needed
        const decryptedCreds: string = credentials ? atob(credentials) : '';
        if (decryptedCreds?.includes(':')) {
            this.account.value.username = decryptedCreds ? decryptedCreds.split(':')[0] : '';
            this.account.value.loginCode = decryptedCreds ? decryptedCreds.split(':')[1] : '';
        }
        return this.doLogin();
    }

    public doLogin(): Observable<void> {
        this.loggingIn = true;
        if (this.toast) {
            this.toast.dismiss();
            this.toast = null;
        }

        return this.user.login(this.account.value.username, this.account.value.loginCode).subscribe((resp) => {
            this.account.reset();
            this.environment.getEnvironment(this.user.environment);
            this.environment.environmentDataLoading.pipe(take(1)).subscribe(() => {
                this.navCtrl.navigateRoot('/home');
                this.loggingIn = false;
            });
        }, (err) => {
            this.logoutErrorToast();
        }).add(() => {
            this.loggingIn = false;
            this.account.reset();
        });
    }

    private async logoutErrorToast(): Promise<OverlayRef> {
        this.toast = await this.overlay.openToast({
            variant: 'error',
            message: this.translate.instant('SIGN_IN_ERROR'),
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'login_signin_error_toast'
        });
        return this.toast;
    }

    public openAdmin(): void {
        this.adminService.openAdmin();
        this.adminClicks++;
        if (this.adminClicks >= 5) {
            if (this.menuEl) this.menuEl['nativeElement'].toggle();
            this.adminClicks = 0;
        }
    }

    /**
     * Start support call
     */
    public initSupportCall(): void {
        this.supportCallService.initSupportCall(false);
    }
}

