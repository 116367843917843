import {Schedule} from '../schedule/schedule';
import {TaskType} from './task-type.enum';

export interface Reminder {
    id: string,
    schedule: Schedule,
    modules: Module[],
}

export interface Module {
    name: string
}

export const MULTIPLE_REMINDER_TYPES = [
    TaskType.BloodPressure,
    TaskType.Glucose,
    TaskType.PulseOx,
    TaskType.Temperature,
    TaskType.Weight,
    TaskType.WoundImaging
];
