<hrs-content background="white">
    <div class="c_disconnect-device">
        <span *ngIf="isActivityPage" class="device-details">
            <hrs-text font="h1-bold">{{device.type}} - {{device.name}}</hrs-text>
        </span>
        <span *ngIf="!isActivityPage" class="device-details">
            <hrs-text font="h1-bold">{{device.name}}</hrs-text>
            <hrs-text font="h2-regular">{{device.id}}</hrs-text>
            <hrs-chip variant="success" [toggle]="true">{{'BLUETOOTH.DEVICE_PAIRED' | translate}}</hrs-chip>
        </span>
        <hrs-image variant="enlarged" [imageUrl]="imageUrl"></hrs-image>
    </div>
</hrs-content>
<hrs-footer variant="two-button">
    <hrs-button
            variant="secondary"
            text="{{'CANCEL_BUTTON' | translate}}"
            (click)="dismiss()"
    ></hrs-button>
    <hrs-button
            text="{{'BLUETOOTH.DISCONNECT_BUTTON' | translate}}"
            (click)="disconnectPairedDevice()"
    ></hrs-button>
</hrs-footer>
