<hrs-content #content class="app-chat--container">
    <ng-container *ngIf="caregiver">
        <ng-container *ngFor="let message of (loadingMessages$ | async); index as i;">
            <hrs-chat *ngIf="message.hrsid === user.id"
                variant="outgoing"
                label="{{ 'SENDER_PATIENT' | translate }}"
                timestamp="{{ message.createdAt ? convertTimeAndDate(message.createdAt) : message.messageStatus }}"
                [qa]="'outgoing-' + i">
                {{ message.message }}
            </hrs-chat>
            <hrs-chat *ngIf="message.hrsid !== user.id"
                variant="incoming"
                label="{{ caregiver.firstName ? caregiver.firstName + ' ' + caregiver.lastName : 'SENDER_CAREGIVER' | translate }}"
                timestamp="{{ convertTimeAndDate(message.createdAt) }}"
                [qa]="'incoming-' + i">
                {{ message.message }}
            </hrs-chat>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!caregiver">
        <ng-container *ngFor="let message of (loadingMessages$ | async); index as i;">
            <hrs-chat *ngIf="message.type === 'patient'"
                variant="outgoing"
                label="{{ 'SENDER_PATIENT' | translate }}"
                timestamp="{{ message.time ? convertTimeAndDate(message.time) : message.messageStatus }}"
                [qa]="'outgoing-' + i">
                {{ message.text }}
            </hrs-chat>
            <hrs-chat *ngIf="message.type !== 'patient'"
                variant="incoming"
                label="{{ 'SENDER_CLINICIAN' | translate }}"
                timestamp="{{ convertTimeAndDate(message.time) }}"
                [qa]="'incoming-' + i">
                {{  message.text }}
            </hrs-chat>
        </ng-container>
    </ng-container>
</hrs-content>

<hrs-footer variant="messaging">
    <hrs-textarea placeholder="{{ 'CHAT_MESSAGE' | translate }}"
                [(ngModel)]="data.message"
                autogrow
                maxrows="4"
                name="message"
                qa="chat_textarea">
    </hrs-textarea>
    <hrs-button type="submit"
                icon="send"
                [text]="'SEND' | translate"
                qa="chat--btn"
                (click)="sendTextMessage()"
                [loading]="isLoading"
                [disabled]="!data.message.length">
    </hrs-button>
</hrs-footer>
