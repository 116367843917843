import {Injectable} from '@angular/core';
import {CacheService} from 'ionic-cache';
import {Observable} from 'rxjs';
import {TaskStorageKey} from '../tasks/task-storage-key.enum';

@Injectable({
    providedIn: 'root',
})
export class HRSSecureCache {
    public static CACHE_PREFIX = 'apiCache.';
    public static CACHE_CLEARED = 'cacheCleared';
    public static EDUCATION_QUIZ_CACHE_KEY = 'quizzes';
    public static EDUCATION_FILES_CACHE_KEY = 'v1/files';
    public static CONSENT_REQUIRED_CACHE_KEY = 'consent-required';
    public static ENVIRONMENT_SETTINGS_CACHE_KEY = 'env-settings';
    public static ENCRYPTION_KEY_CACHE_KEY = 'encryption-key';

    private mUserID: string;

    constructor(
        private readonly cache: CacheService
    ) {
    }

    public get userID(): string {
        return this.mUserID;
    }

    public set userID(userID: string) {
        this.mUserID = userID;
    }

    /**
     * @deprecated - use CacheService directly
     */
    public setDefaultTTL(ttl: number): number {
        return this.cache.setDefaultTTL(ttl);
    }

    /**
     * @deprecated - use CacheService directly
     */
    public async saveItem(key: string, value: any): Promise<any> {
        return this.cache.saveItem(key, value);
    }

    /**
     * @deprecated - use CacheService directly
     */
    public async removeItem(key: string): Promise<any> {
        return this.cache.removeItem(key);
    }

    /**
     * @deprecated - use CacheService directly
     */
    public loadFromDelayedObservable<T = any>(key: string, observable: Observable<T>, groupKey?: string, ttl?: number, delayType?: string, metaKey?: string): Observable<T> {
        return this.cache.loadFromDelayedObservable(key, observable, groupKey, ttl, delayType, metaKey);
    }

    // resolves out of memory issues related to sqlite, called on app launch to clear out legacy cache values.
    // Newly cached rows include the hrsid of the patient to ensure they are unique to the patient and prevent displaying incorrect data
    // https://healthrecoverysolutions.atlassian.net/wiki/spaces/~5ac36e887ddadd4a9df5d1b7/pages/3683876865/PCMT+-+Out+Of+Memory+Black+Screen
    public async fixBlackScreen(): Promise<void> {
        const cacheKeys = [
            HRSSecureCache.EDUCATION_QUIZ_CACHE_KEY,
            HRSSecureCache.EDUCATION_FILES_CACHE_KEY,
            HRSSecureCache.CONSENT_REQUIRED_CACHE_KEY,
            HRSSecureCache.ENVIRONMENT_SETTINGS_CACHE_KEY,
            HRSSecureCache.ENCRYPTION_KEY_CACHE_KEY,
            TaskStorageKey.CACHE_KEY
        ];

        for (const key of cacheKeys) {
            // the cache service includes functions like clearAll and removeItems but they both select the entire database before removing cached items
            // Using removeItem to avoid selecting the entire database unnecessarily
            await this.cache.removeItem(HRSSecureCache.CACHE_PREFIX + key);
        }
    }

    // Custom implementation of the clearAll function provided by ionic-cache, prevents the selection of the entire table to delete the cache
    public async clearAll(): Promise<void> {
        const cacheKeys = [
            HRSSecureCache.EDUCATION_QUIZ_CACHE_KEY,
            HRSSecureCache.EDUCATION_FILES_CACHE_KEY,
            HRSSecureCache.CONSENT_REQUIRED_CACHE_KEY,
            HRSSecureCache.ENVIRONMENT_SETTINGS_CACHE_KEY,
            HRSSecureCache.ENCRYPTION_KEY_CACHE_KEY,
            TaskStorageKey.CACHE_KEY
        ];

        for (const key of cacheKeys) {
            // the cache service includes functions like clearAll and removeItems but they both select the entire database before removing cached items
            // Using removeItem to avoid selecting the entire database unnecessarily
            await this.cache.removeItem(HRSSecureCache.CACHE_PREFIX + key + this.mUserID);
        }
    }
}
