import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentLibraryModule} from '@hrsui/angular';
import {IonicModule} from '@ionic/angular';

import {ConsentFormPage} from './consent-form.page';
import {SignaturePadModule} from '../components/signature-pad/signature-pad.module';

const routes: Routes = [
    {
        path: '',
        component: ConsentFormPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        SignaturePadModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [ConsentFormPage]
})
export class ConsentFormPageModule {}
