import {APKIntentService} from './knox-service-intents/apk-intent.service';
// import {AutoUpdaterService} from './apk/auto-updater.service';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentLibraryModule} from '@hrsui/angular';
import {DeviceIDIntentService} from './knox-service-intents/deviceid-intent.service';
import {DeviceProfileService} from './device-profile/device-profile.service';
import {DeviceSettingsComponent} from './device-settings/device-settings.component';
import {FormsModule} from '@angular/forms';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {IonicModule} from '@ionic/angular';
import {KioskIntentService} from './knox-service-intents/kiosk-intent.service';
import {KioskSettingsComponent} from './kiosk-settings/kiosk-settings.component';
import {LanguageSelectionPage} from './language-selection/language-selection.page';
import {LicenseIntentService} from './knox-service-intents/license-intent.service';
import {PCKSAPKService} from './apk/pcks-apk.service';
import {PCKSVersionIntentService} from './knox-service-intents/pcks-version-intent.service';
import {PCMTAPKService} from './apk/pcmt-apk.service';
import {RegistrationComponent} from './registration/registration.component';
import {RebootPCMTIntentService} from './knox-service-intents/reboot-pcmt-intent.service';
import {RemoteDiagnosticsService} from './remote-view/remote-diagnostics.service';
import {RemoteUpdaterService} from './apk/remote-updater.service';
import {RestartDeviceIntentService} from './knox-service-intents/restart-device-intent.service';
import {RouterModule} from '@angular/router';
import {Screenshot} from '@ionic-native/screenshot/ngx';
import {SoftwareUpdateComponent} from './software-update/software-update.component';
import {TabletDeviceIdService} from '../services/tablet-device-id/tablet-device-id';
import {TabletWhiteScreenPage} from './hrs-tablet-white-screen/tablet-white-screen.page';
import {TranslateModule} from '@ngx-translate/core';
import {Uptime} from '@ionic-native/uptime/ngx';
import {WifiSettingsComponent} from './wifi-settings/wifi-settings.component';
import {RemoteDiagnosticsIntentService} from './knox-service-intents/remote-diagnostics-intent.service';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {HideStatusBarIntentService} from './knox-service-intents/hide-status-bar-intent.service';

@NgModule(
    {
        imports: [
            CommonModule,
            ComponentLibraryModule,
            FormsModule,
            IonicModule,
            RouterModule.forChild([
                {
                    path: 'tablet-white-screen',
                    component: TabletWhiteScreenPage
                }
            ]),
            TranslateModule.forChild(),
            SharedDirectivesModule
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
        exports: [
            DeviceSettingsComponent,
            KioskSettingsComponent,
            LanguageSelectionPage,
            RegistrationComponent,
            SoftwareUpdateComponent,
            TabletWhiteScreenPage,
            WifiSettingsComponent
        ],
        declarations: [
            DeviceSettingsComponent,
            KioskSettingsComponent,
            LanguageSelectionPage,
            RegistrationComponent,
            SoftwareUpdateComponent,
            TabletWhiteScreenPage,
            WifiSettingsComponent
        ]
    }
)
export class HRSTabletModule {
    /**
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ HrsTabletModule ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * This is a shared module of services related to HRS TABLET specific functionality and requests.
 * These services are provided in app.module using the static forRoot method.
 * Todo: Could move admin module related services and components here as well
 */
    static forRoot(): ModuleWithProviders<HRSTabletModule> {
        return {
            ngModule: HRSTabletModule,
            providers: [
                APKIntentService,
                // AutoUpdaterService,
                DeviceIDIntentService,
                DeviceProfileService,
                Geolocation,
                KioskIntentService,
                HideStatusBarIntentService,
                LicenseIntentService,
                PCKSAPKService,
                PCKSVersionIntentService,
                PCMTAPKService,
                RebootPCMTIntentService,
                RemoteDiagnosticsService,
                RemoteUpdaterService,
                RemoteDiagnosticsIntentService,
                RestartDeviceIntentService,
                Screenshot,
                TabletDeviceIdService,
                Uptime
            ]
        };
    }
}
