<hrs-content variant="comms" [content]="contentConfig"></hrs-content>
<hrs-footer *ngIf="!calling" variant="two-button">
    <hrs-button
            variant="secondary"
            text="{{'CANCEL_BUTTON' | translate}}"
            qa="voice--btn-cancel"
            (click)="dismiss(true)"
    ></hrs-button>
    <hrs-button
            text="{{primaryButtonText | translate}}"
            qa="voice--btn-toggle"
            (click)="toggleCall()"
    ></hrs-button>
</hrs-footer>
<hrs-footer *ngIf="calling" variant="one-button">
    <hrs-button
            expand
            variant="toggle-on"
            text="{{'END_CALL' | translate}}"
            qa="voice--btn-end"
            (click)="toggleCall(true)"
            [disabled]="modalClosing"
    ></hrs-button>
</hrs-footer>
