import {Injectable, inject} from '@angular/core';
import {Network} from '@ionic-native/network/ngx';
import {getLogger} from '@hrs/logging';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {BuildUtility} from '@hrs/utility';
import {SIGNAL_STRENGTH_PLUGIN} from '@app/native-plugins';
import {
    SignalStrengthEvent,
    SignalStrengthEventType,
    WifiState,
    CellState
} from 'cordova-plugin-signal-strength';

export interface SignalStrengthState {
    type: string; // one of the options from Network.Connection
    dbm: number;
    level: number;
    metadata?: any;
}

export enum ConnectionStatus {
    UNKNOWN = 0,
    QUALITY_POOR = 1,
    QUALITY_WEAK = 2,
    QUALITY_MODERATE = 3,
    QUALITY_GOOD = 4,
    QUALITY_EXCELLENT = 5,
    DISCONNECTED = -1,
    LOADING = -2,
    DISABLED = -3,
    CONNECTED_NO_INTERNET = -4
}

const TARGET_MAX_LEVEL = 4;

export function cellularStateToStrengthQuality(state: CellState): ConnectionStatus {
    if (!state || !state.cellDataLoaded) {
        return ConnectionStatus.UNKNOWN;
    }

    return state.level + 1; // plugin level is in range [0, 4]
}

export function wifiStateToStrengthQuality(state: WifiState): ConnectionStatus {
    if (!state) {
        return ConnectionStatus.UNKNOWN;
    }
    if (!state.enabled) {
        return ConnectionStatus.DISABLED;
    }
    if (!state.connected || !state.info) {
        return ConnectionStatus.DISCONNECTED;
    }
    if (!state.hasInternetAccess) {
        return ConnectionStatus.CONNECTED_NO_INTERNET;
    }

    const {level, maxLevel} = state.info;
    let resultLevel = level;

    // re-scale the value if needed
    if (maxLevel > TARGET_MAX_LEVEL) {
        resultLevel = Math.ceil((level / maxLevel) * TARGET_MAX_LEVEL);
    } else if (maxLevel < TARGET_MAX_LEVEL) {
        resultLevel = Math.round((level / maxLevel) * TARGET_MAX_LEVEL);
    }

    return resultLevel + 1;
}

const MAX_EVENT_ENABLE_ATTEMPTS = 3;

@Injectable({
    providedIn: 'root'
})
export class SignalStrengthService {
    private readonly logger = getLogger('SignalStrengthService');
    private readonly eventsSubject = new Subject<SignalStrengthEvent>();
    private readonly cellularStrengthSubject = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.UNKNOWN);
    private readonly wifiStrengthSubject = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.UNKNOWN);
    private readonly signalStrengthNative = inject(SIGNAL_STRENGTH_PLUGIN);
    private readonly eventDelegateSuccessProxy: (ev: SignalStrengthEvent) => void;
    private readonly eventDelegateErrorProxy: (err: any) => void;

    private broadcastUnknown: boolean = false;
    private eventEnableAttemptCount: number = 0;
    private filterUnknownCellStrength = true;
    private mLastWifiState: WifiState | null = null;
    private mLastCellState: CellState | null = null;
    private cellStrengthFilterTimeout: any = null;
    private delegateEnableTimeout: any = null;

    public readonly events$: Observable<SignalStrengthEvent>;
    public readonly cellularStrength$: Observable<ConnectionStatus>;
    public readonly wifiStrength$: Observable<ConnectionStatus>;

    constructor(
        private readonly network: Network
    ) {
        this.eventDelegateSuccessProxy = this.onSignalStrengthEvent.bind(this);
        this.eventDelegateErrorProxy = this.onSignalStrengthDelegateError.bind(this);
        this.events$ = this.eventsSubject.asObservable();
        this.cellularStrength$ = this.createCellularStrengthStream();
        this.wifiStrength$ = this.createWifiStrengthStream();
    }

    public get lastWifiState(): WifiState | null {
        return this.mLastWifiState;
    }

    public get lastCellState(): CellState | null {
        return this.mLastCellState;
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        if (BuildUtility.isHRSTab()) {
            this.startCellStrengthFilterTimeout();
            this.enableUnsolicitedEvents();
        }
    }

    private enableUnsolicitedEvents(): void {
        const current = this.eventEnableAttemptCount;
        const max = MAX_EVENT_ENABLE_ATTEMPTS;

        if (current >= max) {
            this.logger.warn(`enableUnsolicitedEvents() max attempts reached (${current} / ${max})`);
            return;
        }

        this.eventEnableAttemptCount++;
        this.signalStrengthNative.setSharedEventDelegate(
            this.eventDelegateSuccessProxy,
            this.eventDelegateErrorProxy
        );
    }

    /**
     * Manually emit a signal strength event from the JS layer.
     * Mostly used for spec testing, but left here as an optional bypass.
     */
    public emitSignalStrengthEvent(ev: SignalStrengthEvent): void {
        this.onSignalStrengthEvent(ev);
    }

    private onSignalStrengthDelegateError(err: any): void {
        this.logger.warn(`onSignalStrengthDelegateError()`, err);
        this.startDelegateEnableTimeout();
    }

    private startDelegateEnableTimeout(): void {
        if (this.delegateEnableTimeout !== null) {
            clearTimeout(this.delegateEnableTimeout);
        }
        this.delegateEnableTimeout = setTimeout(() => {
            this.delegateEnableTimeout = null;
            this.enableUnsolicitedEvents();
        }, 5000);
    }

    private onSignalStrengthEvent(ev: SignalStrengthEvent): void {
        this.eventsSubject.next(ev);

        switch (ev?.type) {
            case SignalStrengthEventType.CELL_STATE_UPDATED:
                this.mLastCellState = ev.data;
                this.cellularStrengthSubject.next(cellularStateToStrengthQuality(ev.data));
                break;
            case SignalStrengthEventType.WIFI_STATE_UPDATED:
                this.mLastWifiState = ev.data;
                this.wifiStrengthSubject.next(wifiStateToStrengthQuality(ev.data));
                break;
            default:
                break;
        }
    }

    private createCellularStrengthStream(): Observable<ConnectionStatus> {
        return this.cellularStrengthSubject.asObservable().pipe(
            // once a value other than unknown comes through, turn off filtering
            map((v) => {
                if (this.filterUnknownCellStrength && v === ConnectionStatus.UNKNOWN) {
                    this.broadcastUnknown = true;
                    return ConnectionStatus.LOADING;
                } else {
                    this.filterUnknownCellStrength = false;
                    this.broadcastUnknown = false;
                    return v;
                }
            }),
            distinctUntilChanged((previous: ConnectionStatus, current: ConnectionStatus) => {
                if (previous !== current) {
                    this.logger.debug(`cellularStrength$ status updated: ${previous} -> ${current}`);
                    return false;
                }
                return true;
            })
        );
    }

    private createWifiStrengthStream(): Observable<ConnectionStatus> {
        return this.wifiStrengthSubject.asObservable().pipe(
            distinctUntilChanged((previous: ConnectionStatus, current: ConnectionStatus) => {
                if (previous !== current) {
                    this.logger.debug(`wifiStrength$ status updated: ${previous} -> ${current}`);
                    return false;
                }
                return true;
            })
        );
    }

    private startCellStrengthFilterTimeout(): void {
        if (this.cellStrengthFilterTimeout !== null) {
            clearTimeout(this.cellStrengthFilterTimeout);
        }

        // filter out unknown state for first 5 minutes of app run, then allow unkown to pass
        // if no other state has come through yet, broadcast unknown
        this.cellStrengthFilterTimeout = setTimeout(() => {
            this.filterUnknownCellStrength = false;
            if (this.broadcastUnknown) {
                this.cellularStrengthSubject.next(ConnectionStatus.UNKNOWN);
                this.broadcastUnknown = false;
            }
            this.cellStrengthFilterTimeout = null;
        }, 5 * 60 * 1000); // 5 minutes
    }

    /**
     * High level details for current network quality.
     * The "dbm" and "level" properties will be
     * relative to the type of network we are currently using.
     * (i.e. will be wifi data if on wifi, cellular data if on cellular)
     */
    public async getCurrentState(): Promise<SignalStrengthState> {
        this.logger.trace(`getCurrentState()`);
        const type = this.network.type;
        let dbm: number = -1;
        let level: number = 0;
        let metadata: any = null;

        this.logger.trace(`loading signal strength for connection type = ${type}`);

        if (type === this.network.Connection.WIFI) {
            const wifiState = await this.signalStrengthNative.getWifiState().catch((err) => {
                this.logger.error(`failed to load wifi state`, err);
                return null;
            });
            if (wifiState?.info) {
                dbm = wifiState.info.rssi;
                level = wifiState.info.level;
            }
            metadata = wifiState;
        } else {
            this.logger.trace(`attempting to load cell info for non-wifi connection type`);
            const cellState = await this.signalStrengthNative.getCellState().catch((err) => {
                this.logger.error(`failed to load cell state`, err);
                return null;
            });
            if (cellState?.cellDataLoaded) {
                dbm = cellState.dbm;
                level = cellState.level;
            }
            metadata = cellState;
        }

        const result = {
            type,
            dbm,
            level,
            metadata
        };

        this.logger.trace(`getCurrentState() result`, result);
        return result;
    }
}
