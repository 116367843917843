import {ChangeDetectorRef, Component, HostListener} from '@angular/core';
import {NavController} from '@ionic/angular';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {FitbitService} from '../../services/fitbit/fitbit.service';
import {OverlayService} from '../../services/overlay/overlay.service';
import {TaskService} from '../../services/tasks/task.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {TaskType} from '../../services/tasks';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {FooterClickDetails, FooterEvent, MainPageTypes} from '../../main/main-config';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {ActivityDevice, activityDevices, fitbitDevice} from '../generic-device/types/template-devices';
import {DisconnectDevicePage} from '../disconnect/disconnect-device.page';
import {getLogger} from '@hrs/logging';

/**
 * Generated class for the ActivityMonitorsPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
    selector: 'page-activity-monitors',
    templateUrl: 'activity-monitors.page.html'
})
export class ActivityMonitorsPage {
    private readonly logger = getLogger('ActivityMonitorsPage');
    private connectSubscription: Subscription;
    private connectedDevices: ActivityDevice[] = [];
    // Data to pass to HRSContent.
    public contentDevices: ContentDetail = {
        lists: []
    };

    get fitbitIsConnected() {
        return this.taskService.hasTask(TaskType.Steps) && this.fitbitService.isConnected();
    }

    // Click Handler for individual device items.
    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId}}): void {
        this.executeListItemAction(itemId);
    }

    constructor(
        private browser: InAppBrowser,
        private fitbitService: FitbitService,
        private overlay: OverlayService,
        private ref: ChangeDetectorRef,
        private taskService: TaskService,
        private taskTrackingService: TaskTrackingService,
        private translate: TranslateService,
        public navCtrl: NavController
    ) {}

    ngOnInit() {
        this.taskTrackingService.startTracking('activity-monitors-page', 'Opened activity monitors page');
        this.connectSubscription = FooterEvent.clicked.subscribe((details: FooterClickDetails) => {
            if (details.page === MainPageTypes['ACTIVITY_MONITORS']) {
                this.refresh();
            }
        });
        this.fitbitService.getDeviceData();
    }

    ngOnDestroy() {
        this.taskTrackingService.stopTracking();
        this.connectSubscription.unsubscribe();
    }

    ionViewWillEnter() {
        if (this.fitbitService.isConnected()) {
            this.getData();
        } else {
            this.populateContent();
        }
    }

    /**
     * Populate the content to pass HRSContent in the template.
     */
    private populateContent(): void {
        const updatedContent = {
            lists: []
        };

        // Populate "Connected Devices" section if there are activity monitors connected.
        if (this.connectedDevices.length > 0) {
            updatedContent.lists.push({
                legend: this.translate.instant('ACTIVITY_MONITORS.CONNECTED_DEVICES'),
                items: this.connectedDevices.map((device) => {
                    return {
                        defaultChecked: false,
                        mainTitle: `${device.type} - ${device.name}`,
                        subtitles: [
                            `${this.fitbitService.steps || '--'} ${this.translate.instant('ACTIVITY_MONITOR.STEPS')}`,
                            `${this.translate.instant('ACTIVITY_MONITOR.UPDATED')} ${this.fitbitService.lastUpdated || '--'}`
                        ],
                        imgUrl: device.imageURL,
                        itemId: device.name
                    };
                })
            });
        }

        // Populate "Compatible Devices" section.
        updatedContent.lists.push({
            legend: this.translate.instant('ACTIVITY_MONITORS.COMPATIBLE_DEVICES'),
            items: activityDevices.map((device) => {
                return {
                    defaultChecked: false,
                    mainTitle: device.type,
                    imgUrl: device.imageURL,
                    itemId: device.type
                };
            })
        });

        this.contentDevices = updatedContent;
    }

    private executeListItemAction(itemId: string): void {
        const connectedDevice = this.connectedDevices.filter((device) => device.name === itemId);
        if ((connectedDevice.length > 0) && this.fitbitIsConnected) {
            this.disconnect(connectedDevice[0]);
        } else {
            this.connect();
        }
    }

    private async connect(): Promise<any> {
        this.fitbitService.getLoginUrl().subscribe(
            {
                next: (loginUrl: string) => {
                    // this will launch the system browser, android and ios both forbid the use off the in app broswer for oauth (fitbit will also ban you!)
                    this.browser.create(loginUrl, '_system', 'location=no,clearsessioncache=yes,clearcache=yes');
                    // If the app is already on the Activity Monitors page when the deeplinks routes back to the page, the component enter lifecycle hooks won't fire.
                    // Navigate back home so lifecycle hooks fire when rerouted back to Activity Monitors page.
                    this.navCtrl.pop();
                },
                error: async (err) => {
                    await this.overlay.openAlert({
                        header: this.translate.instant('ACTIVITY_MONITOR.SIGN_IN_URL_ERROR.TITLE'),
                        message: [this.translate.instant('ACTIVITY_MONITOR.SIGN_IN_URL_ERROR.MESSAGE')],
                        variant: 'error',
                        buttons: [{
                            text: this.translate.instant('OK_BUTTON'),
                        }],
                        qa: 'activity_connect_alert'
                    });
                }
            }
        );
    }

    private async disconnect(device: ActivityDevice): Promise<any> {
        const overlay = await this.overlay.openModal({
            component: DisconnectDevicePage,
            title: this.translate.instant('BLUETOOTH.DISCONNECT_TITLE'),
            inputs: {
                device: device,
                imageUrl: device.imageURL,
                peripheralType: 'activity'
            },
            qa: 'activity_disconnect_modal'
        });

        overlay.result$
            .pipe(take(1))
            .subscribe((result) => {
                if (result.reason.data.didDisconnect) {
                    this.fitbitService.disconnect().subscribe((disconnectedDevice) => {
                        this.ref.detectChanges();
                        this.connectedDevices = this.connectedDevices.filter((device) => device.name !== disconnectedDevice);
                        this.populateContent();
                    });
                }
            });
    }

    private async getData(): Promise<void> {
        forkJoin([
            this.fitbitService.getData().pipe(take(1)),
            this.fitbitService.getDeviceData().pipe(take(1))
        ]).subscribe({
            next: () => {
                this.logger.phic.debug('Fitbit data updated: Activity Monitors Page');
                const hasDevice = this.connectedDevices.filter((device) => device.name === this.fitbitService.device);
                if (hasDevice.length === 0) {
                    this.connectedDevices.push({...fitbitDevice, name: this.fitbitService.device});
                }
                this.populateContent();
            },
            error: (err) => {
                this.handleError(err);
            }
        });
    }

    private refresh(): void {
        this.getData();
    }

    private handleError(err: Response): void {
        this.logger.phic.error('Failed getting acitivity monitor data', err);
        if (err.status === 401) {
            this.fitbitService.refreshToken().subscribe({
                next: () => {
                    this.getData();
                },
                error: (err) => {
                    if (this.fitbitIsConnected) {
                        this.showDisconnectMessage();
                        const connectedDevice = this.connectedDevices.filter((device) => device.name === this.fitbitService.device);
                        if (connectedDevice.length > 0) {
                            this.disconnect(connectedDevice[0]);
                        }
                    }
                }
            });
        }
    }

    private async showDisconnectMessage(): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('ACTIVITY_MONITOR.SIGN_OUT_TITLE'),
            message: [this.translate.instant('ACTIVITY_MONITOR.SIGN_OUT_MESSAGE', {connect: this.translate.instant('ACTIVITY_MONITORS.CONNECT')})],
            buttons: [
                {text: this.translate.instant('OK_BUTTON')}
            ],
            qa: 'activity_disconnect_alert'
        });
    }
}
