import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {PCMTAPKService} from './pcmt-apk.service';
import {PCKSAPKService} from './pcks-apk.service';
import {BuildUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';

interface APKUpdateNotification {
    pcmtapk?: string,
    pcksapk?: string,
    type: string
}

@Injectable({
    providedIn: 'root',
})
export class RemoteUpdaterService {
    private readonly logger = getLogger('RemoteUpdaterService');

    public listen = new Subject();

    constructor(
        private pcmtAPK: PCMTAPKService,
        private pcksAPK: PCKSAPKService,
    ) {
        this.registerListener();
    }

    public registerListener(): void {
        if (!BuildUtility.isHRSTab()) return;
        this.listen.subscribe(async (res: APKUpdateNotification) => {
            if (res) {
                try {
                    this.handleAPKUpdate(res);
                } catch (e) {
                    this.logger.phic.error(RemoteUpdaterService.name + 'Remote update failed: ' + JSON.stringify(e));
                }
            }
        });
    }

    private async handleAPKUpdate(res: APKUpdateNotification): Promise<void> {
        try {
            if (res.pcmtapk) {
                await this.pcmtAPK.install(res.pcmtapk);
                this.logger.phic.info(RemoteUpdaterService.name + '- Successfully attempting to install: PCMT');
            } else if (res.pcksapk) {
                await this.pcksAPK.install(res.pcksapk);
                this.logger.phic.info(RemoteUpdaterService.name + '- Successfully attempting to install: PCKS');
            }
        } catch (e) {
            this.logger.phic.error(RemoteUpdaterService.name + '- Failed to complete update: ' + JSON.stringify(e));
        }
    }
}
