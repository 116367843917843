import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription, throttleTime} from 'rxjs';
import {THROTTLE_CLICK_TIME_MS_DEFAULT} from '../utility/throttle-click';

@Directive({
    selector: '[appThrottleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
    @Input()
    public throttleTime = THROTTLE_CLICK_TIME_MS_DEFAULT;

    @Output()
    public throttledClick = new EventEmitter();

    private clicks = new Subject<any>();
    private subscription: Subscription;

    constructor() {
    }

    private emitThrottledClick(ev: any): void {
        this.throttledClick.emit(ev);
    }

    public ngOnInit(): void {
        if (!this.subscription) {
            this.subscription = this.clicks.pipe(
                throttleTime(this.throttleTime)
            ).subscribe((e) => this.emitThrottledClick(e));
        }
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    @HostListener('click', ['$event'])
    public onClick(ev: any): void {
        ev?.preventDefault();
        ev?.stopPropagation();
        this.clicks.next(ev);
    }
}
