import {Component} from '@angular/core';
import {TaskTrackingService} from '../services/task-tracking/task-tracking.service';
import {User} from '../services/user/user.service';
import {GatewayApi, ModalService} from '@hrs/providers';
import {OverlayRef} from '../hrs-overlay';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-caregiver-pin',
    templateUrl: './caregiver-pin.page.html',
    styleUrls: ['./caregiver-pin.page.scss'],
})
export class CaregiverPinPage {
  public code: string;
    public error: boolean = false;
    public body: string = '';

    constructor(
      private gatewayApi: GatewayApi,
      private modalService: ModalService,
      private overlayRef: OverlayRef,
      private taskTrackingService: TaskTrackingService,
      private translate: TranslateService,
      private user: User
    ) { }

    ngOnInit() {
        this.getCaregiverPin();
        this.body = this.translate.instant('CAREGIVER_PIN.BODY');
        this.modalService.setModalStatus('CaregiverPinPage', true);
        this.taskTrackingService.startTracking('add-caregiver', 'Opened add caregiver page');
    }

    ngOnDestroy() {
        this.modalService.setModalStatus('CaregiverPinPage', false);
        this.taskTrackingService.stopTracking();
    }

    private getCaregiverPin(): void {
        const body = {
            data: {
                patientId: this.user.id,
                environment: this.user.environment
            }
        };
        this.gatewayApi.post('patient-link-pins', body).subscribe({
            next: (res: any) => {
                this.code = res.data.code;
            },
            error: (err) => {
                this.body = this.translate.instant('CAREGIVER_PIN.ERROR');
                this.error = true;
            }
        });
    }

    public dismiss(): void {
        this.overlayRef.dismiss();
        this.user.getCaregivers();
    }
}
