import {Task} from './task.model';

/**
 * Contains a collection of Task objects
 *
 * Some different logic is needed based on whether we retrieved the tasks from cache versus from the backend, so we get a "meta" property
 * assigned by the ionic-cache package. This class was created just to help play nice with Typescript recognizing the meta property.
 */
export class TaskCollection {
    tasks: Task[] = [];
    meta?: {
        fromCache?: boolean;
    };
}
