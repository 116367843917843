import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2
} from '@angular/core';
import {OverlayComponent} from '../overlay.component';
import {DomService} from '../../services/overlay/dom.service';
import {
    OverlayConfig,
    OverlayRef
} from '../overlay';

@Component({
    selector: 'toast-overlay',
    template: `
        <hrs-toast
            #hrsToast
            [cssClass]="cssClass"
            [variant]="variant"
            [header]="header"
            [message]="message"
            [handler]="handler"
            [allowRedirect]="allowRedirect"
            [redirectText]="redirectText"
            [qa]="qa"
        ></hrs-toast>
    `
})
export class ToastTemplateComponent extends OverlayComponent {
    public variant: string = 'default';
    public header: string;
    public cssClass: string;
    public message: string;
    public allowRedirect: boolean = false;
    public redirectText: string;
    public qa: string;

    constructor(
        cd: ChangeDetectorRef,
        dom: DomService,
        el: ElementRef,
        renderer: Renderer2,
        config: OverlayConfig,
        ref: OverlayRef,
    ) {
        super(cd, dom, el, renderer, config, ref);
    }
}
