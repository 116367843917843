<hrs-content background="white" class="tablet-settings--container">
    <div class="tablet_settings--volume section-margin">
        <hrs-text font="h1-semibold" align="center">
            {{'TABLET_SETTINGS.VOLUME' | translate}}
        </hrs-text>
        <div class="tablet_settings--volume-controls">
            <hrs-button variant="secondary" icon="minus" [disabled]="volume === DISPLAY_VOLUME_MIN" (click)="volumeChange('decrement')"></hrs-button>
            <hrs-text font="h2-bold">
                {{percentageVolume}}%
            </hrs-text>
            <hrs-button variant="secondary" icon="plus" [disabled]="volume === DISPLAY_VOLUME_MAX" (click)="volumeChange('increment')"></hrs-button>
        </div>
    </div>
    <div class="tablet_settings--audio section-margin">
        <hrs-text font="h1-semibold" align="center">
            {{'TABLET_SETTINGS.AUDIO_REMINDERS' | translate}}
        </hrs-text>
        <hrs-radio-group>
            <hrs-radio variant="horizontal" [labelText]="'TABLET_SETTINGS.ON' | translate" input-id="enable" [defaultChecked]="audioReminders"></hrs-radio>
            <hrs-radio variant="horizontal" [labelText]="'TABLET_SETTINGS.OFF' | translate" input-id="disable" [defaultChecked]="!audioReminders"></hrs-radio>
        </hrs-radio-group>
    </div>
    <div class="section-margin">
        <div class="tablet-settings--button_container">
            <hrs-button
                    text="{{'TABLET_SETTINGS.LANGUAGE' | translate}}"
                    (click)="openLanguageModal()"
            ></hrs-button>
            <hrs-button
                *ngIf="knoxEnabled"
                text="{{'TABLET_SETTINGS.WIFI_SETTINGS' | translate}}"
                (click)="openWifiSettings()"
            ></hrs-button>
        </div>
    </div>
    <div class="section-margin">
        <hrs-button
                variant="toggle-on"
                text="{{'TABLET_SETTINGS.RESTART' | translate}}"
                (click)="restartTablet()"
        ></hrs-button>
    </div>
</hrs-content>
