import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GatewayApi} from '@hrs/providers';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FitbitDataInterface} from './fitbit-data.interface';
import {FitbitDeviceInterface} from './fitbit-device.interface';
import {tap} from 'rxjs/operators';
import {Settings} from '../settings/settings.service';
import {getLogger} from '@hrs/logging';

/*
  Generated class for the FitbitProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
    providedIn: 'root',
})
export class FitbitService {
    private readonly logger = getLogger('FitbitService');
    // scope is the permissions we are requesting (read only)
    // expires_in is in seconds, here it is set to one year
    // redirect uri is the url that fitbit will redirect to on login, for us this is a deeplink that relaunches the app 'pcmobile://fitbit'
    static LOGIN_URL = 'https://www.fitbit.com/oauth2/authorize?client_id=';
    static LOGIN_PARAM = '&response_type=code&scope=activity%20settings';
    private _accessToken: string;
    private _device: string;
    private _steps: number;
    private _lastUpdated: string;

    get device() {
        return this._device;
    }

    get steps() {
        return this._steps;
    }

    get lastUpdated() {
        return this._lastUpdated ? moment(this._lastUpdated).format('MM/DD/YYYY h:mm A') : null;
    }

    constructor(
        public http: HttpClient,
        private settings: Settings,
        private gateway: GatewayApi,
    ) {
    }

    public getLoginUrl(): Observable<string> {
        return this.gateway.get('v1/fitbit/app').pipe(map((data: any) => {
            return FitbitService.LOGIN_URL + data.data.id + FitbitService.LOGIN_PARAM;
        }));
    }

    public accessToken(code: string): Observable<any> {
        this.logger.phic.debug('Fitbit login code: ' + code);
        return this.gateway.post(
            'v1/fitbit/authorization',
            {code: code}
        ).pipe(tap((res:any) => {
            this._accessToken = res.data.token;
            this.updateLocalStorage();
        }));
    }

    public isConnected(): boolean {
        return !!this._accessToken;
    }

    public disconnect(): Observable<any> {
        return this.gateway.delete('v1/fitbit/authorization/current')
            .pipe(map((res: any) => {
                const disconnectedDevice = this._device;
                this._device = null;
                this._accessToken = null;
                this._steps = null;
                this._lastUpdated = null;
                this.settings.setValue('fitbit', {});
                return disconnectedDevice;
            }));
    }

    public checkLocalStorage(): void {
        const data = this.settings.getValue('fitbit');
        if (data) {
            this._accessToken = data.accessToken;
            this._device = data.device || null;
            this._lastUpdated = data.lastUpdated || null;
            this._steps = data.steps || null;
        }
    }

    private updateLocalStorage(): void {
        this.settings.setValue('fitbit', {
            accessToken: this._accessToken,
            device: this._device,
            lastUpdated: this._lastUpdated,
            steps: this._steps
        });
    }

    public refreshToken() {
        return this.gateway.get(
            '/v1/fitbit/authorization/current'
        ).pipe(tap((res: any) => {
            this._accessToken = res.data.token;
            this.logger.phic.debug('Fitbit token refreshed: ' + this._accessToken);
            this.updateLocalStorage();
        }));
    }

    public getDeviceData(): Observable<FitbitDeviceInterface> {
        const deviceUrl = 'https://api.fitbit.com/1/user/-/devices.json';
        return this.get(deviceUrl).pipe(map((res: any) => {
            if (res[0] && res[0].deviceVersion) {
                this._device = res[0].deviceVersion;
                this.updateLocalStorage();
                return {device: this._device};
            }
        }));
    }

    public getData(): Observable<FitbitDataInterface> {
        const date = new Date();
        const dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const dataUrl = 'https://api.fitbit.com/1/user/-/activities/date/' + dateString + '.json';
        return this.get(dataUrl).pipe(map((res: any) => {
            // get the total steps for the day
            this._steps = res.summary.steps || null;
            if (this._steps) {
                this._lastUpdated = new Date().toISOString();
                this.updateLocalStorage();
                return {steps: this._steps, lastUpdated: this._lastUpdated, startDate: dateString};
            }
        }));
    }

    private get(url: string): Observable<Object> {
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this._accessToken);
        const options = {
            headers: headers
        };

        return this.http.get(url, options);
    }
}
