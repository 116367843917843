import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {Observable, Subject} from 'rxjs';
import {ModalService} from '@hrs/providers';
import {WelchBPService} from './welch/welch.bloodpressure.service';
import {TranslateService} from '@ngx-translate/core';
import {OverlayService} from '../overlay/overlay.service';
import {OverlayRef} from '../../hrs-overlay';
import {AnDClassicBPService} from './andclassic/andclassic.bloodpressure.service';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class BloodPressureDeviceService {
    private readonly logger = getLogger('BloodPressureDeviceService');

    // Metrics
    systolic: number;
    diastolic: number;
    heartRate: number;

    isRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    metricChange = new Subject();
    bluetoothMetricChange$: Observable<any>;

    private AND_CHAR_DATETIME: string = '2a08';
    private AND_SERVICE: string = '1810';
    private AND_CUSTOM_SERVICE = '233bf000-5a34-1b6d-975c-000d5690abe4';
    private AND_CUSTOM_CHAR = '233bf001-5a34-1b6d-975c-000d5690abe4';
    private maxTimeDifference: number = 120;

    constructor(
        private ble: BLE,
        private modalService: ModalService,
        private ngZone: NgZone,
        private overlay: OverlayService,
        private translateService: TranslateService,
        private welchBPService: WelchBPService,
        private fbAnalytics: HRSFirebaseAnalytics,
        private andClassicBPService: AnDClassicBPService
    ) {
        this.bluetoothMetricChange$ = this.metricChange.asObservable();
    }

    public onBPMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        if (
            peripheralType == 'bloodpressure' &&
            peripheralName.includes('UA-651') &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            let arrayBuffer = new Uint8Array(buffer);

            this.ngZone.run(() => {
                this.parseAndSubmitDataForUA651(arrayBuffer, peripheral);
            });
        }
    }

    /**
     * This will call the blood pressure device onConnected flow
     * specifying a callback method to handle the device vital measurement data
     * @param peripheral
     */
    public onConnected(peripheral: any): void {
        if (peripheral.name.includes('BP100')) {
            this.welchBPService.onConnected(peripheral, this.onMeasurementReceived.bind(this));
        } else if (peripheral.name.includes('UA-767')) {
            this.andClassicBPService.onConnected(peripheral, this.onMeasurementReceived.bind(this));
        }
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (peripheral?.name?.includes('BP100')) {
            this.welchBPService.onDeviceDisconnected(peripheral, isPairingFlow);
        } else if (peripheral?.name?.includes('UA-767')) {
            this.andClassicBPService.onDeviceDisconnected(peripheral, isPairingFlow);
        } else {
            if (!isPairingFlow){
                let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'bloodpressure');
                if (!this.isRecentReading && !this.readingErrorOccurred) {
                    let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
                }
            }
            // cleanup
            this.isRecentReading = false;
            this.historicalReadingObtained = false;
            this.readingErrorOccurred = false;
        }
    }

    /**
    * Blood pressure devices will pass the parsed measurement data to the blood pressure
    * service to process it further and post to server
    * @param sys
    * @param dias
    * @param hr
    * @param peripheralName
    */
    public onMeasurementReceived(sys: number, dias: number, hr: number, peripheral: any): void {
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'bloodpressure');
        if (
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                this.systolic = sys;
                this.diastolic = dias;
                this.heartRate = hr;
                this.metricChange.next(peripheral.name);
                this.isRecentReading = true;
                this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi});
            });
        }
    }
    // If the reading is invalid, it asks the user to repeat the measurement
    private async invalidReadingError(): Promise<OverlayRef> {
        return await this.overlay.openToast({
            message: this.translateService.instant('BLUETOOTH.INVALID_READING'),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'bp_toast'
        });
    }

    /**
     * This method will do any device setting or configuration related work for the
     * peripheral such as setting date/time etc.
     * @param peripheral
     */
    public configureDevice(peripheral: any): void {
        if (peripheral.name.includes('UA-651')) { // setting date and time on UA-651 as early as possible when device is connected
            setTimeout(() => {
                this.logger.phic.debug('AnD UA 651 trying to set time on device');
                this.setDateTimeForUA651(peripheral);
            }, 1600); // As per AnD recommendation, after service discovery, next write operation should be after 1600 ms to avoid Err9 scenario
        }
    }

    // ----************* Methods for UA-651 bp monitor handling *****************---//

    /**
     * Sets the date and time on UA-651 blood pressure monitor. This is needed for
     * discarding the historical data being obtained from device
     * @param peripheral
     */
    public setDateTimeForUA651(peripheral: any): void {
        this.ble.write(peripheral.id, this.AND_SERVICE, this.AND_CHAR_DATETIME, BluetoothUtils.getTimeStampForAndBLEDevice().buffer).then(
            (data) => {
                this.logger.phic.debug('AnD UA-651: Success result of setting date time' );
                this.startNotificationForUA651(peripheral);
            },
            (error) => {
                this.logger.phic.error('AnD UA-651: Failed to write date and time', error);
                this.startNotificationForUA651(peripheral);
            });
    }

    private startNotificationForUA651(peripheral: any): void {
        setTimeout(() => {
            let AD_SERVICE = '1810';
            let AD_CHARACTERISTIC = '2A35';
            this.logger.phic.debug('AnD UA-651: Registering to start notification' );
            this.ble.startNotification(peripheral.id, AD_SERVICE, AD_CHARACTERISTIC).subscribe(
                {
                    next: (data) => {
                    // instead of passing data, as per the changes in BLE central plugin, we now pass data[0]
                    // change id - https://github.com/danielsogl/awesome-cordova-plugins/pull/3509
                        this.onBPMetricMeasurementChange(data[0], 'bloodpressure', peripheral);
                    },
                    error: (error) => {
                        this.logger.phic.error('onConnected: Failed to notify characteristic for UA651 ', error);
                        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'bloodpressure');
                        this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                                [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                                [HRSFirebaseParams.META_DATA]: error});
                    }
                }
            );
        }, 100);
    }

    /**
     * This method parses the blood pressure readings data and validates it. Based on the validations,
     * it decides whether it should be submitted further to the server or not. If it is an
     * invalid reading, a toast/pop-up is shown to the user.
     * @param buffer
     * @param peripheralName
     */
    public parseAndSubmitDataForUA651(buffer: Uint8Array, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralId = peripheral.id;
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'bloodpressure');
        let measurementStatusFlag;
        this.logger.phic.debug('Data obtained from ' + peripheralName + ', size ' + buffer.length + ' ' + BluetoothUtils.buf2hex(buffer));
        enum ByteOffset { // signifies the length of the data bytes for different reading params
            FLAGS_OFFSET = 1,
            SYS_OFFSET = 2,
            DIASTOLIC_OFFSET = 2,
            MEAN_ARTERIAL_PRESSURE_OFFSET = 2,
            TIMESTAMP_OFFSET = 7,
            PULSE_RATE_OFFSET = 2,
            USER_ID_OFFSET = 1
        }
        let readingTimeStamp;
        let offset = 0; // offset will vary according to the data obtained for reading

        // Read flag value which indicates various parameters are present in the reading or not
        const flag = buffer[offset];
        const isTimeStampPresent = ((flag & 0xff) & (0x01 << 1)) != 0;
        const isPulseRatePresent = ((flag & 0xff) & (0x01 << 2)) != 0;
        const isUserIdPresent = ((flag & 0xff) & (0x01 << 3)) != 0;
        const isMeasurementStatusPresent = ((flag & 0xff) & (0x01 << 4)) != 0;

        this.logger.phic.debug(peripheralName + ' Flags present : Timestamp ' + isTimeStampPresent + ' Pulse rate flag ' +
          isPulseRatePresent + ' User Id flag ' + isUserIdPresent + ' Measurement flag ' + isMeasurementStatusPresent);

        // Read systolic value
        offset += ByteOffset.FLAGS_OFFSET;
        this.systolic = buffer[offset];
        this.logger.phic.debug(peripheralName + ' Systolic reading ' + this.systolic);

        // Read diastolic value
        offset += ByteOffset.SYS_OFFSET;
        this.diastolic = buffer[offset];
        this.logger.phic.debug(peripheralName + ' Diastolic reading ' + this.diastolic);

        // update the offset to read next available data
        offset += ByteOffset.DIASTOLIC_OFFSET;
        offset += ByteOffset.MEAN_ARTERIAL_PRESSURE_OFFSET;

        if (isTimeStampPresent) {
            let timestampOffset = offset;
            this.logger.phic.debug(peripheralName + ' Timestamp is present with offset ' + timestampOffset);
            readingTimeStamp = BluetoothUtils.fetchReadingTimestampForAnDBLEDevice(buffer, timestampOffset);
            this.logger.phic.debug(peripheralName + ' Reading timestamp ' + readingTimeStamp);
            offset += ByteOffset.TIMESTAMP_OFFSET;
        } else {
            this.logger.phic.debug('Discarding reading as Time stamp not present with it for ' + peripheralName);
        }

        if (isPulseRatePresent) { // Read Heart Rate value
            this.logger.phic.debug(peripheralName + ' Pulse rate measured at offset level? ' + offset);
            this.heartRate = buffer[offset];
            offset += ByteOffset.PULSE_RATE_OFFSET;
        } else {
            this.logger.phic.debug(peripheralName + ' Pulse rate not present');
        }

        if (isUserIdPresent) {
            offset += ByteOffset.USER_ID_OFFSET;
        }

        if (isMeasurementStatusPresent) {
            // Measurement Status Flag
            measurementStatusFlag = buffer[offset];
            this.checkMeasurementStatusForUA651(measurementStatusFlag);
        }

        if (!isTimeStampPresent || BluetoothUtils.isAnOldReadingForAnDDevice(readingTimeStamp, this.maxTimeDifference)) { // discard historical data
            this.logger.phic.debug('And UA 651: Discarding Old reading. IsTimeStampPresent ' + isTimeStampPresent + ' Sys : ' + this.systolic + ' Dias : ' + this.diastolic + ' heartrate ' + this.heartRate);
            this.historicalReadingObtained = true;
        } else { // checking if there is any garbage value captured before saving
            // 255 is sent over for all three metrics if the cuff has an error in the reading
            // we want to ignore those readings and let the user know to resubmit
            if (this.isValidReading(this.systolic) || (this.isValidReading(this.diastolic) || this.isValidReading(this.heartRate))){
                this.logger.phic.debug('And UA 651: Is time stamp present for valid reading? ' + isTimeStampPresent);
                this.logger.phic.info('AnD UA 651: Latest reading obtained ' + this.systolic + ' Dias : ' + this.diastolic + ' heartrate ' + this.heartRate + ' will show on metric page for submission');
                this.metricChange.next(peripheralName);
                this.isRecentReading = true;
                this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.READING_OFFSET]: BluetoothUtils.getReadingTimeDifference(readingTimeStamp)});
                this.logger.phic.debug('Metric obtained for UA-651, now disconnect the device');
                this.disconnectUA651(peripheralId);
            } else {
                this.logger.phic.error('Got reading error :' + this.systolic + ' ' + this.diastolic + ' ' + this.heartRate);
                this.systolic = null;
                this.diastolic = null;
                this.heartRate = null;
                this.invalidReadingError();
                this.readingErrorOccurred = true;
                this.logReadingError(buffer, peripheralDisplayName, peripheral, measurementStatusFlag);
            }
        }
    }

    private isValidReading(value: number): boolean {
        return value > 0 && value !== 255; // checking for 0 or 255
    }

    public logReadingError(buffer: Uint8Array, peripheralDisplayName: string, peripheral: any, measurementFlagData: number): void {
        // each value, SYS, DIA, PUL, MAP is 2 bytes. When both bytes are decoded, then Err and ErrCuff will generate 2047 and E will generate 2048.

        enum BytePosition { // signifies the length of the data bytes for different error params
            SYSTOLIC_VALUE = 1, // systolic value byte 1
            SYSTOLIC_SECOND_BYTE = 2, // systolic byte 2
            DIASTOLIC_VALUE = 3, // diastolic byte 1
            DIASTOLIC_SECOND_BYTE = 4, // diastolic byte 2
            PULSE_VALUE = 14, // pulse byte 1
            PULSE_SECOND_BYTE = 15, // pulse byte 2
        }

        enum MeasurementFlags { // Measurement flags to provide additional error details
            BODY_MOVEMENT_DETECTION_FLAG = 0,
            CUFF_FIT_DETECTION_FLAG = 1,
            MEASUREMENT_POSITION_DETECTION_FLAG = 5
        }

        let errorReason = HRSFirebaseErrorReason.VALIDATION_FAILURE; // default error reason

        // As per AnD documentation, Err and ErrCuff will generate 2047 [that is 0x07 0xff]
        let isError2047 = ((buffer[BytePosition.SYSTOLIC_VALUE] == 0xff && buffer[BytePosition.SYSTOLIC_SECOND_BYTE] == 0x07) ||
        (buffer[BytePosition.DIASTOLIC_VALUE] == 0xff && buffer[BytePosition.DIASTOLIC_SECOND_BYTE] == 0x07) );

        if (isError2047) {
            errorReason = HRSFirebaseErrorReason.AND_651_BP_ERR_ERRCUFF_2047;
            if (measurementFlagData) { // if we get Measurement flag bits set, we have more detailed error to be logged
                if (BluetoothUtils.iskthBitSet(measurementFlagData, MeasurementFlags.BODY_MOVEMENT_DETECTION_FLAG)) {
                    errorReason = HRSFirebaseErrorReason.AND_651_BP_ERR_2047_BODY_MOVEMENT_DETECTED;
                } else if (BluetoothUtils.iskthBitSet(measurementFlagData, MeasurementFlags.CUFF_FIT_DETECTION_FLAG)) {
                    errorReason = HRSFirebaseErrorReason.AND_651_BP_ERR_2047_CUFF_TOO_LOOSE;
                } else if (BluetoothUtils.iskthBitSet(measurementFlagData, MeasurementFlags.MEASUREMENT_POSITION_DETECTION_FLAG)) {
                    errorReason = HRSFirebaseErrorReason.AND_651_BP_ERR_2047_IMPROPER_MEASUREMENT_POSITION;
                }
            }
        } else if (buffer[BytePosition.PULSE_VALUE] == 0x00 && buffer[BytePosition.PULSE_SECOND_BYTE] == 0x08) { //  E will generate 2048 [that is 0x08 0x00]
            errorReason = HRSFirebaseErrorReason.AND_651_BP_ERR_NO_PULSE_2048;
        }

        this.logger.phic.error('AnD UA 651: Invalid reading ' + errorReason);

        this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: errorReason});
    }

    private disconnectUA651(peripheralId: any): void {
        var disconnectCmd = new Uint8Array([0x03, 0x02, 0x03, 0x00]);
        this.ble.write(peripheralId, this.AND_CUSTOM_SERVICE, this.AND_CUSTOM_CHAR, disconnectCmd.buffer).then(
            (data) => {
                this.logger.phic.info('Success result of disconnecting BT for BP UA-651' + data);
            },
            (error) => {
                this.logger.phic.error('Failed to disconnect BT on UA-651', error);
            });
    }

    /**
     * This is only for better logging purpose. This will help log measurement status for the
     * blood pressure monitor such as whether pulse is irregular or cuff is too loose etc.
     * @param measurementStatusFlag
     */
    public checkMeasurementStatusForUA651(measurementStatusFlag: number): void {
        this.logger.phic.debug('And UA 651: Measurement status flag ' + measurementStatusFlag);

        const isBodyMovementDetected = ((measurementStatusFlag & 0xff) & 0x01) != 0;
        this.logger.phic.debug('And UA 651: Is body movement detected while measuring? ' + isBodyMovementDetected);

        const isCuffTooLoose = ((measurementStatusFlag & 0xff) & (0x01 << 1)) != 0;
        this.logger.phic.debug('And UA 651: Is the cuff too loose? ' + isCuffTooLoose);

        const isPulseIrregular = ((measurementStatusFlag & 0xff) & (0x01 << 2)) != 0;
        this.logger.phic.debug('And UA 651: Is pulse irregular? ' + isPulseIrregular);

        const pulseRateRange = ((measurementStatusFlag & 0xff) >> 3) & 0x03;
        this.logger.phic.debug('And UA 651: Pulse rate range ' + pulseRateRange);

        const isMeasurementPositionImproper = ((measurementStatusFlag & 0xff) & (0x01 << 5)) != 0;
        this.logger.phic.debug('And UA 651: Is measurement position improper ? ' + isMeasurementPositionImproper);
    }

    // ---*********** Handling for UA-651 bp monitor end ******************----//
}
