import {Component, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SurveyChoiceType, SurveyTask, Task} from '../services/tasks';
import {TaskMetaData} from '../services/tasks/task-metadata.interface';
import {EncryptionService} from '../services/encryption/encryption.service';
import * as moment from 'moment';
import {
    OverlayService,
    TextToSpeechService,
    TaskService,
    EnvironmentService,
    CareplanChangeService
} from '@patient/providers';
import {BuildUtility} from '@hrs/utility';
import {TaskTrackingService} from '../services/task-tracking/task-tracking.service';
import {OverlayRef} from '../hrs-overlay';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {ListItem} from '@hrsui/core/dist/types/components/list/list.interface';
import {statusType, WizardConfigDetail} from '@hrsui/core/dist/types/components/wizard/wizard.interface';
import {SurveyHistoricalDataModal} from './survey-historical-data/survey-historical-data.modal';
import {filter, tap} from 'rxjs/operators';
import {first, Subscription} from 'rxjs';
import {getLogger} from '@hrs/logging';
import {CareplanChangeAction} from '../enums';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.page.html',
    styleUrls: ['./survey.page.scss'],
})
export class SurveyPage {
    private readonly logger = getLogger('SurveyPage');

    task: Task;
    surveys: Task[] = [];
    currentIndex: number = 0;
    atLeastOneWasScheduled: boolean;
    SurveyChoiceType = SurveyChoiceType;
    isHRSTablet: boolean = BuildUtility.isHRSTab();
    public content: ContentDetail[];
    public saving: boolean = false;
    public wizardConfig: WizardConfigDetail;
    public showHistoricalDataButton: boolean = false;
    private taskRemovedSubscription: Subscription;

    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId, selected}}): void {
        // itemId === survey-#-a#
        const answerIndex = parseInt(itemId.split('-a')[1]);
        const surveyId = itemId.split('-a')[0];
        const survey = this.currentSurvey();
        if (survey && survey.id === surveyId && survey.choices && survey.choices.length) {
            const choice = survey.choices[answerIndex];
            if (choice) {
                if (choice.type === SurveyChoiceType.Normal) {
                    choice.answer = selected;
                }
                this.handleChoiceChanged(choice);
            }
        }
    }

    constructor(
      private encryptionService: EncryptionService,
      private environmentService: EnvironmentService,
      private overlay: OverlayService,
      private overlayRef: OverlayRef,
      private taskService: TaskService,
      private taskTrackingService: TaskTrackingService,
      private textToSpeechService: TextToSpeechService,
      private translate: TranslateService,
      private careplanChangeService: CareplanChangeService,
    ) {
    }

    ngOnInit() {
        this.loadSurveys();
        this.updateContent();
        this.updateWizard();
        this.taskRemovedSubscription = this.careplanChangeService.careplanState$.pipe(
            tap((careplanState) => this.logger.debug(`received careplan state update ->`, careplanState, this.task)),
            filter((careplanState) => this.task && careplanState[this.task.type] === CareplanChangeAction.REMOVED),
            first()
        ).subscribe(() => {
            this.logger.info(`returning to home page after module remove!`);
            this.returnToHomePage();
        });
    }

    ngOnDestroy() {
        this.textToSpeechService.stop();
        if (this.taskRemovedSubscription) {
            this.taskRemovedSubscription.unsubscribe();
            this.taskRemovedSubscription = null;
        }
    }

    private loadSurveys(): void {
        if (!this.task.subTasks) {
            return;
        }

        for (let i = 0; i < this.task.subTasks.length; i++) {
            let survey = this.task.subTasks[i] as SurveyTask;

            if (!survey.isScheduled()) {
                this.logger.phic.debug('Excluding survey ' + i + ': not scheduled for today');
                continue;
            }
            this.atLeastOneWasScheduled = true;
            if (survey.isCompleted()) {
                this.logger.phic.debug('Excluding survey ' + i + ': already completed today');
                continue;
            }
            this.surveys.push(survey);
        }
    }

    private updateContent(): void {
        let listItems: ListItem[] = [];
        const survey = this.currentSurvey();
        let updatedContent: ContentDetail[] = [];

        if (survey && survey.choices) {
            for (let i = 0; i < survey.choices.length; i++) {
                let choice = survey.choices[i];
                if (choice.type === SurveyChoiceType.Normal) {
                    listItems.push({
                        itemId: `${survey.id}-a${i}`,
                        mainTitle: choice.getDisplayText(),
                        defaultChecked: !!choice.answer
                    });
                }
            }
        }

        updatedContent.push({
            lists: [{
                variant: this.currentSurveyAllSelectOnly() ? 'radio' : 'checkbox',
                items: listItems
            }]
        });

        this.content = updatedContent;
    }

    private updateWizard(): void {
        let updatedConfig: WizardConfigDetail;

        if (!this.surveys.length) { // set to completed
            updatedConfig = {
                headerText: this.translate.instant('SURVEY_COMPLETED'),
                subheader: {
                    isStatus: true,
                    title: this.translate.instant('SURVEY_NO_SURVEYS'),
                    subtitle: this.translate.instant('SURVEY_FINISHED_SURVEYS'),
                    type: 'complete' as statusType
                },
                totalSteps: 1
            };
            this.showHistoricalDataButton = this.environmentService.hasHistoricalData();
        } else { // update survey questions
            updatedConfig = {
                headerText: this.translate.instant('SURVEY_TITLE'),
                subheader: {
                    serializationText: this.translate.instant('SURVEY_QUESTION_NUMBER_OF_TOTAL', {current: this.currentIndex + 1, total: this.surveys.length})
                },
                totalSteps: this.surveys.length
            };
            this.showHistoricalDataButton = false;
        }
        this.wizardConfig = updatedConfig;
    }

    public hasMoreSurveys(): boolean {
        return this.currentIndex < this.surveys.length - 1;
    }

    public currentSurvey(): SurveyTask {
        return this.surveys[this.currentIndex] as SurveyTask;
    }

    private currentSurveyAllSelectOnly(): boolean {
        let survey = this.currentSurvey();
        if (survey) {
            for (let i = 0; i < survey.choices.length; i++) {
                let choice = survey.choices[i];
                if (!choice.selectOnly) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    private handleChoiceChanged(choice): void {
        if (choice.type !== SurveyChoiceType.Normal || !choice.answer) {
            this.updateWizard();
            return;
        }

        /* Handle "selectOnly" answers -- eg. "None of the above"
           Those are not permitted to be selected at the same time as any other answer.
        */
        for (let i = 0; i < this.currentSurvey().choices.length; i++) {
            let otherChoice = this.currentSurvey().choices[i];
            if (otherChoice.id === choice.id) {
                continue;
            }

            /* If a selectOnly answer was just selected, deselect all other answers.
               Or if a non-selectOnly answer was just selected, deselect any selectOnly answers.
            */
            if (choice.selectOnly || otherChoice.selectOnly) {
                if (otherChoice.answer) {
                    otherChoice.answer = false;
                }
            }
        }

        // list of checkboxes needs the app to update the UI, radio-group already handles updating its radio buttons' UI
        if (!this.currentSurveyAllSelectOnly()) this.updateContent();
        this.updateWizard();
    }

    public isCurrentSurveyAnswered(): boolean {
        if (this.surveys.length) {
            let survey = this.currentSurvey();
            for (let i = 0; i < survey.choices.length; i++) {
                let choice = survey.choices[i];
                if (choice.answer) {
                    if (choice.type === SurveyChoiceType.Numeric) {
                        if (this.isValidNumericAnswer(choice.answer)) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }
            return false;
        } else {
            return true;
        }
    }

    private isValidNumericAnswer(answer): boolean {
        let number = parseFloat(answer);
        return !isNaN(number) && number > 0;
    }

    public async save(survey: SurveyTask) {
        this.saving = true;

        let data = {
            question: survey.surveyId,
            choices: [],
            answer: undefined
        };

        for (let i = 0; i < survey.choices.length; i++) {
            let choice = survey.choices[i];
            if (!choice.answer) {
                continue;
            }
            if (choice.type === SurveyChoiceType.Normal) {
                data.choices.push({
                    id: choice.id,
                    selected: true,
                    value: choice.text
                });
            } else {
                let value = choice.answer;
                if (choice.type === SurveyChoiceType.Numeric) {
                    data.answer = value;
                } else {
                    data.choices.push({
                        id: choice.id,
                        selected: true,
                        value: value
                    });
                }
            }
        }
        const metadata: TaskMetaData = {
            recordedDate: moment().locale('en').format(), // get the date this metric was recorded in case the user is offline when they upload. also, see below note on "language locale"
        };

        /* [language locale] for non-western languages moment converts the date string to a different character-set that the
           server doesn't like so setting locale('en') to english to ensure we get a date string in western characters
           (only an issue for non-western languages like arabic and hindi)
        */
        this.taskService.submitTask(survey, data, metadata, !this.hasMoreSurveys()).subscribe(
            {
                next: () => {
                    this.handleSaveSuccess();
                },
                error: (err) => {
                    this.handleSaveError(survey, err);
                }
            }
        );
    }

    private handleSaveSuccess(): void {
        // Advance to the next survey question or exit if we're done
        if (this.hasMoreSurveys()) {
            this.currentIndex++;
            this.saving = false;
            this.updateContent();
            this.updateWizard();
        } else {
            this.dismiss();
        }
        this.taskTrackingService.submitTracking('submit-survey', 'success submitting ' + this.task.type);
    }

    private markSurveyAsStoredOffline(survey: SurveyTask): void {
        survey.lastCompletedButOffline = survey.lastCompleted;
    }

    private handleSaveError(survey: SurveyTask, err: Error): void {
        this.logger.phic.error('Error submitting survey to server', err);
        let taskTitle: string = this.task && this.task.title ? this.task.title.toLowerCase() : '';
        const hasServerPublicKey: boolean = !!this.encryptionService.serverPublicKey;
        if (hasServerPublicKey) {
            /* we've got a serverPublicKey, which means we encrypted and saved the metric on the patient's device.
               provide an alert explaining that we'll be uploading their metric shortly.
            */
            this.notSavedWillRetryAlert(taskTitle);
            this.markSurveyAsStoredOffline(survey);
            this.handleSaveSuccess();
        } else {
            /* no serverPublicKey, which means we couldn't encrypt their data, which means we couldn't store the data on their device's storage
               thus, provide a generic error toast explaining their data was not saved.
            */
            this.dismiss();
            this.notSavedCantRetryToast(taskTitle);
        }
        this.taskTrackingService.submitTracking('submit-survey', 'failed submitting ' + this.task.type);
    }

    private async notSavedWillRetryAlert(taskTitle: string): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.HEADER'),
            message: [
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.1', {metric: taskTitle}),
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.2')
            ],
            buttons: [
                {text: this.translate.instant('OK_BUTTON')}
            ],
            qa: 'survey_save_alert'
        });
    }

    private async notSavedCantRetryToast(taskTitle: string): Promise<OverlayRef> {
        return await this.overlay.openToast({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.HEADER'),
            message: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.BODY', {metric: taskTitle}),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'survey_toast'
        });
    }

    public dismiss(): void {
        this.overlayRef.dismiss();
    }

    public readContent(): void {
        this.textToSpeechService.speak(this.currentSurvey().question);
    }

    public async openHistoricalData(): Promise<void> {
        await this.overlay.openWizard({
            component: SurveyHistoricalDataModal,
            qa: 'survey_historical_data',
            inputs: {
                task: this.task
            }
        });
        this.dismiss();
    }

    private returnToHomePage(): void {
        this.logger.debug(`returnToHomePage()`);
        // close the metric page/modal
        this.overlayRef.dismiss();
    }
}
