import {Component, HostListener, OnInit} from '@angular/core';
import {LanguageService, TabletSettingsService} from '@patient/providers';
import {OverlayRef} from '../../hrs-overlay';
import {ModalService} from '@hrs/providers';
import {ISOValuesFromLanguage, LanguageValuesFromISO} from '../../enums';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';

@Component({
    selector: 'language-selection',
    templateUrl: './language-selection.page.html'
})
export class LanguageSelectionPage implements OnInit {
    public radioList: ContentDetail;
    public supportedLanguages: string[];
    public selectedLanguage: string;

    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId, selected}}): void {
        if (itemId && selected) {
            this.selectedLanguage = itemId;
        }
    }

    constructor(
        private language: LanguageService,
        private modalService: ModalService,
        private overlayRef: OverlayRef,
        private tablet: TabletSettingsService
    ) {}

    ngOnInit() {
        this.modalService.setModalStatus('LanguageSelectionPage', true);
        this.supportedLanguages = Object.values(LanguageValuesFromISO);
        this.selectedLanguage = LanguageValuesFromISO[this.language.getUserLanguage()];
        this.radioList = {
            lists: [
                {
                    variant: 'radio',
                    items: this.supportedLanguages.map((lang) => {
                        return {
                            mainTitle: lang.charAt(0).toUpperCase() + lang.slice(1),
                            itemId: lang,
                            defaultChecked: lang === this.selectedLanguage
                        };
                    })
                }
            ]
        };
    }

    private async languageChange(): Promise<void> {
        await this.tablet.putTabletSettings({language: this.selectedLanguage});
        await this.language.setTranslation(ISOValuesFromLanguage[this.selectedLanguage]);
    }

    public async dismiss(didSave: boolean): Promise<void> {
        this.modalService.setModalStatus('LanguageSelectionPage', false);
        if (didSave) {
            await this.languageChange();
        }
        this.overlayRef.dismiss();
    }
}
