<hrs-content background="white" *ngIf="view === 'pairingInstructionsView'">
    <div class="c_pairing_instructions--wrapper">
        <hrs-image variant="large-thumbnail" [imageUrl]="imageUrl" qa="pairing_instructions--img"></hrs-image>
        <hrs-text font="h1-bold" qa="pairing_instructions--device_name">{{device.display}}</hrs-text>
        <div class="c_pairing_instructions--list">
            <div *ngFor="let instruction of instructions; index as i;">
                <hrs-text *ngIf="instruction.embedImage" font="b1-regular" [qa]="'pairing_instructions--body-' + i">
                    <ng-container *ngFor="let text of instruction.texts; index as j;">
                        <span [innerHTML]="text"></span>
                        <img *ngIf="instruction.images[j]" [src]="instruction.images[j].url" [alt]="instruction.images[j].text">
                    </ng-container>
                </hrs-text>
                <hrs-text *ngIf="!instruction.embedImage" font="b1-regular" [qa]="'pairing_instructions--body-' + i">
                    <span [innerHTML]="instruction.text"></span>
                </hrs-text>
            </div>
        </div>
    </div>
</hrs-content>

<hrs-content background="white" *ngIf="view === 'enlargedImageView'" class="c_pairing_instructions--enlarged_img">
    <hrs-image variant="enlarged" [imageUrl]="imageUrl" qa="pairing_instructions--img-enlarged"></hrs-image>
</hrs-content>
