import {Storage} from '@ionic/storage-angular';
import {Injectable} from '@angular/core';

/**
 * Extension of the @ionic/storage module
 * Point of access for De/Encrypting stored user data
 * Using EncryptionService methods to augment getter/setter
 * Provides security before calling on the DB to store the data
 */
@Injectable({
    providedIn: 'root',
})
export class HRSStorage {
    public static ACTIVITY_RESET_TS = 'activity_reset_ts';
    public static WEIGHT_RESET_TS = 'weight_reset_ts';

    private mStorage: Storage | null = null;
    private mStorageReady: Promise<any>;

    constructor(
        private readonly storageCreator: Storage
    ) {
        this.mStorageReady = this.init();
    }

    private async init(): Promise<void> {
        this.mStorage = await this.storageCreator.create();
    }

    private async ready(): Promise<void> {
        return this.mStorage ? Promise.resolve() : this.mStorageReady;
    }

    public async get(key: string): Promise<any> {
        await this.ready();
        return this.mStorage.get(key);
    }

    public async set(key: string, value: any): Promise<any> {
        await this.ready();
        return this.mStorage.set(key, value);
    }

    public async remove(key: string): Promise<any> {
        await this.ready();
        return this.mStorage.remove(key);
    }

    /**
     * @deprecated - use remove() instead
     */
    public async removeItem(key: string): Promise<any> {
        return this.remove(key);
    }

    public async clear(): Promise<any> {
        await this.ready();
        return this.mStorage.clear();
    }
}
