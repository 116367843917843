import {Injectable} from '@angular/core';
import {GatewayResourceService, GatewayResponse} from '@hrs/gateway';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {APKIntentService} from '../knox-service-intents/apk-intent.service';
import {lastValueFrom} from 'rxjs';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export abstract class APKService {
    private readonly baseLogger = getLogger('APKService');

    protected _packageName;
    public _version;
    public latestVersion;

    private static shouldAttemptInstall(latestVersion: string, currentVersion: string): boolean {
        return latestVersion !== currentVersion;
    }

    protected constructor(
        private file: File,
        private fileTransfer: FileTransfer,
        private apkIntent: APKIntentService,
        private gateway: GatewayResourceService
    ) {}

    public async getMetaData(folder: string): Promise<GatewayResponse<any[] | any>> {
        const endpoint = 'v1/apk-meta ';
        const options = {
            endpoint: endpoint,
            filter: {
                folder: folder
            }
        };
        const latestAPK: any = await lastValueFrom(this.gateway.get(options)).catch((err) => this.baseLogger.phic.log(err));
        return latestAPK;
    }

    public async getLatest(folder: string): Promise<any[] | any> {
        const endpoint = 'v1/apk-meta/latest';
        const options = {
            endpoint: endpoint,
            filter: {
                folder: folder
            }
        };
        const latestAPK = await lastValueFrom(this.gateway.get(options)).catch((err) => this.baseLogger.phic.log(err));
        return latestAPK['data'];
    }

    public async updateToLatest(folder: string, currentVersion: string): Promise<boolean> {
        const latestAPK = await this.getLatest(folder);
        if (APKService.shouldAttemptInstall(latestAPK.version, currentVersion)) {
            const url = latestAPK['downloadUrl'];
            return await this.install(url, folder);
        }

        return false;
    }

    public async install(url: string, folder: string): Promise<boolean> {
        const filePath = await this.download(url, folder);
        return await this.apkIntent.sendInstallIntent(filePath, this._packageName, this._packageName + '.MainActivity' );
    }

    private async download(url: string, folder: string): Promise<string> {
        const transfer = this.fileTransfer.create();
        const filePath = this.file.externalRootDirectory + '/Download/' + folder + '.apk';
        const entry = await transfer.download(url, filePath);
        return entry.toURL().split('file:///')[1];
    }
}

