import {Injectable} from '@angular/core';
import {Knox, VersionInfo} from 'cordova-plugin-knox';
import {getLogger} from '@hrs/logging';
import {BuildUtility} from '@hrs/utility';

@Injectable({
    providedIn: 'root'
})
export class KnoxManageService {
    private readonly logger = getLogger('KnoxManageService');

    private mEnabled: boolean = false;
    private mVersionInfo: VersionInfo = {
        knoxAppVersion: ''
    };
    private mIMEI: string = null;

    constructor() {}

    public get enabled(): boolean {
        return this.mEnabled;
    }

    public get knoxVersion(): string {
        return this.mVersionInfo.knoxAppVersion;
    }

    public async initialize(): Promise<void> {
        this.logger.debug('initialize()');

        if (!BuildUtility.isHRSTab()) {
            this.logger.debug(`skipping init on non-tablet build`);
            return;
        }

        this.mEnabled = await Knox.isEnabled();

        if (!this.mEnabled) {
            this.logger.debug(`knox plugin is disabled, skipping subsequent plugin calls`);
            return;
        }

        await Knox.activateKnoxLicense();
        this.mIMEI = await this.getIMEI();
        this.mVersionInfo = await Knox.getVersionInfo();
    }

    public async getIMEI(): Promise<string> {
        this.mIMEI = await Knox.getIMEI();
        return this.mIMEI;
    }

    // Reboots device rather than PCMT only
    public async reboot(): Promise<void> {
        await Knox.reboot();
    }

    public async openWifiSettings(): Promise<void> {
        return Knox.openWifiSettings();
    }
}
