import {Injectable} from '@angular/core';
import {KnoxIntentService} from './knox-intent.service';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {BuildUtility} from '@hrs/utility';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class LicenseIntentService extends KnoxIntentService {
    private readonly logger = getLogger('LicenseIntentService');

    public static ACTION_ACTIVATE_LICENSE: string = 'com.hrs.intent.action.ACTIVATE_LICENSE';
    public static ACTION_GET_LICENSE: string = 'com.hrs.intent.action.GET_LICENSE';
    license: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected async registerListeners(): Promise<void> {
        if (!BuildUtility.isHRSTab()) return;
        super.registerListener(LicenseIntentService.ACTION_GET_LICENSE, true).subscribe((event) => {
            this.logger.phic.debug('Listener for Get License', event);
            this.license.next(event);
        });
    }

    public sendLicenseKeyToPCKS(licenseKey: string): Promise<boolean> {
        const extras = {
            license_key: licenseKey,
        };

        return super.sendIntent(LicenseIntentService.ACTION_ACTIVATE_LICENSE, true, extras);
    }
}
