import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2
} from '@angular/core';
import {OverlayComponent} from '../overlay.component';
import {AlertButtonOptions} from '@hrsui/core/dist/types/components/alert/alert.interface';
import {textAlignment} from '@hrsui/core/dist/types/components/text/text.interface';
import {DomService} from '../../services/overlay/dom.service';
import {
    OverlayConfig,
    OverlayRef
} from '../overlay';

@Component({
    selector: 'alert-overlay',
    template: `
        <hrs-alert
            #hrsAlert
            class="{{cssClass}}"
            [variant]="variant"
            [hasFooter]="!hasNoFooter"
            [header]="header"
            [hasSpinner]="hasSpinner"
            [message]="message"
            [messageAlign]="messageAlign"
            [messageSpacing]="messageSpacing"
            [details]="details"
            [buttons]="buttons"
            [backdropDismiss]="backdropDismiss"
            [qa]="qa"
        ></hrs-alert>
    `
})
export class AlertTemplateComponent extends OverlayComponent {
    public backdropDismiss: boolean = true;
    public buttons: AlertButtonOptions[];
    public cssClass: string;
    public details: string[];
    public hasNoFooter: boolean;
    public hasSpinner: boolean;
    public header: string;
    public message: string;
    public messageAlign: textAlignment;
    public messageSpacing: boolean;
    public variant: string = 'default';
    public qa: string;

    constructor(
        cd: ChangeDetectorRef,
        dom: DomService,
        el: ElementRef,
        renderer: Renderer2,
        config: OverlayConfig,
        ref: OverlayRef,
    ) {
        super(cd, dom, el, renderer, config, ref);
    }
}
