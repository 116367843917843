import {Inject, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HRSStorage} from '../storage/storage';

/**
 * A simple settings/config class for storing key/value pairs with persistence.
 */
@Injectable({
    providedIn: 'root',
})
export class Settings {
    private SETTINGS_KEY: string = 'settings';
    settings: any = {};
    _defaults: any;
    _readyPromise: Promise<any>;
    public settingsUpdated: Subject<Settings> = new Subject();

    constructor(
        // private encryptionService: EncryptionService,
        public storage: HRSStorage,
        @Inject('DEFAULTS') defaults: any
    ) {
        this._defaults = defaults;
    }

    async load() {
        return this.storage.get(this.SETTINGS_KEY).then((value) => {
            if (value) {
                this.settings = value;
                this.settingsUpdated.next(this);
                return this._mergeDefaults(this._defaults);
            } else {
                return this.setAll(this._defaults).then((val) => {
                    this.settings = val;
                });
            }
        });
    }

    clear(): void {
        this.settings = this._defaults;
    }

    _mergeDefaults(defaults: any) {
        for (let k in defaults) {
            if (!(k in this.settings)) {
                this.settings[k] = defaults[k];
            }
        }
        return this.setAll(this.settings);
    }

    merge(settings: any) {
        for (let k in settings) {
            if (k) {
                this.settings[k] = settings[k];
            }
        }
        return this.save();
    }

    setValue(key: string, value: any) {
        this.settings[key] = value;
        return this.storage.set(this.SETTINGS_KEY, this.settings);
    }

    setAll(value: any) {
        return this.storage.set(this.SETTINGS_KEY, value);
    }

    getValue(key: string) {
        return this.settings[key];
    }

    save() {
        return this.setAll(this.settings);
    }

    get allSettings() {
        return this.settings;
    }
}
