import {Injectable, NgZone} from '@angular/core';
import {WelchService, WELCH_BP_SCALE_PAIRING_PASS_KEY} from './welch.service';
import {BLE} from '@ionic-native/ble/ngx';
import {HRSStorage} from '../../storage/storage';
import {ModalService} from '@hrs/providers';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class WelchBPService extends WelchService {
    private readonly logger = getLogger('WelchBPService');

    // Metrics
    public systolic: number;
    public diastolic: number;
    public heartRate: number;
    public SERVICE_WELCH_ALLYN_BP = '7809';
    public CHAR_BP_MEASUREMENT = '8A91';
    public DEVICE_NAME = 'BP100';
    public DEVICE_PAIRING_NAME = '1BP100';

    constructor(
        protected ble: BLE,
        protected storage: HRSStorage,
        protected ngZone: NgZone,
        protected fbanalytics: HRSFirebaseAnalytics,
        protected modalService: ModalService,
    ) {
        super(ble, storage, ngZone, modalService, fbanalytics);
    }

    /**
      * Callback function when metrics are updated
      */
    public metricUpdateCallback: (sys: number, dias: number, hr: number, peripheral: any) => void;

    public onConnected(peripheral: any, onMetricUpdateCallback: (sys: number, dias: number, hr: number, peripheral: any) => void): void {
        this.metricUpdateCallback = onMetricUpdateCallback;
        this.onPeripheralConnected(peripheral);
    }

    /**
     * Set the blood pressure specific service, characteristics and other
     * parameters for the welch base class common handling
     */
    public setBluetoothParams(): void {
        this.BASE_WELCH_SERVICE = this.SERVICE_WELCH_ALLYN_BP;
        this.BASE_WELCH_METRIC_MEASUREMENT_CHAR = this.CHAR_BP_MEASUREMENT;
        this.BASE_DEVICE_NAME = this.DEVICE_NAME;
        this.BASE_DEVICE_PAIRING_NAME = this.DEVICE_PAIRING_NAME;
        this.BASE_WELCH_PASSWORD_KEY = WELCH_BP_SCALE_PAIRING_PASS_KEY;
    }

    /**
     * Enable characteristics for notification
     * @param peripheral
     */
    public enableCharacteristics(peripheral: any): void {
        this.enableBPCharacteristics(peripheral);
        this.enableUploadDataTransferChar(peripheral);
    }

    /**
     * Enable the blood pressure measurement characteristic and parse the response
     * @param peripheral
     */
    public enableBPCharacteristics(peripheral: any): void {
        this.ble.startNotification(peripheral.id, this.SERVICE_WELCH_ALLYN_BP, this.CHAR_BP_MEASUREMENT).subscribe(
            {
                next: (data) => {
                    this.parseWelchBPMeasurements(data[0], peripheral);
                },
                error: (error) => {
                    let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'bloodpressure');
                    this.logger.phic.error('Error in starting notification for ' + peripheral.name + error);
                    this.fbanalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                            [HRSFirebaseParams.META_DATA]: error});
                }
            }
        );
    }

    /**
     * Parse the blood pressure vitals as received from the monitor, also pass them
     * for the next handling
     * @param arrayBuffer
     * @param peripheral
     */
    public parseWelchBPMeasurements(arrayBuffer: ArrayBuffer, peripheral: any): void {
        let data = new Uint8Array(arrayBuffer);
        this.ngZone.run(() => {
            this.logger.phic.debug('Got data from Welch BP ' + BluetoothUtils.buf2hex(arrayBuffer));
            // Extract measurement data
            this.systolic = ((data[2] & 0xff) << 8) | (data[1] & 0xff);

            this.diastolic = ((data[4] & 0xff) << 8) | (data[3] & 0xff);
            var map = ((data[6] & 0xff) << 8) | (data[5] & 0xff);
            this.logger.phic.debug('Welch BP Received sys/dia: ' + this.systolic + '/' + this.diastolic + ' map ' + map);

            // Extract heart rate
            this.heartRate = ((data[12] & 0xff) << 8) | (data[11] & 0xff);
            this.logger.phic.debug('Welch BP Received heart rate:' + this.heartRate);

            // Extract timestamp
            var timeStamp = ((data[10] & 0xff) << 24) | ((data[9] & 0xff) << 16) |
                    ((data[8] & 0xff) << 8) | (data[7] & 0xff);
            this.logger.phic.debug('Welch BP Received BP timestamp (sec) ' + timeStamp);

            if (this.isMostRecentReading(this.getNormalizedTime(timeStamp), 120)){
                this.logger.phic.debug('Got recent reading from Welch BP Systolic ' + this.systolic + ' Diastolic ' + this.diastolic + ' heart rate ' + this.heartRate + ' will show on metric reading page for submission');
                this.metricUpdateCallback(this.systolic, this.diastolic, this.heartRate, peripheral);
                this.gotRecentReading = true;
                // app needs to send ACK disconnect after proper reading is received
                this.sendDisconnect(peripheral);
            } else {
                this.logger.phic.debug('Old reading from Welch BP with historical data received sys/dia: ' + this.systolic + '/' + this.diastolic );
                this.historicalReadingObtained = true;
            }
        });
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (!isPairingFlow) {
            let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'bloodpressure');
            if (!this.gotRecentReading && !this.readingErrorOccurred) {
                let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'bloodpressure',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
            }
        }
        // cleanup
        this.gotRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }
}
