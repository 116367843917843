<ion-list>
  <ng-container *ngFor="_ of rowCount | range">
      <ion-item lines="none">
          <ion-thumbnail slot="start">
          </ion-thumbnail>
          <ion-label>
              <h3><ion-skeleton-text [animated]="true" style="width: 85%;"></ion-skeleton-text></h3>
              <p><ion-skeleton-text [animated]="true" style="width: 70%;"></ion-skeleton-text></p>
              <p><ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text></p>
          </ion-label>
      </ion-item>
  </ng-container>
</ion-list>