import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Tarball} from '@obsidize/tar-browserify';
import {SecureLogger} from 'cordova-plugin-secure-logger';
import {lastValueFrom, timeout} from 'rxjs';
import {Device as CdvDevice} from '@ionic-native/device/ngx';
import {File as CdvFile} from '@ionic-native/file/ngx';
import {gzip} from 'pako';
import {environment} from '@app/env';
import {getLogger} from '@hrs/logging';
import {GlobalSettingsService, TokenService} from '@hrs/providers';
import {User} from '../user/user.service';
import {TabletDeviceIdService} from '../tablet-device-id/tablet-device-id';

/*
Metadata that will be included in the upload tarball.
This will give more context about the who/what/where/why/when of the log file.
*/
export interface LogUploadMetadata {

    /* Unique ID of this specific upload instance, provided by CC2 */
    id: string;

    /* Package name of the app performing the log upload (e.g. 'com.hrs.patient') */
    appId: string;

    /* ID of the user or device logged in at the time of upload */
    hrsId: string | null;

    /* ID that will be used by the backend to sort the upload in S3.
        This will be "imei" if "imei" exists; otherwise, it will be "universalMobileId"  */
    logSourceId: string;

    /* GUID of the device uploading logs (obfuscated GUID provided by the OS) */
    universalMobileId: string | null;

    /* IMEI of the device uploading logs */
    imei: string | null;

    /* Provisioned environment for the app at the time of log upload */
    adminEnv: string | null;

    /* Active gateway token at the time of upload */
    token: string | null;

    /* User description of the problem / reason for upload */
    userReport: string | null;

    /* Schema version of the uploaded content */
    logFileVersion: number;
}

/*
Upload options provided by CC2 that give us an S3 bucket / unique file name to upload to.

Sample Data:

{
  "url":"https://sample-upload-host.com",
  "method":"PUT",
  "filename":"444d294a-09c7-4954-ae59-a9082d82dc1b.tar",
  "s3link":"s3:://staging-staging1-device-logdumps/ingest/444d294a-09c7-4954-ae59-a9082d82dc1b.tar",
  "s3key":"ingest/444d294a-09c7-4954-ae59-a9082d82dc1b.tar"
}
*/
export interface LogUploadServiceRequestData {

    /* Upload URL */
    url: string;

    /* Upload HTTP Method (i.e. "PUT") */
    method: string;

    /* Unique name (ID) of the file being uploaded */
    filename: string;

    /* Direct URL to the upload destination */
    s3link: string;

    /* Key to access the upload destination */
    s3key: string;
}

function updateLocalStorageEntry(key: string, value: any): void {
    if (value) {
        localStorage.setItem(key, value);
    } else {
        localStorage.removeItem(key);
    }
}

const LOG_FILE_VERSION = 2;

@Injectable({
    providedIn: 'root'
})
export class LogUploadService {
    public static readonly ERR_UPLOAD_IN_PROGRESS = 'ERR_UPLOAD_IN_PROGRESS';
    private static readonly ELK_HOST_NAME_KEY = 'elkHostName';
    private static readonly ELK_TOKEN_KEY = 'elkToken';
    private readonly logger = getLogger('LogUploadService');

    private elkHostName: string = '';
    private elkToken: string = '';
    private logUploadRequestUrl: string = '';
    private logUploadInProgress: boolean = false;

    constructor(
        private readonly http: HttpClient,
        private readonly cdvDevice: CdvDevice,
        private readonly cdvFile: CdvFile,
        private readonly globalSettingsService: GlobalSettingsService,
        private readonly tabletDeviceIDService: TabletDeviceIdService,
        private readonly tokenService: TokenService,
        private readonly user: User,
    ) {
        this.loadFromStorage();
    }

    private loadFromStorage(): void {
        this.logger.trace(`loadFromStorage()`);
        this.elkHostName = localStorage.getItem(LogUploadService.ELK_HOST_NAME_KEY) || '';
        this.elkToken = localStorage.getItem(LogUploadService.ELK_TOKEN_KEY) || '';
        this.syncRequestUrl();
    }

    private updateStorage(): void {
        this.logger.trace(`updateStorage()`);
        updateLocalStorageEntry(LogUploadService.ELK_HOST_NAME_KEY, this.elkHostName);
        updateLocalStorageEntry(LogUploadService.ELK_TOKEN_KEY, this.elkToken);
        this.syncRequestUrl();
    }

    private syncRequestUrl(): void {
        if (!this.elkHostName) {
            this.logger.warn(`syncRequestUrl() elkHostName not set!`);
            this.logUploadRequestUrl = '';
            return;
        }

        if (!this.elkToken) {
            this.logger.warn(`syncRequestUrl() elkToken not set!`);
            this.logUploadRequestUrl = '';
            return;
        }

        this.logUploadRequestUrl = `${this.elkHostName}/presign?token=${encodeURIComponent(this.elkToken)}`;
        this.logger.trace(`syncRequestUrl() url set to ${this.logUploadRequestUrl}`);
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);

        try {
            await this.globalSettingsService.globalSettingsLoading;
            const globalAttributes = this.globalSettingsService.getGlobalAttributes();
            this.elkHostName = globalAttributes.ELK_HOST_NAME;
            this.elkToken = globalAttributes.ELK_TOKEN;
            this.logger.trace(`loaded settings: elkHostName='${this.elkHostName}', elkToken='${this.elkToken}'`);
            this.updateStorage();
        } catch (e) {
            this.logger.phic.error(`failed to pull options from global settings -> ${e}`, e);
        }
    }

    public async uploadLogs(userReport: string = null): Promise<any> {
        this.logger.info(`uploadLogs() userReport = "${userReport}"`);

        if (this.logUploadInProgress) {
            this.logger.warn(`skipping duplicate call (log upload already in progress)`);
            return Promise.reject(LogUploadService.ERR_UPLOAD_IN_PROGRESS);
        }

        this.logUploadInProgress = true;

        try {
            const {url, filename} = await this.requestUploadServiceData();
            const data = await this.generateUploadBody(filename, userReport);
            // un-comment this line to save to a local file on the device for debugging
            // await this.saveLocalLogSnapshot(data);
            this.logger.info(`uploading ${data.size} bytes to ${url}`);
            const responseStream = this.http.put(url, data).pipe(timeout(30000));
            const result = await lastValueFrom(responseStream);
            this.logger.info(`log upload success!`, result);
            this.logUploadInProgress = false;
            return result;
        } catch (e) {
            this.logger.error(`log upload failed! -> ${e}`, e);
            this.logUploadInProgress = false;
            return Promise.reject(e);
        }
    }

    public async requestUploadServiceData(): Promise<LogUploadServiceRequestData> {
        if (!this.logUploadRequestUrl) await this.initialize();
        this.logger.trace(`requestUploadServiceData()`);
        const responseStream = this.http.get(this.logUploadRequestUrl).pipe(timeout(10000));
        const data: any = await lastValueFrom(responseStream);
        this.logger.debug(`fetched upload data -> ${JSON.stringify(data, null, '\t')}`);
        return data;
    }

    // Debugging purposes only - should not be used in production
    public async saveLocalLogSnapshot(tarballData: Blob): Promise<void> {
        const fileDirectory = this.cdvFile.cacheDirectory;
        const fileName = `patient-connect-mobile.tgz`;
        try {
            const locationDump = JSON.stringify({fileDirectory, fileName}, null, '\t');
            this.logger.info(`saveLocalLogSnapshot() writing ${tarballData.size} bytes to ${locationDump}`);
            await this.cdvFile.writeFile(fileDirectory, fileName, tarballData, {replace: true});
            this.logger.info(`saveLocalLogSnapshot() success!`);
        } catch (e) {
            this.logger.error(`saveLocalLogSnapshot() error! -> ${e}`);
        }
    }

    public getDeviceUniqueIdentifier(): string {
        const {uuid, platform, model} = this.cdvDevice;
        const isAndroidDevice = (platform || '').toLowerCase().includes('android');
        let result: string = '';

        if (isAndroidDevice) {
            // Android UUID is only specific to manufacturer/model,
            // so need to include model to make it unique.
            const safeAndroidModel = (model || 'ANDROID_GENERIC').replace(/ /g, '_');
            const safeAndroidUuid = (uuid || '').padStart(16, '0');
            result = `${safeAndroidModel}-${safeAndroidUuid}`;
        } else {
            // iOS uuid is unique per app install
            result = uuid;
        }

        this.logger.trace(`getDeviceUniqueIdentifier() result = "${result}"`);
        return result;
    }

    public generateLogUploadDataHeader(metadata: LogUploadMetadata): string {
        const logFileHeaderPayload = JSON.stringify(metadata);
        this.logger.debug(`generateLogUploadDataHeader() from ${logFileHeaderPayload}`);
        return `Metadata-Content-Length=${logFileHeaderPayload.length}\n${logFileHeaderPayload}\n\n`;
    }

    public async generateUploadBody(uploadId: string, userReport: string = null): Promise<Blob> {
        this.logger.trace(`generateUploadBody() uploadId = "${uploadId}", userReport = "${userReport}"`);
        const imei = this.tabletDeviceIDService._imei;
        const universalMobileId = this.getDeviceUniqueIdentifier();

        const metadata: LogUploadMetadata = {
            id: uploadId,
            appId: environment.app_id,
            logSourceId: imei || universalMobileId,
            universalMobileId: universalMobileId || null,
            imei: imei || null,
            hrsId: this.user.id || null,
            adminEnv: this.user.environment || null,
            token: this.tokenService.token || null,
            userReport,
            logFileVersion: LOG_FILE_VERSION
        };

        const logFileHeaderStr = this.generateLogUploadDataHeader(metadata);
        const logFileHeader = new TextEncoder().encode(logFileHeaderStr);

        const secureLoggerBuffer = await SecureLogger.getCacheBlob();
        this.logger.debug(`loaded ${secureLoggerBuffer.byteLength} bytes from logger plugin cache`);

        const secureLoggerUint8 = new Uint8Array(secureLoggerBuffer);
        const logFileData = new Uint8Array(logFileHeader.byteLength + secureLoggerUint8.byteLength);

        logFileData.set(logFileHeader, 0);
        logFileData.set(secureLoggerUint8, logFileHeader.byteLength);

        const uploadData = new Tarball()
            .addTextFile(`metadata.json`, JSON.stringify(metadata))
            .addBinaryFile(`app.log`, logFileData)
            .toUint8Array();

        const zippedData = gzip(uploadData);
        this.logger.trace(`log upload data compressed to ${zippedData.byteLength} bytes`);

        return new Blob([zippedData.buffer]);
    }
}
