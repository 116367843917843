<ion-content scroll="false" class="login-page c_splash--pcm">
    <hrs-page variant="splash" class="c_splash--login_container">
        <div class="c_splash--welcome_intro">
            <hrs-text font="h1-semibold" qa="login_intro_text">
                {{ 'WELCOME_INTRO' | translate }}
            </hrs-text>
            <hrs-text font="h2-bold" class="c_splash--app_name" qa="login_name_text">
                {{ 'WELCOME_APP_NAME' | translate }}
            </hrs-text>
            <div class="c_splash--logo_container"  (click)="openAdmin()">
                <img src="/../../assets/img/logo-blue.svg" alt="HRS Logo" class="c_splash--logo" data-qa="welcome--logo" />
            </div>
        </div>
        <form class="c_splash--form"
            [formGroup]="account"
            (ngSubmit)="doLogin()"
        >
            <div class="c_splash--form_inputs">
                <hrs-text font="h2-semibold">{{ 'SIGN_IN_TITLE' | translate }}</hrs-text>
                <hrs-form-item>
                    <hrs-label text="{{ 'SIGN_IN_NAME' | translate }}"></hrs-label>
                    <hrs-input #sign_in_name
                                type="text"
                                placeholder='Tap to enter'
                                qa="login--input-username"
                                formControlName="username"></hrs-input>
                </hrs-form-item>
                <hrs-form-item>
                    <hrs-label text="{{ 'SIGN_IN_CODE' | translate }}"></hrs-label>
                    <hrs-input #sign_in_code
                                type="text"
                                placeholder='Tap to enter'
                                qa="login--code"
                                formControlName="loginCode"></hrs-input>
                </hrs-form-item>
            </div>
            <hrs-button data-qa="login--btn-submit"
                        type="submit"
                        [disabled]="!account.valid || loggingIn">
                {{ 'SIGN_IN_BUTTON' | translate }}
            </hrs-button>
        </form>
        <div class="c_splash--contact">
            <hrs-text font="h3-regular">{{ 'WELCOME_CONTACT_SUPPORT' | translate }}</hrs-text>
            <hrs-text font="h3-regular">{{'WELCOME_CONTACT_CALL' | translate }}
                <a href="javascript:void(0)" (click)="initSupportCall()">
                    {{ 'WELCOME_SUPPORT_NUMBER' | translate }}
                </a>
            </hrs-text>
        </div>
    </hrs-page>
</ion-content>
