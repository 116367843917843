<hrs-content>
    <div class="imaging-container">
        <ng-container *ngIf="!image">
            <span *ngIf="!imageLoading && !imageTaken && !isHRSTablet" class="image-icon-background">
                <hrs-icon icon="wound-imaging" qa="wound_imaging--icon"></hrs-icon>
            </span>
            <hrs-text *ngIf="!imageLoading && !isHRSTablet" font="h2-semibold" color="sub-dark" align="center" qa="wound_imaging--text-help">{{ helpText }}</hrs-text>
        </ng-container>
        <img *ngIf="image" data-qa="wound_imaging--image" [src]="domSanitizer.bypassSecurityTrustUrl(imageSrc)" (load)="imageLoaded()" />
    </div>
</hrs-content>

<hrs-footer *ngIf="!imageTaken" [attr.variant]="isHRSTablet ? 'two-button' : 'one-button'">
    <hrs-button *ngIf="isHRSTablet" type="button" variant="secondary" icon="refresh" qa="wound_imaging--btn-switch" [disabled]="imageLoading" (click)="switchCameraFeed()"></hrs-button>
    <hrs-button type="button" [expand]="!isHRSTablet" [disabled]="imageLoading" [loading]="imageLoading" (click)="takePicture()" qa="wound_imaging--btn-picture">
        {{ 'WOUND_IMAGING_TAKE_PICTURE_BUTTON' | translate }}
    </hrs-button>
</hrs-footer>

<hrs-footer *ngIf="imageTaken" variant="two-button">
    <hrs-button type="button" variant="secondary" icon="redo" qa="wound_imaging--btn-retake" (click)="retakePicture()"></hrs-button>
    <hrs-button type="button" (click)="save()" qa="wound_imaging--btn-submit" [disabled]="!image || saving" [loading]="saving">
        {{ 'SAVE_BUTTON' | translate }}
    </hrs-button>
</hrs-footer>
