import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {
    from as fromPromise,
    Observable,
    of,
    retry,
    Subject,
    Subscription,
    throwError,
} from 'rxjs';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {GatewayApi} from '@hrs/providers';
import {getLogger} from '@hrs/logging';
import {ActivityTask, MedicationTask, SurveyTask, Task, TaskStorageKey, TaskType} from '.';
import {Schedule, SchedulerService} from '../schedule';
import {TaskCollection} from './task.collection';
import {TaskMetaData} from './task-metadata.interface';
import {OfflineTaskService} from './task-offline.service';
import {MULTIPLE_REMINDER_TYPES, Reminder} from './multiple-reminders.interface';
import {EnvironmentService} from '../environment/environment.service';
import {User} from '../user/user.service';
import {HRSSecureCache} from '../storage/cache';
import {HRSStorage} from '../storage/storage';
import {EventService} from '../events/event.service';
import {DailyMetrics} from '../daily-metrics/daily-metrics.service';

export interface TaskFetchError {
    error: any;
    retryCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    private readonly logger = getLogger('TaskService');
    private readonly taskFetchErrorSubject = new Subject<TaskFetchError>();

    private SUPPORTED_TYPES = [
        TaskType.Activity,
        TaskType.BloodPressure,
        TaskType.Glucose,
        TaskType.Medication,
        TaskType.PulseOx,
        TaskType.Steps,
        // TaskType.Stethoscope,
        TaskType.Survey,
        TaskType.Temperature,
        TaskType.Tracking,
        TaskType.Weight,
        TaskType.WoundImaging
    ];

    public tasks: Task[];
    public taskUpdated: Subject<Task> = new Subject();
    public readonly taskFetchError$: Observable<TaskFetchError>;

    private hasMultipleReminders: boolean = false;
    private multipleReminders: Reminder[];

    private environmentLoadedSubscription: Subscription;
    private loginStateChangeSubscription: Subscription;
    private offlineMetricUploadedSubscription: Subscription;

    static getTasksRetryIntervals = {
        1: 5 * 1000,
        2: 30 * 1000,
        3: 75 * 1000
    };

    constructor(
        private cache: HRSSecureCache,
        private dailyMetrics: DailyMetrics,
        private eventService: EventService,
        private environmentService: EnvironmentService,
        private gatewayApi: GatewayApi,
        private offlineTaskService: OfflineTaskService,
        private scheduler: SchedulerService,
        private storage: HRSStorage,
        private translate: TranslateService,
        private user: User
    ) {
        this.taskFetchError$ = this.taskFetchErrorSubject.asObservable();
        this.loginStateChangeSubscription = this.eventService.loginStateChanged.subscribe((hasLoggedIn: boolean) => {
            if (!hasLoggedIn) { // when user logs out
                this.scheduler.clearNotifications();
            }
        });

        // offline metric was uploaded successfully, clear completedButOffline state for the task
        this.offlineMetricUploadedSubscription = this.offlineTaskService.offlineMetricUploaded.subscribe((taskId: string) => {
            this.clearCompletedButOffline(taskId);
        });

        this.user.authenticationState.subscribe((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
                this.tasks = [];
            }
        });
    }

    ngOnDestroy() {
        this.logger.debug(`ngOnDestroy()`);
        if (this.environmentLoadedSubscription) {
            this.environmentLoadedSubscription.unsubscribe();
            this.environmentLoadedSubscription = null;
        }

        if (this.loginStateChangeSubscription) {
            this.loginStateChangeSubscription.unsubscribe();
            this.loginStateChangeSubscription = null;
        }
        if (this.offlineMetricUploadedSubscription) {
            this.offlineMetricUploadedSubscription.unsubscribe();
            this.offlineMetricUploadedSubscription = null;
        }
    }

    /**
     * Orchestrator to get tasks from whereever they are stored, perform any necessary operations on them and return them
     */
    public getTasks(bypassCache: boolean = false): Observable<Task[]> {
        this.logger.debug(`getTasks | bypassCache = ${bypassCache}`);
        return this.getSavedTasks().pipe(
            switchMap((savedTasks) => {
                return this.getApiTasks(savedTasks, bypassCache);
            }),
            map((taskCollection: TaskCollection) => {
                this.logger.debug(`getTasks() filtering tasks`);
                return this.filterTasks(taskCollection);
            }),
            tap((taskCollection: TaskCollection) => {
                this.tasks = taskCollection.tasks;
                this.logger.debug(`getTasks() loaded ${this.tasks?.length} tasks` +
                    ` (fromCache = ${taskCollection?.meta?.fromCache})`);
            }),
            tap((taskCollection: TaskCollection) => {
                this.logger.debug(`getTasks() scheduling tasks`);
                this.scheduleTasks(taskCollection);
            }),
            map((taskCollection: TaskCollection) => {
                return taskCollection.tasks;
            }),
            map((tasks: Task[]) => {
                this.logger.debug(`getTasks() grouping tasks`);
                return this.groupTasks(tasks);
            }),
            map((tasks: Task[]) => {
                this.logger.debug(`getTasks() sorting tasks`);
                const sorted = this.sortTasks(tasks);
                this.eventService.tasksLoaded.next(sorted);
                return sorted;
            })
        );
    }

    /**
     * Complete a task by recording its metric reading
     */
    public submitTask(
        task: Task,
        data: any,
        metadata: TaskMetaData, // additional information about the metric (i.e. isBlueToothMetric and recordedDate)
        // isCompleteForToday defaults to true for everything except surveys since they are submitted to the server individually, but
        // have a single notification for all surveys together which we want to keep active until the final survey gets answered.
        isCompleteForToday = true
    ) {
        this.logger.debug(`submitTask()`, {task, data, metadata, isCompleteForToday});
        if (!this.dataIsValid(task, data)) {
            this.logger.phic.error('Invalid or missing metric data', data, task, metadata);
        }
        const dataToSubmit = this.getTaskDataToSubmit(task, data, metadata);
        this.logger.debug(`dataToSubmit ->`, dataToSubmit);

        // since this is only called when the patient is actively submitting metrics, making this extra request to update users last activity, see JIR-3593
        return this.gatewayApi.post('metrics', {
            data: dataToSubmit
        }).pipe(
            tap((res) => {
                this.logger.debug(`submitTask() response`, res);
                if (task.type !== TaskType.Tracking) this.markTaskCompleted(task, isCompleteForToday, res);
            }),
            catchError((error) => {
                this.logger.warn(`submitTask() catchError`, error);
                // failure scenario - task did not save!
                // locally handle errors
                if (task.type === TaskType.Tracking) {
                    this.logger.error(`submitTask() task did not save!`);
                    return throwError(() => error);
                }

                this.eventService.storingOfflineTask.next(true);
                this.logger.debug(`submitTask() catchError -> offlineTaskService.addTaskToOfflineMetricStorage`);
                return this.offlineTaskService.addTaskToOfflineMetricStorage(task.id, dataToSubmit, metadata).pipe(
                    switchMap(() => {
                        // metric was stored to offline storage, update completedButOffline state for the task
                        this.logger.debug(`submitTask() catchError -> offlineTaskService.addTaskToOfflineMetricStorage -> setCompletedButOffline`);
                        this.setCompletedButOffline(task);
                        // its important that we use "throwError" here as it lands in the error function of the subscribe()
                        // block for additional error call-site specific error handling. If we just return of(error)
                        // it sends us back to the success function of the subscribe() block.
                        return throwError(() => error);
                    }),
                    catchError((error: Error) => {
                        this.logger.error(`submitTask() catchError -> offlineTaskService.addTaskToOfflineMetricStorage -> error`, error);
                        return throwError(() => error);
                    }),
                    finalize(() => {
                        this.logger.debug(`submitTask() catchError -> offlineTaskService.addTaskToOfflineMetricStorage -> finalize`);
                        this.eventService.storingOfflineTask.next(false);
                    })
                );
            })
        );
    }

    /**
     * Get previously-saved task data from local storage - data that we'll need to merge with what comes back from the API
     */
    private getSavedTasks(): Observable<any[]> {
        this.logger.debug(`getSavedTasks`);
        return fromPromise(this.storage.get(TaskStorageKey.UI_STORAGE_KEY + this.user.id)).pipe(
            map((json: any) => {
                this.logger.debug(`getSavedTasks | success`, json);
                const jsonParse = (json) => {
                    return JSON.parse(json, (key, value) => {
                        if (key === 'lastCompleted') {
                            if (value) {
                                // JSON.parse doesn't deserialize to Date objects, so we have to do it manually
                                return moment(value).toDate();
                            }
                        } else {
                            return value;
                        }
                    }) || [];
                };

                let parsed = jsonParse(json);
                parsed.forEach((task) => {
                    if (task.subTasks) {
                        task.subTasks = jsonParse(task.subTasks);
                    }
                });
                const result = parsed || [];
                this.logger.debug(`getSavedTasks() parsed ${result?.length} tasks`);
                return result;
            }),
            catchError((err) => {
                // Failure to load the saved tasks isn't a showstopper, we'll just continue onward and load them from the API.
                this.logger.error('getSavedTasks | Error getting saved tasks', err);
                return of([]);
            })
        );
    }

    /**
     * Get tasks from the API or cache
     */
    private getApiTasks(savedTasks, bypassCache: boolean = false): Observable<TaskCollection> {
        this.logger.debug(`getApiTasks | bypassCache = ${bypassCache}`);
        // DEV-11580:
        // There has been an issue where a tablet was able to make GET /user and GET /tasks requests with an IMEI in the path.
        // This overloaded the User service and caused it to crash.
        // Log user out if the token is type Device.
        if (this.user.isDevice()) {
            this.logger.error(`Getting API tasks. User ${this.user.environment} ${this.user.id} does not have an HRSID. Logging out.`);
            this.user.logout();
            return throwError(() => new Error('Invalid User'));
        }
        return this.cache.loadFromDelayedObservable(
            TaskStorageKey.CACHE_KEY + this.user.id,
            this.getTasksApiWrapper(),
            undefined,
            undefined,
            bypassCache ? 'all' : undefined,
            'meta'
        ).pipe(
            map((res:any) => {
                this.logger.debug(`getApiTasks | loadFromDelayedObservable -> next`);
                let taskCollection = new TaskCollection();
                let apiTasks;
                let medicationCounts = {};

                // remove any null data sets
                apiTasks = res.data.filter((data) => {
                    return data;
                });

                let fromCache: boolean = false;
                taskCollection.meta = res.meta;
                if (taskCollection.meta && taskCollection.meta.fromCache) {
                    this.logger.phic.debug(`getApiTasks: loadFromDelayedObservable loaded ${apiTasks?.length} from cache`);
                    fromCache = true;
                } else {
                    this.logger.phic.debug(`getApiTasks: loadFromDelayedObservable loaded ${apiTasks?.length} from API`);
                }

                // data returned from /v1/tasks endpoint has all attributes nested in the attributes property
                // need to flatten that for consistency with data returned from /tasks endpoint
                apiTasks = apiTasks.map((data) => {
                    data = {...data, ...data.attributes};
                    delete data.attributes;
                    return data;
                });

                // apply multiple reminders, if any, and setup schedules accordingly in each task
                apiTasks = [...this.setTaskSchedules(apiTasks)];

                // merge the tasks from the API with the savedTasks (the tasks in the cache)
                taskCollection.tasks = apiTasks.map((data) => this.createTask(data, savedTasks, medicationCounts, fromCache));
                this.logger.debug(`getApiTasks | task collection constructed successfully!`);
                return taskCollection;
            })
        );
    }

    private getTasksApiWrapper(): Observable<any> {
        this.logger.debug(`getTasksWrapper`);
        this.hasMultipleReminders = this.environmentService.hasMultipleReminders();
        let endpoint: string = this.hasMultipleReminders ? 'v1/tasks' : 'tasks';

        return this.gatewayApi.get(endpoint + '/?filter[patient]=' + this.user.id).pipe(
            tap((res) => {
                this.logger.debug(`getTasksWrapper | got tasks successfully`, res);
            }),
            retry({
                // retry hook will trigger on GET error and try up to 3 times.
                // If all 3 times fail, the observable will error out.
                count: 3,
                delay: ((err: any, retryCount: number) => {
                    this.taskFetchErrorSubject.next({error: err, retryCount});
                    this.logger.error(`getTasksWrapper | retry delay hook attempt ${retryCount} in ${TaskService.getTasksRetryIntervals[retryCount]}ms`, err);
                    return new Promise((resolve) => setTimeout(resolve, TaskService.getTasksRetryIntervals[retryCount]));
                }),
                resetOnSuccess: true // reset the retry counter when the retried subscription emits its first value.
            }),
        );
    }

    /**
     * Create a Task object from the API response AND the saved Tasks (cached tasks)
     */
    private createTask(data, savedTasks, medicationCounts, fromCache): Task {
        let task: Task;

        // Data to initialize the task regardless of what type it is
        // We defer initializing the task variable so that we can call the correct constructor for derived Task types
        let taskData = {
            id: data.type,
            type: data.type,
            title: this.getTaskTitle(data.type),
            schedule: data.schedule ? data.schedule.map((sched) => new Schedule(sched)) : [],
            fromCache: fromCache
        };

        if (data.action) {
            if (data.type === TaskType.Medication) {
                task = new MedicationTask(taskData);

                // Each medication could be prescribed multiple times in the day. But they don't have real IDs from the backend.
                // In order to keep track of which meds have been taken today in the scenario when the clinician modifies the scheduled time
                // AFTER the patient has already taken a medication, we're assigning it an ID composed of the name and index.
                let medicationName = data.action.name;
                if (!medicationName) {
                    medicationName = 'unknown';
                }
                if (medicationCounts[medicationName] === undefined) {
                    medicationCounts[medicationName] = 0;
                }

                (task as MedicationTask).set({
                    id: task.type + '-' + medicationName + '-' + ++medicationCounts[medicationName],
                    medicationId: data.action.medicationId,
                    name: data.action.name || this.translate.instant('MEDICATION_NAME_UNKNOWN'),
                    strength: data.action.strength,
                    units: data.action.units,
                    count: data.action.count,
                    route: data.action.route,
                    dosage: data.action.dosage,
                    instruction: data.instruction
                });
            } else if (data.type === TaskType.Survey) {
                task = new SurveyTask(taskData);
                (task as SurveyTask).set({
                    id: task.type + '-' + data.action.surveyId,
                    surveyId: data.action.surveyId,
                    answered: data.action.answered,
                    onetime: data.action.onetime,
                    discharge: data.action.discharge,
                    question: data.action.question,
                    choices: data.action.choices
                });
                if (data.areAnswersInReverseOrder && data.action.choices) {
                    (task as SurveyTask).choices.reverse();
                }
            } else if (data.type === TaskType.Activity) {
                task = new ActivityTask(taskData);
                (task as ActivityTask).set({
                    goal: data.action.goal
                });
            }
        }
        if (!task) {
            task = new Task({
                id: data.type,
                type: data.type,
                title: this.getTaskTitle(data.type),
                schedule: data.schedule ? data.schedule.map((sched) => new Schedule(sched)) : [],
                hasMultipleReminders: this.hasMultipleReminders,
                fromCache: fromCache
            });
        }

        // Look up and apply previously-saved lastCompleted date to the API task object
        if (savedTasks && Array.isArray(savedTasks) && task.id) {
            cacheApplicationLoop: // Loop Label
            for (let i = 0; i < savedTasks.length; i++) {
                const savedTask = savedTasks[i];
                const applyCachedProperties = (cachedTask) => {
                    if (cachedTask && cachedTask.id && cachedTask.id === task.id) {
                        // update the task with the "lastCompleted" date from the cache
                        // persist isTaskReset to prevent 'completion' upon retrieving new tasks from getTasks()
                        task.lastCompleted = cachedTask.lastCompleted;
                        task.isTaskReset = cachedTask.isTaskReset;
                        if (!!cachedTask.lastCompletedButOffline) {
                            // update the task with the "lastCompletedButOffline" Date from the cache...
                            // ...when the patient's device is offline and they try to save a metric...
                            // ...it will get flagged with the "lastCompletedButOffline" Date...
                            // ...which gets added to the task object here
                            task.lastCompletedButOffline = cachedTask.lastCompletedButOffline;
                        } else {
                            task.lastCompletedButOffline = null;
                        }
                        return true;
                    }
                    return false;
                };

                if (savedTask.subTasks) {
                    for (const subtask of savedTask.subTasks) {
                        if (applyCachedProperties(subtask)) break cacheApplicationLoop;
                    }
                } else {
                    if (applyCachedProperties(savedTask)) break cacheApplicationLoop;
                }
            }
        }

        return task;
    }

    private getTaskTitle(taskType): string {
        let key = 'TASK_TITLE_' + taskType.toUpperCase();
        let translation = this.translate.instant(key);
        if (translation && translation !== key) {
            return translation;
        } else {
            // There wasn't a translation, so just use the type and capitalize it
            return taskType.charAt(0).toUpperCase() + taskType.substr(1);
        }
    }

    private setTaskSchedules(apiTasks: any): [] {
        // pull out the multiple reminder data from the task data, if any
        let reminders = apiTasks.filter((task) => task.type === TaskType.ModuleReminder);
        this.multipleReminders = reminders.map((reminder) => {
            return {
                id: reminder.id,
                schedule: new Schedule(reminder.schedule),
                modules: reminder.modules
            };
        });

        this.logger.debug(`setTaskSchedules | {multipleReminders = ${!!this.multipleReminders}}`);

        // filter out the multiple reminder data from the tasks
        let tasks = apiTasks.filter((task) => task.type != TaskType.ModuleReminder);

        // setup schedule array for each task
        tasks.forEach((task) => task.schedule = task.schedule ? [task.schedule] : undefined);

        // if there are multiple reminders, add schedule data to the individual task's data
        if (reminders) {
            reminders.forEach((reminder) => {
                if (reminder.modules) {
                    reminder.modules.forEach((module) => {
                        const moduleTask = tasks.find((task) => task.type === module.name);
                        if (moduleTask) {
                            if (!moduleTask.schedule) moduleTask.schedule = [];
                            moduleTask.schedule.push(reminder.schedule);
                        }
                    });
                }
            });
        }
        return tasks;
    }

    /**
     * Filter out tasks received from the back end that this app doesn't support or that are invalid
     *
     * Note that we are filtering out invalid tasks at this early stage in the service (rather than later when showing a task view, for
     * example) so that we don't schedule notifications to be shown when there wouldn't be anything for the user to take action on.
     */
    private filterTasks(taskCollection: TaskCollection): TaskCollection {
        this.logger.debug(`filterTasks() unfiltered count = ${taskCollection?.tasks?.length}`);
        taskCollection.tasks = taskCollection.tasks.filter((task) => {
            return this.SUPPORTED_TYPES.indexOf(task.type) > -1 && task.isValid();
        });
        this.logger.debug(`filterTasks() filtered count = ${taskCollection?.tasks?.length}`);
        return taskCollection;
    }

    /**
     * The API provided tasks to us flattened, meaning that there are multiple tasks with type=medication or type=survey
     * Group those tasks together by type here since that's how we want to present them in the UI.
     */
    private groupTasks(tasks: Task[]): Task[] {
        this.logger.debug(`groupTasks()`);

        let groupedTasks: Task[] = [];
        let groups = {};

        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];

            if (task.type !== TaskType.Medication && task.type !== TaskType.Survey) {
                groupedTasks.push(task);
            } else {
                if (!groups[task.type]) {
                    groups[task.type] = new Task({
                        type: task.type,
                        title: task.title,
                        subTasks: []
                    });
                }
                groups[task.type].subTasks.push(task);
            }
        }

        for (let groupName in groups) {
            if (groupName) {
                groupedTasks.push(groups[groupName]);
            }
        }

        this.logger.debug(`groupTasks() result length = ${groupedTasks.length}, input length = ${tasks.length}`);
        return groupedTasks;
    }

    /**
     * Sort tasks alphabetically
     */
    private sortTasks(tasks: Task[]): Task[] {
        this.logger.debug(`sortTasks() sorting ${tasks.length} tasks`);
        return tasks.sort((a, b) => {
            return a.type.localeCompare(b.type);
        });
    }

    /**
     * Schedule reminder notifications for tasks
     */
    private scheduleTasks(taskCollection: TaskCollection): void {
        this.logger.debug(`scheduleTasks()`);
        // Only schedule reminders for tasks when they come from the API (rather than from cache) because if they came from cache then
        // we have already scheduled them and it would be redundant to do it again; it also could result in timing issues if the API
        // response comes back while we're still scheduling the cached tasks.
        if (!taskCollection.meta || !taskCollection.meta.fromCache) {
            this.logger.debug(`scheduleTasks() scheduling non-cache tasks that came from API`);
            this.scheduler.scheduleTasks(taskCollection.tasks, this.multipleReminders);
        }
    }

    private getTaskDataToSubmit(task: Task, data: Object, metaData: TaskMetaData): Object {
        let remindAt: string = null;
        if (task.schedule && task.schedule[0] && task.schedule[0].at &&
            !(this.hasMultipleReminders && MULTIPLE_REMINDER_TYPES.includes(task.type))) {
            let remindAtDate = moment();
            let remindAtTime = moment(task.schedule[0].at, moment.HTML5_FMT.TIME_SECONDS);
            remindAtDate.set({
                hour: remindAtTime.get('hour'),
                minute: remindAtTime.get('minute'),
                second: remindAtTime.get('second')
            });
            remindAt = remindAtDate.locale('en').format(); // see footnote on language locale
        }

        const entered = metaData.isBluetoothMetric ? 'wireless' : 'manual';
        // task data can be coming from previously entered metrics that are stored offline in ionic storage (because the user's device was offline
        // when they tried to save it) OR from a more normal flow where the user is manually entering (or bluetooth entering) a metric right _now_.
        // If coming from ionic storage, we want to use the date/time it was originally stored, not the current date/time.

        const takenAt = metaData.recordedDate ? metaData.recordedDate : moment().locale('en').format(); // see below note on "language locale"
        return {
            owner: this.user.id,
            entered: entered.toString(),
            metric: task.type,
            remindAt: remindAt,
            takenAt: takenAt,
            facet: data
        };

        // [language locale] for non-western languages moment converts the date string to a different character-set that
        // the server doesn't like so setting locale('en') to english to ensure we get a date string in western characters
        // (only an issue for non-western languages like arabic and hindi)
    }

    /**
     * markTaskComplete - updates UI_STORAGE with this.tasks putting now's date as the lastCompleted date
     * Called from submitTask()
     * @param: task: the task in this.tasks being marked as complete
     * @param: isCompleteForToday: prevents task from being scheduled later if completed before its scheduled time
     * @param: metricData: response data from submitTask() used to update the lastCompleted date of the task
     * @param: skipStorageUpdate (optional) - used when calling from submitEncryptedTask (a separate function used when uploading _offline_ metrics)
     * to skip updating the UI_STORAGE since that is done separately in a different sequence when uploading offline tasks.
     */
    private markTaskCompleted(task: Task, isCompleteForToday: boolean, metricData: any, skipStorageUpdate?: boolean): void {
        this.logger.debug(`markTaskCompleted() ${task?.type} isCompleteForToday = ${isCompleteForToday}`);
        // update the lastCompleted date with either the date it was originally recorded (may have been recorded offline at some point previous to now)
        // isTaskReset is stored in storage to correctly map the value when tasks are refreshed/added from an API response
        // markTaskCompleted is run on successful post, hence isTaskReset flag is set to false
        this.tasks.forEach((singleTask, i) => {
            if (singleTask.id === task.id) {
                this.tasks[i].lastCompleted = metricData.data && metricData.data.takenAt ? moment(metricData.data.takenAt).toDate() : moment().toDate();
                this.tasks[i].isTaskReset = false;
                this.markTaskUpdated(this.tasks[i]);
            }
        });
        if (!skipStorageUpdate) {
            // Store the tasks on the device so that we don't lose track of which ones were completed
            // the next time we reload all the tasks from the server.
            this.storage.set(TaskStorageKey.UI_STORAGE_KEY + this.user.id, JSON.stringify(this.tasks));
        }
        if (isCompleteForToday) {
            if (this.hasMultipleReminders && MULTIPLE_REMINDER_TYPES.includes(task.type)) {
                this.scheduler.skipScheduledReminders(task, this.tasks, this.multipleReminders);
            } else {
                // If the user completed the task prior to its scheduled time for today, prevent showing the notification later today
                this.scheduler.skipScheduledTask(task);
                if (task.schedule[0] && task.schedule[0].reminder) {
                    const reminder = this.scheduler.buildReminder(task, task.schedule[0]);
                    this.scheduler.skipScheduledTask(reminder);
                }
            }
        }
    }

    /**
     * markTaskUpdated - method for updating the taskUpdated Subject, so that anything that has subscribed to it is notified that
     * some process has updated a Task.
     * Used externally in MedicationPage save() to communicate status update to the HomePage Medication tile.
    */
    public markTaskUpdated(task: Task): void {
        this.taskUpdated.next(task);
    }

    public hasTask(taskType: TaskType): boolean {
        let hasTask = false;
        if (this.tasks) {
            this.tasks.forEach((task) => {
                if (task.type === taskType) hasTask = true;
            });
        }
        return hasTask;
    }

    public getTask(taskType: TaskType): Task {
        let taskIndex;
        let groupedTasks = this.groupTasks(this.tasks);

        if (groupedTasks) {
            groupedTasks.forEach((task, i) => {
                if (task.type === taskType) taskIndex = i;
            });
        }

        return taskIndex == undefined ? null : groupedTasks[taskIndex];
    }

    private setCompletedButOffline(task: Task) {
        this.logger.debug(`setCompletedButOffline()`, task);
        // update lastCompleted for task, update offline UIMetrics & notify that task has been updated
        task.lastCompleted = moment().toDate();
        // a date in lastCompletedButOffline indicates this task was not fully completed (Updates the UI with an orange checkmark)
        task.lastCompletedButOffline = task.lastCompleted;
        task.isTaskReset = false;
        this.setUIMetrics(this.tasks);
        this.markTaskUpdated(task);
    }

    private clearCompletedButOffline(taskId: string) {
        this.logger.debug(`clearCompletedButOffline()`, taskId);
        const taskIndex = this.tasks.findIndex((task) => task.id === taskId);
        // if we found a task
        if (taskIndex >= 0) {
            // update lastCompletedBufOffline for task in task list, update offline UIMetrics & notify that task has been updated
            this.tasks[taskIndex].lastCompletedButOffline = null;
            this.setUIMetrics(this.tasks);
            this.markTaskUpdated(this.tasks[taskIndex]);
        }
    }

    // also called from HomePage
    public setUIMetrics(tasks: Task[]): Observable<Task[] | Error> {
        const jsonTasks = this.stringifyTasks(tasks);
        return fromPromise(this.storage.set(TaskStorageKey.UI_STORAGE_KEY + this.user.id, jsonTasks)).pipe(
            map((response: string) => {
                return JSON.parse(response);
            }),
            catchError((err)=> {
                return of(err);
            })
        );
    }

    private stringifyTasks(tasks: Task[]): string {
        let toStringify = [];

        // JSON.stringify() removes the subTasks property if it is not stringified first.
        tasks.forEach((task: Task) => {
            const editedTask = task.subTasks ? {...task, subTasks: JSON.stringify(task.subTasks)} : task;
            toStringify.push(editedTask);
        });
        return JSON.stringify(toStringify);
    }

    public getMedicationDescription(medication: MedicationTask): string {
        const pieces = [];
        pieces.push(medication.name);
        if (medication.strength) {
            pieces.push(medication.strength);
            if (medication.units) {
                pieces.push(this.getTranslation(medication.units, 'MEDICATION.UNIT'));
            }
        }
        return pieces.join(' ');
    }

    public getMedicationMethod(medication: MedicationTask): string {
        const pieces = [];
        if (medication.count) {
            pieces.push('x' + medication.count);
        }
        if (medication.route) {
            pieces.push(this.getTranslation(medication.route, 'MEDICATION.ROUTE'));
        }
        return pieces.join(' ');
    }

    private getTranslation(medicationValue: string, translationKey: string): string {
        const key = translationKey + medicationValue.toUpperCase().replace(/ /g, '-');
        const translation = this.translate.instant(key);
        if (translation && translation !== key) {
            return translation;
        } else {
            return medicationValue;
        }
    }

    /**
     * As a result of JIR-9729, this function checks that we are not sending any empty fields to the metrics endpoint
     * The intention is mainly to log any attempts to submit data with empty value fields
     */
    private dataIsValid(task: Task, data: any) {
        switch (task.type) {
            case TaskType.Activity:
                if (data['value'] && data['value'] > 0) return true;
                break;
            case TaskType.BloodPressure:
                if (data['systolic'] && data['diastolic'] && data['heartrate'] && data['systolic'] > 0 && data['diastolic'] > 0 && data['heartrate'] > 0) return true;
                break;
            case TaskType.Glucose:
                if (data['bloodsugar'] && data['bloodsugar'] > 0) return true;
                break;
            case TaskType.PulseOx:
                if (data['spo2'] && data['hr'] && data['spo2'] > 0 && data['hr'] > 0) return true;
                break;
            case TaskType.Temperature:
                if (data['temperature'] && data['unit'] && data['temperature'] > 0) return true;
                break;
            case TaskType.Weight:
                if (data['weight'] && data['weight'] > 0) return true;
                break;
            case TaskType.WoundImaging:
                if (data['image']) return true;
                break;
            case TaskType.Survey:
                if (data['question'] && data['choices'] && data['answer']) return true;
                break;
            case TaskType.Medication:
                if (data['medication'] && data['dosage']) return true;
                break;
            case TaskType.Tracking:
                return true;
                break;
            default:
                this.logger.phic.error('Unknown task type: ' + task.type);
                break;
        }

        return false;
    }

    public updateStatusWithDailyMetrics(): void {
        if (this.tasks) {
            let updatedTask = false;
            this.tasks.forEach(async (task) => {
                if (this.dailyMetrics.supportsMetric(task.id)) { // not all tasks have daily metrics
                    const taskCompleted = task.isCompleted() && !task.isTaskReset;
                    const taskSubmitted = taskCompleted && !task.isCompletedButOffline();

                    if (this.dailyMetrics.hasMetric(task.id)) {
                        let resetTs = 0;
                        let currentTs = 0;
                        if (task.type === TaskType.Activity) {
                            const ts = await this.storage.get(HRSStorage.ACTIVITY_RESET_TS);
                            resetTs = ts || 0;
                            currentTs = this.dailyMetrics.activity.ts || 0;
                        }
                        if (task.type === TaskType.Weight) {
                            const ts = await this.storage.get(HRSStorage.WEIGHT_RESET_TS);
                            resetTs = ts || 0;
                            currentTs = this.dailyMetrics.weight.ts || 0;
                        }

                        const isReset = resetTs && resetTs >= currentTs;
                        // if a metric reading is in daily metrics and either
                        // this task was not completed on device  or  the timestamp of the metric reading is later than the task's timestamp
                        // then clinician added a metric in CC - we need to update the lastCompleted timestamp
                        const metricTimestamp: number = this.dailyMetrics.getMetricTimestamp(task.id);
                        const lastCompleted: number = task.lastCompleted ? task.lastCompleted.valueOf() : 0;
                        if ((taskCompleted && metricTimestamp > lastCompleted && !isReset) || (!taskCompleted && !isReset)) {
                            task.lastCompleted = new Date(metricTimestamp);
                            task.isTaskReset = false;
                            updatedTask = true;
                        }
                    } else if (taskSubmitted) {
                        // if task was completed and submission was successful but a reading is not in daily metrics
                        // then clinician has deleted it - we need to clear the lastCompleted timestamp
                        delete task.lastCompleted;
                        task.isTaskReset = false;
                        updatedTask = true;
                    }
                }
            });

            if (updatedTask) {
                // Store the tasks on the device so that we don't lose track of which ones were completed
                this.storage.set(TaskStorageKey.UI_STORAGE_KEY + this.user.id, JSON.stringify(this.tasks));
                // reschedule notifications
                this.scheduler.scheduleTasks(this.tasks, this.multipleReminders);
            }
        }
    }
}
