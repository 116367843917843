import {Component, Input, ViewChild} from '@angular/core';
import {PDFDocumentProxy, PDFSource, PdfViewerComponent, ZoomScale} from 'ng2-pdf-viewer';
import {getLogger} from '@hrs/logging';
import {ScreenOrientationService} from '../../services/screen-orientation/screen-orientation.service';

@Component({
    selector: 'hrs-pdf-viewer',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss'],
})
export class PdfComponent {
    private readonly logger = getLogger('PdfComponent');
    private localSrc: PDFSource | null = null;

    @ViewChild(PdfViewerComponent, {static: true})
    public pdfContent!: PdfViewerComponent;

    public showErrorAlert: (error: ErrorEvent) => void;
    public startTracking: () => void;
    public submitTracking: () => void;
    public zoomState: number = 1.0;
    public zoomScale: ZoomScale = 'page-width';
    public zoomIncrement: number = 0.1;
    public zoomMin: number = 0.5;
    public downloading: boolean = false;
    public error = false;

    constructor(
        private readonly screenOrientation: ScreenOrientationService
    ) {
        this.setZoomScale();
    }

    @Input()
    public get src(): PDFSource {
        return this.localSrc;
    }

    public set src(value: PDFSource) {
        this.logger.debug(`set src = `, value);
        this.localSrc = value;
        this.pdfContent.src = value;
        this.downloading = true;
    }

    public ngOnDestroy() {
        this.logger.trace(`ngOnDestroy()`);
        if (!this.error && this.submitTracking) this.submitTracking();
    }

    public zoomIn(): void {
        this.zoomState += this.zoomIncrement;
    }

    public zoomOut(): void {
        if (this.zoomState <= this.zoomMin) return;
        this.zoomState -= this.zoomIncrement;
    }

    private setZoomScale(): void {
        this.zoomScale = this.determineZoomScale();
    }

    private determineZoomScale(): ZoomScale {
        const isLandscape = this.screenOrientation.getCurrentOrientation().includes('landscape');
        return isLandscape ? 'page-width' : 'page-fit';
    }

    public afterLoadComplete($event: PDFDocumentProxy): void {
        this.logger.trace(`afterLoadComplete()`, $event);
        this.downloading = false;

        if (this.startTracking) {
            this.startTracking();
        }
    }

    public onError($event: any): void {
        this.logger.error(`onError()`, $event);
        this.downloading = false;

        if ($event.message === 'Worker was destroyed') {
            return;
        }

        this.error = true;

        if (this.showErrorAlert) {
            this.showErrorAlert($event);
        }
    }
}
