import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {ComponentLibraryModule} from '@hrsui/angular';
import {SurveyHistoricalDataModal} from './survey-historical-data.modal';

const routes: Routes = [
    {
        path: '',
        component: SurveyHistoricalDataModal
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [SurveyHistoricalDataModal]
})
export class SurveyHistoricalDataModule {}
