import * as moment from 'moment';
import 'moment-recur-ts';

export class Schedule {
    start?: string;
    end?: string;
    every?: {
        units?: any[] | string,
        measure?: string
    };
    window?: number;
    at: string;
    reminder?: string;

    constructor(data?: Partial<Schedule>) {
        if (data && data.at && data.at === 'PRN') {
            // To simplify logic elsewhere, the "at" property should only ever be empty or a valid time string. Filtering out PRN.
            data.at = undefined;
        }
        Object.assign(this, data);
    }

    /**
     * @returns true if the schedule is for date passed in or today, if no date is passed in
     */
    public matchesDate(date: Date = new Date()): boolean {
        if (!this.every) {
            // When "every" is not defined but the "at" time is defined, then it's implied to be everyday.
            // If neither is defined then we don't have enough information to create a schedule.
            return !!this.at;
        }
        if (this.every.measure !== 'nthDay' && this.every.measure !== 'dayOfWeek') {
            return false;
        }

        if (this.every.units === '0') {
            return false;
        }

        let interval = moment().recur({
            start: this.start,
            end: this.end,
            rules: [{
                units: this.every.units,
                measure: this.every.measure === 'nthDay' ? 'day' : this.every.measure
            }]
        });

        return interval.matches(date);
    }

    /**
     * @returns true if the scheduled time is before now
     */
    public matchesTime(): boolean {
        if (!this.at) {
            return false;
        }
        const scheduledTime = moment(this.at, 'HH:mm');
        const scheduledTimeGranular = moment(this.at, moment.HTML5_FMT.TIME_SECONDS);

        return scheduledTime.isBefore(moment()) && scheduledTimeGranular.isSame(moment(), 'day');
    }

    /**
     * @returns true if the scheduled reminder window end is before now
     */
    public missedWindow(): boolean {
        if (!this.at || !this.window) {
            return false;
        }
        const scheduledTime = moment(this.at, 'HH:mm');
        const timeWindowClosed = moment(scheduledTime).add(this.window, 'm');

        return this.matchesTime() && timeWindowClosed.isBefore(moment());
    }

    /**
     * @returns true if the time passed in, or now if no time is passed in, falls between the scheduled time +/- the defined reminder window
     */
    public inAdherenceWindow(time: moment.Moment = moment()): boolean {
        if (!this.at || !this.window) {
            return false;
        }
        const scheduledTime = moment(this.at, 'HH:mm');
        const scheduledTimeGranular = moment(this.at, moment.HTML5_FMT.TIME_SECONDS);
        const timeWindowOpened = moment(scheduledTime).subtract(this.window, 'm');
        const timeWindowClosed = moment(scheduledTime).add(this.window, 'm');

        return scheduledTimeGranular.isSame(time, 'day') && timeWindowOpened.isBefore(time) && timeWindowClosed.isAfter(time);
    }

    /**
     * @returns true if the time passed in, or now if no time is passed in, falls before the scheduled time - the defined reminder window
     */
    public beforeAdherenceWindow(time: moment.Moment = moment()): boolean {
        if (!this.at || !this.window) {
            return false;
        }
        const scheduledTime = moment(this.at, 'HH:mm');
        const scheduledTimeGranular = moment(this.at, moment.HTML5_FMT.TIME_SECONDS);
        const timeWindowOpened = moment(scheduledTime).subtract(this.window, 'm');

        return scheduledTimeGranular.isSame(time, 'day') && timeWindowOpened.isAfter(time);
    }
}
