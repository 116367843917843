<div class="c_admin_kiosk">
    <ion-list>
        <ion-list-header>
            <ion-label>{{'ADMIN_REGISTRATION.CURRENT_REGISTRATION_HEADER' | translate}}</ion-label>
        </ion-list-header>
        <p class="{{currentStatus === 'Active' ? 'status_active' : 'status_inactive'}}">{{currentStatus}}</p>

        <ion-item>
            <ion-label>IMEI:</ion-label>
            <p>{{currentIMEI}}</p>
        </ion-item>
        <ion-item>
            <ion-label>{{'ADMIN_REGISTRATION.NAME_LABEL' | translate}}:</ion-label>
            <p>{{currentName}}</p>
        </ion-item>
        <ion-item>
            <ion-label>{{'ADMIN_REGISTRATION.ENV_LABEL' | translate}}:</ion-label>
            <p>{{currentEnv}}</p>
        </ion-item>
    </ion-list>

    <ion-list>
        <ion-list-header>
            <ion-label>{{'ADMIN_REGISTRATION.DROPDOWN_LABEL' | translate}}</ion-label>
        </ion-list-header>

        <ion-select [(ngModel)]="selectedEnv" [value]="selectedEnv" placeholder="{{currentEnv}}">
            <ion-select-option *ngFor="let environment of environments" value="{{environment.id}}">{{environment.id}}</ion-select-option>
        </ion-select>

        <ion-buttons class="c_app_header--buttons">
            <ion-button class="c_button" (click)="register()">{{'ADMIN_REGISTRATION.BUTTON' | translate}}</ion-button>
        </ion-buttons>
    </ion-list>
</div>
