<hrs-wizard [config]="wizardConfig" variant="qna">
    <ng-container *ngIf="surveys.length">
        <hrs-text slot="subhead-text" qa="survey--question">
            {{ currentSurvey().question }}
        </hrs-text>
        <hrs-content variant="list" [content]="content" qa="survey_content"></hrs-content>
    </ng-container>

    <hrs-button
            *ngIf="showHistoricalDataButton"
            slot='centered-slot'
            type="button"
            variant="secondary"
            [text]="'SURVEY_VIEW_RESPONSES_BUTTON' | translate"
            icon="next"
            qa="survey--btn-historical_data"
            (click)="openHistoricalData()"
    ></hrs-button>

    <hrs-footer [variant]="surveys.length ? 'two-button' : 'one-button'">
        <hrs-button
            *ngIf="surveys.length"
            type="button"
            variant="secondary"
            icon="volume"
            qa="survey--btn-speak"
            (click)="readContent()"
        ></hrs-button>
        <hrs-button
            *ngIf="surveys.length"
            type="submit"
            variant="primary"
            qa="survey--btn-save"
            [expand]="!surveys.length"
            [text]="(hasMoreSurveys() ? 'SURVEY_NEXT_BUTTON' : 'SURVEY_FINISH_BUTTON') | translate"
            [icon]="hasMoreSurveys() ? 'next' : null"
            [loading]="saving"
            [disabled]="!isCurrentSurveyAnswered() || saving"
            (click)="save(currentSurvey())"
        ></hrs-button>
        <hrs-button
            *ngIf="!surveys.length"
            expand
            type="submit"
            variant="primary"
            text="{{ 'CLOSE_BUTTON' | translate }}"
            qa="survey--btn-dismiss"
            (click)="dismiss()"
        ></hrs-button>
    </hrs-footer>
</hrs-wizard>
