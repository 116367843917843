import * as moment from 'moment';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {finalize, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {
    LegendIconButton,
    ListDetail,
    ListLegend
} from '@hrsui/core/dist/types/components/list/list.interface';
import {WizardConfigDetail} from '@hrsui/core/dist/types/components/wizard/wizard.interface';
import {OverlayService, TextToSpeechService} from '@patient/providers';
import {BuildUtility} from '@hrs/utility';
import {OverlayRef} from '../../hrs-overlay';
import {HistoricalDataService} from '../../services/historical-data/historical-data.service';
import {TaskType, Task} from '../../services/tasks';
import {HistoricalData, SurveyFacet, SurveyFacetQuestion} from '../../services/historical-data/historical-data.interface';
import {QandADetail} from '@hrsui/core/dist/types/components/list-item/list-item.interface';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-survey-historical-data',
    templateUrl: './survey-historical-data.modal.html',
    styleUrls: ['./survey-historical-data.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyHistoricalDataModal implements OnInit {
    private readonly logger = getLogger('SurveyHistoricalDataModal');
    private task: Task;
    private answerPrompt: string;
    private currentlyReading: number | 'all';
    private datePrompt: string;
    public isHRSTab: boolean = BuildUtility.isHRSTab();
    private isReading: boolean;
    public listConfig: ListDetail;
    public listInitialLoad: boolean = true;
    private numLegendButtons: number;
    private questionPrompt: string;
    private timePrompt: string;
    public wizardConfig: WizardConfigDetail;
    private currentPage: number = 0;
    private historicalData: HistoricalData[];
    private toast: OverlayRef;
    private hasOfflineMetric: boolean = false;
    private stopAudioButtonAnimation: Subscription;

    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId}}): void {
        if (itemId) {
            this.readContent(parseInt(itemId));
        }
    }

    constructor(
        private changeRef: ChangeDetectorRef,
        private historicalDataService: HistoricalDataService,
        private overlayRef: OverlayRef,
        private overlayService: OverlayService,
        private translate: TranslateService,
        private tts: TextToSpeechService
    ) {}

    ngOnInit() {
        this.answerPrompt = this.translate.instant('SURVEY_HISTORICAL_DATA.ANSWER_PROMPT');
        this.datePrompt = this.translate.instant('SURVEY_HISTORICAL_DATA.DATE_PROMPT');
        this.questionPrompt = this.translate.instant('SURVEY_HISTORICAL_DATA.QUESTION_PROMPT');
        this.timePrompt = this.translate.instant('SURVEY_HISTORICAL_DATA.TIME_PROMPT');
        this.wizardConfig = {
            headerText: this.translate.instant('SURVEY_HISTORICAL_DATA.HEADER'),
            totalSteps: 10
        };

        this.historicalDataService.getHistoricalData(TaskType.Survey).pipe(
            finalize(() => {
                this.listInitialLoad = false;
                this.sortResponses();
                this.updateListConfig();
            })
        ).subscribe({
            next: (data: HistoricalData[]) => {
                // filter out days with no questions
                data = data.filter((day: HistoricalData) => {
                    if (day.attributes) {
                        const surveyFacet = <SurveyFacet>day.attributes.facet;
                        return surveyFacet.questions.length > 0;
                    }

                    return false;
                });
                this.historicalData = data;
            },
            error: (err) => {
                this.logger.phic.error('Error fetching survey historical data', err);
                this.historicalData = undefined;
                this.handleGetHistoricalDataError();
            }
        });

        this.stopAudioButtonAnimation = this.tts.ttsForceStopped$.subscribe(async () => {
            await this.stopReading();
        });
    }

    async ngOnDestroy() {
        await this.stopReading();
        if (this.stopAudioButtonAnimation) {
            this.stopAudioButtonAnimation.unsubscribe();
            this.stopAudioButtonAnimation = null;
        }
    }

    private sortResponses(): void {
        this.historicalData?.forEach((responseSet) => {
            const facet = <SurveyFacet> responseSet.attributes?.facet;
            if (facet && facet.questions) {
                // sort the responses into order questions were submitted
                if (facet.questions.length > 1) {
                    facet.questions.sort((a, b) => {
                        return moment(a.takenAt).valueOf() - moment(b.takenAt).valueOf();
                    });
                }
            }
        });
    }

    private updateListConfig(): void {
        let updatedConfig: ListDetail;
        let questions: QandADetail[] = [];
        const isOfflineTask = this.task.isCompleted() && this.task.isCompletedButOffline();
        let offlineSubmitTS: Date;
        if (isOfflineTask) {
            // Because Survey has subTasks, we need to get the timestamp of the most recent offline submission.
            offlineSubmitTS = this.task.subTasks.reduce((a, b) => (new Date(a.lastCompletedButOffline) > new Date(b.lastCompletedButOffline) ? a : b)).lastCompletedButOffline;
        }
        if (offlineSubmitTS) {
            this.hasOfflineMetric = true;
            questions.push({
                itemId: '0', // corresponds to questionIndex used in readContent, as a string
                question: this.translate.instant('METRIC.OFFLINE_READING'),
                status: 'submitted',
                readingColor: 'gray-7',
                readingItalicize: true,
                readButton: {
                    handler: () => {}
                }
            });
        }

        if (this.historicalData && this.historicalData.length) {
            const title = moment(this.historicalData[this.currentPage]?.attributes?.takenAt).format('M/D/YYYY');
            if (moment(offlineSubmitTS).format('M/D/YYYY') !== title) {
                questions = [];
                this.hasOfflineMetric = false;
            }

            this.numLegendButtons = 0;
            let leftIconButton: LegendIconButton;
            if (this.currentPage !== this.historicalData.length - 1) {
                leftIconButton = {
                    icon: 'previous',
                    align: 'right',
                    handler: this.getPreviousDay.bind(this)
                };
                this.numLegendButtons++;
            }
            let rightIconButton: LegendIconButton;
            if (this.currentPage !== 0) {
                rightIconButton = {
                    icon: 'next',
                    align: 'left',
                    handler: this.getNextDay.bind(this)
                };
                this.numLegendButtons++;
            }

            const facet = <SurveyFacet> this.historicalData[this.currentPage]?.attributes?.facet;
            facet?.questions.forEach((question: SurveyFacetQuestion) => {
                questions.push({
                    itemId: (questions.length).toString(), // corresponds to questionIndex used in readContent, as a string
                    question: question.question,
                    answer: question.answers.replace(/;$/, ''), // some answers have a semicolon at the end
                    time: moment(question.takenAt).format('hh:mm A'),
                    readingColor: 'black'
                });
            });

            updatedConfig = {
                variant: 'q-and-a',
                legend: {
                    title,
                    leftIconButton,
                    rightIconButton
                },
                questions
            };

            if (this.isHRSTab) {
                // add read button, but don't need handler as <hrs-list-item> will fire 'hrsListItemSelected' event
                // on row click or button click
                updatedConfig.questions.forEach((q, i) => {
                    q.readButton = {
                        handler: () => {
                        }
                    };
                });
            }
        } else if (questions.length) {
            this.numLegendButtons = 0;
            // will hit this block if we have an offline reading and no historical data was returned or we are still offline
            const title = moment(offlineSubmitTS).format('M/D/YYYY');
            updatedConfig = {
                variant: 'q-and-a',
                legend: {
                    title,
                },
                questions
            };
        }

        this.listConfig = updatedConfig;
        this.changeRef.detectChanges();
    }

    private getPreviousDay(): void {
        if (this.currentPage + 1 === this.historicalData.length) return; // shouldn't hit this case, but just ~in case~
        this.currentPage += 1; // +1 for previous, bc data is returned sorted newest -> oldest
        this.stopReading();
        this.updateListConfig();
    }

    private getNextDay(): void {
        if (this.currentPage === 0) return; // shouldn't hit this case, but ~in case~
        this.currentPage -= 1; // -1 for next, bc data is returned sorted newest -> oldest
        this.stopReading();
        this.updateListConfig();
    }

    private async buttonAnimation(startOrStop: 'start' | 'stop', questionIndex: number | 'all'): Promise<void> {
        const isStart = startOrStop === 'start';
        let button;

        if (questionIndex === 'all') {
            button = document.querySelector('.historical_survey--btn-speak');
        } else {
            const content = document.querySelector('hrs-modal hrs-content');
            const list = content ? content.querySelector('hrs-list') : undefined;
            const buttons = list ? list.shadowRoot.querySelectorAll('hrs-button') : undefined;
            const buttonIndex = questionIndex + this.numLegendButtons;

            if (buttons && (buttons.length > buttonIndex)) {
                button = buttons[buttonIndex];
            }
        }

        if (button) {
            if (isStart) {
                await button.startAnimation();
            } else {
                await button.stopAnimation();
            }
            this.changeRef.detectChanges();
        }
    }

    private async readScript(questionIndex: number, skipDate: boolean = false): Promise<void> {
        // flag that a script is being read and track which question is being read
        // the flag will be switched to 'false' if the user clicks a read button/row,
        // so check whether isReading is still true before calling tts.speak()
        this.isReading = true;

        const question = this.listConfig.questions[questionIndex].question;
        const answer = this.listConfig.questions[questionIndex].answer;
        const time = this.listConfig.questions[questionIndex].time;
        if (!skipDate) {
            const titleDate = (this.listConfig.legend as ListLegend).title;
            const date = moment(titleDate).format('LL');
            if (this.isReading) await this.tts.speak(`${this.datePrompt} ${date}`);
        }
        if (this.isReading && questionIndex === 0 && this.hasOfflineMetric) {
            await this.tts.speak(this.translate.instant('METRIC.OFFLINE_READING.SCRIPT'));
        } else {
            let questionScript = `${this.questionPrompt}, ${question}`;
            if (this.isReading && this.currentlyReading === 'all' && questionIndex !== 0) {
                questionScript = `${this.translate.instant('SURVEY_NEXT')} ${questionScript}`;
            }
            if (this.isReading) await this.tts.speak(questionScript);
            if (this.isReading) await this.tts.speak(`${this.answerPrompt}, ${answer} ${this.timePrompt} ${time}`);
        }
    }

    private async stopReading(): Promise<void> {
        // stop the TTS reading
        // flag to stop reading current script and
        // if reading all questions, flag to stop reading any more scripts
        // and stop animation on currently animated button
        this.tts.stop();
        this.isReading = false;
        await this.buttonAnimation('stop', this.currentlyReading);
        this.currentlyReading = undefined;
    }

    private async readContent(questionIndex: number): Promise<void> {
        if (this.isHRSTab) {
            const startNewReading = questionIndex != this.currentlyReading;

            // if we're here & isReading is true,
            // user has either clicked the same read button/row to stop the reading, or
            // clicked another read button/row to start a different reading
            // so stop the current script
            if (this.isReading) {
                await this.stopReading();
            }

            if (startNewReading) {
                this.currentlyReading = questionIndex;
                await this.buttonAnimation('start', questionIndex);
                await this.readScript(questionIndex);
                if (this.isReading) await this.tts.speak(this.translate.instant('METRIC.HISTORICAL.READOUT_COMPLETE'));
                await this.buttonAnimation('stop', questionIndex);
                this.isReading = false;
                this.currentlyReading = undefined;
            }
        }
    }

    public async readAllContent(): Promise<void> {
        const startNewReading = this.currentlyReading != 'all';

        // if we're here & isReading is true or we're already reading all questions,
        // user has either clicked the read all button to stop the reading, or
        // clicked another read button/row to start a different reading
        // either way, stop the current script
        if (this.isReading || this.currentlyReading === 'all') {
            await this.stopReading();
        }

        if (startNewReading) {
            this.currentlyReading = 'all';
            await this.buttonAnimation('start', 'all');
            if (!this.listConfig) {
                await this.tts.speak(this.translate.instant('GENERIC_METRIC.HISTORICAL.NO_READINGS'));
            } else {
                for (let i = 0; i < this.listConfig.questions.length; i++) {
                    await this.readScript(i, i > 0);
                }
                await this.tts.speak(this.translate.instant('METRIC.HISTORICAL.READOUT_COMPLETE'));
            }
            await this.buttonAnimation('stop', 'all');
            this.isReading = false;
            this.currentlyReading = undefined;
        }
    }

    private async handleGetHistoricalDataError(): Promise<void> {
        if (this.toast) {
            this.toast.dismiss();
            this.toast = null;
        }
        this.toast = await this.overlayService.openToast({
            header: this.translate.instant('DAILY_METRICS.ERROR.UNAVAILABLE'),
            variant: 'error',
            duration: 5000,
            qa: 'survey_history_modal--historical_data-error'
        });
    }

    public dismiss(): void {
        this.overlayRef.dismiss();
    }
}
