import {NgZone, Injectable} from '@angular/core';
import {HRSStorage} from '../../storage/storage';
import {ModalService} from '@hrs/providers';
import moment from 'moment';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics} from '../../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export abstract class AnDClassicService {
    private readonly baseLogger = getLogger('AnDClassicService');

    /**
     * ResponseCommands contains responses which device can send
     **/
    protected CMD_ENTERED_CONFIG = 'PWRQCF'; // Device sends ACK when it entered into configuration mode.
    protected CMD_CONFIG_FAIL = 'PWC0'; // Device sends response when setting configuration get failed.
    protected CMD_CONFIG_SUCCESS = 'PWC1'; // Device sends response when setting configuration get successful.

    // Packet IDs
    protected PACKET_REQUEST_PATIENT_INFO = 'PWRQPI';
    protected PACKET_NO_PATIENT_INFO = 'PWCAPI';
    protected CMD_DATA_RESPONSE = '';

    /**
     * RequestCommands contains command which can set to device
     **/
    protected CMD_ENTER_CONFIG_WITH_ACK = 'PWA2'; // Send acknowledge to device with entering into configuration mode.
    protected CMD_ACK_NO_DISCONNECT = 'PWA4'; // Send acknowledge to device with request not to disconnect.

    public isRecentReading: boolean;
    protected weight: number = 0;

    gotRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    public abstract processData(arrayBuffer: Uint8Array, peripheral: any): void;
    public abstract getDataSize(): number;

    constructor(
        protected bluetoothSerial: BluetoothSerial,
        protected storage: HRSStorage,
        protected ngZone: NgZone,
        protected fbanalytics: HRSFirebaseAnalytics,
        protected modalService: ModalService,
    ) {

    }

    /**
     * This method Processes the common data header section. Communication packet consists of two sections.
     * First, it is the data header section. The size of the data header section is fixed for all A&D PBT-C series.
     * @param rxPacket
     */
    public processDataHeaderSection(rxPacket: ArrayBuffer): void {
        // Capture information for Packet Type
        let packetType = ((rxPacket[0] & 0xff) + ((rxPacket[1] & 0xff) << 8)).toString(16);
        this.baseLogger.phic.debug('And classic Device :: Data Header Info :: Packet Type Value ' + packetType);

        // Capture information for packet length
        let packetLen = ((rxPacket[2] & 0xff) + ((rxPacket[3] & 0xff) << 8) +
            ((rxPacket[4] & 0xff) << 16) + ((rxPacket[5] & 0xff) << 24));
        this.baseLogger.phic.debug('And classic Device :: Data Header Info :: Packet Length ' + packetLen);

        // Capture information for Device type whether BP or Scale
        let deviceType = ((rxPacket[6] & 0xff) + ((rxPacket[7] & 0xff) << 8));
        this.baseLogger.phic.debug('And classic Device :: Data Header Info :: Device Type ' + deviceType);

        // Capture information for device ID that is the Mac Address
        let btDeviceId = rxPacket.slice(23, 28);
        var macAddress = BluetoothUtils.buf2hex(btDeviceId, true);
        this.baseLogger.phic.debug('And classic : Data Header Info: Device ID ' + BluetoothUtils.buf2hex(btDeviceId));
        this.baseLogger.phic.debug('And classic : Data Header Info: Device ID (mac address) ' + macAddress);

        // Capture information for device name
        let deviceNameUpperBytes = BluetoothUtils.getStringValueFromByteArray(rxPacket, 29, 6);
        let deviceNameLowerBytes = BluetoothUtils.getStringValueFromByteArray(rxPacket, 47, 10);
        this.baseLogger.phic.debug('And classic : Data Header Info: Device Name ' + deviceNameUpperBytes + deviceNameLowerBytes);

        // Capture information for device serial number
        let serialNumber = BluetoothUtils.getStringValueFromByteArray(rxPacket, 35, 12);
        this.baseLogger.phic.debug('And classic : Data Header Info: Serial Number ' + serialNumber);

        // Capture information for device battery status
        let slicedArray = rxPacket.slice(57, 59);
        let deviceBatteryStatus = BluetoothUtils.buf2hex(slicedArray);
        this.baseLogger.phic.debug('And classic: Data Header Info: Device Battery Status ' + deviceBatteryStatus);

        // Capture information for device Firmware and Hardware revision numbers
        // The byte stores the firmware and hardware revision numbers. The first 5 bits specifies the firmware version.
        // The last 3 bits specifies the hardware version.
        let deviceFirmwareHWRevision3 = rxPacket[59].toString(2);
        this.baseLogger.phic.debug('And classic: Data Header Info: Device Firmware & Hardware Revision ' + deviceFirmwareHWRevision3);
    }

    /**
      * Multiple request and response commands need to be executed for setting date and time,
      * handling stored readings, acknowledge the data read and so on.
      * This flow will execute the next steps once device is connected
      * @param peripheral
      * @param onMetricUpdateCallback
    */
    public onPeripheralConnected(peripheral: any): void {
        this.baseLogger.phic.debug('onPeripheral Connected: ' + peripheral.name);
        this.executeNextStep(peripheral);
    }

    public executeNextStep(peripheral: any): void {
        this.ngZone.run(() => {
            this.bluetoothSerial.subscribeRawData().subscribe(
                {
                    next: (data) => {
                        let arrayBuffer = new Uint8Array(data);
                        this.baseLogger.phic.log('AnD classic ' + peripheral.name + ' data: ' + BluetoothUtils.buf2hex(arrayBuffer));
                        // Recieve communication packet from 351/767/355
                        if (arrayBuffer.length >= this.getDataSize()) {
                            this.baseLogger.phic.debug('AnD classic ' + peripheral.name + ' Process raw data');
                            // Process reading
                            this.processData(arrayBuffer, peripheral);
                            this.processRecentReading(peripheral);
                        } else if (BluetoothUtils.bytesToString(data).includes(this.CMD_ENTERED_CONFIG)) {// PWRQCF
                            // Return Acknowledgement and config packet to AnD Classic device
                            this.baseLogger.phic.debug('AnD classic ' + peripheral.name + ': GOT PWQRCF, writing config now');
                            this.writeConfig(peripheral);
                        } else if (BluetoothUtils.bytesToString(data).includes(this.CMD_CONFIG_SUCCESS)) {// Check whether config writing was success
                            this.baseLogger.phic.debug('AnD classic ' + peripheral.name + ': Configuration written to device successfully');
                        } else if (BluetoothUtils.bytesToString(data).includes(this.CMD_CONFIG_FAIL)) {
                            this.baseLogger.phic.debug('AnD classic ' + peripheral.name + ': Configuration failed to write');
                        } else {
                            this.baseLogger.phic.debug('AnD classic ' + peripheral.name + ' Got an unknown response from AnD device');
                        }
                    },
                    error: (error) => {
                        this.baseLogger.phic.error('AnD classic ' + peripheral.name + 'Error reading raw data ', JSON.stringify(error));
                    }
                }
            );
        });
    }

    public processRecentReading(peripheral: any): void {
        if (this.isRecentReading) {
            this.baseLogger.phic.debug('And classic ' + peripheral.name + ': Recent reading thus sending CMD_ENTER_CONFIG_WITH_ACK: PWA2');
            this.writeAnDClassicDataPacket(BluetoothUtils.convertStringToUTF8ByteArray(this.CMD_ENTER_CONFIG_WITH_ACK), peripheral);
        } else {
            this.baseLogger.phic.debug('And classic ' + peripheral.name + ': Not Recent reading thus sending CMD_ACK_NO_DISCONNECT: PWA4');
            this.writeAnDClassicDataPacket(BluetoothUtils.convertStringToUTF8ByteArray(this.CMD_ACK_NO_DISCONNECT), peripheral);
        }
    }

    /**
     * Check whether reading recieved from AnD classic device is a recent one
     * All reading data consist of transmit time and measurement time. Decision will be done based on this delta
     * @param rxPacket
     * @returns boolean isreading a recent one
     */
    public isRecentReadingRecieved(rxPacket: ArrayBuffer, peripheral: any): boolean {
        // Measure time
        let mYearHByte = rxPacket[9];
        let mYearLByte = rxPacket[10];
        let mMonthByte = rxPacket[11];
        let mDayByte = rxPacket[12];
        let mHourByte = rxPacket[13];
        let mMinuteByte = rxPacket[14];
        let mSecByte = rxPacket[15];

        // Transmit time
        let tYearHByte = rxPacket[16];
        let tYearLByte = rxPacket[17];
        let tMonthByte = rxPacket[18];
        let tDayByte = rxPacket[19];
        let tHourByte = rxPacket[20];
        let tMinuteByte = rxPacket[21];
        let tSecByte = rxPacket[22];

        let mYearByte = (mYearLByte & 0XFF).toString(16) +
            (mYearHByte & 0XFF).toString(16);

        let tYearByte = (tYearLByte & 0xFF).toString(16) +
            (tYearHByte & 0xFF).toString(16);

        let measureYear = parseInt(mYearByte, 16);
        let measureMonth = parseInt((mMonthByte & 0XFF).toString(16), 16);
        let measureDay = parseInt((mDayByte & 0XFF).toString(16), 16);
        let measureHrs = parseInt((mHourByte & 0XFF).toString(16), 16);
        let measureMin = parseInt((mMinuteByte & 0XFF).toString(16), 16);
        this.baseLogger.phic.log('And classic ' + peripheral.name + ' Reading: Meassure cal = Y ' + measureYear + ' M ' + measureMonth + ' D ' + measureDay + ' HH ' + measureHrs + ' MM ' + measureMin + ' ');

        let transmitYear = parseInt(tYearByte, 16);
        let transmitMonth = parseInt((tMonthByte & 0XFF).toString(16), 16);
        let transmitDay = parseInt((tDayByte & 0XFF).toString(16), 16);
        let transmitHrs = parseInt((tHourByte & 0XFF).toString(16), 16);
        let transmitMin = parseInt((tMinuteByte & 0XFF).toString(16), 16);

        this.baseLogger.phic.log('And classic ' + peripheral.name + 'Reading: Transmit cal = Y ' + transmitYear + ' M ' + transmitMonth + ' D ' + transmitDay + ' HH ' + transmitHrs + ' MM ' + transmitMin + ' ');

        const measureCal = moment(new Date(measureYear, measureMonth - 1, measureDay,
            measureHrs, measureMin, mSecByte));

        const transmitCal = moment(new Date(transmitYear, transmitMonth - 1, transmitDay,
            transmitHrs, transmitMin, tSecByte));

        let readingTimeDiff = (transmitCal.valueOf() - measureCal.valueOf());
        this.baseLogger.phic.debug('And classic ' + peripheral.name + 'Reading time difference: ' + readingTimeDiff);
        // Measure and Transmit times are not equal and more than 15s apart
        if (transmitCal.diff(measureCal) != 0 &&
            readingTimeDiff >= 15000) {
            this.isRecentReading = false;
            this.baseLogger.phic.debug('And classic ' + peripheral.name + ': Read old data, will discard');
        } else {
            this.isRecentReading = true;
            this.baseLogger.phic.debug('And classic ' + peripheral.name + ': Reading Recent data, pass it to metric reading page');
        }

        return this.isRecentReading;
    }

    private writeAnDClassicDataPacket(bytes: ArrayBuffer, peripheral: any): void {
        this.bluetoothSerial.write(bytes).then(
            (success) => {
                this.baseLogger.phic.debug('And classic ' + peripheral.name + ': Successful writing of data packet ', success);
            }, (error) => {
                this.baseLogger.phic.error('And classic ' + peripheral.name + ': Error writing data packet', error);
            }
        );
    }

    /**
     * Writing configuration with tablet date
    **/
    public writeConfig(peripheral: any) {
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth() + 1;
        var day = currentDate.getDate();
        var hour = currentDate.getHours();
        var min = currentDate.getMinutes();
        var sec = currentDate.getSeconds();
        this.baseLogger.phic.debug('Writing date time config Y:' + year + ' M:' + month + ' D:' + day + ' H:' + hour + ' M:' + min + ' S:' + sec);
        var dataToWrite = new Uint8Array(90);
        dataToWrite[19] = (0X01);
        dataToWrite[20] = (0x00); // SIZE of stored data, set this to 0 to store only 1 reading on device
        dataToWrite[62] = (0X01);
        dataToWrite[63] = (year & 0XFF);
        dataToWrite[64] = ((year >> 8) & 0xFF);
        dataToWrite[65] = month;
        dataToWrite[66] = day;
        dataToWrite[67] = hour;
        dataToWrite[68] = min;
        dataToWrite[69] = sec;
        this.writeAnDClassicDataPacket(dataToWrite, peripheral);
    }
}
