export interface EducationFile {
    id: string;
    resourceType: string;
    attributes: {
        data?: any;
        name: string;
        conditions: string[];
        description: string;
        type: string;
        url: string | string[];
        slides: EducationSlide[];
        duration: string;
    }
}

export interface EducationSlide {
    url: string;
    referenceTime: number;
}

export interface EducationVideoFile {
    id: string;
    resourceType: string;
    attributes: {
        data?: any;
        name: string;
        conditions: string[];
        description: string;
        type: string;
        url: string[];
        duration: string;
    }
}
