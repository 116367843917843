<hrs-content variant="list" [content]="[contentDevices]" qa="device_settings"></hrs-content>
<hrs-footer variant="one-button">
    <hrs-button
            variant="primary"
            expand
            icon="refresh"
            [text]="'REFRESH_BUTTON' | translate"
            qa="device_settings--btn"
            appThrottleClick
            (throttledClick)="scanAvailableBluetoothDevices()"></hrs-button>
</hrs-footer>
