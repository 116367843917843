export enum TaskStorageKey {
    // Task Cache
    CACHE_KEY = 'tasks',

    // Ionic Storage Buckets
    // UI_STORAGE_KEY - stores the task id, type, and lastCompleted date to speed up how fast the metrics display on the UI and to keep track of completed and "offline-completed" tasks
    UI_STORAGE_KEY = 'tasks',

    // OFFLINE_METRICS_STORAGE_KEY - stores encrypted metric data recorded by the patient but was unable to update the DB
    OFFLINE_METRICS_STORAGE_KEY = 'offlineTaskData'
}
