import {ActivatedRoute} from '@angular/router';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ContentDetail, ContentGridItem} from '@hrsui/core/dist/types/components/content/content.interface';
import {EducationFile} from '../education-file.interface';
import {EducationQuizzesPage} from '../quizzes/education-quizzes.page';
import {
    EducationService,
    EducationFileService,
    TaskTrackingService,
    OverlayService
} from '@patient/providers';
import {NavController} from '@ionic/angular';
import {Quiz} from '../quizzes/education-quiz.model';
import {EducationRailTabs} from '../education-rail-tabs.enum';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-education-condition',
    templateUrl: './education-condition.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EducationConditionPage {
    private readonly logger = getLogger('EducationConditionPage');
    public content: ContentDetail[];

    constructor(
        private education: EducationService,
        private educationFileService: EducationFileService,
        private overlay: OverlayService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private taskTracking: TaskTrackingService,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.getContent(params.condition, params.educationType);
        });

        this.taskTracking.startTracking('education', 'Opened education page');
    }

    ngOnDestroy() {
        this.taskTracking.stopTracking();
    }

    private getContent(condition: string, type: string): void {
        const contentList = this.getCurrentContent(condition, type);
        let items = [];
        contentList.forEach((item) => {
            items.push(this.getGridItem(item, type));
        });

        // sort grid tiles alphabetically by title
        items.sort((a, b) => {
            return a.mainTitle.localeCompare(b.mainTitle);
        });

        this.content = [{items: [...items]}];
        // tiles sometimes not rendering, forcing change detection just in case
        this.ref.detectChanges();
    }

    private getCurrentContent(condition: string, type: string): EducationFile[] | Quiz[] {
        if (type === EducationRailTabs.QUIZZES) {
            return this.education.getQuizzesByCondition(condition);
        } else if (type === EducationRailTabs.FILES) {
            return this.education.getFilesByCondition(condition);
        } else if (type === EducationRailTabs.VIDEOS) {
            return this.education.getVideosByCondition(condition);
        }
    }

    private getGridItem(item: EducationFile | Quiz, type: string): ContentGridItem {
        return {
            mainTitle: 'attributes' in item ? item.attributes.name : item.name,
            variant: 'generic',
            handler: () => {
                this.open(type, item.id);
            }
        };
    }

    private async open(type: string, id: string) {
        try {
            if (type === EducationRailTabs.VIDEOS) {
                await this.openVideo(id);
            } else if (type === EducationRailTabs.FILES) {
                const file = this.education.getFileById(id)[0];
                await this.educationFileService.downloadAndOpenFile(file);
            } else if (type === EducationRailTabs.QUIZZES) {
                await this.openQuiz(id);
            }
        } catch (e) {
            this.logger.phic.error('Failed to open education item', id, e);
        }
    }

    async openVideo(id: string): Promise<void> {
        const video = this.education.getVideoById(id)[0];
        const navigationExtras = {
            queryParams: {
                data: video,
                headerTitle: video.attributes.name
            }
        };

        await this.navCtrl.navigateForward('/education/video', navigationExtras);
    }

    async openQuiz(id: string): Promise<void> {
        await this.overlay.openWizard({
            component: EducationQuizzesPage,
            inputs: {quizId: id},
            qa: 'quiz_wizard'
        });
    }
}
