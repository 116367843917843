<div class="c_admin_access">
    <ion-tab-bar class="c_admin_settings--tabs">
        <ion-tab-button (click)="selectLogin(LoginTypes.admin)" [ngClass]="{'tab-selected': loginType === LoginTypes.admin }">
                <ion-label>{{'ADMIN_CODE_TAB' | translate}}</ion-label>
        </ion-tab-button>
        <ion-tab-button
            *ngIf="isHRSTab"
            (click)="selectLogin(LoginTypes.superAdmin)"
            [ngClass]="{'tab-selected': loginType === LoginTypes.superAdmin}">
            <ion-label>{{ 'ADMIN_CREDENTIALS_TAB' | translate}}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
    <hrs-form-item
        *ngIf="loginType ===  LoginTypes.admin"
        [hasInvalidInput]="hasError"
        [errorMessage]="errorMessage">
        <hrs-label
            class="c_admin_access--label"
            slot="start"
            text="{{ 'ADMIN_ACCESS_HEADER' | translate }}">
        </hrs-label>
        <hrs-input
                class="c_admin_access--input"
                type="text"
                autocapitalize="characters"
                maxlength="14"
                placeholder="{{ 'ADMIN_PASSCODE_PLACEHOLDER' | translate }}"
                [(ngModel)]="enteredPasscode"
                (ngModelChange)="passcodeChangeHandler()"
        ></hrs-input>
    </hrs-form-item>

    <div *ngIf="loginType === LoginTypes.superAdmin">
        <hrs-form-item
            [hasInvalidInput]="usernameError"
            [errorMessage]="usernameErrorMessage"
            class="c_admin_access--login-form"
        >
            <hrs-label
                class="c_admin_access--label"
                slot="start"
                text="{{ 'SUPER_ADMIN_ACCESS_HEADER' | translate }}">
            </hrs-label>
            <hrs-input
                (hrsFocus)="removeErrors()"
                class="c_admin_access--input c_admin_access--input-credentials"
                type="text"
                placeholder="{{ 'ADMIN_USERNAME_PLACEHOLDER' | translate }}"
                name="enteredUsername"
                ngDefaultControl
                [(ngModel)]="enteredUsername"
            ></hrs-input>
        </hrs-form-item>
        <hrs-form-item
            [hasInvalidInput]="passwordError"
            [errorMessage]="passwordErrorMessage"
            class="c_admin_access--login-form"
        >
            <hrs-input
                (hrsFocus)="removeErrors()"
                class="c_admin_access--input c_admin_access--input-credentials"
                type="password"
                name="enteredPassword"
                ngDefaultControl
                placeholder="{{ 'ADMIN_PASSWORD_PLACEHOLDER' | translate }}"
                [(ngModel)]="enteredPassword"
            ></hrs-input>
        </hrs-form-item>
        <hrs-form-item class="c_admin_access--login-form">
            <hrs-button (click)="superAdminLogin()">Sign In</hrs-button>
        </hrs-form-item>
    </div>
</div>

