import {Injectable} from '@angular/core';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {HrsMenu} from '@hrsui/angular';
import {MenuItemDetail} from '@hrsui/core/dist/types/components/menu/menu.interface';
import {VideoPage} from '../../communication/video/video.page';
import {ChatPage} from '../../communication/chat/chat.page';
import {CaregiverPinPage} from '../../caregiver-pin/caregiver-pin.page';
import {AdminService} from '../admin/admin.service';
import {CareplanChangeService} from '../care-plan-change/care-plan-change.service';
import {EducationService} from '../education/education.service';
import {EnvironmentService} from '../environment/environment.service';
import {EventService} from '../events/event.service';
import {OfflineTaskService} from '../tasks/task-offline.service';
import {OverlayService} from '../overlay/overlay.service';
import {SupportCallService} from '../support-call/support-call.service';
import {TaskService} from '../tasks/task.service';
import {User} from '../user/user.service';
import {Caregiver} from '../user/caregiver.interface';
import {NavigationExtras, Router} from '@angular/router';
import {Task, TaskType} from '../tasks';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService} from '@hrs/providers';
import {BuildUtility} from '@hrs/utility';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {MenuConfig} from './config/menu-config';
import {OverlayConfig, OverlayRef} from '../../hrs-overlay';
import {HRSModalOptions} from './modal-options.interface';
import {EducationRailTabs} from '../../education/education-rail-tabs.enum';
import {LogUploadViewControllerService} from '../logs/log-upload-view-controller.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private readonly logger = getLogger('MenuService');
    private isHRSTab: boolean = BuildUtility.isHRSTab();
    private versionNumber: string;
    public caregiverParams: {string?: MenuItemDetail} = {};
    public emitMenu: Subject<MenuItemDetail[]> = new Subject();
    public menu: MenuItemDetail[] = MenuConfig;

    private launchLogoutWarningSubscription: Subscription;
    private caregiverSubscription: Subscription;
    private careplanChangeSubscription: Subscription;
    private loginStateChangedSubscription: Subscription;

    constructor(
        private adminService: AdminService,
        private appVersion: AppVersion,
        private careplanChangeService: CareplanChangeService,
        private communicationService: CommunicationService,
        private educationService: EducationService,
        private environmentService: EnvironmentService,
        private eventService: EventService,
        private logUploadViewControllerService: LogUploadViewControllerService,
        private menuCtrl: MenuController,
        private modalService: ModalService,
        private navCtrl: NavController,
        private offlineTaskService: OfflineTaskService,
        private overlay: OverlayService,
        private platform: Platform,
        private supportCallService: SupportCallService,
        private taskService: TaskService,
        private translate: TranslateService,
        private user: User,
        public router: Router,
    ) {}

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    public init(): void {
        this.educationService.load(true);
        this.initSubscriptions();
        this.assignTranslatedMenuLabels();
        this.populateVersionNumber();
        this.menuCtrl.swipeGesture(false);
    }

    private initSubscriptions(): void {
        this.careplanChangeService.initListeners();
        this.careplanChangeSubscription = this.careplanChangeService.emitTasks.subscribe(() => {
            // Check and recheck for changes in which conditional items are present in the menu
            this.menu = [...this.syncConditionalMenuItems()];
            this.emitMenu.next(this.menu);
        });

        this.eventService.tasksLoaded.pipe(take(1)).subscribe((tasks: Task[]) => {
            this.user.getCaregivers();
            this.careplanChangeService.emitTasks.next(tasks);
        });
        this.caregiverSubscription = this.eventService.caregiversLoaded.subscribe(() => {
            this.loadCaregiverMenu();
            this.emitMenu.next(this.menu);
        });

        this.loginStateChangedSubscription = this.eventService.loginStateChanged.subscribe((hasLoggedIn: boolean) => {
            if (!hasLoggedIn) {
                // If user is logged out, reset the caregiver submenu so that newly logged in patients aren't seeing old caregiver data.
                this.menu.forEach((menuItem) => {
                    if (menuItem.titleKey === 'MENU_CAREGIVER' && User.hasCaregivers()) {
                        menuItem.submenu = [...[menuItem.submenu[0]]];
                    }
                });
                this.emitMenu.next(this.menu);
                this.cleanSubscriptions();
            }
        });
    }

    private cleanSubscriptions(): void {
        if (this.launchLogoutWarningSubscription) {
            this.launchLogoutWarningSubscription.unsubscribe();
            this.launchLogoutWarningSubscription = undefined;
        }
        if (this.caregiverSubscription) {
            this.caregiverSubscription.unsubscribe();
            this.caregiverSubscription = undefined;
        }
        if (this.careplanChangeSubscription) {
            this.careplanChangeSubscription.unsubscribe();
            this.careplanChangeSubscription = undefined;
        }
        if (this.loginStateChangedSubscription) {
            this.loginStateChangedSubscription.unsubscribe();
            this.loginStateChangedSubscription = undefined;
        }
    }

    private loadCaregiverMenu(): void {
        const caregiverMenuItem = this.menu.filter((item) => item.titleKey === 'MENU_CAREGIVER')[0];
        caregiverMenuItem.label = this.translate.instant(caregiverMenuItem.titleKey);
        if (User.caregivers && User.hasCaregivers()) {
            caregiverMenuItem.submenu = [caregiverMenuItem.submenu[0], ...User.caregivers.map((caregiver, i) => {
                const newMenuItem: MenuItemDetail = {
                    icon: 'caregiver-contact',
                    label: caregiver.firstName + ' ' + caregiver.lastName,
                    titleKey: `MENU_CAREGIVER_CONTACT-${i}`
                };
                this.caregiverParams[newMenuItem.titleKey] = caregiver;
                return newMenuItem;
            })];
        } else { // reset submenu to clear previously held caregiver(s)
            caregiverMenuItem.submenu = [caregiverMenuItem.submenu[0]];
        }
    }

    private syncConditionalMenuItems(menu: MenuItemDetail[] = this.menu) {
        menu.map(async (item) => {
            if (/EDUCATION|DEVICES|COMMS/.test(item.titleKey)) {
                // Hide any device or education menu items that are not associated with the patient's careplan
                item.isHidden = !this.isConditionalMenuItemPresent(item.titleKey);
            } else {
                item.isHidden = !this.checkForHRSTabMenuItems(item);
            }
            // Recursively sync conditional items in submenu
            if (item.submenu) item.submenu = this.syncConditionalMenuItems(item.submenu);
        });
        return menu;
    }

    private isConditionalMenuItemPresent(titleKey: string): boolean {
        if (/MENU_COMMS/.test(titleKey)) return this.hasClinicianComms();
        if (/EDUCATION/.test(titleKey)) {
            // education submenu
            if (titleKey.includes('FILES')) return this.educationService.hasFiles();
            if (titleKey.includes('VIDEOS')) return this.educationService.hasVideos();
            if (titleKey.includes('QUIZZES')) return this.educationService.hasQuizzes();
            return this.educationService.hasEducationContent();
        }
        if (/DEVICES/.test(titleKey)) return this.hasDeviceTasks(titleKey);
        return true;
    }

    private hasClinicianComms(): boolean {
        return !this.environmentService.isClinicianCommunicationDisabled();
    }

    private hasDeviceTasks(titleKey: string): boolean {
        const taskTypes = {
            MENU_DEVICES_GLUCOMETERS: TaskType.Glucose,
            MENU_DEVICES_OXIMETERS: TaskType.PulseOx,
            MENU_DEVICES_BPMONITORS: TaskType.BloodPressure,
            MENU_DEVICES_THERMOMETERS: TaskType.Temperature,
            MENU_DEVICES_SCALES: TaskType.Weight,
            // TODO: remove this when ios eko integration is done. Until Ekoduo integration is done for ios, we will not show its menu option under devices.
            // Update: removed Stethoscope from PCM completely.
            // ...this.platform.is('android') ? {MENU_DEVICES_STETHOSCOPES: TaskType.Stethoscope} : {}
        };

        if (!BuildUtility.isHRSTab()) taskTypes['MENU_DEVICES_ACTIVITY_MONITORS'] = TaskType.Steps;

        // On initial render, check whether any devices exist to know if Devices menu item should be rendered at all
        if (titleKey === 'MENU_DEVICES') return Object.values(taskTypes).some((type) => this.taskService.hasTask(type));

        return this.taskService.hasTask(taskTypes[titleKey]);
    }

    private checkForHRSTabMenuItems(menuItem): boolean {
        if (!this.isHRSTab && menuItem.isHRSTabOnly) return false;
        if (/MENU_SIGN_OUT/.test(menuItem.titleKey) && this.isHRSTab) return false;
        return true;
    }

    public assignTranslatedMenuLabels(): void {
        this.menu.forEach((item) => {
            item.label = this.translate.instant(item.titleKey);
            if (item.submenu) {
                item.submenu.map((subitem) => {
                    if (!subitem.titleKey.includes('MENU_CAREGIVER_CONTACT')) { // don't try to translate caregivers' names
                        subitem.label = this.translate.instant(subitem.titleKey);
                    }
                });
            }
        });
    }

    public async openPage(menuEl: HrsMenu, menuItem: MenuItemDetail, caregiverParams?: Caregiver): Promise<void> {
        const key = menuItem.titleKey.split('_')[1];
        const pageAction = {
            CAREGIVER: 'launchCaregiverMenuActions',
            COMMS: 'launchCommsModal',
            DEVICES: 'launchDevices',
            EDUCATION: 'launchEducationPage',
            SIGN: 'subscribeToLogout',
            HELP: 'performHelpAction'
        }[key];

        if (pageAction) {
            this[pageAction](menuItem, caregiverParams);
        } else {
            this.navCtrl.navigateForward(`/${key.toLowerCase()}`);
        }

        menuEl['nativeElement'].toggle();
    }

    public async launchEducationPage({titleKey}: MenuItemDetail): Promise<OverlayRef> {
        if (this.hasEducationData(titleKey)) {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    type: titleKey
                }
            };
            this.router.navigate(['education'], navigationExtras);
        } else {
            return await this.overlay.openToast({
                message: this.translate.instant(titleKey + '.DATA-UNAVAILABLE'),
                duration: 5000, // 5 seconds is the standard toast duration
                variant: 'error',
                qa: 'menu_launch_education_toast'
            });
        }
    }

    private hasEducationData(titleKey: string) {
        let functions = {
            [EducationRailTabs.VIDEOS]: () => this.educationService.hasVideos(),
            [EducationRailTabs.QUIZZES]: () => this.educationService.hasQuizzes(),
            [EducationRailTabs.FILES]: () => this.educationService.hasFiles()
        };
        return functions[titleKey]();
    }

    public launchDevices({titleKey}: MenuItemDetail): void {
        const peripheralType = {
            MENU_DEVICES_GLUCOMETERS: 'glucose',
            MENU_DEVICES_OXIMETERS: 'pulseox',
            MENU_DEVICES_THERMOMETERS: 'temperature',
            MENU_DEVICES_SCALES: 'weight',
            MENU_DEVICES_BPMONITORS: 'bloodpressure',
            // MENU_DEVICES_STETHOSCOPES: 'stethoscope'
        }[titleKey];
        const headerTitleKey = {
            MENU_DEVICES_ACTIVITY_MONITORS: 'ACTIVITY_MONITORS.TITLE',
            MENU_DEVICES_GLUCOMETERS: 'GLUCOMETERS.TITLE',
            MENU_DEVICES_OXIMETERS: 'OXIMETERS.TITLE',
            MENU_DEVICES_THERMOMETERS: 'THERMOMETERS.TITLE',
            MENU_DEVICES_SCALES: 'SCALES.TITLE',
            MENU_DEVICES_BPMONITORS: 'BPMONITORS.TITLE',
            // MENU_DEVICES_STETHOSCOPES: 'STETHOSCOPE.TITLE'
        }[titleKey];
        if (/ACTIVITY_MONITORS/.test(titleKey)) {
            this.navCtrl.navigateForward('/activity-monitors', {queryParams: {header: headerTitleKey}});
        } else {
            this.navCtrl.navigateForward('/generic-device', {queryParams: {peripheralType: peripheralType, header: headerTitleKey}});
        }
    }

    public async launchCaregiverMenuActions(menuItem: MenuItemDetail, caregiverParams?: NavigationExtras): Promise<void> {
        if (!caregiverParams) {
            // No CG params means we just want to launch the CG Pin modal
            return this.launchModal({component: CaregiverPinPage, headerTitle: this.translate.instant('CAREGIVER_PIN.TITLE')});
        }
        await this.overlay.openAlert({
            variant: 'multi-button',
            header: menuItem.label,
            cssClass: 'c_caregiver_alert',
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    cssClass: 'c_caregiver_alert--button-cancel',
                },
                {
                    text: this.translate.instant('CAREGIVER_MESSAGES'),
                    handler: () => this.launchCommsModal(menuItem, {component: ChatPage, componentProps: {caregiver: caregiverParams}})
                },
                {
                    text: this.translate.instant('CAREGIVER_VIDEO_CALL'),
                    handler: () => this.launchCommsModal(menuItem, {component: VideoPage, componentProps: {caregiver: caregiverParams}})
                }
            ],
            qa: 'caregiver_action_alert'
        });
    }

    public async launchCommsModal(menuItem?: MenuItemDetail, options?: any): Promise<any> {
        let component;
        if (menuItem && menuItem.component) {
            component = menuItem.component;
        } else if (options && options.component) {
            component = options.component;
        } else {
            this.logger.phic.error('missing component specification in MenuService.launchCommsModal');
            return;
        }

        const modalOptions: HRSModalOptions<any> = {component};
        const videoCall: boolean = /VIDEO/i.test(component.name);
        const voiceCall: boolean = /VOICE/i.test(component.name);
        const chatMessage: boolean = /CHAT/i.test(component.name);

        const callModalIsOpen: boolean = this.modalService.getModalStatus('VideoPage') || this.modalService.getModalStatus('VoicePage');
        const videoCallIsActive: boolean = this.communicationService.hasActiveVideoCall();

        if (options) modalOptions.componentProps = options.componentProps;

        // if we're trying to open a new video or voice call while a video or voice call is active, show an error
        // otherwise launch the appropriate comms modal
        if ((videoCall || voiceCall) &&
            (callModalIsOpen || videoCallIsActive)) {
            return await this.overlay.openToast({
                message: this.translate.instant('LAUNCH_CALL_BLOCKED'),
                duration: 5000, // 5 seconds is the standard toast duration
                qa: 'app_active_call_error'
            });
        } else if (videoCall) {
            modalOptions.cssClass = 'c_video_modal';
            modalOptions.headerTitle = this.translate.instant('VIDEO_PANEL');
        } else if (voiceCall) {
            modalOptions.headerTitle = this.translate.instant('VOICE_PANEL');
        } else if (chatMessage) {
            modalOptions.headerTitle = this.translate.instant('MENU_COMMS_CHAT');
        }
        this.launchModal(modalOptions);
    }

    private async launchModal(opts: HRSModalOptions<any>): Promise<void> {
        await this.overlay.openModal({
            component: opts.component,
            title: opts.headerTitle,
            inputs: opts.componentProps,
            qa: 'menu_modal'
        });
    }

    public subscribeToLogout(): void {
        this.launchLogoutWarningSubscription = this.launchLogoutWarning().subscribe();
    }

    /**
     * Checks to see if the patient currently has metrics stored offline (that weren't able to get uploaded)
     * used for the launchLogoutWarning()
     * @private
     */
    private hasOfflineMetrics(): Observable<boolean> {
        return this.offlineTaskService.getOfflineMetrics().pipe(
            map((offlineMetrics)=> {
                if (offlineMetrics && Array.isArray(offlineMetrics) && offlineMetrics.length > 0) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    private launchLogoutWarning(): Observable<Promise<void>> {
        // first, determine if we have offline metrics
        return this.hasOfflineMetrics().pipe(
            // next, set the modal's message - it varies based on whether we have offline metrics or not
            map((hasOfflineMetrics: boolean): string[] => {
                let messages = [this.translate.instant('APP_SIGNOUT.ALERT.BODY_1')];
                if (hasOfflineMetrics) messages.push(this.translate.instant('APP_SIGNOUT.ALERT.BODY_2'));
                messages.push(this.translate.instant('APP_SIGNOUT.ALERT.BODY_3'));
                return messages;
            }),
            // next, create the modal's config object
            map((message: string[]): any => {
                return {
                    header: this.translate.instant('APP_SIGNOUT.ALERT.HEADER'),
                    message: message,
                    variant: 'error',
                    buttons: [
                        {
                            text: this.translate.instant('APP_SIGNOUT.ALERT.BUTTON.CANCEL'),
                            role: 'cancel'
                        },
                        {
                            text: this.translate.instant('APP_SIGNOUT.ALERT.BUTTON.SIGNOUT'),
                            handler: () => {
                                this.taskService.tasks = [];
                                this.user.logout();
                            }
                        }
                    ],
                    qa: 'menu_logout_alert'
                };
            }),
            // finally, create the modal, and then present it
            tap(async (config) => {
                await this.overlay.openAlert(config as OverlayConfig);
            })
        );
    }

    public async performHelpAction(menuItem: MenuItemDetail): Promise<void> {
        switch (menuItem.titleKey) {
            case 'MENU_HELP_SUBMIT_APP_LOGS':
                await this.logUploadViewControllerService.submitAppLogs();
                break;
            case 'MENU_HELP_CALL_SUPPORT':
                this.initSupportCall();
                break;
            default:
                break;
        }
    }

    /**
     * Start support call
     */
    public initSupportCall(): void {
        this.supportCallService.initSupportCall();
    }

    public async populateVersionNumber(): Promise<string> {
        if (this.versionNumber) {
            return this.versionNumber;
        } else {
            if (this.isNativePlatform) {
                const version = await this.appVersion.getVersionNumber();
                if (version) {
                    this.versionNumber = version;
                    return 'v' + version;
                } else {
                    return 'v1.0.0';
                }
            } else {
                return 'v1.0.0';
            }
        }
    }

    public openAdmin(menuEl: HrsMenu): void {
        this.adminService.openAdmin(menuEl['nativeElement']);
    }
}
