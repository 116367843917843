import {enableProdMode, ViewEncapsulation} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from '@app/env';
import {shim as shimPromiseFinally} from 'promise.prototype.finally';

shimPromiseFinally();

// Need to prevent Local Notifications plugin from firing notifications on app start
// so that we can fire them manually after our event subscriptions are in place.
(<any>window).skipLocalNotificationReady = true;

if (environment.HRS_DOMAIN === 'healthrecoverysolutions.com') {
    environment.production = true;
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {defaultEncapsulation: ViewEncapsulation.None})
    .catch((err) => console.error(err));
