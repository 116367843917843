import {Injectable, Optional} from '@angular/core';
import {DeviceIDIntentService} from '../../hrs-tablet/knox-service-intents/deviceid-intent.service';
import {LicenseIntentService} from '../../hrs-tablet/knox-service-intents/license-intent.service';
import {KnoxManageService} from '../knox/knox-manage.service';
import {HRSStorage} from '../storage/storage';
import {GatewayApi} from '@hrs/providers';
import {tap} from 'rxjs/operators';
import {Observable, lastValueFrom, take} from 'rxjs';
import {getLogger} from '@hrs/logging';

/*
  The main goal of this class is to provide the IMEI to Android 10 or 11 devices
*/

@Injectable({
    providedIn: 'root',
})
export class TabletDeviceIdService {
    private readonly logger = getLogger('TabletDeviceIdService');

    public _imei: string;
    private licenseKey: string;

    constructor(
        @Optional() private deviceIntent: DeviceIDIntentService,
        private gatewayApi: GatewayApi,
        @Optional() private licenseIntent: LicenseIntentService,
        private knoxManageService: KnoxManageService,
        private storage: HRSStorage
    ) {}

    public async getImei(): Promise<string> {
        if (this.knoxManageService.enabled && !this._imei) {
            this._imei = await this.knoxManageService.getIMEI();
        } else if (!this.knoxManageService.enabled && !this._imei) {
            this._imei = await this.storage.get('imei');
            this.logger.phic.info('Fetched imei from storage', this._imei);
            if (!this._imei) {
                // This kicks off functionality to get the IMEI as needed
                // Fixed DEV-17302: Added pipe in lastValueFrom method which was not returning imei value and was stuck waiting indefinitely
                let imeiValue;
                imeiValue = await lastValueFrom(this.subscribeIMEIIntent().pipe(take(1))).catch((err) => {
                    this.logger.phic.info('Fetched imei subscribeIMEIIntent() error', err);
                });
                this.logger.phic.info('Fetched imei from subscribeIMEIIntent()', imeiValue);
                this._imei = imeiValue;
                return this._imei;
            }
        }

        this.licenseKey = await this.storage.get('licenseKey');
        this.setLicenseKey();
        this.logger.phic.info('Fetched imei after subscribeIMEIIntent() 1', this._imei);
        return this._imei;
    }

    public setLicenseKey(): void {
        if (this.licenseKey) {
            this.licenseIntent.sendLicenseKeyToPCKS(this.licenseKey);
        } else {
            this.getLicenseKey();
        }
    }

    public getLicenseKey(): void {
        if (!this.gatewayApi.isLoggedIn()) return;
        this.gatewayApi.get('apiv2/').subscribe(
            {
                next: (res: any) => {
                    if (res.knox.license) {
                        this.licenseKey = res.knox.license;
                        this.storage.set('licenseKey', this.licenseKey);
                        this.setLicenseKey();
                    }
                },
                error: (err) => {
                    this.logger.phic.error('Get License Key error', err);
                }
            }
        );
    }

    public subscribeIMEIIntent(): Observable<string> {
        return this.deviceIntent.imei.pipe(tap((hardwareIdentifier: string) => {
            this.storage.set('imei', hardwareIdentifier);
            this._imei = hardwareIdentifier;
            this.setLicenseKey();
            return this._imei;
        }));
    }

    public subscribeLicenseKeyIntent(): Observable<string> {
        return this.licenseIntent.license.pipe(tap((event) => {
            this.setLicenseKey();
        }));
    }
}
