import {Injectable} from '@angular/core';
import {from, from as fromPromise, Observable, Subject, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap, concatMap, finalize} from 'rxjs/operators';
import {GatewayApi} from '@hrs/providers';
import {getLogger} from '@hrs/logging';
import {TaskStorageKey} from '.';
import {TaskMetaData} from './task-metadata.interface';
import {EncryptedData, EncryptedDataSubmit, OfflineMetric, Recording} from './task-offline.interface';
import {User} from '../user/user.service';
import {EncryptionService} from '../encryption/encryption.service';
import {HRSStorage} from '../storage/storage';

@Injectable({
    providedIn: 'root',
})

export class OfflineTaskService {
    private readonly logger = getLogger('OfflineTaskService');

    public uploadingOfflineMetrics: boolean = false; // flag to indicate if uploadOfflineMetrics() is in progress
    public uploadingOfflineMetricsUpdated: Subject<boolean> = new Subject<boolean>(); // keeps track of changes to this.uploadingOfflineMetrics flag
    public processingOfflineMetricsEnd: Subject<boolean> = new Subject<boolean>();
    public pullToRefreshInProgress: boolean = false;
    // inform the uploadOfflineMetrics to stop, if the user initiates the pullToRefresh flow. Note: pullToRefresh automatically
    // kicks off an uploadOfflineMetrics() when pullToRefresh is fully completed.
    public pullToRefreshInProgressEvent: Subject<void> = new Subject<void>();
    public OFFLINE_METRICS_TIMER: number = 60000;

    public offlineMetricUploaded: Subject<string> = new Subject();

    constructor(
        private encryptionService: EncryptionService,
        private gatewayApi: GatewayApi,
        private storage: HRSStorage,
        private user: User
    ) {
        // subscribe to the "pullToRefreshInProgressEvent" which is 'published'' from home.page.ts when the pullToRefresh process is started
        this.pullToRefreshInProgressEvent.subscribe(() => {
            // flag to mark pullToRefresh as in progress (notifies the uploadOfflineMetrics() function to stop if in progress, and to not start)
            this.pullToRefreshInProgress = true;
        });
    }

    // cleanUpEmptyOfflineMetrics
    // reviews the offline metrics from storage and deletes any metrics that no longer have any recordings in the metric's sub-array
    cleanUpEmptyOfflineMetrics(offlineMetrics: OfflineMetric[]): OfflineMetric[] {
        this.logger.debug(`cleanUpEmptyOfflineMetrics() for ${offlineMetrics?.length} metrics`);
        return offlineMetrics.filter((offlineMetric: OfflineMetric) => (offlineMetric.recordings && offlineMetric.recordings.length > 0));
    }

    // gets metrics from offline storage
    getOfflineMetrics(): Observable<OfflineMetric[] | Error> {
        return fromPromise(this.storage.get(TaskStorageKey.OFFLINE_METRICS_STORAGE_KEY + this.user.id)).pipe(
            map((response: string) => {
                return JSON.parse(response);
            }),
            catchError((err)=> {
                return of(err);
            })
        );
    }
    // sets metrics in offline storage
    setOfflineMetrics(offlineMetrics: OfflineMetric[]): Observable<OfflineMetric[] | Error> {
        this.logger.debug(`cleanUpEmptyOfflineMetrics() for ${offlineMetrics?.length} metrics`);
        return fromPromise(this.storage.set(TaskStorageKey.OFFLINE_METRICS_STORAGE_KEY + this.user.id, JSON.stringify(offlineMetrics))).pipe(
            map((response: string) => {
                return JSON.parse(response);
            }),
            catchError((err)=> {
                return of(err);
            })
        );
    }

    // ///////////////////////////
    // UPLOAD: uploadOfflineMetrics()
    // ///////////////////////////
    // if the patient's device is offline or another type of failed attempt to submitTask() then this recursive function tries to upload the metric every 60 seconds
    uploadOfflineMetrics(): void {
        this.logger.trace(`uploadOfflineMetrics()`);
        // before running this function, make sure its not already running (this.uploadingOfflineMetrics flag).
        // This is important when a user enters more than one metric while offline. We don't want this function to run several times concurrently.
        // Ensures only one instance of this function is running at any given time.
        // Also, make sure the "pullToRefresh" process is not currently running as that process gets tasks from the server overwriting how metrics are stored in UI_STORAGE_KEY.
        if (!this.uploadingOfflineMetrics && !this.pullToRefreshInProgress) {
            this.uploadingOfflineMetrics = true;
            this.getOfflineMetrics().subscribe((offlineMetrics: OfflineMetric[]) => {
                this.logger.trace(`uploadOfflineMetrics() -> getOfflineMetrics() -> next`);
                // ensure offline metrics is an array
                if (offlineMetrics && Array.isArray(offlineMetrics)) {
                    this.logger.debug(`uploadOfflineMetrics() -> getOfflineMetrics() got ${offlineMetrics?.length} results`);
                    // remove any metrics that don't have any recordings (they were already previously uploaded)
                    // note: this is also done when we clear offline metrics from ionic storage
                    let cleanedOfflineMetrics: OfflineMetric[] = this.cleanUpEmptyOfflineMetrics(offlineMetrics);
                    this.logger.debug(`cleanedOfflineMetrics length = ${cleanedOfflineMetrics.length}`);

                    if (cleanedOfflineMetrics.length > 0) {
                        // flatten the offline metrics into a single array of recordings with the metric.id now tucked into each recording
                        let flattenedOfflineMetrics: Recording[] = this.flattenOfflineMetrics(cleanedOfflineMetrics);

                        this.logger.debug(`looping over the flattened recordings array, and submitting each one sequentially`);
                        // looping over the flattened recordings array, and submitting each one sequentially
                        let recordingsStream: Observable<OfflineMetric[] | Error> = this.submitAllEncryptedMetricsSequentially(flattenedOfflineMetrics);

                        recordingsStream.pipe(
                            tap(() => {
                                // if pullToRefresh is in progress, throw an error. This will land us in the catchError block, which will send us to the to error
                                // functinon of the subscribe() block, effectively stopping this uploadOfflineMetrics() function from uploading any further offlineMetrics.
                                if (this.pullToRefreshInProgress) {
                                    // there are no offline metrics in storage, reset the uploadingOfflineMetrics flag to false
                                    this.uploadingOfflineMetrics = false;
                                    // notify the pullToRefresh function in home.page.ts that the uploadOfflineMetrics() function is completed and its now safe to resume the pullToRefresh process
                                    this.uploadingOfflineMetricsUpdated.next(this.uploadingOfflineMetrics);
                                    throw new Error('pull to refresh is in progess, stop the offline upload process.');
                                }
                            }),
                            catchError((err) => {
                                this.logger.error(`getOfflineMetrics() -> recordingsStream caught error`, err);
                                return throwError(() => err);
                            }),
                            finalize(() => {
                                this.logger.debug(`getOfflineMetrics() -> recordingsStream finished`);
                            })
                        ).subscribe({
                            next: () => {
                                this.logger.debug(`getOfflineMetrics() -> recordingsStream success!`);
                                this.uploadingOfflineMetricsEnd(true);
                            },
                            error: (error) => {
                                this.logger.error(`==>> Error submittingEncryptedMetrics, attempting to try again in ${this.OFFLINE_METRICS_TIMER}ms`, error);
                                this.uploadingOfflineMetricsEnd();
                            }
                        });
                    } else {
                        this.logger.debug(`getOfflineMetrics() cleaned offline metrics array is empty`);
                        this.uploadingOfflineMetricsEnd();
                    }
                } else {
                    // This is a common (noisy) occurance - do not raise it above trace level.
                    this.logger.trace(`getOfflineMetrics() result is not an array`);
                    this.uploadingOfflineMetricsEnd();
                }
            });
        } else {
            this.uploadingOfflineMetricsEnd();
        }
    }

    private uploadingOfflineMetricsEnd(dataUploaded: boolean = false): void {
        this.uploadingOfflineMetrics = false;
        // notify that upload process is done - regardless of whether
        //  - there was anything to upload
        //  - upload was successful or failed
        this.processingOfflineMetricsEnd.next(dataUploaded);
    }

    // submitAllEncryptedMetricsSequentially
    // basically this is an rxjs loop (concatMap) over the offlineMetrics array and submit each metric asynchronously
    submitAllEncryptedMetricsSequentially(flatMetrics: Recording[]): Observable<OfflineMetric[] | Error> {
        this.logger.debug(`submitAllEncryptedMetricsSequentially()`);
        return from(flatMetrics).pipe(
            // concatMap - ensures that each post happens asynchronously waiting for the previous recording in the flatMetrics array to complete before starting the next
            concatMap((item: Recording) => {
                let recording: Recording = item;

                // ensure we have the taskData which is the encrypted data AND taskData.data which is the actual encrypted recording
                if (recording.taskData && recording.taskData.data) {
                    // makes a single request to post the encrypted metric to the 'metrics' endpoint
                    return this.submitEncryptedMetric(recording);
                } else {
                    throw new Error('uploadOfflineMetrics: OfflineMetric has falsy recording.taskData or recording.taskData.data');
                }
            })
        );
    }

    // makes a single request to post the encrypted metric to the 'metrics' endpoint
    submitEncryptedMetric(recording: Recording): Observable<OfflineMetric[] | Error> {
        this.logger.debug(`submitAllEncryptedMetricsSequentially()`);
        return this.submitEncryptedTask(recording.taskData).pipe(
            switchMap((response) => {
                return this.clearTaskFromOfflineMetricStorage(recording.id, recording.taskData.data);
            }),
            catchError((error: Error) => {
                // failure, task did not submit successfully
                // if any tasks have an error when posting, throwError returns us to the subscribe() block's error function to retry uploadOfflineMetrics() again
                return throwError(() => error);
            })
        );
    }

    /**
     * Complete a task by recording its metric reading from the offline storage (in the event that the original task could not be submitted successfully)
     */
    public submitEncryptedTask(encryptedData: EncryptedData): Observable<any | Error> {
        this.logger.debug(`submitEncryptedTask()`);
        let dataToSubmit: EncryptedDataSubmit = {
            data: {
                message: encryptedData.data,
                key: encryptedData.clientPublicKey,
                nonce: encryptedData.nonce,
                gid: this.user.id,
            }
        };
        return this.gatewayApi.post('metrics', dataToSubmit as EncryptedDataSubmit).pipe(
            catchError((error) => {
                this.logger.debug(`submitEncryptedTask() task did not save!`, error);
                // failure scenario - task did not save!
                // locally handle errors
                // its important that we use "throwError" here as it lands in the error function of the subscribe() block for additional error
                // call-site specific error handling. If we just return of(error) it sends us back to the success function of the subscribe() block.
                return throwError(() => error);
            })
        );
    }

    // when uploadingOfflineMetrics() we need to convert the nested 'OfflineMetric' array into a flat 'Recording' array
    flattenOfflineMetrics(metrics: OfflineMetric[]): Recording[] {
        // 1 - map over offlineMetric[] and add the metric.id to each recording. Return only the recordings in a new array
        let recordingsArrayNested: Recording[][] = metrics.map((metric: OfflineMetric) => {
            let recordings: Recording[] = metric.recordings.map((recording: Recording) => {
                recording.id = metric.id;
                return recording;
            });
            return recordings;
        });

        // 2 - now flatten the array of arrays into a new array.
        let recordingsArrayFlat: Recording[] = [].concat(...recordingsArrayNested);

        return recordingsArrayFlat;
    }

    // ///////////////////////////////
    // ADD: addTaskToOfflineMetricStorage
    // ///////////////////////////////
    addTaskToOfflineMetricStorage(taskId: string, unencryptedData, metadata: TaskMetaData): Observable<OfflineMetric[] | Error> {
        this.logger.debug(`addTaskToOfflineMetricStorage()`, {taskId, unencryptedData, metadata});
        // get the Public Server Key
        const serverPublicKey: string = this.encryptionService.serverPublicKey;

        // check if public key exists, before starting. If not, abort.
        if (serverPublicKey) {
            // prepare the data in the exact format required by the backend and encrypt it
            const encryptedData = this.prepAndEncrypt(taskId, unencryptedData, serverPublicKey, metadata);
            // get the offlineMetrics string from the device's storage
            return this.getOfflineMetrics()
                .pipe(
                    switchMap((offlineMetrics: OfflineMetric[]) => {
                        // First, update the OfflineMetrics Storage after adding this new Recording to the OfflineMetrics array in memory
                        return this.setOfflineMetrics(this.addRecordingToOfflineMetrics(taskId, encryptedData, metadata, offlineMetrics));
                    }),
                    catchError((error: Error) => {
                        return throwError(() => error);
                    })
                );
        } else {
            const err = new Error('No serverPublicKey available for encryption. Task was not added to offline storage.');
            return throwError(() => err);
        }
    }

    prepAndEncrypt(taskId: string, unencryptedData, serverPublicKey: string, metadata: TaskMetaData): EncryptedData {
        // put the data in the exact format that is required by the backend
        const unencryptedDataToSubmit = {data: unencryptedData};
        // encrypt the metric data
        const encryptedData: EncryptedData = this.encryptionService.encryptDataUsingSodium(unencryptedDataToSubmit, serverPublicKey);

        return encryptedData;
    }

    addRecordingToOfflineMetrics(taskId: string, encryptedData, metadata: TaskMetaData, offlineMetrics: OfflineMetric[]): OfflineMetric[] {
        // create the recording object from the encryptedData adn the metadata arguments
        let recording: Recording = {
            taskData: encryptedData,
            recordedDate: metadata.recordedDate
        };
        offlineMetrics = offlineMetrics || []; // handle situation where there is nothing in offline storage
        // make a copy of the OfflineMetric object OR return falsey (null) if the OfflineMetric doesn't exist currently in Ionic Storage.
        let existingOfflineMetric: OfflineMetric = offlineMetrics.length > 0 ? offlineMetrics.find((metric: OfflineMetric) => metric.id === taskId) : null;
        // if this metric (i.e. 'activity', 'glucose') already exist in ionic's offline storage
        if (existingOfflineMetric) {
            // remove this metric from offlineMetrics
            offlineMetrics = offlineMetrics.filter((metric: OfflineMetric) => metric.id !== taskId);
            // update this metric with the new encrypted metric
            existingOfflineMetric.recordings.push(recording);
            // add the new metric back to the offline metrics
            offlineMetrics.push(existingOfflineMetric);
        } else {
            let newOfflineMetric: OfflineMetric = {
                id: taskId,
                recordings: [recording]
            };
            // add the new metric back to the offline metrics
            offlineMetrics.push(newOfflineMetric);
        }
        return offlineMetrics;
    }

    // //////////////////////////////////
    // CLEAR: clearTaskFromOfflineMetricStorage
    // //////////////////////////////////
    // REMOVES the recently uploaded offline metric recording from ionic's offline storage
    // only gets called when a task ia uploaded from ionic storage from the uploadOfflineMetrics() function.
    // it compares the encryptedData string from what was uploaded successfully to that contained in the ionic storage and deletes that entire recording
    clearTaskFromOfflineMetricStorage(taskId: string, encryptedData: string): Observable<OfflineMetric[] | Error> {
        // Adding the metric was successful, clear that metric from offline storage
        return this.getOfflineMetrics().pipe(
            switchMap((response:OfflineMetric[]) => {
                // remove this task from offline storage
                return this.removeFromOfflineStorage(response, taskId, encryptedData);
            }),
            tap(() => {
                // notify that this offline metric has been uploaded
                this.offlineMetricUploaded.next(taskId);
            }),
            catchError((error: Error) => {
                return of(error);
            })
        );
    }

    removeFromOfflineStorage(offlineMetrics: OfflineMetric[], taskId: string, encryptedData: string): Observable<OfflineMetric[] | Error> {
        if (offlineMetrics && Array.isArray(offlineMetrics) && offlineMetrics.length) {
        // get the index of the recently uploaded mtric from the offlineMetrics array
            let metricIndex: number = offlineMetrics.findIndex((metric) => metric.id === taskId);

            // make a copy of the recently uploaded metric from the offlineMetrics array
            let offlineMetric: OfflineMetric = offlineMetrics.find((offlineMetric: OfflineMetric) => offlineMetric.id === taskId);
            // ensure the offline metric has at least one recording
            if (offlineMetric && offlineMetric.recordings && offlineMetric.recordings.length > 0) {
            // REMOVE the recently uploaded metric from the offlineMetrics array by comparing the encryptedData string
                // in each Recording to the encryptedData string that we recently uploaded to the server
                offlineMetrics = this.filterClearedFromOfflineMetrics(offlineMetrics, offlineMetric, metricIndex, encryptedData);
                // filter out the offlineMetric IF it now has zero records
                let cleanedOfflineMetrics: OfflineMetric[] = this.cleanUpEmptyOfflineMetrics(offlineMetrics);

                // update the ionic storage bucket for offlineTasks with the updated offlineMetrics array (which removed one
                // offline recording that was previously submitted successfully)
                return this.setOfflineMetrics(cleanedOfflineMetrics);
            }
        }
    }

    filterClearedFromOfflineMetrics(offlineMetrics: OfflineMetric[], offlineMetric: OfflineMetric, metricIndex: number, encryptedData: string): OfflineMetric[] {
        let filteredRecordings: Recording[] = offlineMetric && offlineMetric.recordings ?
            offlineMetric.recordings.filter((offlineRecording: Recording) => offlineRecording.taskData.data !== encryptedData) : null;

        // update the metric's offline recordings with the new array of offline recordings minus the one we deleted
        offlineMetric.recordings = filteredRecordings;

        // update this metric in the offlineMetrics object
        offlineMetrics[metricIndex] = offlineMetric;

        return offlineMetrics;
    }
}
