import {Injectable} from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import {map} from 'rxjs/operators';
import {AdminGatewayApiService} from './admin-gateway-api.service';
import {AdminTokenService} from './admin-token.service';
import {Observable} from 'rxjs';

@Injectable()
export class AdminUserService {
    public readonly sourceApp: string = 'admin';

    constructor(
        private adminGatewayApi: AdminGatewayApiService,
        private tokenService: AdminTokenService
    ) {
        this.tokenService.sourceApp = 'admin';
    }

    login(username: string, password: string): Observable<boolean> {
        const credentials = {
            data: {
                type: 'credentials',
                username: username,
                password: password,
                source: this.tokenService.sourceApp
            }
        };
        let data = this.adminGatewayApi.login(credentials);

        return data.pipe(map((res: any) => {
            let resData = res.data.attributes;
            let token = resData.token;
            return token && jwtDecode(token)['type'] === 'admin';
        }));
    }

    isLoggedIn(): boolean {
        return this.adminGatewayApi.isLoggedIn();
    }

    logout(): void {
        this.adminGatewayApi.logout();
    }
}
