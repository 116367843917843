<hrs-content>
    <div class="c_edu_video">
        <div class="c_edu_video--overlay"
             [ngClass]="{'is-hidden': hideControls}"
             (click)="toggleControls()">
            <hrs-buttons class="c_edu_video--ctrl_group">
                <hrs-button variant="action-icon" icon="backward-10" class="c_edu_video--ctrl-backward" (click)="rewind()"></hrs-button>
                <hrs-button variant="action-icon" class="c_edu_video--ctrl-play" (click)="togglePausePlay()" [attr.icon]="!video || video.paused ? 'play-pcmt' : 'pause'"></hrs-button>
                <hrs-button variant="action-icon" icon="forward-10" class="c_edu_video--ctrl-forward" (click)="fastForward()"></hrs-button>
            </hrs-buttons>
        </div>
        <video preload="auto" poster="none" autoplay playsinline webkit-playsinline></video>
    </div>
</hrs-content>
