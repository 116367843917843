import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {VoicePage} from './voice.page';
import {ComponentLibraryModule} from '@hrsui/angular';

const routes: Routes = [
    {
        path: '',
        component: VoicePage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    declarations: [VoicePage]
})
export class VoicePageModule {}

export {VoicePage};
