import {ConsentFormElement} from './consent-form-element';

interface ConsentFormPage {
    elements: ConsentFormElement[];
}

export class ConsentForm {
    pages: ConsentFormPage[];

    constructor(data?: Partial<ConsentForm>) {
        this.set(data);
    }

    set(data: Partial<ConsentForm>): void {
        Object.assign(this, data);
    }

    hasContent(): boolean {
        if (this.pages && this.pages.length) return true;
    }
}
