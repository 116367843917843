import {Injectable} from '@angular/core';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {APKIntentService} from '../knox-service-intents/apk-intent.service';
import {APKService} from './apk.service';
import {PCKSVersionIntentService} from '../knox-service-intents/pcks-version-intent.service';
import {GatewayResourceService, GatewayResponse} from '@hrs/gateway';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class PCKSAPKService extends APKService {
    private readonly logger = getLogger('PCKSAPKService');

    public folder = 'HRSPatientConnectKnoxService';
    public static PACKAGE_NAME = 'com.hrs.intent.patientconnectknoxservice';

    constructor(
        file: File,
        fileTransfer: FileTransfer,
        apk: APKIntentService,
        gateway: GatewayResourceService,
        private PCKSIntent: PCKSVersionIntentService
    ) {
        super(file, fileTransfer, apk, gateway);
        this._packageName = PCKSAPKService.PACKAGE_NAME;
        this.subscribeToVersionIntent();
    }

    get version() {
        return this._version;
    }

    public async install(url: string): Promise<boolean> {
        return super.install(url, this.folder);
    }

    public async getLatestAPKMetaData(): Promise<any[] | any> {
        return await super.getLatest(this.folder);
    }

    public async updateToLatest(): Promise<boolean> {
        return await super.updateToLatest(this.folder, this._version);
    }

    public async getMetaData(): Promise<GatewayResponse<any[] | any>> {
        this.logger.phic.log('getMetaData called');
        return await super.getMetaData(this.folder);
    }

    private subscribeToVersionIntent() {
        this.PCKSIntent.version.subscribe((version) => {
            this._version = version;
        });
    }
}
