import {map, of, Observable} from 'rxjs';
import {
    ActivityEvent,
    ActivityMetrics,
    BloodPressureMetrics,
    DailyMetricsData,
    GlucoseMetrics,
    PulseOxMetrics,
    SurveyMetrics,
    TemperatureMetrics,
    WeightMetrics,
    WoundImagingMetrics
} from './daily-metrics.interface';
import {EnvironmentService} from '../environment/environment.service';
import {TaskType} from '../tasks/task-type.enum';
import {MetricReadingDetail} from '@hrsui/core/dist/types/components/metrics/metrics.interface';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment-timezone';
import {GatewayApi} from '@hrs/providers';
import {HRSStorage} from '../storage/storage';
import {Injectable} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {ActivityTask} from '../tasks/activity-task.model';

@Injectable({
    providedIn: 'root',
})
export class DailyMetrics {
    private readonly logger = getLogger('DailyMetrics');

    private static ACTIVITY_RESET_EVENT_ID = 'activity_reset_event_id';
    public activity: ActivityMetrics;
    public activityResetId = 0;
    public bloodpressure: BloodPressureMetrics;
    public glucose: GlucoseMetrics;
    public hasHistoricalData: boolean = false;
    public pulseox: PulseOxMetrics;
    public survey: SurveyMetrics;
    public temperature: TemperatureMetrics;
    public weight: WeightMetrics;
    public woundimaging: WoundImagingMetrics;
    private emptyReading: MetricReadingDetail = {reading: '-', readingColor: 'black', readingItalicize: false};
    private offlineReading: MetricReadingDetail = {reading: 'Sending...', readingColor: 'gray-7', readingItalicize: true};

    constructor(
        private environmentService: EnvironmentService,
        private gatewayApi: GatewayApi,
        private storage: HRSStorage,
        private translateService: TranslateService
    ) {}

    public async initDailyMetrics(): Promise<void> {
        try {
            this.activityResetId = await this.storage.get(DailyMetrics.ACTIVITY_RESET_EVENT_ID) || 0;
            this.offlineReading.reading = this.translateService.instant('METRIC.OFFLINE_READING');
        } catch (err) {
            this.logger.phic.error('initDailyMetrics failed', err);
        }
    }

    /**
     * Clears data so they don't persist between patients.
     */
    public clearDailyMetrics(): void {
        Object.assign(this, {
            activity: undefined,
            bloodpressure: undefined,
            glucose: undefined,
            pulseox: undefined,
            survey: undefined,
            temperature: undefined,
            weight: undefined,
            woundimaging: undefined,
            reset: {activity: {ts: 0, resetDuration: 0}, survey: {ts: 0}, weight: {ts: 0}},
            offlineReading: {reading: 'Sending...', readingColor: 'gray-7', readingItalicize: true}
        });
    }

    public getTodaysMetrics(hrsid: string, env: string): Observable<DailyMetricsData> {
        const hasHistoricalData = this.environmentService.hasHistoricalData();
        this.logger.debug(`getTodaysMetrics()`, {hrsid, env, hasHistoricalData});
        if (hasHistoricalData) {
            const today = moment().locale('en').format('YYYY-MM-DD');
            const endpoint = `patient/daily-metrics?filter[hrsId]=${hrsid}&filter[env]=${env}&filter[date]=${today}`;
            this.logger.debug(`api endpoint = ${endpoint}`);
            return this.gatewayApi.get(endpoint).pipe(
                map((metrics: DailyMetricsData) => {
                    this.parseMetrics(metrics);
                    return metrics;
                })
            );
        } else {
            this.logger.warn(`getTodaysMetrics() skipping refresh for daily metrics (hasHistoricalData = ${hasHistoricalData})`);
            return of(null);
        }
    }

    public async resetMetric(metric: string): Promise<void> {
        if (metric === TaskType.Activity) {
            const mostRecentActivityEvent = this.activity.events.reduce((prev: ActivityEvent, current: ActivityEvent) => {
                return (prev && prev.id > current.id) ? prev : current;
            });
            this.activityResetId = mostRecentActivityEvent.id;
            // activity id event is stored in order to maintain activity reset state between app restarts
            // this id allows us to correctly sum activity event durations from the point the metric was reset
            await this.storage.set(DailyMetrics.ACTIVITY_RESET_EVENT_ID, mostRecentActivityEvent.id);
            await this.storage.set(HRSStorage.ACTIVITY_RESET_TS, this.activity.ts);
        }

        if (metric === TaskType.Weight) {
            await this.storage.set(HRSStorage.WEIGHT_RESET_TS, this.weight.ts);
        }
    }

    private parseMetrics(metrics: DailyMetricsData): void {
        this.logger.debug(`parseMetrics(): ${JSON.stringify(metrics, null, '\t')}`);
        Object.assign(this, metrics.data.attributes);
    }

    /**
     *
     * @param metric string representing TaskType
     * @returns timestamp of metric, as a UTC value or -1 if metric is not supported here
     */
    public getMetricTimestamp(metric: string): number {
        if (this.hasMetric(metric)) {
            let tsDate: Date;
            // Survey returns UTC time -- WELL IT USED TO, leaving this here in case things get reverted back to the old ways
            // if (metric === 'survey') { // ts in UTC
            //     tsDate = new Date(this[metric].ts);
            //     return tsDate.valueOf();
            // }

            // ts in EST
            const ts: string = this.convertTimestamp(this[metric].ts);
            tsDate = new Date(ts);
            return tsDate.valueOf();
        }
        return -1;
    }

    public supportsMetric(metric: string): boolean {
        return Object.keys(this).includes(metric);
    }

    public hasMetric(metric: string): boolean {
        return this.supportsMetric(metric) &&
            this[metric] !== undefined &&
            // if no data exists for a given metric the backend returns null for each key for the metric
            Object.keys(this[metric]).every((key) => this[metric][key] !== null);
    }

    private shouldShowData(taskType: TaskType, isTaskReset: boolean): boolean {
        return this.hasMetric(taskType) && !isTaskReset;
    }

    private calculateActivityDuration(): number {
        const validActivityEvents = this.activity.events.filter((event: ActivityEvent) => event.id > this.activityResetId);
        return validActivityEvents.reduce((n, ev) => n + ev.duration, 0);
    }

    public getActivityDetail(task: ActivityTask, offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.activity || !this.activity.ts || moment(offlineSubmitTS).isAfter(this.activity.ts))) return this.offlineReading;
        let subText = this.translateService.instant('METRIC.ACTIVITY.SUBTEXT');
        let duration = 0;
        let result: MetricReadingDetail;
        const goal = task?.goal ?? 0;

        if (this.shouldShowData(TaskType.Activity, task?.isTaskReset)) {
            duration = this.calculateActivityDuration();
            if (duration >= 100 || goal >= 100) subText = '';
            const reading = goal ? this.translateService.instant('METRIC.ACTIVITY.READING', {duration: `${this.checkValue(duration)}`, goal: goal}) :
                this.checkValue(duration);
            result = {
                reading: reading,
                subText: subText,
                time: this.activity.ts ? this.formatTimestamp(this.activity.ts) : '',
                readingColor: 'black',
                readingItalicize: false
            };
        } else {
            const reading = goal ? this.translateService.instant('METRIC.ACTIVITY.READING', {duration, goal}) : '0';
            result = {
                reading: reading,
                readingColor: 'black',
                readingItalicize: false,
                subText: this.translateService.instant('METRIC.ACTIVITY.SUBTEXT'),
            };
        }

        if (task) {
            // Need to set the duration on the task right after it is calculated
            // so we can determine the correct module status in UI afterward.
            task.duration = duration;
        }

        return result;
    }

    public getBloodPressureDetail(offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.bloodpressure || !this.bloodpressure.ts || moment(offlineSubmitTS).isAfter(this.bloodpressure.ts))) return this.offlineReading;
        if (!this.hasMetric(TaskType.BloodPressure)) return this.emptyReading;
        return {
            reading: `${this.checkValue(this.bloodpressure.systolic)}\/${this.checkValue(this.bloodpressure.diastolic)}`,
            subText: `${this.checkValue(this.bloodpressure.heartrate)}`,
            subTextIcon: 'heartrate',
            subTextIconColor: 'risk-high',
            time: this.bloodpressure.ts ? this.formatTimestamp(this.bloodpressure.ts) : '',
            readingColor: 'black',
            readingItalicize: false
        };
    }

    public getPulseOxDetail(offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.pulseox || !this.pulseox.ts || moment(offlineSubmitTS).isAfter(this.pulseox.ts))) return this.offlineReading;
        if (!this.hasMetric(TaskType.PulseOx)) return this.emptyReading;
        return {
            reading: `${this.checkValue(this.pulseox.sp02)}`,
            readingIcon: 'percent',
            readingIconColor: 'black',
            subText: `${this.checkValue(this.pulseox.heartrate)}`,
            subTextIcon: 'heartrate',
            subTextIconColor: 'risk-high',
            time: this.pulseox.ts ? this.formatTimestamp(this.pulseox.ts) : '',
            readingColor: 'black',
            readingItalicize: false
        };
    }

    public getGlucoseDetail(offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.glucose || !this.glucose.ts || moment(offlineSubmitTS).isAfter(this.glucose.ts))) return this.offlineReading;
        if (!this.hasMetric(TaskType.Glucose)) return this.emptyReading;
        return {
            reading: `${this.checkValue(parseFloat(this.glucose.glucose.toString()))}`,
            time: this.glucose.ts ? this.formatTimestamp(this.glucose.ts) : '',
            readingColor: 'black',
            readingItalicize: false
        };
    }

    public getWeightDetail(isTaskReset: boolean, offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.weight || !this.weight.ts || moment(offlineSubmitTS).isAfter(this.weight.ts))) return this.offlineReading;
        if (this.shouldShowData(TaskType.Weight, isTaskReset)) {
            return {
                reading: `${this.checkValue(parseFloat(this.weight.weight.toString()))}`,
                time: this.weight.ts ? this.formatTimestamp(this.weight.ts) : '',
                readingColor: 'black',
                readingItalicize: false
            };
        }

        return this.emptyReading;
    }

    public getSurveyDetail(scheduledQuestionCount: number, answeredQuestionCount: number, offlineSubmitTS?: Date): MetricReadingDetail {
        this.logger.debug(`getSurveyDetail()`, {scheduledQuestionCount, answeredQuestionCount, offlineSubmitTS});
        if (offlineSubmitTS && (!this.survey || !this.survey.ts || moment(offlineSubmitTS).isAfter(this.survey.ts))) return this.offlineReading;
        const remainingQuestionCount = scheduledQuestionCount - answeredQuestionCount;
        this.logger.debug(`remainingQuestionCount = ${remainingQuestionCount}`);
        let result: MetricReadingDetail;
        if (remainingQuestionCount <= 0) {
            result = {
                reading: this.translateService.instant('METRIC.SURVEY.READING_COMPLETE'),
                time: this.survey?.ts ? this.formatTimestamp(this.survey.ts) : '',
                readingColor: 'black',
                readingItalicize: false
            };
        } else {
            result = {
                reading: `${remainingQuestionCount}`,
                subText: remainingQuestionCount === 1 ? this.translateService.instant('METRIC.SURVEY.SUBTEXT_SINGULAR') : this.translateService.instant('METRIC.SURVEY.SUBTEXT'),
                readingColor: 'black',
                readingItalicize: false
            };
        }
        this.logger.debug(`getSurveyDetail() result = `, result);
        return result;
    }

    public getTemperatureDetail(offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.temperature || !this.temperature.ts || moment(offlineSubmitTS).isAfter(this.temperature.ts))) return this.offlineReading;
        if (!this.hasMetric(TaskType.Temperature) || !this.temperature.temperature) return this.emptyReading;
        return {
            reading: `${parseFloat(this.temperature.temperature.toString())}`,
            time: this.temperature.ts ? this.formatTimestamp(this.temperature.ts) : '',
            readingColor: 'black',
            readingItalicize: false
        };
    }

    public getWoundImagingDetail(offlineSubmitTS?: Date): MetricReadingDetail {
        if (offlineSubmitTS && (!this.woundimaging || !this.woundimaging.ts || moment(offlineSubmitTS).isAfter(this.woundimaging.ts))) return this.offlineReading;
        if (!this.hasMetric(TaskType.WoundImaging)) return {reading: ''};
        return {
            reading: '',
            time: this.woundimaging.ts ? this.formatTimestamp(this.woundimaging.ts) : '',
            readingColor: 'black',
            readingItalicize: false
        };
    }

    private convertTimestamp(ts: number): string {
        // get est offset
        const estMoment: moment.Moment = moment().tz('America/New_York');
        const offsetHours: number = estMoment.utcOffset(); // -240 or -300 depending on time of year
        const timezone: string = offsetHours === -240 ? 'EDT' : 'EST';
        // create date/time with offset
        let tsDate: string = new Date(ts * 1000).toUTCString();
        // Remove GMT from string, add EST/EDT
        tsDate = tsDate.slice(0, tsDate.length - 4) + ' ' + timezone;
        return tsDate;
    }

    private formatTimestamp(ts: number): string {
        // convert ts to user timezone
        return moment(this.convertTimestamp(ts)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mm A');
    }

    // Survey returns UTC time -- WELL IT USED TO, leaving this here in case things get reverted back to the old ways
    // private formatSurveyTimestamp(ts: number): string {
    //     return moment.unix(ts).utc(true).format('h:mm A');
    // }

    private checkValue(value): string {
        if ((value || value === 0)) {
            return value.toString();
        } else {
            return '-';
        }
    }
}
