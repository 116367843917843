import {NgModule, InjectionToken} from '@angular/core';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {CameraPreview} from '@awesome-cordova-plugins/camera-preview/ngx';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {CookieService} from 'ngx-cookie-service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {Device} from '@ionic-native/device/ngx';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {File as CdvFile} from '@ionic-native/file/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {Network} from '@ionic-native/network/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Screenshot} from '@ionic-native/screenshot/ngx';
import {Vibration} from '@ionic-native/vibration/ngx';
import {WifiWizard2} from '@ionic-native/wifi-wizard-2/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {Zoom} from '@ionic-native/zoom/ngx';
import {Keyboard, KeyboardPlugin} from '@capacitor/keyboard';
import {BatteryStatus} from '@ionic-native/battery-status/ngx';
import {Uptime} from '@ionic-native/uptime/ngx';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';
import {SplashScreen, SplashScreenPlugin} from '@capacitor/splash-screen';
import {SignalStrength, SignalStrengthCordovaInterface} from 'cordova-plugin-signal-strength';
import {StatusBar, StatusBarPlugin} from '@capacitor/status-bar';
import {FirebaseMessaging, FirebaseMessagingPlugin} from '@capacitor-firebase/messaging';
import {FirebaseAnalytics, FirebaseAnalyticsPlugin} from '@capacitor-firebase/analytics';
import {WakeLock, WakeLockPlugin} from 'capacitor-plugin-wakelock';

export const SPLASH_SCREEN_PLUGIN = new InjectionToken<SplashScreenPlugin>('SplashScreenPlugin');
export const SIGNAL_STRENGTH_PLUGIN = new InjectionToken<SignalStrengthCordovaInterface>('SignalStrengthPlugin');
export const STATUS_BAR_PLUGIN = new InjectionToken<StatusBarPlugin>('StatusBarPlugin');
export const FIREBASE_MESSAGING_PLUGIN = new InjectionToken<FirebaseMessagingPlugin>('FirebaseMessagingPlugin');
export const FIREBASE_ANALYTICS_PLUGIN = new InjectionToken<FirebaseAnalyticsPlugin>('FirebaseAnalyticsPlugin');
export const WAKE_LOCK_PLUGIN = new InjectionToken<WakeLockPlugin>('WakeLockPlugin');
export const KEYBOARD_PLUGIN = new InjectionToken<KeyboardPlugin>('KeyboardPlugin');

@NgModule({
    providers: [
        {provide: SIGNAL_STRENGTH_PLUGIN, useValue: SignalStrength},
        {provide: SPLASH_SCREEN_PLUGIN, useValue: SplashScreen},
        {provide: STATUS_BAR_PLUGIN, useValue: StatusBar},
        {provide: FIREBASE_MESSAGING_PLUGIN, useValue: FirebaseMessaging},
        {provide: FIREBASE_ANALYTICS_PLUGIN, useValue: FirebaseAnalytics},
        {provide: WAKE_LOCK_PLUGIN, useValue: WakeLock},
        {provide: KEYBOARD_PLUGIN, useValue: Keyboard},
        AndroidPermissions,
        AppVersion,
        BLE,
        BatteryStatus,
        BluetoothSerial,
        Broadcaster,
        CallNumber,
        Camera,
        CameraPreview,
        CookieService,
        Deeplinks,
        Device,
        Dialogs,
        CdvFile,
        FileTransfer,
        Geolocation,
        InAppBrowser,
        Insomnia,
        LocalNotifications,
        Network,
        OpenNativeSettings,
        ScreenOrientation,
        Screenshot,
        Uptime,
        Vibration,
        WifiWizard2,
        Zoom,
    ]
})
export class NativePluginsModule {
}
