import {ChangeDetectorRef, Component, HostListener, ChangeDetectionStrategy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../services/events/event.service';
import {OverlayService} from '../../services/overlay/overlay.service';
import {User} from '../../services/user/user.service';
import {Subject, Subscription, timer} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {InjectedChat, ResponseChat} from '@hrs/interfaces';
import {Caregiver} from '../../services/user/caregiver.interface';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {OverlayRef} from '../../hrs-overlay';
import {CommunicationService, ModalService} from '@hrs/providers';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.page.html',
    styleUrls: ['./chat.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPage {
    private readonly logger = getLogger('ChatPage');
    public loadingMessages$: Subject<(ResponseChat | InjectedChat)[]> = new Subject<(ResponseChat | InjectedChat)[]>();
    public isLoading: boolean = false;
    private messages: (ResponseChat | InjectedChat)[];
    data: {message: string} = {message: ''};
    poll: any;
    caregiver: Caregiver;
    scrolling: boolean;
    getMessagesEvent: Subscription;
    openNewChatEvent: Subscription;

    @HostListener('hrsChange', ['$event'])
    handleInput({detail: value}) {
        this.data.message = value.value;
    }

    constructor(
        private communication: CommunicationService,
        private eventService: EventService,
        private modalService: ModalService,
        private overlay: OverlayService,
        private overlayRef: OverlayRef,
        private ref: ChangeDetectorRef,
        private taskTrackingService: TaskTrackingService,
        private translateService: TranslateService,
        public user: User,
    ) {
        this.modalService.setModalStatus('ChatPage', true);
    }

    ngOnInit() {
        this.logger.debug(`ngOnInit()`);
        if (this.overlay.currentToast) {
            this.logger.debug(`dismissing current toast`);
            this.overlay.currentToast.dismiss();
        }
        this.getMessagesEvent = this.communication.getChatNewMessage$.subscribe(() => {
            this.logger.debug(`ngOnInit() -> communication.getChatNewMessage$`);
            this.getTextMessages(true);
        });

        // incoming chats do not provide caregiver names.
        // waiting for completed requests for caregiver data to match up incoming chat's Chatroom ID to caregiver's name.
        if (this.caregiver && this.caregiver.chatroom && !this.caregiver.firstName) {
            this.logger.debug(`ngOnInit() loading caregiver name for chat room...`);
            let cgLoaded = this.eventService.caregiversLoaded.subscribe(() => {
                const cg = this.user.getCaregiver(this.caregiver.chatroom);
                this.logger.debug(`ngOnInit() loaded caregiver for chat room`, cg);
                this.caregiver.firstName = cg.firstName;
                this.caregiver.lastName = cg.lastName;
                cgLoaded.unsubscribe();
            });
        }
        if (!this.caregiver || (this.caregiver && this.caregiver.chatroom)) {
            this.logger.debug(`ngOnInit() loading text messages for chat room...`);
            this.getTextMessages();
        }

        this.scrolling = true;
        this.callScrollFunction();
        this.taskTrackingService.startTracking('chat', 'Opened chat modal');
        this.logger.debug(`ngOnInit() done!`);
    }

    ngOnDestroy() {
        this.logger.debug(`ngOnDestroy()`);
        this.taskTrackingService.stopTracking();
        this.modalService.setModalStatus('ChatPage', false);
        this.stopPolling();
        if (this.getMessagesEvent) this.getMessagesEvent.unsubscribe();
        if (this.openNewChatEvent) this.openNewChatEvent.unsubscribe();
    }

    /**
     * Gets all of the messages
     */
    private getTextMessages(incoming?: boolean): void {
        const hasCaregiver = !!this.caregiver;
        this.logger.debug(`getTextMessages()`, {incoming, hasCaregiver});
        // show loader if not already loading and if there are no existing messages
        if (
            !this.isLoading && (hasCaregiver ? !this.caregiver.messages : !this.messages) ||
            !incoming
        ) {
            this.logger.debug(`setting isLoading = true`);
            this.isLoading = true;
        }

        const req = hasCaregiver ?
            this.communication.getTextMessages(this.user.id, this.caregiver.id, this.caregiver.chatroom) :
            this.communication.getTextMessages();
        req.pipe(take(1)).subscribe(
            {
                next: (res: ResponseChat[]) => {
                    this.logger.debug(`getTextMessages() recovered ${res?.length ?? -1} messages`);
                    if (this.caregiver) {
                        this.caregiver.messages = res;
                    } else {
                        this.messages = res;
                    }
                    this.loadingMessages$.next(res);
                    if (!incoming) {
                        this.logger.debug(`getTextMessages() -> pollGetChats()`);
                        this.pollGetChats();
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.logger.error(`getTextMessages() ERROR`, err);
                    if (this.caregiver && !this.caregiver.messages) {
                        this.logger.debug(`resetting caregiver messages`);
                        this.caregiver.messages = [];
                    } else if (!this.caregiver && !this.messages) {
                        this.logger.debug(`resetting local messages`);
                        this.messages = [];
                    }
                    this.handleGetTextMessagesError(incoming, err);
                },
                complete: () => {
                    this.logger.debug(`getTextMessages() complete!`);
                    this.isLoading = false;
                    this.ref.detectChanges();
                    this.callScrollFunction();
                }
            }
        );
    }

    private async handleGetTextMessagesError(incoming: boolean, err: HttpErrorResponse): Promise<OverlayRef> {
        this.logger.phic.error('Error getting chat messages', err);

        const alert = await this.overlay.openAlert({
            header: this.translateService.instant('ERROR_TITLE'),
            message: [this.translateService.instant('GET_MESSAGE_ERROR')],
            variant: 'error',
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    variant: 'secondary',
                    role: 'cancel'
                }, {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        alert.dismiss();
                        this.getTextMessages(incoming);
                    }
                }
            ],
            qa: 'get_chat_alert'
        });

        return alert;
    }

    /**
     * Poll get chat endpoint
     * If multiple clinicians are chatting with the same patient we want to be sure that everyone receives all new messages
     * this is a temporary fix until we can update firebase to notify all clinicians of any new message to a chat thread
     * We will hit the get chat endpoint every thirty seconds anytime the a chat is in view
     */
    private pollGetChats(): void {
        this.logger.debug(`pollGetChats()`);
        let poll;
        if (this.caregiver) {
            poll = timer(30000, 30000).pipe(
                switchMap(() => this.communication.getTextMessages(this.user.id, this.caregiver.id, this.caregiver.chatroom))
            );
        } else {
            poll = timer(30000, 30000).pipe(
                switchMap(() => this.communication.getTextMessages())
            );
        }

        this.poll = poll
            .subscribe((res: any) => {
                this.logger.debug(`pollGetChats() next`, res);
                let messages = res;
                if (this.caregiver ? this.caregiver.messages.length < messages.length : this.messages.length < messages.length) {
                    // the new response has more messages than the previous response
                    // add to dom and scroll to bottom
                    this.logger.debug(`pollGetChats() next has more messages than the previous response, initiating scroll`);
                    this.caregiver ?
                        (this.caregiver.messages = messages) :
                        (this.messages = messages);
                    this.loadingMessages$.next(res);
                    this.callScrollFunction();
                }
            });
    }

    private stopPolling(): void {
        this.logger.debug(`stopPolling()`);
        if (this.poll) {
            this.logger.debug(`halting active poll subscription`);
            this.poll.unsubscribe();
        }
    }

    /**
     * Convert time and date into a consistent format
     */
    public convertTimeAndDate(value: string): string {
        try {
            return this.caregiver ?
                moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YYYY h:mm:ss A') :
                moment.unix(parseInt(value) / 1000).format('MM/DD/YYYY h:mm:ss A');
        } catch (e) {
            this.logger.phic.error('Error getting message time', e);
            return value;
        }
    }

    /**
     * Scrolls to bottom of chat message list
     */
    private callScrollFunction(): void {
        this.logger.debug(`callScrollFunction()`);
        let content = document.querySelector('hrs-modal hrs-content');

        if (content) {
            setTimeout(() => {
                content.scroll({top: content.scrollHeight});
            }, 100);
        }
        this.scrolling = false;
    }

    /**
     * Posts the new message
     */
    public sendTextMessage(): void {
        this.logger.debug(`sendTextMessage()`);
        if (this.caregiver) { // send to caregiver
            this.logger.debug(`sending message to caregiver...`);
            let message: InjectedChat = {message: this.data.message, hrsid: this.user.id, messageStatus: this.translateService.instant('SENDING'), id: new Date()};
            if (this.caregiver.messages) {
                this.caregiver.messages.push(message);
            } else {
                this.caregiver.messages = [message];
            }
            this.data.message = '';
            this.communication.sendTextMessage(message.message, this.user.id, this.caregiver.id, this.caregiver.chatroom).subscribe(
                {
                    next: (res: {data: ResponseChat}) => {
                        this.logger.debug(`sendTextMessage() message to caregiver sent!`);
                        message.messageStatus = this.translateService.instant('SENT');
                        if (!this.caregiver.chatroom) {
                            this.caregiver.chatroom = res.data.chatroomId;
                        }
                        this.loadingMessages$.next(this.caregiver.messages);
                        this.callScrollFunction();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.logger.phic.error('Error sending chat message ', err);
                        message.messageStatus = this.translateService.instant('FAILED_TO_SEND');
                        this.callScrollFunction();
                        this.handleSendTextMessageError(message);
                    }
                }
            );
        } else { // send to clinician
            this.logger.debug(`sending message to clinician...`);
            let message: InjectedChat = {text: this.data.message, type: 'patient', messageStatus: this.translateService.instant('SENDING'), id: new Date()};
            this.messages.push(message);
            this.data.message = '';
            this.communication.sendTextMessage(message.text).subscribe(
                {
                    next: (res: {data: ResponseChat}) => {
                        this.logger.debug(`sendTextMessage() message to clinician sent!`);
                        message.messageStatus = this.translateService.instant('SENT');
                        this.loadingMessages$.next(this.messages);
                        this.callScrollFunction();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.logger.phic.error('Error sending chat message', err);
                        message.messageStatus = this.translateService.instant('FAILED_TO_SEND');
                        this.callScrollFunction();
                        this.handleSendTextMessageError(message);
                    }
                }
            );
        }
    }

    private async handleSendTextMessageError(message: InjectedChat): Promise<OverlayRef> {
        this.logger.debug(`handleSendTextMessageError()`, message);
        return await this.overlay.openAlert({
            header: this.translateService.instant('ERROR_TITLE'),
            message: [this.translateService.instant('SEND_MESSAGE_ERROR')],
            variant: 'error',
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    role: 'cancel',
                }, {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.messages = this.messages.filter((msg) => {
                            return (JSON.stringify(msg) !== JSON.stringify(message));
                        });
                        this.sendTextMessage();
                    }
                }
            ],
            qa: 'send_chat_alert'
        });
    }

    public dismiss(): void {
        this.logger.debug(`dismiss()`);
        this.overlayRef.dismiss();
    }
}
