import {Injectable} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {Platform} from '@ionic/angular';
import {Subject, Observable} from 'rxjs';

declare var Twilio: any;

export enum TwilioEventType {
    CALL_DID_CONNECT = 'callDidConnect',
    CALL_DID_DISCONNECT = 'callDidDisconnect',
    CLIENT_INITIALIZED = 'clientInitialized',
    CALL_INVITE_RECEIVED = 'callInviteReceived',
    CALL_INVITE_CANCELED = 'callInviteCanceled',
    CLIENT_REGISTERED = 'clientRegistered',
    CLIENT_REGISTER_ERROR = 'clientRegisterError',
    CALL_CONNECT_FAILURE = 'callConnectFailure',
    CALL_RINGING = 'callRinging',
    CALL_DID_RECONNECT = 'callDidReconnect',
    CALL_RECONNECTING = 'callReconnecting'
}

export interface TwilioCall {
    from?: string;
    to?: string;
    state?: string;
    callSid?: string;
    isMuted?: boolean;
}

export interface TwilioEventData extends TwilioCall {
    call?: TwilioCall;
    accessToken?: string;
    fcmToken?: string;
    errorCode?: number;
    errorMessage?: string;
    [key: string]: any;
}

export interface TwilioEvent {
    type: TwilioEventType;
    data: TwilioEventData;
}

@Injectable({
    providedIn: 'root'
})
export class TwilioService {
    private readonly logger = getLogger('TwilioService');

    private readonly nativeEventSubject = new Subject<TwilioEvent>();
    private readonly nativeEventDelegateProxy: (ev: TwilioEvent) => void;
    private readonly nativeEventDelegateErrorProxy: (error: any) => void;
    private delegateInitializeRetryCount: number = 0;

    public readonly nativeEvents$: Observable<TwilioEvent>;

    constructor(
      private readonly platform: Platform
    ) {
        this.nativeEvents$ = this.nativeEventSubject.asObservable();
        this.nativeEventDelegateProxy = this.onNativeEvent.bind(this);
        this.nativeEventDelegateErrorProxy = this.onNativeEventDelegateError.bind(this);
        this.initNotificationListeners();
    }

    private onNativeEvent(ev: TwilioEvent): void {
        this.logger.debug(`onNativeEvent`, ev);
        this.nativeEventSubject.next(ev);
    }

    private onNativeEventDelegateError(error: any): void {
        this.logger.error(`onNativeEventDelegateError -> ${error}`);
        const maxAttempts = 3;

        // prevent infinite loops from potential exceptions
        if (this.delegateInitializeRetryCount > maxAttempts) {
            this.logger.warn(`max retry attempts exceeded! (${maxAttempts})`);
            return;
        }

        this.delegateInitializeRetryCount++;
        this.logger.error(`retry = ${this.delegateInitializeRetryCount} out of ${maxAttempts})`);
        this.initializeNativeEventDelegateAsync();
    }

    private initializeNativeEventDelegateAsync(): void {
        this.logger.trace(`initializeNativeEventDelegateAsync()`);
        this.initializeNativeEventDelegate().catch((err) => {
            this.logger.error(`initializeNativeEventDelegate() failed! -> ${err}`);
        });
    }

    private async initializeNativeEventDelegate(): Promise<void> {
        this.logger.trace(`initializeNativeEventDelegate()`);
        await this.platform.ready();

        if (!this.platform.is('android')) {
            return;
        }

        this.logger.trace(`initializeNativeEventDelegate() registering shared event listener...`);
        Twilio.TwilioVoiceClient.setSharedEventListener(
            this.nativeEventDelegateProxy,
            this.nativeEventDelegateErrorProxy
        );
    }

    private initNotificationListeners(): void {
        this.initializeNativeEventDelegateAsync();
    }
}
