import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AdminGatewayApiService} from '../../services/user/admin-gateway-api.service';
import {EnvironmentResource} from './environment-resource.interface';
import {TabletInfo} from './tablet-info.interface';
import {TabletDeviceIdService} from '../../services/tablet-device-id/tablet-device-id';
import {OverlayService, User} from '@patient/providers';
import {OverlayRef} from '../../hrs-overlay';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'registration',
    templateUrl: 'registration.component.html',
    styleUrls: ['registration.component.scss']
})

// Requires Admin Gateway Token to work correctly, must be used behind the admin page tab guard
export class RegistrationComponent {
    private readonly logger = getLogger('RegistrationComponent');

    public currentStatus: 'Active' | 'Inactive';
    public currentIMEI: string;
    public currentName: string;
    public currentEnv: string;
    public isActive: boolean;
    public environments: EnvironmentResource[] = [];
    public selectedEnv: string;

    constructor(
        private adminGateway: AdminGatewayApiService,
        private overlay: OverlayService,
        private tabletDeviceIDService: TabletDeviceIdService,
        private translate: TranslateService,
        private user: User,
    ) {
        this.init();
    }

    private async init(): Promise<void> {
        try {
            this.currentIMEI = await this.tabletDeviceIDService.getImei();
            this.getTabletData();
            this.getEnvironments();
        } catch (err) {
            this.handleError(this.translate.instant('ADMIN_REGISTRATION.GET_ERROR', {resource: 'IMEI'}));
        }
    }

    private getTabletData(): void {
        this.adminGateway.get(`v1/tablets/${this.currentIMEI}`).subscribe(
            {
                next: (res: TabletInfo) => {
                    this.currentName = res.data.attributes.name;
                    this.currentStatus = this.currentName ? 'Active' : 'Inactive';
                    this.currentEnv = res.data.relationships.environment.data.id;
                    this.selectedEnv = this.currentEnv;
                },
                error: (err) => {
                    this.logger.phic.error(`getTabletData failed`, err);
                    const errResource = this.translate.instant('ADMIN_REGISTRATION.NAME_LABEL');
                    const message = this.translate.instant('ADMIN_REGISTRATION.GET_ERROR', {resource: errResource});
                    this.handleError(message);
                }
            }
        );
    }

    // This request cannot be made with a patient token
    // When a patient is assigned to a device, there is no way for us to get a device token because gateway will return a patient token
    // Therefore this request requires an Admin token which is acquired when a user logins to the admin page using a admin username and password
    private getEnvironments(): void {
        this.adminGateway.get(`v1/environments`).subscribe(
            {
                next: (res: {data: EnvironmentResource[]}) => {
                    this.environments = res.data;
                },
                error: (err) => {
                    this.logger.phic.error(`getEnvironments failed`, err);
                    const errResource = this.translate.instant('ADMIN_REGISTRATION.ENVIRONMENTS_ERR');
                    const message = this.translate.instant('ADMIN_REGISTRATION.GET_ERROR', {resource: errResource});
                    this.handleError(message);
                }
            }
        );
    }

    public register(): void {
        if (this.selectedEnv !== this.currentEnv) {
            const body = {
                data: {
                    env: this.selectedEnv
                }
            };
            this.adminGateway.patch(`v1/tablets/${this.currentIMEI}`, body).subscribe(
                {
                    next: () => {
                        this.currentEnv = this.selectedEnv;
                        this.createToast(this.translate.instant('ADMIN_REGISTRATION.REGISTER_SUCCESS'));
                        this.user.logout();
                    },
                    error: (err) => {
                        this.logger.phic.error(`register failed`, err);
                        this.handleError(this.translate.instant('ADMIN_REGISTRATION.REGISTER_ERROR'));
                    }
                }
            );
        } else {
            this.createToast(this.translate.instant('ADMIN_REGISTRATION.ALREADY_REGISTERED'));
        }
    }

    private async handleError(err: string): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('ERROR_TITLE'),
            message: [err],
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                }, {
                    text: this.translate.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.init();
                    }
                }
            ],
            qa: 'registration_error_alert'
        });
    }

    private async createToast(header: string): Promise<OverlayRef> {
        return await this.overlay.openToast({
            header: header,
            duration: 5000, // 5 seconds is the standard toast duration'
            qa: 'registration_toast'
        });
    }
}
