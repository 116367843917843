import {Injectable} from '@angular/core';
import {KnoxIntentService} from './knox-intent.service';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {Platform} from '@ionic/angular';
import {BuildUtility} from '../../../../../../libs/utility/BuildUtility';

@Injectable({
    providedIn: 'root',
})
export class HideStatusBarIntentService extends KnoxIntentService {
    private static ACTION_HIDE_STATUS_BAR = 'com.hrs.intent.action.HIDE_STATUS_BAR';
    private static ACTION_HIDE_STATUS_BAR_RESULT = 'com.hrs.intent.action.HIDE_STATUS_BAR_RESULT';

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected registerListeners(): void {
        if (!BuildUtility.isHRSTab()) return;
        super.registerListener(HideStatusBarIntentService.ACTION_HIDE_STATUS_BAR_RESULT, true).subscribe((event) => {
            super.handleResult(event, HideStatusBarIntentService.ACTION_HIDE_STATUS_BAR_RESULT);
        });
    }

    public async hideStatusBar(): Promise<boolean> {
        return super.sendIntent(HideStatusBarIntentService.ACTION_HIDE_STATUS_BAR);
    }
}
