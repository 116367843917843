import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {BLE} from '@ionic-native/ble/ngx';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {DeviceInstructionsUsagePage} from './device-instructions-usage.page';
import {ComponentLibraryModule} from '@hrsui/angular';

const routes: Routes = [
    {
        path: '',
        component: DeviceInstructionsUsagePage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
    ],
    providers: [
        BLE
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [DeviceInstructionsUsagePage]
})
export class DeviceInstructionsUsagePageModule {}
