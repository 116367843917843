export enum LanguageValuesFromISO {
    en = 'english',
    es = 'spanish',
    zh = 'chinese',
    ja = 'japanese',
    de = 'german',
    fr = 'french',
    pt = 'portuguese',
    ru = 'russian',
    ar = 'arabic',
    ko = 'korean',
    it = 'italian',
    pl = 'polish',
    he = 'hebrew',
    vi = 'vietnamese',
    hi = 'hindi',
    fil = 'filipino'
}

export enum ISOValuesFromLanguage {
    english = 'en',
    spanish = 'es',
    chinese = 'zh',
    japanese = 'ja',
    german = 'de',
    french = 'fr',
    portuguese = 'pt',
    russian = 'ru',
    arabic = 'ar',
    korean = 'ko',
    italian = 'it',
    polish = 'pl',
    hebrew = 'he',
    vietnamese = 'vi',
    hindi = 'hi',
    filipino = 'fil'
}

export enum ISOValuesWithCountry {
    en = 'en-US',
    es = 'es-MX',
    zh = 'zh-CN',
    ja = 'ja-JP',
    de = 'de-DE',
    fr = 'fr-GP',
    pt = 'pt-PT',
    ru = 'ru-RU',
    ar = 'ar-AE',
    ko = 'ko-KR',
    it = 'it-IT',
    pl = 'pl-PL',
    he = 'he-IL',
    vi = 'vi-VN',
    hi = 'hi-IN',
    fil = 'fil-PH'
}
