<div class="c_admin_software_update">
    <ion-header class="current-version">
        Current {{pcmtAPK.folder}} Version: {{pcmtCurrentVersion}}
    </ion-header>
    <ion-grid>
        <ion-row>
            <ion-col size="9">
                <ion-list>
                    <ion-item>
                        Latest Available Version: {{pcmtLatestAPKData?.version}}
                    </ion-item>
                </ion-list>
            </ion-col>
            <ion-col size="3">
                <ion-buttons>
                    <ion-button class="c_button c_button-center" (click)="confirmUpdateToLatest(pcmtAPK)" [disabled]="disable">Update to Latest</ion-button>
                </ion-buttons>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-header class="current-version">
        Current {{pcksAPK.folder}} Version: {{pcksAPK?.version}}
    </ion-header>
    <ion-grid>
        <ion-row>
            <ion-col size="9">
                <ion-list>
                    <ion-item>
                        Latest Available Version: {{pcksLatestAPKData?.version}}
                    </ion-item>
                </ion-list>
            </ion-col>
            <ion-col size="3">
                <ion-buttons>
                    <ion-button class="c_button c_button-center" (click)="confirmUpdateToLatest(pcksAPK)" [disabled]="disable">Update to Latest</ion-button>
                </ion-buttons>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>


