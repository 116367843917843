import {Component, EventEmitter, Optional, Output} from '@angular/core';
import {
    AdminService,
    OverlayService
} from '@patient/providers';
import {TranslateService} from '@ngx-translate/core';
import {AdminUserService} from '../../services/user/admin-user.service';
import {BuildUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'hrs-admin-access',
    templateUrl: 'admin-access.component.html',
    styleUrls: ['admin-access.component.scss']
})
export class AdminAccessComponent {
    private readonly logger = getLogger('AdminAccessComponent');
    @Output() hasCorrectPassword = new EventEmitter<boolean>();

    public isHRSTab: boolean = BuildUtility.isHRSTab();
    hasError: boolean = false;
    enteredPasscode: string;
    errorMessage: string;
    enteredPassword: string;
    passwordError: boolean;
    enteredUsername: string;
    usernameError: boolean;
    usernameErrorMessage: string;
    passwordErrorMessage: string;
    loginType = 'admin';
    LoginTypes = {
        admin: 'admin',
        superAdmin: 'super-admin'
    };

    private reroute: ReturnType<typeof setTimeout>;
    private readonly timeout: () => ReturnType<typeof setTimeout>;
    private toast: any;

    constructor(
        private adminService: AdminService,
        private overlay: OverlayService,
        private translate: TranslateService,
        @Optional() private adminUser: AdminUserService,
    ) {
        this.timeout = () => setTimeout(() => {
            this.adminService.rerouteHome();
        }, 45000);
        this.reroute = this.timeout();
        this.errorMessage = this.translate.instant('ADMIN_PASSCODE_ERROR');
        this.passwordErrorMessage = this.translate.instant('ADMIN_PASSWORD_ERROR');
        this.usernameErrorMessage = this.translate.instant('ADMIN_USERNAME_ERROR');
    }

    ngOnDestroy() {
        clearTimeout(this.reroute);
    }

    selectLogin(loginType: string): void {
        if (this.loginType !== loginType) {
            this.enteredUsername = null;
            this.enteredPassword = null;
            this.enteredPasscode = null;
            this.loginType = loginType;
        }
    }

    /**
     * On changes to the input, the reroute gets reset and isCorrect gets emitted to parent.
     */
    passcodeChangeHandler(): void {
        const isCorrect = AdminService.PASSCODE === this.enteredPasscode;
        clearTimeout(this.reroute);
        if (isCorrect) {
            this.hasCorrectPassword.emit(isCorrect);
        } else {
            this.checkErrorState(isCorrect);
            this.reroute = this.timeout();
        }
    }

    checkErrorState(isCorrect: boolean): void {
        const haveSameLength = AdminService.PASSCODE.length === this.enteredPasscode.length;
        this.hasError = !isCorrect && haveSameLength;
    }

    async superAdminLogin(): Promise<void> {
        if (!this.isHRSTab) return;

        if (!this.enteredUsername) {
            this.usernameError = true;
        } else {
            this.enteredUsername = this.enteredUsername.trim();
        }

        if (!this.enteredPassword) {
            this.passwordError = true;
        } else {
            this.enteredPassword = this.enteredPassword.trim();
        }

        if (this.passwordError || this.usernameError) {
            return;
        } else {
            await this.doLogin(this.enteredUsername, this.enteredPassword);
        }
    }

    private async doLogin(enteredUserName: string, enteredPassword: string): Promise<void> {
        this.adminUser.login(enteredUserName, enteredPassword).subscribe(
            {
                next: (res) => {
                    if (res === true) {
                        this.hasCorrectPassword.emit(true);
                    } else {
                        this.logger.phic.debug('Failed to login as  `super` admin: bad result');
                        this.logoutErrorToast();
                    }
                },
                error: (err) => {
                    this.logger.phic.error('Failed to login as `super` admin', err);
                    this.logoutErrorToast();
                }
            }
        );
    }

    private async logoutErrorToast(): Promise<any> {
        this.toast = await this.overlay.openToast({
            message: this.translate.instant('SIGN_IN_ERROR_ADMIN'),
            duration: 5000, // 5 seconds is the standard toast duration
            variant: 'error',
            qa: 'admin_logout_error_toast'
        });
        return await this.toast;
    }

    removeErrors(): void {
        this.usernameError = false;
        this.passwordError = false;
    }
}
