import {VideoPage} from '../../../communication/video/video.page';
import {VoicePage} from '../../../communication/voice/voice.page';
import {ChatPage} from '../../../communication/chat/chat.page';
import {CaregiverPinPage} from '../../../caregiver-pin/caregiver-pin.page';
import {ActivityMonitorsPage} from '../../../devices/activity-monitors/activity-monitors.page';
import {EducationRailTabs} from '../../../education/education-rail-tabs.enum';

export const MenuConfig = [
    {isHidden: false, titleKey: 'MENU_HOME_TITLE', icon: 'home-solid', isRoot: true},
    {
        isHidden: false, titleKey: 'MENU_COMMS',
        icon: 'clinician-contact',
        submenu: [
            {isHidden: false, titleKey: 'MENU_COMMS_VIDEO', icon: 'video-call', component: VideoPage},
            {isHidden: false, titleKey: 'MENU_COMMS_VOICE', icon: 'voice-call', component: VoicePage},
            {isHidden: false, titleKey: 'MENU_COMMS_CHAT', icon: 'text-message', component: ChatPage}
        ]
    },
    {
        isHidden: false, titleKey: 'MENU_CAREGIVER',
        icon: 'caregiver-pcmt',
        submenu: [
            {isHidden: false, titleKey: 'MENU_CAREGIVER_PIN', icon: 'generate-access-code', component: CaregiverPinPage},
        ]
    },
    {
        isHidden: false, titleKey: 'MENU_DEVICES',
        icon: 'devices',
        submenu: [
            {isHidden: false, titleKey: 'MENU_DEVICES_ACTIVITY_MONITORS', icon: 'activity', component: ActivityMonitorsPage},
            {isHidden: false, titleKey: 'MENU_DEVICES_BPMONITORS', icon: 'bp-monitor'},
            {isHidden: false, titleKey: 'MENU_DEVICES_GLUCOMETERS', icon: 'glucose'},
            {isHidden: false, titleKey: 'MENU_DEVICES_OXIMETERS', icon: 'pulse-ox'},
            {isHidden: false, titleKey: 'MENU_DEVICES_SCALES', icon: 'weight'},
            {isHidden: false, titleKey: 'MENU_DEVICES_THERMOMETERS', icon: 'temperature'},
            // {isHidden: false, titleKey: 'MENU_DEVICES_STETHOSCOPES', icon: 'stethoscope'}
        ]
    },
    {
        isHidden: false, titleKey: 'MENU_EDUCATION',
        icon: 'education-solid',
        submenu: [
            {isHidden: false, titleKey: EducationRailTabs.VIDEOS, icon: 'play', tab: 'Videos'},
            {isHidden: false, titleKey: EducationRailTabs.QUIZZES, icon: 'quiz-pcmt', tab: 'Quizzes'},
            {isHidden: false, titleKey: EducationRailTabs.FILES, icon: 'file', tab: 'Files'},
        ]
    },
    {
        isHidden: false, titleKey: 'MENU_SETTINGS',
        icon: 'settings-solid',
        isHRSTabOnly: true
    },
    {
        isHidden: false, titleKey: 'MENU_HELP',
        icon: 'help-solid',
        submenu: [
            {isHidden: false, titleKey: 'MENU_HELP_CALL_SUPPORT', icon: 'voice-call'},
            {isHidden: false, titleKey: 'MENU_HELP_SUBMIT_APP_LOGS', icon: 'send-file'},
        ]
    },
    {isHidden: false, titleKey: 'MENU_SIGN_OUT', icon: 'sign-out-pcmt'}
];
