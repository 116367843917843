import moment from 'moment';

import {Schedule} from '../schedule/schedule';
import {TaskType} from './task-type.enum';
import {MULTIPLE_REMINDER_TYPES} from './multiple-reminders.interface';

export class Task {
    id: string;
    type: TaskType;
    lastCompleted: Date;
    lastCompletedButOffline: Date; // this is the most recent entry that was recorded but offline. If there were multiple entries, this would be the date of the most recent of those entries.

    schedule: Schedule[];
    title: string;
    subTasks: Task[];
    isTaskReset: boolean = false;

    hasMultipleReminders: boolean = false;
    fromCache: boolean = false;

    constructor(data?: Partial<Task>) {
        this.set(data);
    }

    public set(data: Partial<Task>) {
        Object.assign(this, data);
    }

    public toJSON() {
        // Data that gets serialized when using JSON.stringify
        // No need for properties like title, which is always generated at runtime
        // NOTE: if you're trying to JSON.stringify() a Task object, not ALL properties of the class object will be stringified.
        // only the fields returned below will be stringified
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#toJSON_behavior
        return {
            id: this.id,
            type: this.type,
            lastCompleted: this.lastCompleted,
            lastCompletedButOffline: this.lastCompletedButOffline,
            isTaskReset: this.isTaskReset
        };
    }

    public isValid(): boolean {
        // Overridden in child classes for metric-specific logic
        return true;
    }

    /**
     * @returns true if task is scheduled for today
     * Assumption: we can have multiple times scheduled for a single Task but all times are for the same day
     */
    public isScheduled(): boolean {
        // For grouped tasks (ie. medication, survey), consider it scheduled if any one of the sub tasks is scheduled for today
        if (this.subTasks && this.subTasks.length) {
            for (let i = 0; i < this.subTasks.length; i++) {
                if (this.subTasks[i].isScheduled()) {
                    return true;
                }
            }
            return false;
        }

        if (!this.schedule || !this.schedule[0] || !this.schedule[0].at) {
            // Task is as-needed or not scheduled
            return false;
        }

        // We have a schedule, so see if it matches today or not.
        return this.schedule[0].matchesDate();
    }

    /**
     * @returns true if task is within the reminder window for a scheduled time
     * Assumption: we can have multiple times scheduled for a single Task but all times are for the same day
     */
    public isDue(): boolean {
        // For grouped tasks (ie. medication, survey), consider it due if any one of the sub tasks is due (triggered but not missed)
        if (this.subTasks && this.subTasks.length) {
            for (let i = 0; i < this.subTasks.length; i++) {
                if (this.subTasks[i].isDue()) {
                    return true;
                }
            }
            return false;
        }

        if (this.hasMultipleReminders && MULTIPLE_REMINDER_TYPES.includes(this.type)) { // enforce adherence window
            if (this.isScheduled()) {
                const activeSchedule: Schedule = this.getActiveSchedule();
                if (this.isCompleted()) {
                    return activeSchedule && !activeSchedule.inAdherenceWindow(moment(this.lastCompleted, 'HH:mm'));
                } else {
                    return !!activeSchedule;
                }
            }
        } else { // this task has a single scheduled time
            if (this.isScheduled() && !this.isCompleted()) {
                return this.schedule[0].matchesTime() && !this.schedule[0].missedWindow();
            }
        }
        return false;
    }

    /**
     * @returns true if task is beyond the reminder window for a scheduled time and has not been completed
     * Assumption: we can have multiple times scheduled for a single Task but all times are for the same day
     */
    public isMissed(): boolean {
        // For grouped tasks (i.e. medication, survey), consider it missed if any one of the sub tasks is missed
        if (this.subTasks && this.subTasks.length) {
            for (let i = 0; i < this.subTasks.length; i++) {
                if (this.subTasks[i].isMissed()) {
                    return true;
                }
            }
            return false;
        }

        if (this.hasMultipleReminders && MULTIPLE_REMINDER_TYPES.includes(this.type)) { // enforce adherence window
            if (this.isScheduled()) {
                const activeSchedule: Schedule = this.getActiveSchedule();
                if (!activeSchedule) { // if we're in an activeSchedule we are not missed, yet
                    const prevSchedule: Schedule = this.getPreviousSchedule();
                    if (prevSchedule) {
                        // if we have a previous Schedule, then we're missed if we were not completed today or
                        // not completed before the previous Schedule's adherence window
                        return !this.lastCompleted || !moment(this.lastCompleted).isSame(moment(), 'day') || prevSchedule.beforeAdherenceWindow(moment(this.lastCompleted, 'HH:mm'));
                    }
                }
            }
        } else { // this task has a single scheduled time
            if (this.isScheduled() && !this.isCompleted()) {
                return this.schedule[0].missedWindow();
            }
        }
        return false;
    }

    /**
     * @returns true if task has been completed
     * doesn't care whether the task is completedButOffline - that is determined separately only when a task is considered completed
     * Assumption 1: we can have multiple times scheduled for a single Task but all times are for the same day
     * Assumption 2: if we have subTasks, then each subTask can only have a single time scheduled
     */
    public isCompleted(untilNow? : boolean): boolean {
        // For grouped tasks (ie. medication, survey), we need to perform special considerations, specifically only consider it complete only if ALL the _scheduled_ sub tasks are complete
        // additionally, we may need to only consider subtasks prior to now; i.e. as long as all subtasks prior to now are complete, we consider the task complete
        if (this.subTasks && this.subTasks.length) {
            // get just the subtasks that are scheduled for today (if they are not scheduled for today, or if it is a PRN (as needed) medication, we exclude it)
            let scheduledSubTasks: Task[] = this.subTasks.filter((subtask) => subtask.isScheduled());

            // if we only care about subtasks up until now, filter out anything that is later
            if (untilNow) {
                scheduledSubTasks = scheduledSubTasks.filter((subtask) => subtask.schedule[0].matchesTime());
            }

            // Make sure there's at least one _scheduled_ subtask
            if (scheduledSubTasks.length > 0) {
                // determine if there's any subtasks in the new array that are NOT completed
                let atLeastOneUnCompletedSubTask: boolean = scheduledSubTasks.some((subtask) => !subtask.isCompleted());
                // set isTaskReset for the parent task based on whether the subtasks are reset (tasks are no longer reset once completed again)
                // ...Note: PRN medications are excluded from the below check as they can be taken as many times per day.
                this.isTaskReset = scheduledSubTasks.some((subtask) => subtask.isTaskReset);
                if (atLeastOneUnCompletedSubTask) {
                    // found one subtask is NOT completed, the whole task is incomplete
                    return false;
                } else {
                    // if ALL scheduled subtasks are completed, the whole task is complete
                    // unless task is reset, in which case the tasks is returned as incomplete.
                    // bool isTaskReset is defaulted to false except when firebase notification is triggered from CC.
                    return !this.isTaskReset;
                }
            } else {
                // if there are no _scheduled_ subtasks, the task is not considered completed, even if unscheduled subtasks have been marked completed.
                return false;
            }
        }

        return !!this.lastCompleted && moment(this.lastCompleted).isSame(moment(), 'day') && !this.isTaskReset;
    }

    // isCompletedButOffline - determines if a task is completed, but stored on the device waiting to be uploaded
    // note: this function __ONLY__ fires if isCompleted() evaluates to 'true', thus the task is considered "completed",
    // this is just determining whether the task has been uploaded to the server or not - hence "isCompletedButOffline"
    public isCompletedButOffline(): boolean {
        // For grouped tasks (meds and survey), we have to  look at the subtask level
        if (this.subTasks && this.subTasks.length) {
            // get just the subtasks that are scheduled for today (if they are not scheduled for today, or if it is a PRN (as needed) medication, we exclude it)
            let scheduledSubTasks: Task[] = this.subTasks.filter((subtask) => subtask.isScheduled());
            // at this point, we know the scheduled tasks are completed, now we just need to check if ANY of them are completedButOffline.
            return scheduledSubTasks.some((subtask: Task) => subtask.isCompletedButOffline()); // returns true if ANY subtask isCompletedButOffline, false otherwise
        }
        // check to see if the task has a lastCompletedButOffline date that is today
        return !!this.lastCompletedButOffline && moment(this.lastCompletedButOffline).isSame(moment(), 'day');
    }

    public getLastCompletedTime(): string {
        if (this.lastCompleted) {
            return moment(this.lastCompleted).format('h:mm A');
        }
    }

    public getLastCompletedDate(): string {
        if (this.lastCompleted) {
            return moment(this.lastCompleted).format('M/D/YY');
        }
    }

    public isLastCompletedToday(): boolean {
        return this.lastCompleted && moment(this.lastCompleted).isSame(moment(), 'day');
    }

    public isLastCompletedTodayButOffline(): boolean {
        return this.lastCompletedButOffline && moment(this.lastCompletedButOffline).isSame(moment(), 'day');
    }

    /**
     * returns either the Schedule whose reminder window is active now or undefined
     */
    public getActiveSchedule(): Schedule {
        let activeSchedule: Schedule;
        this.schedule.forEach((schedule) => {
            if (schedule.matchesTime() && !schedule.missedWindow()) {
                activeSchedule = schedule;
            }
        });
        return activeSchedule;
    }

    /**
     * returns either the last Schedule whose reminder window ended before now or undefined
     */
    public getPreviousSchedule(): Schedule {
        let previousSchedule: Schedule;
        this.schedule.forEach((schedule) => {
            if (schedule.matchesTime() && schedule.missedWindow()) {
                previousSchedule = schedule;
            }
        });
        return previousSchedule;
    }

    /**
     * returns either the first Schedule whose reminder window begins after now or undefined
     */
    public getNextSchedule(): Schedule {
        let nextSchedule: Schedule;
        this.schedule.forEach((schedule) => {
            if (!nextSchedule && !schedule.matchesTime()) {
                nextSchedule = schedule;
            }
        });
        return nextSchedule;
    }
}
