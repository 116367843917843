import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Camera} from '@ionic-native/camera/ngx';
import {TranslateService} from '@ngx-translate/core';
import {finalize, first, filter, tap} from 'rxjs/operators';
import {CareplanChangeService, OverlayService, TaskService, User} from '@patient/providers';
import {Task} from '../services/tasks';
import {TaskMetaData} from '../services/tasks/task-metadata.interface';
import {EncryptionService} from '../services/encryption/encryption.service';

import * as moment from 'moment';
import {BuildUtility} from '@hrs/utility';
import {TextToSpeechService} from '@patient/providers';
import {TaskTrackingService} from '../services/task-tracking/task-tracking.service';
import {OverlayRef} from '../hrs-overlay';
import {CameraPreview, CameraPreviewOptions} from '@awesome-cordova-plugins/camera-preview/ngx';
import {CareplanChangeAction} from '../enums';
import {Subscription} from 'rxjs';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-wound-imaging',
    templateUrl: './wound-imaging.page.html',
    styleUrls: ['./wound-imaging.page.scss'],
})
export class WoundImagingPage {
  private readonly logger = getLogger('WoundImagingPage');
  task: Task;
  helpText: string;
  image: string;
  imageSrc: string;
  imageLoading: boolean;
  imageTaken: boolean;
  saving: boolean = false;
  showingCanLeaveAlert: boolean;
  canLeave: boolean;
  isHRSTablet: boolean = BuildUtility.isHRSTab();
  private isPreviewOpen: boolean = false;
  private taskRemovedSubscription: Subscription;

  constructor(
      private camera: Camera,
      private cameraPreview: CameraPreview,
      private encryptionService: EncryptionService,
      private overlay: OverlayService,
      private overlayRef: OverlayRef,
      private taskService: TaskService,
      private taskTrackingService: TaskTrackingService,
      private textToSpeechService: TextToSpeechService,
      private translate: TranslateService,
      private user: User,
      public domSanitizer: DomSanitizer,
      private careplanChangeService: CareplanChangeService
  ) {}

  ngOnInit() {
      this.helpText = this.translate.instant('WOUND_IMAGING_INSTRUCTIONS');
      this.taskRemovedSubscription = this.careplanChangeService.careplanState$.pipe(
          tap((careplanState) => this.logger.debug(`received careplan state update ->`, careplanState, this.task)),
          filter((careplanState) => this.task && careplanState[this.task.type] === CareplanChangeAction.REMOVED),
          first()
      ).subscribe(async () => {
          this.logger.info(`returning to home page after module remove!`);
          await this.returnToHomePage();
      });
  }

  ngAfterViewInit() {
      // Because modals have a 500ms animation, we need to wait to calculate the placing of the camera preview.
      setTimeout(() => {
          if (this.isHRSTablet) {
              this.startCameraPreview();
          }
      }, 510);
  }

  ngOnDestroy() {
      if (this.taskRemovedSubscription) {
          this.taskRemovedSubscription.unsubscribe();
          this.taskRemovedSubscription = null;
      }
  }

  private async startCameraPreview(): Promise<void> {
      const woundImagingModal = document.querySelector('app-wound-imaging');
      const modalContent = document.querySelector('app-wound-imaging > hrs-content');
      const currentModal = document.querySelector('hrs-modal');
      const modalHeader = currentModal.shadowRoot.querySelector('hrs-header');
      const statusBarHeight = window.innerHeight - modalHeader.clientHeight - woundImagingModal.clientHeight;
      const cameraPreviewOpts: CameraPreviewOptions = {
          x: 0,
          y: statusBarHeight + modalHeader.clientHeight,
          width: modalContent.clientWidth,
          height: modalContent.clientHeight,
          camera: 'rear',
          tapPhoto: true,
          previewDrag: false,
          toBack: false,
          alpha: 1,
          storeToFile: false
      };
      // Start the camera preview.
      this.cameraPreview.startCamera(cameraPreviewOpts).then(() => {
          this.isPreviewOpen = true;
          this.cameraPreview.show();
      });
  }

  public switchCameraFeed(): void {
      this.cameraPreview.switchCamera();
  }

  private canUserLeave(): boolean {
      // When the user tries to leave the page, show an alert if a wound image has been taken but not yet saved
      // We also are checking if the user is still logged in because if the Api Interceptor has just kicked us out we should allow it to close this page.
      // Also, if the user is offline (or there was another error uploading the image during the POST) and we are able to
      // save the image to the device's storage to upload later, the user can leave without a prompt
      if (!this.image || this.canLeave || !this.user.isLoggedIn()) {
          return true;
      }
      if (this.showingCanLeaveAlert) {
          return false;
      }

      // Prevent our page from closing until the user answers the prompt
      return false;
  }

  public async saveImageOnLeave(): Promise<OverlayRef> {
      return await this.overlay.openAlert({
          header: this.translate.instant('WOUND_IMAGING_NOT_SAVED_ALERT_TITLE'),
          message: [this.translate.instant('WOUND_IMAGING_NOT_SAVED_ALERT_TEXT')],
          buttons: [
              {
                  text: this.translate.instant('NO_BUTTON'),
                  role: 'cancel',
                  handler: () => {
                      // Close our page
                      this.canLeave = true;
                      this.dismiss();
                  }
              }, {
                  text: this.translate.instant('YES_BUTTON'),
                  handler: () => {
                      // Dismiss this alert and try to save
                      // Resetting this value so that the alert will show if they again try to navigate away before successfully saving
                      this.showingCanLeaveAlert = false;
                      this.save();
                  }
              }
          ],
          qa: 'wound_imaging_leave_alert'
      });
  }

  public takePicture(): void {
      // Note on takingPicture: on android opening the camera fires the 'platform.pause' event, which there is a function in
      // home.page.ts that closes any open modals when the platform is paused. Set this flag on the user that this patient is
      // currently taking a picture and even though the platform is paused, we don't want to close the wound-imaging modal.
      this.user.takingPicture = true;
      this.imageLoading = true;
      this.imageTaken = false;
      this.image = undefined;
      this.imageSrc = undefined;

      const onSuccess = (imageData) => {
          // the CameraPreview plugin we use for PCMT returns an array of the imageData string,
          // whereas the Camera plugin we use for PCM returns just the imageData string
          const finalImage = this.isHRSTablet ? imageData[0] : imageData;
          this.image = finalImage;
          this.imageSrc = `data:image/jpeg;base64,${finalImage}`;
      };

      const onError = (err) => {
          // err might be the string "No Image Selected" if they just canceled the camera, which isn't really an error, or
          // it might be an numeric error code such as 20 for camera permissions were denied
          this.logger.phic.info('Wound image not captured:', err);
          this.helpText = this.translate.instant('WOUND_IMAGING_NOT_SELECTED');
          this.imageLoading = false;
          this.user.takingPicture = false;
      };

      const onComplete = () => {
          this.imageTaken = true;
          // Stop the camera preview so we can display the captured image.
          this.cameraPreview.stopCamera();
          this.isPreviewOpen = false;
      };

      if (this.isHRSTablet) {
          // Take the picture.
          this.cameraPreview.takePicture({
              quality: 40,
              width: 1680,
              height: 1680
          }).then(onSuccess, onError).finally(onComplete);
      } else {
          this.camera.getPicture({
              quality: 40,
              targetWidth: 1680,
              targetHeight: 1680,
              destinationType: this.camera.DestinationType.DATA_URL,
              saveToPhotoAlbum: false,
              mediaType: this.camera.MediaType.PICTURE
          }).then(onSuccess, onError).finally(onComplete);
      }
  }

  public retakePicture(): void {
      this.user.takingPicture = false;
      this.imageLoading = false;
      this.imageTaken = false;
      this.image = undefined;
      this.imageSrc = undefined;

      if (this.isHRSTablet) this.startCameraPreview();
  }

  public imageLoaded(): void {
      this.imageLoading = false;
  }

  public async save(): Promise<void> {
      this.saving = true;
      const metadata: TaskMetaData = {
          recordedDate: moment().locale('en').format(), // get the date this metric was recorded in case the user is offline when they upload. also, see below note on "language locale",
      };

      // [language locale] for non-western languages moment converts the date string to a different character-set that
      // the server doesn't like so setting locale('en') to english to ensure we get a date string in western characters
      // (only an issue for non-western languages like arabic and hindi)
      this.taskService.submitTask(this.task, {image: this.image}, metadata).pipe(
          finalize(() => {
              this.saving = false;
          })
      ).subscribe(
          {
              next: () => {
                  this.handleSaveSuccess();
              },
              error: (err) => {
                  this.handleSaveError(err);
              }
          }
      );
  }

  private handleSaveSuccess(): void {
      this.canLeave = true;
      this.taskTrackingService.submitTracking('submit-imaging', 'success submitting ' + this.task.type);
      // Close our page
      this.dismiss();
      this.saving = false;
  }

  private async handleSaveError(err): Promise<void> {
      this.logger.phic.error('Error submitting wound image to server', err);
      const hasServerPublicKey: boolean = !!this.encryptionService.serverPublicKey;
      if (hasServerPublicKey) {
          // we've got a serverPublicKey, which means we encrypted and saved the metric on the patient's device.
          // provide an alert explaining that we'll be uploading their metric shortly.
          this.notSavedWillRetryAlert();
      } else {
          // no serverPublicKey, which means we couldn't encrypt their data, which means we couldn't store the data on their device's storage
          // thus, provide a generic error toast explaining their data was not saved.
          this.canLeave = false; // will trigger the "did not save, try again?" alert
          this.dismiss(); // Dismiss the WoundImagingPage.
          this.notSavedCantRetryToast();
      }

      this.taskTrackingService.submitTracking('submit-imaging', 'failed submitting ' + this.task.type);
  }

  private async notSavedWillRetryAlert(): Promise<OverlayRef> {
      let taskTitle = this.task && this.task.title ? this.task.title.toLowerCase() : '';
      return await this.overlay.openAlert({
          header: this.translate.instant('TASK_SUBMIT_FAILURE.HEADER'),
          message: [
              this.translate.instant('TASK_SUBMIT_FAILURE.BODY.1', {metric: taskTitle}),
              this.translate.instant('TASK_SUBMIT_FAILURE.BODY.2')
          ],
          buttons: [{
              text: this.translate.instant('OK_BUTTON'),
              role: 'cancel',
              handler: () => {
                  // Dismiss the WoundImagingPage. (the AlertCtrl will also be dismissed implicitly since we don't return false here)
                  this.canLeave = true; // will bypass the "did not save, try again?" alert
                  this.dismiss();
              }
          }],
          qa: 'wound_imaging_save_alert'
      });
  }

  private async notSavedCantRetryToast(): Promise<OverlayRef> {
      let taskTitle = this.task && this.task.title ? this.task.title.toLowerCase() : '';
      return await this.overlay.openToast({
          header: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.HEADER'),
          message: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.BODY', {metric: taskTitle}),
          variant: 'error',
          duration: 5000, // 5 seconds is the standard toast duration
          qa: 'wound_imaging_toast'
      });
  }

  public async handleModalClose(): Promise<void> {
      try {
          await this.dismiss();
      } catch (e) {
          this.logger.phic.error('Error dismissing wound imaging modal', e);
      }
  }

  public async dismiss(): Promise<void> {
      if (this.isPreviewOpen) {
          await this.cameraPreview.stopCamera();
      }
      if (this.canUserLeave()) {
          this.overlayRef.dismiss();
      } else {
          await this.saveImageOnLeave();
      }
      this.user.takingPicture = false; // dismiss gets called when the user saves successfully, unsuccessfully,
      // and when the user cancels. Indicating the user is no longer taking a picture at this point.
  }

  public readContent(): void {
      this.textToSpeechService.speak(this.task.title);
  }

  private async returnToHomePage(): Promise<void> {
      this.logger.debug(`returnToHomePage()`);
      // close the metric page/modal
      await this.handleModalClose();
  }
}
