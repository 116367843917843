import {Component} from '@angular/core';
import {
    AdminService,
    DeviceService,
} from '@patient/providers';
import {AdminSettings} from '../enums';
import {Setting} from './admin-settings.interface';
import {NavController} from '@ionic/angular';
import {AdminUserService} from '../services/user/admin-user.service';
import {BuildUtility} from '@hrs/utility';

@Component({
    selector: 'hrs-admin',
    templateUrl: 'admin.page.html',
    styleUrls: ['admin.page.scss']
})

export class AdminPage {
    adminSettings = AdminSettings;
    hasCorrectAccess: boolean = false;
    selectedSetting: Setting;
    settings: Setting[];
    isHrsTab: boolean;

    /**
     * To add a new tablet setting:
     * add settings object to this.settings which contains an enum which points to the AdminSettings enum,
     * add the component to admin.module declarations, do not add to app.module,
     * add the component instantiation to this template's ng-container#settingsPanel
     */
    constructor(
        private adminService: AdminService,
        private deviceService: DeviceService,
        private navCtrl: NavController,
        private adminUserService: AdminUserService,
    ) {
        this.isHrsTab = BuildUtility.isHRSTab();
        this.settings = this.adminService.settings;
        this.selectSetting(this.settings[0]);
    }

    ngOnInit() {
        this.deviceService.getBondedBluetoothDevices();
    }

    ngOnDestroy() {
        this.logoutSuperAdmin();
    }

    hasCorrectPassword(isCorrect: boolean): void {
        this.hasCorrectAccess = isCorrect;
    }

    selectSetting(setting: Setting): void {
        this.selectedSetting = setting;
    }

    exitAdmin(): void {
        this.navCtrl.back({animated: false});
    }

    logoutSuperAdmin(): void {
        if (this.adminUserService.isLoggedIn()) {
            this.adminUserService.logout();
        }
    }

    // The registration tab contains a call to get an Environment List
    // This call requires a device or admin token
    // Since we end up with a patient token and no device token if a patient is assigned, the registration tab requires the user to login with admin credentials
    tabGuard(setting: string): boolean {
        if (this.isHrsTab && setting === this.adminSettings.REGISTRATION) {
            return !this.adminUserService.isLoggedIn();
        }

        return false;
    }
}
