import {Injectable} from '@angular/core';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {KnoxIntentService} from './knox-intent.service';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';
import {KnoxManageService} from '../../services/knox/knox-manage.service';

@Injectable({
    providedIn: 'root',
})
export class RestartDeviceIntentService extends KnoxIntentService {
    private readonly logger = getLogger('RestartDeviceIntentService');
    private static ACTION_REBOOT_DEVICE = 'com.hrs.intent.action.REBOOT_DEVICE';

    constructor(
        broadcaster: Broadcaster,
        platform: Platform,
        private readonly knoxManageService: KnoxManageService
    ) {
        super(broadcaster, platform);
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        await this.registerListeners();
    }

    protected async registerListeners(): Promise<void> {
        return null;
    }

    public async restartDevice(): Promise<void> {
        const knoxPluginEnabled = this.knoxManageService.enabled;
        this.logger.trace(`restartDevice() knoxPluginEnabled = ${knoxPluginEnabled}`);
        if (this.knoxManageService.enabled) {
            await this.knoxManageService.reboot();
        } else {
            await super.sendIntent(RestartDeviceIntentService.ACTION_REBOOT_DEVICE, true);
        }
    }
}
