import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {DeviceProfilePartial} from '../../hrs-tablet/device-profile/device-profile.interface';
import {Task} from '../tasks';
import {overlayType} from '../../hrs-overlay';

Injectable({
    providedIn: 'root'
});

export class EventService {
    public loginStateChanged: Subject<boolean> = new Subject<boolean>();
    public caregiversLoaded: Subject<void> = new Subject<void>();
    public addPatient: Subject<void> = new Subject<void>();
    public removePatient: Subject<void> = new Subject<void>();
    public incomingNotification: Subject<any> = new Subject<any>();
    public updateDeviceProfile: Subject<DeviceProfilePartial> = new Subject<DeviceProfilePartial>();
    public postCompleteDeviceProfileEvent: Subject<void> = new Subject<void>();
    public storingOfflineTask: Subject<boolean> = new Subject<boolean>();
    public tasksLoaded: Subject<Task[]> = new Subject(); // To emit when cached and api tasks are fetched and formatted.
    public remoteLogDumpTriggered: Subject<void> = new Subject<void>();
    public restartDevice: Subject<void> = new Subject<void>();
    public overlayOpened: Subject<overlayType> = new Subject<overlayType>();
    public screenPauseChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public get screenPaused(): boolean {
        return this.screenPauseChange.value;
    }
}
