import {Injectable} from '@angular/core';
import {BatteryStatus, BatteryStatusResponse} from '@ionic-native/battery-status/ngx';
import {BehaviorSubject} from 'rxjs';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root'
})
export class BatteryStatusService {
    private readonly logger = getLogger('BatteryStatusService');
    public batteryStatusSubject = new BehaviorSubject<BatteryStatusResponse>({level: 0, isPlugged: false});
    public level: number;
    public isPlugged: boolean;

    constructor(
      private battery: BatteryStatus
    ) {}

    /**
     * Will update battery status when battery status changes, keeping that value up to date speeds up firebase requests by
     * fulfilling those requests with most recent values instead of delaying request until status changes next.
     * If onChange fails to work as desired with subsequent Android versions, try the suggested approach in the link below
     * https://github.com/apache/cordova-plugin-battery-status/issues/66#issuecomment-527070672
     */
    public initBatteryListener(): void {
        this.battery.onChange().subscribe((status: BatteryStatusResponse) => {
            const {level, isPlugged} = status;
            if (this.isPlugged !== isPlugged) {
                this.logger.debug(`battery.onChange()`, {level, isPlugged});
            }
            this.level = level;
            this.isPlugged = isPlugged;
            this.batteryStatusSubject.next(status);
        });
    }
}
