<hrs-wizard [config]="wizardConfig" variant="qna">
    <hrs-text slot="subhead-text">
        {{currentQuestion.text}}
    </hrs-text>
    <hrs-content
        [variant]="isStatusCard ? 'default' : 'list'"
        [content]="content"
        [background]="isStatusCard ? 'white' : null"
    >

        <!--   ANSWER REVIEW   -->

        <ng-container *ngIf="isStatusCard && !isQuizComplete">
            <hrs-text *ngIf="currentQuestion.explanation">
                {{currentQuestion.explanation}}
            </hrs-text>
            <hrs-text font="h2-bold">
                {{(correctAnswers.length === 1 ? 'REVIEW_QUIZZES.CORRECT-ANSWER' : 'REVIEW_QUIZZES.CORRECT-ANSWERS') | translate}}
            </hrs-text>
            <ng-container *ngFor="let answer of correctAnswers; index as i">
                <hrs-text>
                    {{answer.text}}
                </hrs-text>
            </ng-container>
        </ng-container>

        <!--   QUIZ IS COMPLETE   -->

        <ng-container *ngIf="isStatusCard && isQuizComplete">
            <hrs-text font="h1-semibold" align="center">
                {{quiz.name}}
            </hrs-text>
        </ng-container>
    </hrs-content>

    <hrs-footer [variant]="isQuizComplete ? 'one-button' : 'two-button'">
        <hrs-button
            *ngIf="!isQuizComplete"
            type="button"
            variant="secondary"
            icon="previous"
            [disabled]="!isStatusCard && currentStep < 2"
            (click)="moveSeries(false)"
        ></hrs-button>
        <hrs-button
            *ngIf="!isQuizComplete"
            type="submit"
            variant="primary"
            [text]="(isStatusCard ? 'NEXT_BUTTON' : 'REVIEW_QUIZZES.ANSWER') | translate"
            icon="next"
            [disabled]="!isStatusCard && !isAnswered"
            (click)="moveSeries(true)"
        ></hrs-button>
        <hrs-button
            *ngIf="isQuizComplete"
            expand
            type="submit"
            variant="primary"
            text="{{ 'REVIEW_QUIZZES.COMPLETE' | translate }}"
            (click)="handleModalClose()"
        ></hrs-button>
    </hrs-footer>
</hrs-wizard>
