import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DeviceService, OverlayService} from '@patient/providers';
import {DisconnectDevicePage} from '../../devices/disconnect/disconnect-device.page';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {ListDetail} from '@hrsui/core/dist/types/components/list/list.interface';
import {take} from 'rxjs/operators';
import {BuildUtility} from '@hrs/utility';
import {TNG_BLE_GLUCOMETER} from 'src/app/services/device/glucose.service';
import {BT_DEVICE_TYPE} from 'src/app/services/device/device.service';
import {Subject} from 'rxjs';
import {throttleClick} from 'src/app/utility/throttle-click';

@Component({
    selector: 'device-settings',
    templateUrl: './device-settings.component.html',
    styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsComponent implements OnInit {
    // Data to pass to HRSContent.
    public contentDevices: ContentDetail = {
        lists: []
    };
    // List Item Data to populate contentDevices.
    private pairedContent: ListDetail;
    private availableContent: ListDetail;
    private isHRSTablet: boolean = BuildUtility.isHRSTab();
    public selectedToPair: boolean = false;
    private listItemSelected$: Subject<string> = new Subject();

    // Click Handler for individual device items.
    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId}}): void {
        this.listItemSelected$.next(itemId);
    }

    constructor(
        private deviceService: DeviceService,
        private overlay: OverlayService,
        private translateService: TranslateService
    ) {
        this.listItemSelected$.pipe(throttleClick(500)
        ).subscribe((itemId) => {
            this.executeListItemAction(itemId);
        });
    }

    ngOnInit() {
        this.deviceService.selectedPeripheralType = undefined; // as we are handling all peripheral types here
        this.deviceService.getBondedBluetoothDevices(); // required on tab switch
        // we dont want polling on this screen as this is pairing screen
        if (this.isHRSTablet) {
            this.deviceService.unsubscribePollingForBluetoothDevices();
        }

        // Routing between tabs does not destroy the component so need to clean up manually.
        this.deviceService.availableBLEDevices = [];
        this.deviceService.availableClassicDevices = [];
        this.contentDevices = {
            lists: []
        };

        this.populateContent();
        this.deviceService.devicesUpdated.subscribe(() => {
            this.selectedToPair = false;
            // Repopulate only the paired and available content because device's in the Pairing Instructions section never change.
            this.populateContent();
        });

        setTimeout(() => {
            this.scanAvailableBluetoothDevices();
        }, 1000); // to stop any ongoing discovery when we move to this screen
    }

    ngOnDestroy() {
        if (this.isHRSTablet) {
            this.deviceService.cancelDeviceDiscovery();
            this.deviceService.subscribePollingForBluetoothDevices();
        }
    }

    private executeListItemAction(itemId: string): void {
        const getDevice = (allDevices, targetDevice) => {
            const deviceName = targetDevice.substring(targetDevice.indexOf('--') + 2);
            return allDevices.filter((device) => device.name === deviceName);
        };

        const isPaired = getDevice(this.deviceService.pairedDevices, itemId);
        const allAvailableDevices = this.isHRSTablet ?
            this.deviceService.availableBLEDevices.concat(this.deviceService.availableClassicDevices) :
            this.deviceService.availableBLEDevices;
        const isAvailable = getDevice(allAvailableDevices, itemId);

        if (itemId.includes('paired')) {
            this.launchDisconnectModal(isPaired[0]);
        } else if (itemId.includes('available')) {
            let availableDevice = isAvailable[0];
            if (availableDevice?.type === BT_DEVICE_TYPE.BLE || !this.selectedToPair){
                this.pairToBluetoothPeripheral(isAvailable[0]);
            }
            if (availableDevice?.type === BT_DEVICE_TYPE.CLASSIC) {
                this.selectedToPair = true;
            }
        }
    }

    /**
     * Populate the content to pass HRSContent in the template.
     */
    private populateContent(): void {
        const updatedContent = {
            lists: []
        };
        this.populatePairedDevices();
        this.populateAvailableDevices();
        if (this.pairedContent) updatedContent.lists.push(this.pairedContent);
        if (this.availableContent) updatedContent.lists.push(this.availableContent);

        this.contentDevices = updatedContent;
    }

    private populatePairedDevices(): void {
        const paired = this.deviceService.pairedDevices;
        let listItem: ListDetail;

        // Only create a "Paired" section if there are paired devices.
        if (paired.length > 0) {
            listItem = {
                legend: this.translateService.instant('BLUETOOTH.DEVICE_PAIRED'),
                items: paired.map((device) => {
                    return {
                        defaultChecked: false,
                        mainTitle: device.name,
                        subtitles: [device.id],
                        imgUrl: this.deviceService.getThumbnail(device.name),
                        itemId: `paired--${device.name}`
                    };
                })
            };
        } else {
            // No Devices Paired”
            listItem = {
                legend: this.translateService.instant('BLUETOOTH.DEVICE_PAIRED'),
                items: [{
                    defaultChecked: false,
                    mainTitle: this.translateService.instant('BLUETOOTH.NO_DEVICE_PAIRED'),
                    itemId: 'BLUETOOTH.NO_DEVICE_PAIRED'
                }]
            };
        }

        this.pairedContent = listItem;
    }

    private populateAvailableDevices(): void {
        const allAvailable = this.isHRSTablet ?
            this.deviceService.availableBLEDevices.concat(this.deviceService.availableClassicDevices) :
            this.deviceService.availableBLEDevices;
        let listItem: ListDetail;

        // Only create a "Ready For Pairing" section if there are devices available to pair.
        if (allAvailable.length > 0) {
            listItem = {
                legend: this.translateService.instant('BLUETOOTH.READY_FOR_PAIRING'),
                items: allAvailable.map((device) => {
                    return {
                        defaultChecked: false,
                        mainTitle: device.name,
                        subtitles: [device.id],
                        imgUrl: this.deviceService.getThumbnail(device.name),
                        itemId: `available--${device.name}`
                    };
                })
            };
        } else {
            listItem = {
                legend: this.translateService.instant('BLUETOOTH.READY_FOR_PAIRING'),
                items: [{
                    defaultChecked: false,
                    mainTitle: this.translateService.instant('BLUETOOTH.NONE_AVAILABLE'),
                    itemId: 'BLUETOOTH.NONE_AVAILABLE'
                }]
            };
        }

        this.availableContent = listItem;
    }

    public scanAvailableBluetoothDevices(): void {
        if (!this.isHRSTablet){
            this.deviceService.scanAvailableBLEDevices();
        } else {
            this.deviceService.startDeviceDiscovery(); // start device discovery for Classic and BLE devices
        }
    }

    public async pairToBluetoothPeripheral(peripheral: any): Promise<any> {
        if (peripheral.name.includes('Nonin3230')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.NONIN_3230_SERVICE, this.deviceService.NONIN_3230_CHARACTERISTIC, 'pulseox', true);
        } else if (peripheral.name.includes('TNG SPO2')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'pulseox', true);
        } else if (peripheral.name.includes('Nipro') || peripheral.name.includes('TRUEAIR')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.NIPROBGM_SERVICE, this.deviceService.NIPROBGM_CHARACTERISTIC, 'glucose', true);
        } else if (peripheral.name.includes('UA-651')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.AD_SERVICE, this.deviceService.AD_CHARACTERISTIC, 'bloodpressure', true);
        } else if (peripheral.name.includes('BP100')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.WELCH_BP_SERVICE, this.deviceService.WELCH_BP_MEASUREMENT_CHAR, 'bloodpressure', true);
        } else if (peripheral.name.includes('IR20')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'temperature', true);
        } else if (peripheral.name.includes('TD1107')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'temperature', true);
        } else if (peripheral.name.includes('TD1107')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'temperature', true);
        } else if (peripheral.name.includes('TNG SCALE')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'weight', true);
        } else if (peripheral.name.includes('SC100')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.WELCH_SCALE_SERVICE, this.deviceService.WELCH_SCALE_CHARACTERISTIC, 'weight', true);
        } else if (peripheral.name.includes('TAIDOC TD8255')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'pulseox', true);
        } else if (peripheral.name === TNG_BLE_GLUCOMETER) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.FORACARE_SERVICE, this.deviceService.FORACARE_CHARACTERISTIC, 'glucose', true);
        } else if (peripheral.name.includes('Nonin_Medical')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'pulseox', true);
        } else if (peripheral.name.includes('UC-352')) {
            await this.deviceService.pairToPeripheral(peripheral, this.deviceService.ADSCALE_SERVICE, this.deviceService.ADSCALE_CHARACTERISTIC, 'weight', true);
        } else if (peripheral.name.includes('Taidoc-Device')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'temperature', true);
        } else if (peripheral.name.includes('TEST-N-GO')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'glucose', true);
        } else if (peripheral.name.includes('UC-351')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'weight', true);
        } else if (peripheral.name.includes('UC-355')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'weight', true);
        } else if (peripheral.name.includes('UA-767')) {
            await this.deviceService.pairToClassicPeripheral(peripheral, 'bloodpressure', true);
        }
    }

    public async launchDisconnectModal(pairedDevice: any): Promise<void> {
        this.selectedToPair = false;
        const peripheralType = this.deviceService.getPeripheralType(pairedDevice.name);
        const image = this.deviceService.getDeviceImageUrl(peripheralType, pairedDevice.name);

        const overlay = await this.overlay.openModal({
            component: DisconnectDevicePage,
            title: this.translateService.instant('BLUETOOTH.DISCONNECT_TITLE'),
            inputs: {
                device: pairedDevice,
                imageUrl: image,
                peripheralType: peripheralType
            },
            qa: 'disconnect_device_modal'
        });

        overlay.result$
            .pipe(take(1))
            .subscribe((result) => {
                if (result.reason.data.didDisconnect) {
                    const device = result.reason.data.device;

                    // Filtering device from paired devices array and available devices since it's turned off
                    // This is will only be a problem while we are in development. We will need to remove from individual arrays as well as the joint arrays.
                    this.deviceService.removePeripheralsWithId(this.deviceService.getPairedPeripherals(peripheralType), device.id);
                    // Removing the available devices from the list as well as the paired devices
                    this.deviceService.removePeripheralsWithId(this.deviceService.availableBLEDevices, device.id);
                    if (this.isHRSTablet) {
                        this.deviceService.removePeripheralsWithId(this.deviceService.availableClassicDevices, device.id);
                    }
                    this.deviceService.removePeripheralsWithId(this.deviceService.allPairedDevices, device.id);
                    this.deviceService.removePeripheralsWithId(this.deviceService.pairedDevices, device.id);

                    if (device.name.includes('Nonin3230')) {
                        // Nonin had previously stored by the service and type, so in case someone has it stored that way
                        // this will make it backwards compatible. This was the first device so eventually we can remove.
                        this.deviceService.removeStorageValue(this.deviceService.NONIN_3230_SERVICE, peripheralType);
                        this.deviceService.removeStorageValue(device.id, peripheralType);
                    } else {
                        this.deviceService.removeStorageValue(device.id, peripheralType);
                    }

                    this.populateContent();
                }
            });
    }
}
