<hrs-content variant="list" [content]="[radioList]"></hrs-content>
<hrs-footer variant="two-button">
    <hrs-button
            variant="secondary"
            [text]="'CANCEL_BUTTON' | translate"
            (click)="dismiss(false)"></hrs-button>
    <hrs-button
            variant="primary"
            [text]="'SAVE_BUTTON' | translate"
            (click)="dismiss(true)"></hrs-button>
</hrs-footer>
