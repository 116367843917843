import {Injectable} from '@angular/core';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {KnoxIntentService} from './knox-intent.service';
import {BuildUtility} from '@hrs/utility';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';

declare var cordova: any;

@Injectable({
    providedIn: 'root',
})
export class RebootPCMTIntentService extends KnoxIntentService {
    private readonly logger = getLogger('RebootPCMTIntentService');
    private static ACTION_REBOOT_PCMT = 'com.hrs.intent.action.REBOOT_PCMT';

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected registerListeners(): void {
        if (!BuildUtility.isHRSTab()) return;
        super.registerListener(RebootPCMTIntentService.ACTION_REBOOT_PCMT, true).subscribe((event) => {
            this.logger.phic.debug('Listener for PCMT Reboot', event);
            this.restartPCMT();
        });
    }

    public startListening(): void {
        this.registerListeners();
    }

    public restartPCMT(): void {
        const onError = (error) => {
            this.logger.phic.error('ERROR: App reboot failed: ' + error);
        };
        // // Cold restarts are the desired pattern - they kill the app instance and launch a new instance
        cordova.plugins.diagnostic.restart(onError, false);
    }
}
