import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {ConsentFormElement, ConsentFormService} from '../services/consent-form/index';
import {TaskTrackingService} from '../services/task-tracking/task-tracking.service';
import {OverlayService} from '@patient/providers';
import {getLogger} from '@hrs/logging';
import {OverlayRef} from '../hrs-overlay';
import {WizardConfigDetail} from '@hrsui/core/dist/types/components/wizard/wizard.interface';
import {SignaturePadComponent as SignaturePad} from '../components/signature-pad/signature-pad.component';

@Component({
    selector: 'app-consent-form',
    templateUrl: './consent-form.page.html',
    styleUrls: ['./consent-form.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentFormPage {
  private readonly logger = getLogger('ConsentFormPage');
  public currentPageNumber: number = 1;
  public signature: string;
  public signatureLabel: string;
  public signaturePad: SignaturePad;
  public wizardConfig: WizardConfigDetail;

  private consentFormElements: ConsentFormElement[] = [];
  private introElements: ConsentFormElement[];
  public saving: boolean;
  private signatureSaved: boolean = false;
  private signaturePadHeight: number = 0;
  private totalPages: number;

  @ViewChild(SignaturePad, {static: false}) public set setSignaturePad(signaturePad: SignaturePad) {
      this.logger.trace(`set signature-pad element ref = `, signaturePad);
      // Resizes the signature pad canvas to fill the viewport when the user navigates to the signature page
      let shouldResize = !this.signaturePad && signaturePad;
      this.signaturePad = signaturePad;
      if (shouldResize) {
          this.resizeSignaturePad();
      }
  }

  constructor(
      private changeRef: ChangeDetectorRef,
      private consentFormService: ConsentFormService,
      private overlay: OverlayService,
      private overlayRef: OverlayRef,
      private taskTrackingService: TaskTrackingService,
      private translate: TranslateService,
  ) {}

  ngOnInit() {
      this.taskTrackingService.startTracking('consent-form', 'Opened sign consent form page');
      if (this.consentFormService.consentForm && this.consentFormService.consentForm.pages && this.consentFormService.consentForm.pages.length) {
          this.consentFormElements = this.consentFormService.consentForm.pages[0].elements;
          this.totalPages = this.consentFormService.consentForm.pages.length + 2; // plus 2 additional steps to the total # (1 for intro, 1 for signature block)
      }
      this.introElements = [
          {
              type: 'text',
              text: this.translate.instant('CONSENT_FORM_INTRO_1')
          },
          {
              type: 'text',
              text: this.translate.instant('CONSENT_FORM_INTRO_2')
          },
          {
              type: 'text',
              text: this.translate.instant('CONSENT_FORM_INTRO_3', {next: this.translate.instant('NEXT_BUTTON')})
          }
      ];
      this.signatureLabel = this.translate.instant('CONSENT_FORM_SIGNATURE_LABEL');
      this.updateWizard();
  }

  ngOnDestroy() {
      this.taskTrackingService.stopTracking();
  }

  public currentPage(): ConsentFormElement[] {
      if (this.currentPageNumber == 1) {
          return this.introElements;
      } else if (!this.isOnSignaturePage()) {
          return this.consentFormElements;
      }
  }

  public isOnSignaturePage(): boolean {
      // The signature page is the last page we display
      return this.currentPageNumber === this.totalPages;
  }

  private resizeSignaturePad(): void {
      let ratio;

      if (!this.signaturePadHeight) {
          this.signaturePadHeight = this.calculateSignaturePadHeight();

          // Scaling based on the device as per https://github.com/szimek/signature_pad#tips-and-tricks
          // Without this, the signature can look bad (thick and blurry) and get drawn in a different spot from where your finger is placed.
          ratio = Math.max(window.devicePixelRatio || 1, 1);
          this.signaturePadHeight = this.signaturePadHeight * ratio;
      }

      if (this.signaturePad) {
          this.signaturePad.height = this.signaturePadHeight;
      }
  }

  private calculateSignaturePadHeight(): number {
      try {
          let content = document.querySelector('hrs-wizard hrs-content').getBoundingClientRect();
          let labelEl = document.querySelector('hrs-wizard hrs-content hrs-text');
          let labelRect = labelEl.getBoundingClientRect();
          let labelPadding = parseFloat(getComputedStyle(labelEl).paddingBottom.replace('px', ''));
          let xtraPadding = 48; // ~3rem additional padding between signature pad and footer
          return content.height - labelRect.height - labelPadding - xtraPadding;
      } catch (ex) {
          // Just in case, a fallback height
          this.logger.phic.error('Error computing signature pad height', ex);
          return 250;
      }
  }

  public onSignatureComplete(): void {
      const hasSignaturePadRef = !!this.signaturePad;
      this.logger.debug(`onSignatureComplete() hasSignaturePadRef = ${hasSignaturePadRef}`);
      // This gets called when they finish drawing (ie. each time the finger is lifted or mouse press is released)
      if (hasSignaturePadRef) {
          this.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
          this.updateWizard();
      }
  }

  private updateWizard(): void {
      this.wizardConfig = {
          headerText: this.translate.instant('CONSENT_FORM_TITLE'),
          subheader: {
              serializationText: this.translate.instant('CONSENT_FORM_SERIALIZATION', {index: this.currentPageNumber, total: this.totalPages})
          },
          totalSteps: this.totalPages
      };
  }

  public navigate(direction: string): void {
      if (direction === 'back' && this.currentPageNumber > 1) {
          this.currentPageNumber--;
      }
      if (direction === 'forward' && this.currentPageNumber < this.totalPages) {
          this.currentPageNumber++;
      }
      this.signature = undefined;
      this.updateWizard();
  }

  public async save(): Promise<void> {
      this.saving = true;
      this.signatureSaved = false;
      this.consentFormService.submitSignature(this.signature)
          .pipe(
              finalize(() => {
                  this.saving = false;
                  this.changeRef.detectChanges();
              })
          ).subscribe({
              next: () => {
                  this.handleSaveSuccess();
              },
              error: (err) => {
                  this.handleSaveError(err);
              }
          });
  }

  private handleSaveSuccess(): void {
      this.signatureSaved = true;
      this.handleModalClose();
  }

  private async handleSaveError(err): Promise<void> {
      this.logger.phic.error('Error submitting consent form to server', err);
      await this.overlay.openAlert({
          header: this.translate.instant('ERROR_TITLE'),
          message: [this.translate.instant('CONSENT_FORM_SAVE_ERROR')],
          variant: 'error',
          buttons: [
              {
                  text: this.translate.instant('CANCEL_BUTTON'),
                  role: 'cancel'
              },
              {
                  text: this.translate.instant('RETRY_BUTTON'),
                  handler: () => {
                      this.save();
                  }
              }
          ],
          qa: 'consent_form_alert'
      });
  }

  public handleModalClose(): void {
      // Prevent our page from closing until the consent form is signed & saved
      if (!!this.signature && this.signatureSaved) {
          this.overlayRef.dismiss();
      }
  }
}
