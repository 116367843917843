import {User} from './services/user/user.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthGuard {
    constructor(
        public user: User
    ) {}

    canActivate(): boolean {
        return this.user.isLoggedIn();
    }
}
