import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {GenericMetricPage} from './generic-metric.page';
import {ComponentLibraryModule} from '@hrsui/angular';
import {SharedDirectivesModule} from '../directives/shared-directives.module';

const routes: Routes = [
    {
        path: '',
        component: GenericMetricPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        SharedDirectivesModule
    ],
    declarations: [GenericMetricPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GenericMetricPageModule {}

export {GenericMetricPage};
