import {Component} from '@angular/core';
import {PCMTAPKService} from '../apk/pcmt-apk.service';
import {PCKSAPKService} from '../apk/pcks-apk.service';
import {PCKSVersionIntentService} from '../knox-service-intents/pcks-version-intent.service';
import {OverlayService} from '@patient/providers';
import {getLogger} from '@hrs/logging';

interface SoftwareComponentAPKData {
    version: string;
    floatVersion: number;
    // eslint-disable-next-line camelcase
    download_url: string;
}

@Component({
    selector: 'software-update',
    templateUrl: './software-update.component.html',
    styleUrls: ['update-apk.component.scss']
})
export class SoftwareUpdateComponent {
    private readonly logger = getLogger('SoftwareUpdateComponent');
    disable: boolean;
    pcmtCurrentVersion: string;
    pcmtLatestAPKData: SoftwareComponentAPKData;
    pcksLatestAPKData: SoftwareComponentAPKData;

    constructor(
        private overlay: OverlayService,
        public pcksAPK: PCKSAPKService,
        private pcksVersionIntent: PCKSVersionIntentService,
        public pcmtAPK: PCMTAPKService,
    ) {}

    async ngOnInit() {
        this.pcmtCurrentVersion = await this.pcmtAPK.version;
        if (!this.pcksAPK.version) this.pcksVersionIntent.getPCKSVersion();
        this.pcmtLatestAPKData = await this.getLatestMetaData(this.pcmtAPK);
        this.pcksLatestAPKData = await this.getLatestMetaData(this.pcksAPK);
    }

    public async getLatestMetaData(service: PCKSAPKService | PCMTAPKService): Promise<any | any[]> {
        try {
            return await service.getLatestAPKMetaData();
        } catch (e) {
            this.logger.phic.error('Error getting ' + service.folder + ' APK meta data', e);
        }
    }

    public async confirmUpdateToLatest(service: PCKSAPKService | PCMTAPKService): Promise<void> {
        await this.overlay.openAlert({
            header: 'Confirm Update',
            message: ['Are you sure you want to update ' + service.folder + ' to the latest version?'],
            buttons: [{
                text: 'YES',
                handler: () => {
                    this.updateToLatestAPK(service);
                }
            }, {
                text: 'NO',
            }],
            qa: 'software_update_alert'
        });
    }

    private async updateToLatestAPK(service: PCKSAPKService | PCMTAPKService): Promise<void> {
        try {
            await service.updateToLatest();
        } catch (e) {
            this.logger.phic.error('Error performing UPDATE TO LATEST APK for: ' + service.folder, e);
        }
    }
}

