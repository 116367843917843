import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {GatewayApi} from '@hrs/providers';
import {GatewayService} from '@hrs/gateway';
import {HRSSecureCache} from '../storage/cache';
import {Settings} from '../settings/settings.service';
import {BuildUtility} from '@hrs/utility';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class LanguageService {
    static LANGUAGE_KEY: string = 'language';
    private userLanguage: string;
    private previousCacheInvalid: boolean = false;
    public languageChange: Subject<string> = new Subject();

    constructor(
        private cache: HRSSecureCache,
        private gateway: GatewayService,
        private gatewayApi: GatewayApi,
        private settings: Settings,
        private translate: TranslateService
    ) {
        // English will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }

    getUserLanguage(): string {
        // Apply the user's stored language, or user's browser language before defaulting to english
        return this.settings.settings && this.settings.getValue(LanguageService.LANGUAGE_KEY) || this.translate.getBrowserLang() || 'en';
    }

    public setTranslation(language: string): Promise<void> {
        const i18nSource = require(`../../../assets/i18n/${language}.json`);
        // on app launch user langauge is undefined, ensure is set and has changed.
        this.previousCacheInvalid = this.userLanguage && language !== this.userLanguage;
        this.userLanguage = language;
        this.gatewayApi.language = language;
        this.gateway.language = language;
        moment.locale(language);
        this.translate.use(language);
        this.translate.setTranslation(language, i18nSource);
        // Only HRSTab users who can access tablet settings language options will have their language choices stored
        // BYOD users who cannot access tablet settings page will persist their language choice via their browser/ native settings
        if (BuildUtility.isHRSTab()) this.storeUserLanguage();
        if (this.previousCacheInvalid) {
            return this.cache.clearAll().then(() => {
                // TranslationService.setTranslation() is not instantaneous - the new language needs to be compiled
                // inserting a delay to ensure language is fully updated prior to application translations kicking off
                // on update event
                setTimeout(() => {
                    this.languageChange.next(language);
                }, 1000);
            });
        } else {
            return Promise.resolve();
        }
    }

    storeUserLanguage(): void {
        this.settings.setValue(LanguageService.LANGUAGE_KEY, this.userLanguage);
    }
}
