import {TokenService} from '@hrs/providers';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AdminTokenService extends TokenService {
    constructor(http: HttpClient) {
        super(http);
    }
}
