import {Component, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavController} from '@ionic/angular';
import {
    AdminService,
    EnvironmentService,
    LogUploadViewControllerService,
    OverlayService,
    User
} from '@patient/providers';
import {Subscription, interval} from 'rxjs';
import {RestartDeviceIntentService} from '../knox-service-intents/restart-device-intent.service';
import {BatteryStatusService} from '../../services/battery-status/battery-status.service';
import {OverlayRef} from '../../hrs-overlay';
import {TabletDeviceIdService} from '../../services/tablet-device-id/tablet-device-id';
import {RebootPCMTIntentService} from '../../hrs-tablet/knox-service-intents/reboot-pcmt-intent.service';
import {take} from 'rxjs/operators';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {getLogger} from '@hrs/logging';
import {KnoxManageService} from '../../services/knox/knox-manage.service';

@Component({
    selector: 'hrs-tablet-white-screen',
    templateUrl: 'tablet-white-screen.page.html',
    styleUrls: ['./tablet-white-screen.page.scss'],
})
export class TabletWhiteScreenPage {
    private readonly logger = getLogger('TabletWhiteScreenPage');
    public batteryPercentage: number;
    private confirmDeviceIsLoggedInInterval: Subscription;
    private batteryStatusSubscription: Subscription;
    public versionNumber: string;

    constructor(
        private adminService: AdminService,
        private appVersion: AppVersion,
        private batteryStatus: BatteryStatusService,
        private environment: EnvironmentService,
        private logUploadViewControllerService: LogUploadViewControllerService,
        private navCtrl: NavController,
        private overlay: OverlayService,
        @Optional() private rebootPCMT: RebootPCMTIntentService,
        private restartDeviceIntent: RestartDeviceIntentService,
        private tabletDeviceIDService: TabletDeviceIdService,
        private translateService: TranslateService,
        private user: User,
        private readonly knox: KnoxManageService
    ) {
        this.rebootPCMT.startListening();
    }

    ngOnInit() {
        this.batteryPercentage = this.batteryStatus.level;
        this.batteryStatusSubscription = this.batteryStatus.batteryStatusSubject.subscribe(() => {
            this.batteryPercentage = this.batteryStatus.level;
        });

        if (!this.tabletDeviceIDService._imei) {
            this.tabletDeviceIDService.subscribeIMEIIntent().subscribe(() => {
                this.confirmDeviceIsLoggedIn();
                this.tabletDeviceIDService.subscribeLicenseKeyIntent();
            });
        } else {
            this.confirmDeviceIsLoggedIn();
            this.tabletDeviceIDService.subscribeLicenseKeyIntent();
        }

        this.populateVersionNumber();
    }

    ngOnDestroy(): void {
        if (this.batteryStatus) this.batteryStatusSubscription.unsubscribe();
        if (this.confirmDeviceIsLoggedInInterval) this.confirmDeviceIsLoggedInInterval.unsubscribe();
    }

    public get knoxEnabled(): boolean {
        return this.knox.enabled;
    }

    public async populateVersionNumber(): Promise<string> {
        if (this.versionNumber) {
            return this.versionNumber;
        } else {
            const version = await this.appVersion.getVersionNumber();
            if (version) {
                this.versionNumber = version;
                return 'v' + version;
            } else {
                return 'v1.0.0';
            }
        }
    }

    openAdmin(): void {
        this.adminService.openAdmin();
    }

    public async restartDevicePrompt(): Promise<OverlayRef> {
        return await this.overlay.openAlert({
            header: this.translateService.instant('TABLET_SETTINGS.RESTART').toUpperCase(),
            message: [this.translateService.instant('WHITE_SCREEN.RESTART_MESSAGE')],
            buttons: [
                {
                    text: this.translateService.instant('YES_BUTTON'),
                    handler: () => {
                        this.restartDeviceIntent.restartDevice();
                    }
                },
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                }
            ],
            qa: 'tablet_restart_alert'
        });
    }

    public async submitAppLogs(): Promise<void> {
        this.logger.trace(`submitAppLogs()`);
        await this.logUploadViewControllerService.submitAppLogs();
    }

    private async confirmDeviceIsLoggedIn(): Promise<void> {
        try {
            await this.user.login();
            if (this.user.isLoggedIn() && this.user.isPatient()) {
                this.logger.phic.debug(`TabletWhiteScreen.confirmDeviceIsLoggedIn | user ${this.user.id} is logged in and is a patient`);
                let status = await this.user.getUserData().then(() => this.user.status);
                if (status !== 'deactivated') {
                    this.environment.getEnvironment(this.user.environment);
                    this.environment.environmentDataLoading.pipe(take(1)).subscribe(() => {
                        this.navCtrl.navigateRoot(['home']);
                    });
                } else {
                    this.logger.phic.error(`TabletWhiteScreen.confirmDeviceIsLoggedIn | Patient is deactivated. Logging ${this.user.id} out`);
                }
            }
        } catch (e) {
            this.logger.phic.error('Device failed to login', e);
        }

        if (!this.user.isPatient()) {
            this.createIntervalToConfirmDeviceIsLoggedIn();
        }
    }

    // this will get a new token every 5 mins
    // if a patient has a been assigned a patient token will be returned and the patient UI will be loaded
    // leaving this here to ensure device is always logged in
    private createIntervalToConfirmDeviceIsLoggedIn(): void {
        this.logger.phic.debug('createIntervalToConfirmDeviceIsLoggedIn');
        if (!this.confirmDeviceIsLoggedInInterval) {
            this.confirmDeviceIsLoggedInInterval = interval(300000).subscribe(async () => {
                await this.confirmDeviceIsLoggedIn();
            });
        }
    }

    public async openWifiSettings(): Promise<void> {
        return await this.knox.openWifiSettings();
    }
}
