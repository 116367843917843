import {Directive, HostListener, Output, EventEmitter} from '@angular/core';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {Platform} from '@ionic/angular';
import {throttle} from 'lodash';

@Directive({
    selector: '[appSubmitOnEnterKey]'
})
export class SubmitOnEnterKeyDirective {
    @Output()
    public enterSubmit: EventEmitter<any> = new EventEmitter<any>();

    private readonly submitThrottled: ($event: any) => void;

    constructor(
        private readonly platform: Platform,
        private readonly keyboard: Keyboard
    ) {
        this.submitThrottled = throttle((ev) => this.submit(ev), 250);
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    private get shouldDismissKeyboard(): boolean {
        return this.isNativePlatform && this.keyboard.isVisible;
    }

    private submit($event: any): void {
        this.enterSubmit.emit($event);
        if (this.shouldDismissKeyboard) {
            this.keyboard.hide();
        }
    }

    @HostListener('keyup.enter', ['$event'])
    public onEnterKeyPressed($event: any): void {
        this.submitThrottled($event);
    }
}
