import {Injectable} from '@angular/core';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {APKIntentService} from '../knox-service-intents/apk-intent.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {APKService} from './apk.service';
import {GatewayResourceService, GatewayResponse} from '@hrs/gateway';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class PCMTAPKService extends APKService {
    private readonly logger = getLogger('PCKSVersionIntentService');

    public folder = 'HRSPatientConnectMobileTablet';

    get version(): Promise<string> {
        if (this._version) {
            return Promise.resolve(this._version);
        }

        return (async () => {
            this._version = await this.appVersion.getVersionNumber();
            return this._version;
        })();
    }

    constructor(
        file: File,
        fileTransfer: FileTransfer,
        apkIntent: APKIntentService,
        gateway: GatewayResourceService,
        private appVersion: AppVersion
    ) {
        super(file, fileTransfer, apkIntent, gateway);
    }

    public async install(url: string): Promise<boolean> {
        if (!this._packageName) {
            try {
                this._packageName = await this.appVersion.getPackageName();
            } catch (e) {
                this.logger.phic.error(PCMTAPKService.name + '- Error getting package name: ' + JSON.stringify(e));
            }
        }

        return await super.install(url, this.folder);
    }

    public async getLatestAPKMetaData(): Promise<any[] | any> {
        return await super.getLatest(this.folder);
    }

    public async updateToLatest(): Promise<boolean> {
        return await super.updateToLatest(this.folder, await this.version);
    }

    public async getMetaData(): Promise<GatewayResponse<any[] | any>> {
        return await super.getMetaData(this.folder);
    }
}
