import {Component, ChangeDetectionStrategy, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@hrs/providers';
import {OverlayRef} from '../hrs-overlay';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {ListItem} from '@hrsui/core/dist/types/components/list/list.interface';
import {TaskService} from '../services/tasks/task.service';
import {MedicationTask, TaskType} from '../services/tasks/index';
import {filter, first, tap} from 'rxjs/operators';
import {CareplanChangeAction} from '../enums';
import {CareplanChangeService} from '@patient/providers';
import {Subscription} from 'rxjs';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-medication-modal',
    templateUrl: './medication.modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationModal {
    private readonly localLogger = getLogger('MedicationModal');
    public content: ContentDetail[] = [];
    public isPRN: boolean = false;
    public medications: MedicationTask[];
    public submitDisabled: boolean = true;

    private submitMedications: string[] = [];
    private taskRemovedSubscription: Subscription;

    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemClicked({detail: {event, itemId, selected}}) {
        if (selected) {
            this.submitMedications.push(itemId);
        } else {
            let index = this.submitMedications.indexOf(itemId);
            if (index >= 0) this.submitMedications.splice(index, 1);
        }
        this.submitDisabled = !this.submitMedications.length;
    }

    constructor(
        private careplanChangeService: CareplanChangeService,
        private modalService: ModalService,
        private overlayRef: OverlayRef,
        private taskService: TaskService,
        private translate: TranslateService,
    ) {
        this.modalService.setModalStatus('MedicationModal', true);
    }

    ngOnInit() {
        const listItems: ListItem[] = [];

        this.medications.forEach((medication) => {
            const subtitles: string[] = [this.taskService.getMedicationMethod(medication)];
            let disableCheckbox: boolean = false;

            if (medication.instruction) {
                subtitles.push(`${this.translate.instant('MEDICATION_SPECIAL_INSTRUCTIONS')} ${medication.instruction}`);
            }

            if (!this.isPRN && medication.isLastCompletedToday() && !medication.isTaskReset) {
                disableCheckbox = true;
            }

            if (medication.lastCompletedButOffline) {
                subtitles.push(`${this.translate.instant('MEDICATION_LAST_TAKEN_AT.COMPLETED_BUT_OFFLINE', {date: medication.getLastCompletedDate(), time: medication.getLastCompletedTime()})}`);
            } else if (this.isPRN && medication.lastCompleted) {
                subtitles.push(`${this.translate.instant('MEDICATION_LAST_TAKEN_AT', {date: medication.getLastCompletedDate(), time: medication.getLastCompletedTime()})}`);
            }

            const listItem: ListItem = {
                checkboxDisabled: disableCheckbox,
                mainTitle: this.taskService.getMedicationDescription(medication),
                subtitles: subtitles,
                itemId: medication.id
            };

            listItems.push(listItem);
        });

        this.content = [{
            lists: [{
                items: listItems,
                variant: 'checkbox'
            }]
        }];

        this.taskRemovedSubscription = this.careplanChangeService.careplanState$.pipe(
            tap((careplanState) => this.localLogger.debug(`received careplan state update ->`, careplanState, TaskType.Medication)),
            filter((careplanState) => careplanState[TaskType.Medication] === CareplanChangeAction.REMOVED),
            first()
        ).subscribe(() => {
            this.localLogger.info(`returning to home page after module remove!`);
            this.overlayRef.close();
        });
    }

    ngOnDestroy() {
        this.modalService.setModalStatus('MedicationModal', false);
        if (this.taskRemovedSubscription) {
            this.taskRemovedSubscription.unsubscribe();
            this.taskRemovedSubscription = null;
        }
    }

    public submit(): void {
        this.overlayRef.close({submittedMeds: this.submitMedications});
        this.modalService.setModalStatus('MedicationModal', false);
    }
}
