<hrs-content [variant]="contentVariant" [content]="contentConfig">
    <!-- styles need to remain inline below for OpenTok plugin to manipulate -->
    <div *ngIf="calling" id='videoCallPublisher' data-qa="video_call--caller_view" style="width: 16vh; height: 24vh; position: absolute; bottom: 2vh; right: 2vh; background: black; overflow: hidden; box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2); z-index: 1000; pointer-events:none;"></div>
    <div id='videoCallSubscriber' data-qa="video_call--callee_view" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 500; pointer-events:none;"></div>
</hrs-content>
<hrs-footer *ngIf="!calling" variant="two-button">
    <hrs-button
            variant="secondary"
            text="{{'CANCEL_BUTTON' | translate}}"
            qa="video--btn-dismiss"
            (click)="dismiss()"
    ></hrs-button>
    <hrs-button
            text="{{primaryButtonText | translate}}"
            qa="video--btn-toggle"
            (click)="toggleCall()"
    ></hrs-button>
</hrs-footer>
<hrs-footer *ngIf="calling" variant="one-button">
    <hrs-button
        expand
        variant="toggle-on"
        text="{{'END_CALL' | translate}}"
        qa="video--btn-end"
        (click)="toggleCall()"
        [disabled]="modalClosing"
    ></hrs-button>
</hrs-footer>
