import {Injectable} from '@angular/core';
import {GatewayService} from '@hrs/gateway';
import moment from 'moment-timezone';
import {TrackingDataRequest} from '@hrs/interfaces';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class EducationTrackingService {
    private readonly logger = getLogger('EducationTrackingService');
    public static TRACKING_SOURCE = 2;
    private startTime: number;

    constructor(
        private gateway: GatewayService,
    ) {}

    public trackingFinishTime(): string {
        // for non-western languages moment converts the date string to a different character set that the server doesn't like
        // so setting __locale()__ to english to ensure we get a date string in western characters (only an issue for non-western languages
        // like arabic and hindi). This fixed an issue where the iOS app was crashing in arabic and hindi.
        return moment().locale('en').format('YYYY-MM-DD hh:mm:ss');
    }

    public trackingStartTime(): void {
        this.startTime = moment().unix();
    }

    public trackingTimeElapsed(): number {
        return moment().unix() - this.startTime;
    }

    public postTrackingData(trackingData: TrackingDataRequest): void {
        const options = {
            path: `v1/${trackingData.path}`,
            body: {data: trackingData.body}
        };
        this.gateway.post(options).subscribe(
            {
                next: () => {
                    this.logger.phic.debug('Education tracking data successfully submitted');
                },
                error: (err) => {
                    this.logger.phic.error('Failed to submit education tracking data', err);
                }
            }
        );
    }
}
