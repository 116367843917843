import {Injectable} from '@angular/core';
import {Screenshot} from '@ionic-native/screenshot/ngx';
import {TabletDeviceIdService} from '../../services/tablet-device-id/tablet-device-id';
import {GatewayService} from '@hrs/gateway';
import moment from 'moment';
import {RemoteDiagnosticsEvent} from './remote-diagnostics-event';
import {RemoteDiagnosticsData} from './remote-diagnostics.interface';
import {lastValueFrom} from 'rxjs';
import {RemoteDiagnosticsIntentService} from '../knox-service-intents/remote-diagnostics-intent.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class RemoteDiagnosticsService {
    private readonly logger = getLogger('RemoteDiagnosticsService');

    private static lowQuality = 1;
    private static medQuality = 10;
    private static highQuality = 20; // can be up to 100 but that creates an unnecessarily large file size ~200kb, 20 is ~ 37kb and still results in a very clear image on Admin
    private static downgradeTime = 900;
    private static upgradeTime = 450;
    private static screenshotInterval = 1000; // milliseconds - controls how often we take and submit a new screenshot
    private event: RemoteDiagnosticsEvent = null;
    private quality = RemoteDiagnosticsService.highQuality;
    private isRunning: boolean = false;
    public isPaused: boolean = false;

    constructor(
        private gateway: GatewayService,
        private screenshot: Screenshot,
        private tabletIdService: TabletDeviceIdService,
        private remote: RemoteDiagnosticsIntentService
    ) {}

    public handleNotification(data: RemoteDiagnosticsData): void {
        // stop kills loop by making event 'stop'
        this.event = new RemoteDiagnosticsEvent(data, RemoteDiagnosticsService.getTime());
        if (this.event.isStart() && !this.isRunning) {
            this.startSendingScreenshots();
        } else if (this.event.isTouch()) {
            // resume screenshare if it has been paused
            if (!this.isRunning) {
                this.startSendingScreenshots();
            }
            this.createTouchInput();
        }
    }

    private async startSendingScreenshots(): Promise<void> {
        if (!this.shouldContinue()) {
            this.isRunning = false;
            this.event = null;
            return;
        }

        this.isRunning = true;
        // using a timeout here to improve performance on the Admin side. A loop although faster, made the Admin side update slower
        // This 1 seconds interval brought update time down from 30 seconds to 5-8 seconds
        setTimeout(async () => {
            try {
                const screenshot = await this.getScreenshot(this.quality);
                const start = RemoteDiagnosticsService.getTime();
                await this.submitScreenshot(screenshot);
                const submitDuration = RemoteDiagnosticsService.getTime() - start;
                this.qualityCheck(submitDuration);
                await this.startSendingScreenshots();
            } catch (e) {
                this.logger.phic.error('Remote Diagnostics failed', e);
            }
        }, RemoteDiagnosticsService.screenshotInterval);
    }

    private async getScreenshot(quality: number): Promise<string> {
        let {URI} = await this.screenshot.URI(quality);
        // remove data tag, make uri base64 string url safe
        URI = URI.replace('data:image/jpeg;base64,', '');
        URI = URI.replace(/[/]/g, '_');
        URI = URI.replace(/[+]/g, '-');

        let urlEncodedScreenshot = new URLSearchParams();
        urlEncodedScreenshot.append('data', URI);
        return urlEncodedScreenshot.toString();
    }

    private async submitScreenshot(screenshot: string): Promise<any> {
        const options = {
            path: `diagnostics/` + await this.tabletIdService.getImei(),
            body: screenshot,
            contentType: 'application/x-www-form-urlencoded'
        };

        return lastValueFrom(this.gateway.post(options)).catch((err) => {
            this.logger.phic.log(`submitScreenshot failed`, err);
        });
    }

    private qualityCheck(submitDuration: number): void {
        if (submitDuration > RemoteDiagnosticsService.downgradeTime) {
            this.reduceQuality();
        } else if (submitDuration < RemoteDiagnosticsService.upgradeTime) {
            this.increaseQuality();
        }
    }

    private reduceQuality(): void {
        if (this.quality === RemoteDiagnosticsService.highQuality) {
            this.quality = RemoteDiagnosticsService.medQuality;
        } else if (this.quality === RemoteDiagnosticsService.medQuality) {
            this.quality = RemoteDiagnosticsService.lowQuality;
        }
    }

    private increaseQuality(): void {
        if (this.quality === RemoteDiagnosticsService.lowQuality) {
            this.quality = RemoteDiagnosticsService.medQuality;
        } else if (this.quality === RemoteDiagnosticsService.medQuality) {
            this.quality = RemoteDiagnosticsService.highQuality;
        }
    }

    private shouldContinue(): boolean {
        const now = RemoteDiagnosticsService.getTime();
        return !(this.event === null || this.event.isStop() || this.event.isTimedOut(now));
    }

    private createTouchInput(): void {
        const touchEvent = this.event.data.touchevent;
        // height/width of the remote tools screen on admin
        let width = 640;
        if (touchEvent.width) width = parseInt(touchEvent.width);

        let height = 400;
        if (touchEvent.height) height = parseInt(touchEvent.height);

        let duration = 0;
        if (touchEvent.duration) duration = parseInt(touchEvent.duration);

        try {
            this.remote.sendPointerEvent(
                parseInt(touchEvent.down.x),
                parseInt(touchEvent.down.y),
                parseInt(touchEvent.up.x),
                parseInt(touchEvent.up.y),
                duration,
                width,
                height
            );
        } catch (e) {
            this.logger.phic.error('Failed to send pointer event', e);
        }
    }

    private static getTime(): number {
        return moment().unix();
    }
}

