export const GenericMetricSchema = {
    activity: [
        {
            id: 'value',
            label: 'Duration',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'minutes',
            min: 0,
            max: 1000,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 1000
            }
        }
    ],
    bloodpressure: [
        {
            id: 'systolic',
            label: 'Systolic',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'mm Hg',
            min: 0,
            max: 299,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 299
            }
        },
        {
            id: 'diastolic',
            label: 'Diastolic',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'mm Hg',
            min: 0,
            max: 199,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 199
            }
        },
        {
            id: 'heartrate',
            label: 'Heart rate',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'bpm',
            min: 0,
            max: 199,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 199
            }
        }
    ],
    glucose: [
        {
            id: 'bloodsugar',
            label: 'Glucose',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'mg/dl',
            unitTTS: 'GENERIC_METRIC_UNITS_LABEL_GLUCOSE_MGDL',
            min: 0,
            max: 1000,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 1000
            }
        }
    ],
    pulseox: [
        {
            id: 'spo2',
            label: 'SpO2',
            type: 'INPUT',
            inputType: 'number',
            placeholder: '%',
            min: 0,
            max: 100,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 100
            }
        },
        {
            id: 'hr',
            label: 'Heart rate',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'bpm',
            min: 0,
            max: 199,
            step: 1,
            validators: {
                required: true,
                min: 0,
                max: 199
            }
        }
    ],
    temperature: [
        {
            id: 'temperature',
            label: 'Temperature',
            type: 'INPUT',
            inputType: 'number',
            placeholder: '°F',
            min: 0,
            max: 199,
            step: 0.1,
            validators: {
                required: true,
                min: 0,
                max: 199
            }
        },
        {
            id: 'unit',
            label: 'Temperature unit',
            type: 'SELECT',
            selectOptions: [
                {
                    label: '°F',
                    value: 'F',
                },
                {
                    label: '°C',
                    value: 'C'
                }
            ],
            value: 'F',
            validators: {
                required: true
            }
        }
    ],
    weight: [
        {
            id: 'weight',
            label: 'Weight',
            type: 'INPUT',
            inputType: 'number',
            placeholder: 'lbs',
            min: 0,
            max: 699,
            step: 0.1,
            validators: {
                required: true,
                min: 0,
                max: 699
            }
        }
    ]
};

export interface InputProperties {
    unitTTS?: string;
    id: string,
    label: string,
    type: string,
    inputType?: string,
    placeholder?: string,
    value?: string,
    min: number,
    max: number,
    step: number,
    validators?: InputValidators,
    selectOptions?: SelectOptions[],
    uiText?: UITextOptions
}

interface InputValidators {
    required: boolean,
    min?: number,
    max?: number
}

interface SelectOptions {
    label: string,
    value: string
}

interface UITextOptions {
    label: string,
    placeholder: string,
    qaLabel: string
}
