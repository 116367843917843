import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {Subject, Observable} from 'rxjs';
import {ConvertTemperature} from '@hrs/utility';
import {ModalService} from '@hrs/providers';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../analytics/firebaseanalytics.service';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class TemperatureDeviceService {
    private readonly logger = getLogger('TemperatureDeviceService');

    // Metrics
    temperature: number;
    metricChange = new Subject();
    bluetoothMetricChange$: Observable<any>;

    isRecentReading: boolean;
    readingErrorOccurred: boolean;

    // Services and Characteristics to connect to device
    FORACARE_SERVICE = '00001523-1212-EFDE-1523-785FEABCD123';
    FORACARE_CHARACTERISTIC = '00001524-1212-EFDE-1523-785FEABCD123';

    // Foracare Byte Commands and Request Messages
    CMD_ACK = 0x51;
    CMD_START = 81;
    CMD_END = 163;
    CMD_COMMS = 0x54;
    CMD_DATA_COUNT = 0x2B;
    CMD_DATA_TIME = 0x25;
    CMD_DATA = 0x26;
    CMD_DATA_SCALE = 0x71;
    CMD_DATA_CLEAR = 0x52;
    CMD_TAKE_MEASUREMENT = 0x41;
    CMD_POWER_OFF = 0x50;
    CMD_DATA_POINTS = 0x2;

    constructor(
        private ble: BLE,
        private fbAnalytics: HRSFirebaseAnalytics,
        private ngZone: NgZone,
        private modalService: ModalService,
        private bluetoothSerial: BluetoothSerial
    ) {
        this.bluetoothMetricChange$ = this.metricChange.asObservable();
    }

    /**
     * Foracare (TNG) is specific in that it needs to be written to in order to continue notifications
     * The addCheckSum ensures that the buffer array includes the correct bytes. We stop writing on clear.
     * This may be used for the other Foracare devices, but currently unsure. I will adjust and cleanup functions
     * if that is the case.
     */
    onTemperatureMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, peripheralType);
        if (
            peripheralType == 'temperature' &&
            (peripheralName.includes('IR20') ||
                peripheralName.includes('TD1107')) &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug('Got FORA IR20 DATA ' + BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer.length == 2 && (arrayBuffer[0] == 1) && arrayBuffer[1] == 0) {
                    let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
                    this.writeForacareThermometerCharacteristic(peripheral, dataCount, false);
                } else if (arrayBuffer.length == 8) {
                    if (arrayBuffer[1] == this.CMD_DATA_COUNT) {
                        this.logger.phic.debug('Request date time for ' + peripheralName);
                        let dataDateTime = this.addCheckSum([81, 0x25, 0, 0, 0, 0, 163]);
                        this.writeForacareThermometerCharacteristic(peripheral, dataDateTime, false);
                    } else if (arrayBuffer[1] == this.CMD_DATA_TIME) {
                        this.logger.phic.debug('Request data measurement for ' + peripheralName);
                        let dataMeasurement = this.addCheckSum([81, 0x26, 0, 0, 0, 0, 163]);
                        this.writeForacareThermometerCharacteristic(peripheral, dataMeasurement, false);
                    } else if (arrayBuffer[1] == this.CMD_DATA && arrayBuffer[3] > 0) {
                        // This sends it over in Celsius, but needs to be converted to Fahrenheit
                        let tempLSB = arrayBuffer[2];
                        let tempMSB = arrayBuffer[3];
                        let celsiusReading = ((tempMSB * 255) + tempLSB + 1) / 10;
                        this.temperature = this.convertCelsiusToFarenheight(celsiusReading);
                        this.logger.phic.log('BT Reading Success for FORA IR20');
                        setTimeout(() => {
                            this.logger.phic.debug('Got latest data from ' + peripheralName + ' and will show on metric reading page for submission. Temperature ' + this.temperature);
                            this.metricChange.next(peripheralName);
                        }, 1000); // this gives the time to execute memory clear and turn off commands before we move to genericMetricPage
                        let memoryClear = this.addCheckSum([81, 0x52, 0, 0, 0, 0, 163]);
                        this.writeForacareThermometerCharacteristic(peripheral, memoryClear, false);
                        this.isRecentReading = true;
                        this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi});
                    } else if (arrayBuffer[1] == this.CMD_DATA_CLEAR) {
                        this.logger.phic.debug('Request power off for ' + peripheralName);
                        let requestPowerOff = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                        this.writeForacareThermometerCharacteristic(peripheral, requestPowerOff, true);
                    }
                }
            });
        } else if (
            peripheralType == 'temperature' &&
            peripheralName.includes('Taidoc-Device') &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            // This had to be written twice as it's annoyingly slightly different than the BLE device.
            // Depending on other devices, this should probably move to it's own service
            this.ngZone.run(() => {
                this.bluetoothSerial.subscribeRawData().subscribe(
                    {
                        next: (data) => {
                            let arrayBuffer = new Uint8Array(data);
                            this.logger.phic.debug('Got data from Temperature ' + peripheralName + ' ' + BluetoothUtils.buf2hex(arrayBuffer));
                            if (arrayBuffer[1] == this.CMD_COMMS) {
                                this.logger.phic.debug('Writing data count for ' + peripheralName);
                                let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataCount);
                                this.onTemperatureMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[1] == this.CMD_DATA_COUNT) {
                                this.logger.phic.debug('Request date time for ' + peripheralName);
                                let dataDateTime = this.addCheckSum([81, 0x25, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataDateTime);
                                this.onTemperatureMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[1] == this.CMD_DATA_TIME) {
                                this.logger.phic.debug('Request data measurement for ' + peripheralName);
                                let dataMeasurement = this.addCheckSum([81, 0x26, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataMeasurement);
                                this.onTemperatureMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[1] == this.CMD_DATA && arrayBuffer[2] > 0) {
                            // This sends it over in celcius, but needs to be converted to Farenheit
                                let tempLSB = arrayBuffer[2];
                                let tempMSB = arrayBuffer[3];
                                let celsiusReading = ((tempMSB * 255) + tempLSB + 1) / 10;
                                this.temperature = this.convertCelsiusToFarenheight(celsiusReading);
                                this.logger.phic.debug('Got latest temperature data' + this.temperature + ' for ' + peripheralName + ', will show on metric page for submission');
                                this.isRecentReading = true;
                                this.metricChange.next(peripheralName);
                                this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                        [HRSFirebaseParams.RSSI]: peripheral.rssi});
                                let memoryClear = this.addCheckSum([81, 0x52, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(memoryClear);
                                this.onTemperatureMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[0] == this.CMD_DATA_CLEAR) {
                                this.logger.phic.debug('Request power off for ' + peripheralName);
                                let dataCount = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataCount);
                            }
                        },
                        error: (error) => {
                            this.logger.phic.error('Error reading raw data', JSON.stringify(error));
                            this.readingErrorOccurred = true;
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.ERROR_READING_DATA,
                                    [HRSFirebaseParams.META_DATA]: error});
                        }
                    }
                );
            });
        } else {
            this.logger.phic.debug('unhandled case for peripheral type ' + peripheralType + ' peripheral name ' + peripheralName + ' is submission modal showing ' + this.modalService.getModalStatus('GenericMetricPage'));
        }
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (!isPairingFlow){
            let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'temperature');
            if (!this.isRecentReading && !this.readingErrorOccurred && !peripheralDisplayName.includes('Taidoc-Device')) {
                this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'temperature',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NO_RECENT_READING});
            }
        }
        // cleanup
        this.isRecentReading = false;
        this.readingErrorOccurred = false;
    }

    writeForacareDataPacket(bytes: ArrayBuffer): void {
        this.bluetoothSerial.write(bytes).then(
            (success) => {
                this.logger.phic.debug('Successful writing of data packet for classic device', success);
            }, (error) => {
                this.logger.phic.error('Error writing data packet for classic device', error);
            }
        );
    }

    convertCelsiusToFarenheight(celsiusReading): number {
        // Need to round here b/c the bluetooth device shows the user the reading with one decimal
        // For some reason the bytes comes back with 2 decimal places, so we want to show and
        // record what the user see so as not to confuse them
        let temperature = ConvertTemperature.celsiusToFarenheit(celsiusReading);
        temperature = Math.round((temperature * 10));
        temperature = temperature / 10;
        return temperature;
    }

    onACLConnect(peripheral: any): void {
        let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
        this.writeForacareThermometerCharacteristic(peripheral, dataCount, false);
    }

    writeForacareThermometerCharacteristic(peripheral: any, data: any, isClearData: boolean): void {
        let peripheralId = peripheral.id;
        let peripheralName = peripheral.name;
        this.ble.write(peripheralId, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, data.buffer).then(
            (res) => {
                // do not want to call this from ACL Connect call for IR20B as this was causing multiple parallel requests and resulting parallel notifications from device
                if (!peripheralName.includes('IR20') && !isClearData) {
                    this.onTemperatureMetricMeasurementChange(data.buffer, 'temperature', peripheral);
                }
            },
            (error) => {
                this.logger.phic.error('Failed to write characteristic from ' + peripheral.name, error);
            });
    }

    /**
     * Foracare documentation requires a check sum [1...n-1]. Checksum appends as the last byte of the transmission
     * you get n bytes, sum of the first n-1 bytes, see if the answer is the same as the last byte, etc
     * This is so that we get our full 8 byte check.
     */
    addCheckSum(bytes: number[]): ArrayBuffer {
        let out = new Uint8Array(bytes.length + 1);
        let checksum = 0;
        for (let i = 0; i < bytes.length; i++) {
            checksum += (bytes[i] & 0xFF);
            out[i] = bytes[i];
        }
        out[out.length - 1] = checksum;
        return out;
    }
}
