import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {EducationPage} from './education.page';
import {EducationConditionPage} from './entry-pages/education-condition.page';
import {EducationVideosPage} from './videos/education-videos.page';

const routes: Routes = [
    {
        path: 'condition',
        component: EducationConditionPage,
        children: []
    },
    {
        path: 'video',
        component: EducationVideosPage,
        children: []
    },
    {
        path: '',
        component: EducationPage,
        children: []
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EducationPageRoutingModule {}
