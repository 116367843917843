import {Injectable} from '@angular/core';
import {KnoxIntentService} from './knox-intent.service';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {BuildUtility} from '@hrs/utility';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';
import {KnoxManageService} from '../../services/knox/knox-manage.service';

@Injectable({
    providedIn: 'root',
})
export class DeviceIDIntentService extends KnoxIntentService {
    private readonly logger = getLogger('DeviceIDIntentService');
    public static ACTION_GET_IMEI = 'com.hrs.intent.action.GET_IMEI';
    public static ACTION_GET_IMEI_RESULT = 'com.hrs.intent.action.GET_IMEI_RESULT';
    imei: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(
        broadcaster: Broadcaster,
        platform: Platform,
        private readonly knoxManageService: KnoxManageService
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        await this.registerListeners();
    }

    // It requests the IMEI if it's Android 11 or above. Needs the serial number if Android 10
    protected async registerListeners(): Promise<void> {
        if (!BuildUtility.isHRSTab()) return;

        super.registerListener(DeviceIDIntentService.ACTION_GET_IMEI_RESULT, true).subscribe((event) => {
            this.logger.phic.debug('Listener for IMEI Intent', event);
            this.imei.next(event.imei);
        });

        if (!this.knoxManageService.enabled) {
            super.registerListener(DeviceIDIntentService.ACTION_GET_IMEI_RESULT, true).subscribe((event) => {
                this.logger.debug('Listener for IMEI Intent', event);
                this.imei.next(event.imei);
            });

            await this.getDeviceIMEI();
        }
    }

    public getDeviceIMEI(): Promise<boolean> {
        return super.sendIntent(DeviceIDIntentService.ACTION_GET_IMEI, true);
    }
}
