import {Injectable} from '@angular/core';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {KnoxIntentService} from './knox-intent.service';
import {BuildUtility} from '@hrs/utility';
import {BehaviorSubject} from 'rxjs';
import {getLogger} from '@hrs/logging';
import {KnoxManageService} from 'src/app/services/knox/knox-manage.service';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class PCKSVersionIntentService extends KnoxIntentService {
    private readonly logger = getLogger('PCKSVersionIntentService');
    private static ACTION_GET_VERSION = 'com.hrs.intent.action.PCKS_VERSION';
    private static ACTION_VERSION_RESULT = 'com.hrs.intent.action.PCKS_VERSION_RESULT';
    version: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(
        broadcaster: Broadcaster,
        platform: Platform,
        private readonly knoxManageService: KnoxManageService
    ) {
        super(broadcaster, platform);
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        await this.registerListeners();
    }

    protected async registerListeners(): Promise<void> {
        if (!BuildUtility.isHRSTab()) {
            return;
        }

        if (!this.knoxManageService.enabled) {
            super.registerListener(PCKSVersionIntentService.ACTION_VERSION_RESULT, true).subscribe((event) => {
                const version = event.version;
                this.version.next(version);
            });
        }

        await this.getPCKSVersion();
    }

    public async getPCKSVersion(): Promise<void> {
        const knoxPluginEnabled = this.knoxManageService.enabled;
        this.logger.trace(`getPCKSVersion() knoxPluginEnabled = ${knoxPluginEnabled}`);
        if (this.knoxManageService.enabled) {
            this.version.next(this.knoxManageService.knoxVersion);
        } else {
            await super.sendIntent(PCKSVersionIntentService.ACTION_GET_VERSION, true);
        }
    }
}
