import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AdminPage} from './admin.page';
import {AdminAccessComponent} from './admin-access/admin-access.component';
import {DomainSettingsComponent} from './domain-settings/domain-settings.component';
import {ComponentLibraryModule} from '@hrsui/angular';
import {AdminUserService} from '../services/user/admin-user.service';
import {AdminGatewayApiService} from '../services/user/admin-gateway-api.service';
import {AdminTokenService} from '../services/user/admin-token.service';
import {HRSTabletModule} from '../hrs-tablet/hrs-tablet.module';
import {BuildUtility} from '@hrs/utility';

const routes: Routes = [
    {
        path: 'admin',
        component: AdminPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        BuildUtility.isHRSTab() ? HRSTabletModule : []
    ],
    providers: [
        AdminGatewayApiService,
        AdminUserService,
        AdminTokenService
    ],
    declarations: [
        AdminPage,
        AdminAccessComponent,
        DomainSettingsComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AdminModule {
}
