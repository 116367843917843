import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-skeleton-content',
    templateUrl: './skeleton-content.component.html',
    styleUrls: ['./skeleton-content.component.scss'],
})
export class SkeletonContentComponent {
  @Input() public rowCount: number = 5;
}
