import {NgZone, Injectable} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {HRSStorage} from '../../storage/storage';
import {ModalService} from '@hrs/providers';
import moment from 'moment';
import {getLogger} from '@hrs/logging';
import {HRSFirebaseAnalytics, HRSFirebaseEvents, HRSFirebaseParams} from '../../analytics/firebaseanalytics.service';

export let WELCH_BP_SCALE_PAIRING_PASS_KEY = 'welchBPPass';
export let WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY = 'welchDevicePass';

@Injectable({
    providedIn: 'root',
})
export abstract class WelchService {
    private readonly baseLogger = getLogger('WelchService');
    public BASE_WELCH_SERVICE = '';
    public BASE_WELCH_METRIC_MEASUREMENT_CHAR = '';
    public BASE_DEVICE_NAME = '';
    public BASE_DEVICE_PAIRING_NAME = '';
    public BASE_WELCH_PASSWORD_KEY = '';

    // WELCH services and characteristics
    public SERVICE_DEVICE_INFO = '180A';
    public CHAR_DOWNLOAD = '8A81';
    public CHAR_UPLOAD = '8A82';
    public CHAR_FIRMWARE_REVISION = '2A26';
    public CHAR_HARDWARE_REVISION = '2A27';
    public CHAR_MANUFACTURER_NAME = '2A29';
    public CHAR_MODEL_NUMBER = '2A24';
    public CHAR_SERIAL_NUMBER = '2A25';
    public CHAR_SOFTWARE_REVISION = '2A28';
    public CHAR_WEIGHT_MEASUREMENT = '8A21';
    public CHAR_BATTERY_STATUS = '8A22';

    // Welch Commands and request messages
    /* Pass command*/
    public CMD_PASS = 0xA0;

    /* Random no command*/
    public CMD_RANDOM_NO = 0xA1;

    /* Verification code command*/
    public CMD_VERIFICATION_CODE = 0x20;

    /* Time offset command*/
    public CMD_TIME_OFFSET = 0x02;

    /* Disconnect command*/
    public CMD_DISCONNECT = 0x22;

    /* Account id command*/
    public CMD_ACCOUNT_ID = 0x21;

    public accountID = 7654321;

    public abstract setBluetoothParams(): void;
    public abstract enableCharacteristics(peripheral: any): void;

    gotRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    constructor(
        protected ble: BLE,
        protected storage: HRSStorage,
        protected ngZone: NgZone,
        protected modalService: ModalService,
        private fbAnalytics: HRSFirebaseAnalytics
    ) {

    }

    /**
      * This will execute the next steps after device is connected.
      * The device broadcasts its name with flag appended at the first position as : Flag: ‘0’-Normal Mode ‘1’-Pairing Mode [example Normal='0BP100' and Pairing='1BP100']
      * If the pairing has not been done, it will execute the pairing mode based on the device name obtained
      * If pairing has happened, it will execute the reading/normal mode where measurements will be read from the device
      * @param peripheral
      * @param onMetricUpdateCallback
    */
    public onPeripheralConnected(peripheral: any): void {
        this.setBluetoothParams();

        if (peripheral.name.includes(this.BASE_DEVICE_PAIRING_NAME)){
            this.baseLogger.phic.debug('Welch Pairing Mode: Performing pairing device sequence ' + peripheral.name);
            this.readDeviceInfo(peripheral, this.CHAR_MANUFACTURER_NAME);
        } else {
            this.baseLogger.phic.debug('Welch Normal Mode: Performing Vitals Data Communication ' + peripheral.name);
            this.enableCharacteristics(peripheral);
        }
    }

    /**
     * Read all the device information.
     * Once all the information is read, execute the next step of Enabling characteristics for notification
     * @param peripheral
     * @param characteristic
    */
    public readDeviceInfo(peripheral: any, characteristic: string): void {
        this.ble.read(peripheral.id, this.SERVICE_DEVICE_INFO, characteristic).then(
            (data) => {
                this.baseLogger.phic.info(peripheral.name + ' Reading device information for ' + characteristic);

                if (characteristic.match(this.CHAR_MANUFACTURER_NAME)){
                    this.onReadDeviceInfo(data);
                    this.readDeviceInfo(peripheral, this.CHAR_FIRMWARE_REVISION);
                } else if (characteristic.match(this.CHAR_FIRMWARE_REVISION)){
                    this.onReadDeviceInfo(data);
                    this.readDeviceInfo(peripheral, this.CHAR_HARDWARE_REVISION);
                } else if (characteristic.match(this.CHAR_HARDWARE_REVISION)){
                    this.onReadDeviceInfo(data);
                    this.readDeviceInfo(peripheral, this.CHAR_SOFTWARE_REVISION);
                } else if (characteristic.match(this.CHAR_SOFTWARE_REVISION)){
                    this.onReadDeviceInfo(data);
                    this.readDeviceInfo(peripheral, this.CHAR_MODEL_NUMBER);
                } else if (characteristic.match(this.CHAR_MODEL_NUMBER)){
                    this.onReadDeviceInfo(data);
                    this.readDeviceInfo(peripheral, this.CHAR_SERIAL_NUMBER);
                } else if (characteristic.match(this.CHAR_SERIAL_NUMBER)) {
                    this.onReadDeviceInfo(data);
                    this.baseLogger.phic.info(peripheral.name + ', read the device serial number, next start enabling characteristics');

                    if (peripheral.name.includes(this.BASE_DEVICE_PAIRING_NAME)){
                        this.enableCharacteristics(peripheral);
                    }
                }
            },
            (err) => {
                this.baseLogger.phic.error(peripheral.name + 'Failed to read device info characteristic' + characteristic, err);
            }
        );
    }

    /**
     * Parse and read the device information data recieved from read request
     * @param data
     */
    public onReadDeviceInfo(data: ArrayBuffer): void {
        let arrayBuffer = new Uint8Array(data);
        var characteristicVal = '';
        var length = arrayBuffer.length;
        for (var i = 0; i < length; i++) {
            characteristicVal += String.fromCharCode(arrayBuffer[i]);
        }
        // for now just logging the information
        this.baseLogger.phic.info('Read the device info ' + characteristicVal);
    }

    /**
     * The App will send the Account ID message to the device. This will contain the
     * command for account id and Account ID [4 bytes] in little endian. The Account ID should be unique for each application.
     * @param peripheral
     */
    public sendAccountId(peripheral: any): void {
        var accountIdBytes = this.getMergedArrayBuffers(this.CMD_ACCOUNT_ID, this.accountID);
        this.ble.write(peripheral.id, this.BASE_WELCH_SERVICE, this.CHAR_DOWNLOAD, accountIdBytes.buffer).then(
            (data) => {
                this.baseLogger.phic.log('Success callback for sending account id ' + peripheral.name);
            },
            (error) => {
                this.baseLogger.phic.error('Account id write failed for ' + peripheral.name, error);
            });
    }

    /**
     * This will start the indication for Information transfer from Device to App
     * Here, the app will get password and random number which is required for pairing/reading flow
     * @param peripheral
     */
    public enableUploadDataTransferChar(peripheral: any): void {
        this.ble.startNotification(peripheral.id, this.BASE_WELCH_SERVICE, this.CHAR_UPLOAD).subscribe(
            (data) => {
                let arrayBuffer = new Uint8Array(data[0]);
                if (arrayBuffer[0] == this.CMD_PASS){
                    this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Got Password command, will send account id now');
                    const password = this.getParsedValueOfResult(arrayBuffer);
                    this.storage.set(this.BASE_WELCH_PASSWORD_KEY, password);
                    this.sendAccountId(peripheral);
                    // welch allyn pairs as we received the password from device
                    let peripheralName = peripheral ? peripheral.name : '';
                    let peripheralType = '';
                    if (peripheralName.includes('SC')) {
                        peripheralType = 'weight';
                    } else if (peripheralName.includes('BP')){
                        peripheralType = 'bloodpressure';
                    }
                    let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, peripheralType);
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_PAIRING_SUCCESS,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                            [HRSFirebaseParams.RSSI]: peripheral.rssi});
                } else if (arrayBuffer[0] == this.CMD_RANDOM_NO) {
                    this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Got Random number, will send verification code now');
                    this.sendVerificationCode(peripheral, arrayBuffer);
                } else {
                    this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Some other case ' + arrayBuffer[0]);
                }
            }
        );
    }

    /**
     * The App will calculate the Verification Code message and will send to the Device.
     * Password and Random Number received in previous sections must be retained to calculate the Verification Code
     * @param peripheral
     * @param arrayBuffer
     */
    public sendVerificationCode(peripheral: any, arrayBuffer: Uint8Array): void {
        var fetchedPassword;
        var randomNumber = this.getParsedValueOfResult(arrayBuffer);
        this.storage.get(this.BASE_WELCH_PASSWORD_KEY).then((pass) => {
            fetchedPassword = pass;
            var xorResult = fetchedPassword ^ randomNumber;
            // write verification code
            this.writeVerificationCode(peripheral, xorResult);
        });
    }

    /**
     * Write the verification code, If the Verification code is correct, then it will proceed to the next sequence.
     * Otherwise, the device will terminate the Bluetooth connection.
     * @param peripheral
     * @param xorResult
     */
    public writeVerificationCode(peripheral: any, xorResult: number): void {
        var xorBytes = this.intToBytesLittleEndian(xorResult);
        var resultCmd = new Uint8Array([this.CMD_VERIFICATION_CODE, xorBytes[0], xorBytes[1], xorBytes[2], xorBytes[3]]);
        this.ble.write(peripheral.id, this.BASE_WELCH_SERVICE, this.CHAR_DOWNLOAD, resultCmd.buffer).then(
            (data) => {
                let arrayBuffer = new Uint8Array(data);
                this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Got result of sending verification code, send time offset now' + arrayBuffer.length);
                this.sendTimeOffset(peripheral);
            },
            (error) => {
                this.baseLogger.phic.error('Welch ' + peripheral.name + 'Failed to write verification code', error);
            });
    }

    /**
     * The App will send the Time Offset message to the Device. This will conatin the command
     * for time offset and the time offset in seconds [4 bytes, Little Endian].
     * Time offset is the number of seconds from base time. The base time is always 0:00:00 1st Jan 2010.
     * @param peripheral
     */
    public sendTimeOffset(peripheral: any): void {
        var timeoffsetVal = Math.round(this.getCurrentTimeToSend());
        var timeOffsetBytes = this.intToBytesLittleEndian(timeoffsetVal);
        var resultCmd = new Uint8Array([this.CMD_TIME_OFFSET, timeOffsetBytes[0], timeOffsetBytes[1], timeOffsetBytes[2], timeOffsetBytes[3]]);
        this.ble.write(peripheral.id, this.BASE_WELCH_SERVICE, this.CHAR_DOWNLOAD, resultCmd.buffer).then(
            (data) => {
                this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Result of sending time offset, now send disconnect' );

                if (peripheral.name.includes( this.BASE_DEVICE_PAIRING_NAME)){
                    this.sendDisconnect(peripheral);
                } else {
                    this.baseLogger.phic.debug('Welch ' + peripheral.name + ' Has already paired thus not executing disconnect flow');
                }
            },
            (error) => {
                this.baseLogger.phic.error('Welch ' + peripheral.name + ' Failed to write time offset', error);
            });
    }

    /**
     * The App will send the Enable Disconnection message to the Device.
     * The App must send the Enable Disconnection in sequence or pairing will not be successful.
     * Also, the App is required to send the enable disconnect ACK message to the Device to
     * indicate that the measurement is received.
     * @param peripheral
     */
    public sendDisconnect(peripheral: any): void {
        var resultCmd = new Uint8Array([this.CMD_DISCONNECT]);
        this.ble.write(peripheral.id, this.BASE_WELCH_SERVICE, this.CHAR_DOWNLOAD, resultCmd.buffer).then(
            (data) => {
                this.baseLogger.phic.debug('Success callback for sending disconnect');
            },
            (error) => {
                this.baseLogger.phic.error('Failed to send disconnect command', error);
            });
    }

    // --------- Utility helper methods ----------//

    /**
    * Method to calculate the time offset which is to be send to the device while pairing and reading
    * Time offset is the number of seconds from base time. The base time is always 0:00:00 1st Jan 2010.
    * @returns
    */
    public getCurrentTimeToSend(): number {
        const gregorianTime = moment(new Date(2010, 0, 1, 0, 0));
        const currentTime = moment();
        const deltaTimeInSeconds = ((currentTime.valueOf() - gregorianTime.valueOf()) / 1000);
        this.baseLogger.phic.info('GregorianTime: ' + gregorianTime +
  ' currentTime: ' + currentTime + ' deltaTimeInSeconds: ' + deltaTimeInSeconds);
        return deltaTimeInSeconds;
    }

    /**
     * The device requires data to be sent in little endian format. This is a utlity method
     * which converts the int value to little endian bytes
     * @param value
     * @returns
     */
    public intToBytesLittleEndian(value: number): Uint8Array {
        const bytes = new Uint8Array(4);
        bytes[0] = (value & 0xff);
        bytes[1] = ((value >> 8) & 0xff);
        bytes[2] = ((value >> 16) & 0xff);
        bytes[3] = ((value >> 24) & 0xff);
        return bytes;
    }

    /**
     * Utility method to parse the password and random number data obtained from
     * blood pressure monitor device.
     * @param arrayBuffer
     * @returns
     */
    public getParsedValueOfResult(arrayBuffer: Uint8Array): number {
        if (arrayBuffer.length != 5){
            return 0;
        } else {
            return (arrayBuffer[4] & 0xFF) << 24 | (arrayBuffer[3] & 0xFF) << 16 | (arrayBuffer[2] & 0xFF) << 8 | arrayBuffer[1] & 0xFF;
        }
    }

    /**
     * This is a utlity method which combines the Command and its data into
     * a single buffer object to be sent to device
     * @param cmd
     * @param data
     * @returns
     */
    public getMergedArrayBuffers(cmd: any, data: any): Uint8Array {
        var accountIdCmdBytes = new Uint8Array([cmd]);
        var accountIdInBytes = this.intToBytesLittleEndian(data);
        var mergedBuffer = new Uint8Array(accountIdInBytes.byteLength + accountIdCmdBytes.byteLength);
        mergedBuffer.set(accountIdCmdBytes, 0);
        mergedBuffer.set(accountIdInBytes, accountIdCmdBytes.byteLength);
        return mergedBuffer;
    }

    /**
     * BP monitor and scale provides historical data also, we need to just keep the latest reading for records.
     * This method will ignore the readind if timestamp is not present or it is an old one
     * @param readingTime
     * @param maxTimeDiffDurationInSeconds
     * @returns whether reading is recent
     */
    public isMostRecentReading(readingTime: number, maxTimeDiffDurationInSeconds: number): boolean {
        if (readingTime){
            const currentTime = Date.now();
            const timeDiff = ((currentTime.valueOf() - readingTime) / 1000);
            return (timeDiff <= maxTimeDiffDurationInSeconds);
        } else { // ignore reading if time stamp is not present
            return false;
        }
    }

    /**
     * With the measurement data, the peripheral also sends a timestamp attached to a reading
     * This is the time in seconds passed from the base time 0:00:00 1st Jan 2010
     * This is a utlity method which helps to normalize the time obtained for further calculations
     * @param receivedTimeInSeconds
     * @returns
     */
    public getNormalizedTime(receivedTimeInSeconds: number): number {
        const baseTime = moment({
            year: 2010, month: 0, day: 1,
        });
        // Add time received from device to base offset to get actual reading time
        const gregorianTime = moment(baseTime.valueOf() + (receivedTimeInSeconds * 1000));
        return gregorianTime.valueOf();
    }

    /**
      * Helper utility method to convert array buffer to hex
      */
    protected buf2hex(buffer: ArrayBuffer): string {
        const byteArray = new Uint8Array(buffer);
        const hexParts = [];
        for (let i = 0; i < byteArray.length; i++) {
            const hex = byteArray[i].toString(16);
            const paddedHex = ('00' + hex).slice(-2);
            hexParts.push(paddedHex);
        }

        // join all the hex values of the elements into a single string
        return hexParts.join(' ');
    }
}
