import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {
    OverlayService,
    SupportCallService
} from '@patient/providers';
import {OverlayRef} from '../../hrs-overlay';

@Component({
    selector: 'app-pin-dialog',
    templateUrl: './pin-dialog.page.html'
})
export class PinDialogPage implements OnInit {
    private verifyPin: string;

    @Input() setPasscodeMode: boolean;

    toast: any;
    title: string;
    text: string;
    prompt: string;
    errorMessage: string = 'SECURITY_PIN_VERIFY_ERROR';
    pin: string;
    pinForm = new UntypedFormGroup({
        pinInput: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern('[0-9]*')
        ])
    });

    get pinInput() {
        return this.pinForm.get('pinInput');
    }

    constructor(
        private overlay: OverlayService,
        private overlayRef: OverlayRef,
        private supportCallService: SupportCallService,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        if (this.setPasscodeMode) {
            this.initSetPasscodeMode();
        } else {
            this.initUnlockMode();
        }
    }

    enterPin() {
        this.pin = this.pinForm.get('pinInput').value;

        if (this.setPasscodeMode) {
            if (!this.verifyPin) {
                this.initVerifyMode();
            } else if (this.verifyPin === this.pin) {
                this.overlayRef.dismiss({data: this.pin});
            } else {
                this.pinVerifyErrorToast();
                this.initSetPasscodeMode();
            }
        } else {
            this.overlayRef.dismiss({data: this.pin});
        }
    }

    private async pinVerifyErrorToast() {
        this.toast = await this.overlay.openToast({
            message: this.translate.instant(this.errorMessage),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'pin_toast'
        });
        return await this.toast;
    }

    private initSetPasscodeMode() {
        this.title = 'SECURITY_PIN_CREATE_TITLE';
        this.text = 'SECURITY_PIN_CREATE_TEXT';
        this.prompt = 'SECURITY_PIN_CREATE_PROMPT';
        this.pinForm.reset();
        this.verifyPin = '';
        this.pin = '';
    }

    private initVerifyMode() {
        this.prompt = 'SECURITY_PIN_VERIFY_PROMPT';
        this.pinForm.reset();
        this.verifyPin = this.pin;
        this.pin = '';
    }

    private initUnlockMode() {
        this.title = 'SECURITY_PIN_UNLOCK_TITLE';
        this.text = 'SECURITY_PIN_UNLOCK_TEXT';
        this.pin = '';
    }

    /**
     * Start support call
     */
    initSupportCall(): void {
        this.supportCallService.initSupportCall();
    }
}
