import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2
} from '@angular/core';
import {OverlayComponent} from '../overlay.component';
import {DomService} from '../../services/overlay/dom.service';
import {OverlayConfig, OverlayRef} from '../overlay';
import {ModalHeaderButton} from '@hrsui//core/dist/types/components/modal/modal.interface';

@Component({
    selector: 'modal-overlay',
    template: `
        <!-- These bindings are dynamically set. DO NOT ADJUST OR REMOVE -->
        <!-- To provide new values, set in OverlayOpts.config upon overlay creation in parent -->
        <!-- A full list of overlay options can be found in ./overlay.ts#OverlayConfig -->
        <hrs-modal
            [headerText]="title"
            [canMinimize]="canMinimize"
            [headerButtons]="headerButtons"
            [fullScreen]="fullScreen"
            [qa]="qa"
        >
            <ng-template appInsertion></ng-template>
        </hrs-modal>
    `
})
export class ModalTemplateComponent extends OverlayComponent {
    public isMinimized: boolean = false;
    public canMinimize: boolean = false;
    public fullScreen: boolean = false;
    public hasNoHeaderButton: boolean = false;
    public headerButtonHandler: Function;
    public headerButtons: ModalHeaderButton[];
    public title: string;
    public minClass: string;
    public qa: string;

    constructor(
        cd: ChangeDetectorRef,
        dom: DomService,
        el: ElementRef,
        renderer: Renderer2,
        config: OverlayConfig,
        ref: OverlayRef,
    ) {
        super(cd, dom, el, renderer, config, ref);
    }
}

/*  the ModalTemplateOptions should only include those properties within ModalTemplateComponent
    that are directly bound to hrs-modal in the template
*/
export interface ModalTemplateOptions {
    canMinimize?: boolean,
    headerButtons?: ModalHeaderButton[],
    title?: string,
    fullScreen?: boolean
}
