import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {OverlayService} from '../../services/overlay/overlay.service';
import {Subscription} from 'rxjs';
import {AudioLoopConfig, AudioPlayType, AudioRingType, AudioService} from '../../services/audio/audio.service';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {OverlayRef} from '../../hrs-overlay';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {BuildUtility} from '@hrs/utility';
import {CommunicationService, ModalService, VoiceCallStatus, CallActionOrigin} from '@hrs/providers';
import {getLogger} from '@hrs/logging';

declare var Twilio: any;

export interface VoiceCallLeftData {
    hrsid: string;
    callid: string;
}

export interface OpentokCallData {
    callid: string;
    access: string;
    calltype?: string;
    num?: string;
}

export interface VoiceCallInitializationData {
    calleeName: string;
    callId: string;
    answer: boolean;
    access: string;
}

@Component({
    selector: 'app-voice',
    templateUrl: './voice.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoicePage {
    private readonly logger = getLogger('VoicePage');
    public contentConfig: ContentDetail[] = [{
        comms: {
            type: 'voice',
            status: '',
            participant: ''
        }
    }];
    private callAccepted: boolean = false;
    public calling: boolean = false;
    public callData: VoiceCallInitializationData = {} as any;
    public isHRSTab: boolean = BuildUtility.isHRSTab();
    public isSupportCall: boolean = false;
    public primaryButtonText: string = 'CALL_BUTTON';
    private didLocalUserLeave: boolean = false;
    public modalClosing: boolean;

    private backAction: Subscription;
    private callerLeftEvent: Subscription;
    private endCallEvent: Subscription;
    private exitCallEnterNewEvent: Subscription;
    private nativeAudioEventSubscription: Subscription; // listen for LOOP end event to timeout call

    constructor(
        private audio: AudioService,
        private communication: CommunicationService,
        private modalService: ModalService,
        private overlay: OverlayService,
        private overlayRef: OverlayRef,
        private platform: Platform,
        private ref: ChangeDetectorRef,
        private taskTrackingService: TaskTrackingService,
        private translateService: TranslateService,
    ) {
        this.modalService.setModalStatus('VoicePage', true);
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    ngOnInit() {
        this.logger.debug(`ngOnInit()`);
        // add handler so modal isn't closed accidentally during a call by the hardware back button
        this.backAction = this.platform.backButton.subscribeWithPriority(1, () => {
            this.logger.debug(`ngOnInit() -> platform.backButton fired!`);
            this.dismiss(true);
        });
        let currentStatus = '';
        const currentParticipant = this.isSupportCall ?
            this.translateService.instant('CALL_SUPPORT.PARTICIPANT') :
            (this.callData.calleeName || this.translateService.instant('GENERIC.CLINICIAN'));

        // incoming call
        if (this.callData.callId) {
            this.logger.debug(`ngOnInit() incoming call`);
            this.communication.activeCallInitiationType = CallActionOrigin.REMOTE;
            currentStatus = this.translateService.instant('RINGING');
            this.primaryButtonText = 'ACCEPT_BUTTON';
            if (!this.callData.answer) {
                this.logger.debug(`ngOnInit() -> audio.startPlayback()`);
                this.callAccepted = false;
                const audioConfig: AudioLoopConfig = {
                    duration: 90 * 1000 // loop for 90 seconds
                };
                // setup to listen for loop end event and terminate incoming call when it ends
                this.nativeAudioEventSubscription = this.audio.filterLoopEnd(AudioRingType.RING, audioConfig.duration).subscribe({
                    next: () => {
                        if (!this.callAccepted) {
                            this.dismiss();
                            this.nativeAudioEventSubscription.unsubscribe();
                            this.nativeAudioEventSubscription = null;
                        }
                    }
                });
                this.audio.startPlayback(AudioRingType.RING, AudioPlayType.LOOP, audioConfig);
            }
        } else {
            this.communication.activeCallInitiationType = CallActionOrigin.LOCAL;
        }

        this.contentConfig = [{
            comms: {
                type: 'voice',
                status: currentStatus,
                participant: currentParticipant
            }
        }];

        // tapped with app in background
        if (this.callData.answer) {
            this.logger.debug(`ngOnInit() tapped with app in background`);
            this.initializeVoiceCall();
        }

        this.initNotificationListeners();
        this.taskTrackingService.startTracking('voice-call', this.isSupportCall ? 'Opened voice call page to start support call' : 'Opened voice call page');
    }

    ngOnDestroy() {
        this.logger.debug(`ngOnDestroy()`);
        // user may not have 'Accept'ed the call
        this.stopRinging();
        // Need to end the call if the modal is dismissed from the header's Back button.
        if (this.calling) {
            this.logger.debug(`ngOnDestroy() modal dismissed from the header's Back button, end the call`);
            this.endCall(false);
        }
        this.modalService.setModalStatus('VoicePage', false);
        this.taskTrackingService.stopTracking();
        // removes hardware back button handler
        if (this.backAction) this.backAction.unsubscribe();
        // unsubscribe from listeners
        if (this.endCallEvent) this.endCallEvent.unsubscribe();
        if (this.exitCallEnterNewEvent) this.exitCallEnterNewEvent.unsubscribe();
        if (this.callerLeftEvent) this.callerLeftEvent.unsubscribe();
        if (this.nativeAudioEventSubscription) this.nativeAudioEventSubscription.unsubscribe();
    }

    private initNotificationListeners(): void {
        this.logger.debug(`initNotificationListeners()`);
        // the person we're calling missed or ignored the call

        this.endCallEvent = this.communication.endVoiceCall$.subscribe((data: any) => {
            this.logger.debug(`initNotificationListeners() -> communication.endVoiceCall`);
            if (
                data &&
                (data.action === 'call_unanswered' || data.action === 'call_declined')
            ) {
                this.endCall();
            }
        });

        this.callerLeftEvent = this.communication.callerLeft$.subscribe((data: VoiceCallLeftData) => {
            const calling = this.calling;
            const callerLeft = data.callid === this.callData.callId;
            this.logger.debug(`initNotificationListeners() -> communication.callerLeft`, {calling, callerLeft});
            // case 1: if the call has not been answered and the callid's match, end the call - called when the call is still ringing but the caller has hung up
            // case 2: Ends the call when call is placed by PCM/T and declined by clinician.
            if (callerLeft) {
                this.endCall();
            }
        });
        // End current call and join incoming call
        this.exitCallEnterNewEvent = this.communication.exitVoiceCallEnterNew$.subscribe(() => {
            this.logger.debug(`initNotificationListeners() -> communication.exitVoiceCallEnterNew`);
            this.endCall();
        });
    }

    /**
     *  Toggle button from place call to end call
     */
    public toggleCall(didLocalUserLeave: boolean = false): void {
        this.logger.debug(`toggleCall() leftLocally = ${didLocalUserLeave}`);
        this.didLocalUserLeave = didLocalUserLeave;
        if (this.calling) {
            this.logger.debug(`toggleCall() -> endCall()`);
            this.endCall();
        } else {
            this.logger.debug(`toggleCall() user accepted the call`);
            // user 'Accepted the call
            this.callAccepted = true;
            this.stopRinging();
            if (!this.modalClosing) {
                this.logger.debug(`toggleCall() -> initializeVoiceCall()`);
                this.initializeVoiceCall();
            }
        }
    }

    /**
     * Start voice call
     */
    private initializeVoiceCall(): void {
        this.logger.debug(`initializeVoiceCall()`);
        this.calling = true;
        this.didLocalUserLeave = false;
        this.setCallStatus(VoiceCallStatus.CONNECTING);
        this.ref.detectChanges();

        if (this.callData.callId) {
            this.logger.debug(`initializeVoiceCall() -> acceptIncomingCall()`);
            this.acceptIncomingCall();
        } else {
            this.logger.debug(`initializeVoiceCall() -> initializeOutgoingCall()`);
            this.initializeOutgoingCall();
        }
    }

    private initializeOutgoingCall(): void {
        this.logger.debug(`initializeOutgoingCall()`);
        const isSupportCall = this.isSupportCall ? 'techsupport' : null;
        this.communication.activeCallInitiationType = CallActionOrigin.LOCAL;
        this.communication.initializeOutgoingVoiceCall(isSupportCall).subscribe(
            {
                next: (res: any) => {
                    this.logger.debug(`initializeOutgoingCall() next`, res);
                    this.communication.notifyVoiceCallStartedLocally(res);
                    this.connectCall(res);
                },
                error: (err) => {
                    this.calling = false;
                    this.setCallStatus(VoiceCallStatus.CALL_FAILED);
                    this.ref.detectChanges();
                    this.logger.phic.error('Error initializing voice call', err);
                }
            }
        );
    }

    /**
     * Get incoming voice call token
     */
    private acceptIncomingCall(): void {
        this.logger.debug(`acceptIncomingCall()`);
        const data = {callid: this.callData.callId, access: this.callData.access};
        this.communication.notifyVoiceCallAnsweredLocally(this.callData);
        this.connectCall(data);
    }

    /**
     * Connect to voice call
     * @param data
     */
    private connectCall(data: OpentokCallData): void {
        this.logger.debug(`connectCall()`, {data});
        this.setCallStatus(VoiceCallStatus.CALL_CONNECTED);
        let twilioParams;

        if (data?.callid) this.callData.callId = data.callid;
        if (data?.access) this.callData.access = data.access;

        Twilio.TwilioVoiceClient.onClientInitialized(() => {
            const calltype = data.calltype;
            this.logger.phic.debug(`connectCall() -> TwilioVoiceClient.onClientInitialized`, {calltype});
            if (calltype === 'phone') {
                twilioParams = {
                    'To': 'phone:' + data.num,
                    'calltype': 'startcall'
                };
            } else {
                twilioParams = {
                    'To': 'conference:' + data.callid,
                    'calltype': 'startcall',
                };
            }
            this.logger.debug(`connectCall() -> TwilioVoiceClient.call`, data.access, twilioParams);
            Twilio.TwilioVoiceClient.call(data.access, twilioParams);
        });

        this.logger.debug(`connectCall() -> TwilioVoiceClient.initialize`, data.access);
        Twilio.TwilioVoiceClient.initialize(data.access);

        // Handle Errors
        Twilio.TwilioVoiceClient.onError((err) => {
            this.logger.phic.error(`connectCall() -> TwilioVoiceClient.onError`, err);
            setTimeout(() => {
                this.logger.debug(`connectCall() -> TwilioVoiceClient.onError -> ensure that error is not overwritten by disconnect`);
                this.calling = false;
                this.setCallStatus(VoiceCallStatus.CALL_FAILED);
                this.ref.detectChanges();
            }, 1000); // to ensure that error is not overwritten by disconnect
        });

        // Handle Call Connection
        Twilio.TwilioVoiceClient.onCallDidConnect((call) => {
            // 'call' might have sensitive data so don't pass it to PHIC transport
            this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallDidConnect`, call);
            this.logger.phic.log('Successfully established call');
            setTimeout(() => {
                this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallDidConnect -> timeout`);
                this.setCallStatus(VoiceCallStatus.CALL_CONNECTED);
                this.ref.detectChanges();
            }, 0);
        });

        // Handle Call Disconnect
        Twilio.TwilioVoiceClient.onCallDidDisconnect((call) => {
            // 'call' might have sensitive data so don't pass it to PHIC transport
            this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallDidDisconnect`, call);
            this.logger.phic.log('Call Ended');
            setTimeout(() => {
                this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallDidDisconnect -> timeout`);
                this.calling = false;
                this.setCallStatus(VoiceCallStatus.CALL_ENDED);
                this.dismiss();
                this.ref.detectChanges();
            }, 0);
        });

        Twilio.TwilioVoiceClient.onCallInviteReceived(() => {
            this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallInviteReceived`);
            setTimeout(() => {
                this.logger.debug(`connectCall() -> TwilioVoiceClient.onCallInviteReceived -> timeout`);
                this.calling = true;
                this.setCallStatus(VoiceCallStatus.CALL_CONNECTED);
                this.ref.detectChanges();
            }, 0);
        });
    }

    /**
     * Stop voice call
     */
    private async endCall(dismiss: boolean = true): Promise<void> {
        this.logger.debug(`endCall()`, {dismiss});
        if (this.isNativePlatform) {
            this.logger.debug(`endCall() -> TwilioVoiceClient.disconnect()`);
            Twilio.TwilioVoiceClient.disconnect();
        }
        this.setCallStatus(VoiceCallStatus.CALL_ENDED);
        this.calling = false;

        if (dismiss) {
            await this.dismiss();
        } else {
            this.communication.voiceCallLeft(this.callData.callId);
        }
    }

    private setCallStatus(status: VoiceCallStatus): void {
        this.logger.debug(`setCallStatus()`, {status});
        this.communication.activeVoiceCallStatus = status;
        const translatedStatus = this.translateService.instant(status);

        switch (status) {
            case 'CALL_CONNECTED':
                // if the call connected, update the modal so it lets the user minimize it
                this.overlay.updateModal(this.overlayRef, {canMinimize: true});
                break;

            case 'CALL_ENDED':
                // if the call ended we will dismiss this modal; make sure other components,
                // such as FAB, are notified that the modal is no longer minimized
                const minimizeEvent = new CustomEvent('hrsMinimizeModal', {detail: false});
                window.dispatchEvent(minimizeEvent);
                break;

            default:
                this.logger.debug(`setCallStatus() default case!`);
                // if the call failed, make sure model is maximized and other components,
                // such as FAB, are notified
                const maximizeEvent = new CustomEvent('hrsMaximizeModal', {detail: true});
                window.dispatchEvent(maximizeEvent);
        }

        // We need to pass a new array to HRSContent for the component to detect the change in its content prop.
        this.contentConfig = [{comms: {...this.contentConfig[0].comms, status: translatedStatus}}];
    }

    public async dismiss(declined: boolean = false): Promise<OverlayRef> {
        const {calling, didLocalUserLeave} = this;
        const isIncomingCall = this.communication.isIncomingCall;
        this.logger.debug(`dismiss()`, {calling, didLocalUserLeave, declined, isIncomingCall});

        if (this.modalClosing) {
            this.logger.warn(`ignoring duplicate dismiss call`);
            return null;
        }

        if (calling) {
            return this.showEndCallPrompt();
        }

        if (isIncomingCall && declined) {
            this.logger.debug(`notifying backend that we are declining the call...`);
            this.communication.notifyVoiceCallDeclinedLocally(this.callData);
        } else if (didLocalUserLeave) {
            this.logger.debug(`notifying backend that we are leaving the call...`);
            this.communication.notifyVoiceCallEndedLocally(this.callData);
        }

        this.communication.voiceCallLeft(this.callData.callId);
        this.modalClosing = true;
        this.overlayRef.dismiss();
    }

    private showEndCallPrompt(): Promise<OverlayRef> {
        return this.overlay.openAlert({
            header: this.translateService.instant('END_CALL_TITLE'),
            message: [this.translateService.instant('END_CALL_MESSAGE')],
            buttons: [
                {
                    text: this.translateService.instant('END_CALL'),
                    role: 'cancel',
                    handler: () => {
                        this.endCall();
                    }
                }, {
                    text: this.translateService.instant('CONTINUE'),
                }
            ],
            qa: 'voice_call_alert'
        });
    }

    private stopRinging(): void {
        this.logger.debug(`stopRinging()`);
        this.audio.endPlayback(AudioRingType.RING);
    }
}
