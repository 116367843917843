import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {TabletGuard} from './tablet.guard';

const routes: Routes = [
    {path: '', canActivate: [AuthGuard], loadChildren: () => import('./main/main.module').then((m) => m.MainPageModule)},
    {path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule)},
    {path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomePageModule)},
    // {path: 'security-setup', loadChildren: './security/security-setup/security-setup.module#SecuritySetupPageModule'},
    {path: 'admin', canActivate: [TabletGuard], loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
