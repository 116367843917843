import {Injectable} from '@angular/core';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {KnoxIntentService} from './knox-intent.service';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class RemoteDiagnosticsIntentService extends KnoxIntentService {
    private static ACTION_POINTER_EVENT = 'com.hrs.intent.action.POINTER_EVENT';
    private static ACTION_KEY_EVENT = 'com.hrs.intent.action.KEY_EVENT';

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected registerListeners(): void {
        return null;
    }

    /* eslint-disable camelcase */
    public sendPointerEvent(down_x, down_y, up_x, up_y, duration, width, height): Promise<boolean> {
        const extras = {down_x, down_y, up_x, up_y, duration, width, height};
        return super.sendIntent(RemoteDiagnosticsIntentService.ACTION_POINTER_EVENT, true, extras);
    }
    /* eslint-enable camelcase */

    public sendKeyEvent(keycode): Promise<boolean> {
        const extras = {keycode};
        return super.sendIntent(RemoteDiagnosticsIntentService.ACTION_KEY_EVENT, true, extras);
    }
}
