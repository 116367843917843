import {Pipe, PipeTransform} from '@angular/core';
import {range} from 'lodash';

@Pipe({
    name: 'range'
})
export class RangePipe implements PipeTransform {
    public transform(start: number, end?: number, step?: number): number[] {
        return range(start, end, step);
    }
}
