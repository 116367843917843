import {Component} from '@angular/core';
import {ModalService} from '@hrs/providers';
import {TextToSpeechService} from '@patient/providers';
import {TemplateDevice} from '../generic-device/types/template-devices';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-devices-instructions-pairing',
    templateUrl: './device-instructions-pairing.page.html',
    styleUrls: ['./device-instructions-pairing.page.scss'],
})

export class DeviceInstructionsPairingPage {
    public imageUrl: string;
    public instructions: any[] = [];
    device: TemplateDevice;
    view: 'enlargedImageView' | 'pairingInstructionsView';

    constructor(
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private taskTrackingService: TaskTrackingService,
        private textToSpeechService: TextToSpeechService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.peripheralType) {
                this.imageUrl = params.image;
                this.instructions = params.instructions;
                this.device = params.device;
                this.view = params.view;
            }
        });
        this.modalService.setModalStatus('DeviceInstructionsPairingPage', true);
    }

    ngOnInit() {
        this.taskTrackingService.startTracking('bluetooth-device-pairing-instructions', 'Opened bluetooth device pairing page for ' + this.device.name);
    }

    ngOnDestroy() {
        this.modalService.setModalStatus('DeviceInstructionsPairingPage', false);
        this.taskTrackingService.stopTracking();
    }

    readContent(): void {
        const text: string = this.instructions.map((instruction) => instruction.text).join(' ');
        this.textToSpeechService.speak('BLUETOOTH.DEVICE_INSTRUCTIONS_PAIRING_TITLE').then(() => {
            this.textToSpeechService.speak(text);
        });
    }
}
