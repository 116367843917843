import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {GatewayService} from '@hrs/gateway';
import {HRSSecureCache} from '../storage/cache';
import * as _sodium from 'libsodium-wrappers';
import {encode} from 'base64-arraybuffer';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class EncryptionService {
    private readonly logger = getLogger('EncryptionService');

    public serverPublicKey: string = null;
    public localPrivateKey: Uint8Array;

    constructor(
        private cache: HRSSecureCache,
        private gatewayService: GatewayService,
    ) {}

    // ///////////////////////////////////////////
    // Get a public key used for encrypting data
    // ///////////////////////////////////////////
    getUserSpecificPublicKeyForEncryption(hrsid: string): Observable<ServerPublicKeyResponse | Error> {
        return this.cache.loadFromDelayedObservable(
            HRSSecureCache.ENCRYPTION_KEY_CACHE_KEY + this.cache.userID,
            this.gatewayService.get({path: `encryption/key?gid=${hrsid}`}),
            undefined,
            undefined,
            undefined,
            'meta'
        ).pipe(
            tap((res: ServerPublicKeyResponse) => {
                this.serverPublicKey = res.data.key;
            }),
            catchError((err: Error) => {
                this.serverPublicKey = null;
                this.logger.phic.error('Error getting public key', err);
                return of(err);
            })
        );
    }

    // ///////////////////////////////////////////
    // Returns encrypted data
    // Designed for encrypting a patients metric data if they were offline and storing on their device locally
    // Uses Asymmetric encryption which requires a serverPublicKey
    // ///////////////////////////////////////////
    encryptDataUsingSodium(dataToEncrypt: any, serverPublicKey: string): EncryptedOutput {
        // Public key will be base64-encoded, so decode to byte array

        let decodedServerPublicKeyToBinary: Uint8Array = this.base64ToArrayBuffer(serverPublicKey);

        // sodium's encryption function requires a string, the object won't work...
        let dataToEncryptString: string = JSON.stringify(dataToEncrypt);

        // Generate a client keypair from a seed (random 32 byte string)
        let seed: Uint8Array = _sodium.randombytes_buf(_sodium.crypto_box_SEEDBYTES);
        let clientKeypair = _sodium.crypto_box_seed_keypair(seed);

        // Generate a nonce (random 24 byte string)
        // Note: Nonce should not be used more than once (unlike keys, which can be used indefinitely)
        let nonce: Uint8Array = _sodium.randombytes_buf(_sodium.crypto_box_NONCEBYTES);

        // Encrypt using nonce and a keypair comprised of the server-provided public key and the local private key
        let encryptedData: Uint8Array = _sodium.crypto_box_easy(dataToEncryptString, nonce, decodedServerPublicKeyToBinary, clientKeypair.privateKey);

        // when uploading metrics to the db, we also will need the nonce and clientPublicKey
        let encryptionOutput: EncryptedOutput = {
            data: encode(encryptedData),
            nonce: encode(nonce),
            clientPublicKey: encode(clientKeypair.publicKey)
        };

        return encryptionOutput;
    }

    // ///////////////////////////////////////////
    // SYMMETRICALLY Encrypt/Decrypt
    // ///////////////////////////////////////////

    // Create the private key used to encrypt and decrypt
    // The same private key used to encrypt needs to be used to decrypt
    generatePrivateKey(): Uint8Array {
        return _sodium.randombytes_buf(_sodium.crypto_box_SECRETKEYBYTES);
    }

    // Symmetrically encrypt
    // This function generates a cryptographic nonce (an arbitrary, random number required for encryption),
    // uses that nonce along with a secret key generated elsewhere to encrypt a message.
    // It then preprends the nonce to the encrypted data (for easy access later when the data gets unencrypted)
    // 2 arguments:
    // 1 - dataToEncrypt - any data you want to encrypt as long as its a string
    // 2 - key - should be the key created by the `generatePrivateKey()` function
    symmetricEncrypt(dataToEncrypt: string, key: Uint8Array): Uint8Array {
        // first, generate a random nonce
        let nonce: Uint8Array = _sodium.randombytes_buf(_sodium.crypto_secretbox_NONCEBYTES);

        // next, encrypt the `dataToEncrypt` using sodium's symmetric encryption algorithn and provide it the nonce and the secret key.
        // Call the encrypted result, `cipherText`, which is the term used to refer to encrypted text
        let cipherText: Uint8Array = _sodium.crypto_secretbox_easy(dataToEncrypt, nonce, key);

        // finally, combine the nonce and the cipherText into one Uint8Array
        // when the cipherText is decrypted later, we'll need the nonce that was originally used to decrypt it,
        // which is why we're combining them together here. Note, need to use the Uint8Array Constructor to ensure when combining 2 Uint8Arrays
        // together the type remains Uint8Array and doesn't get `degraded` to a normal javascript array.
        // create a new Uint8Array that is the length of the nonce + cipherText
        let cipherTextAndNonce: Uint8Array = new Uint8Array(nonce.length + cipherText.length);
        // set the nonce array values at the start of the Uint8Array
        cipherTextAndNonce.set(nonce);
        // set the cipherText array values after the nonce values
        cipherTextAndNonce.set(cipherText, nonce.length);
        return cipherTextAndNonce;
    }

    // Symmetrically decrypt
    // This function is used to decrypt a message. Requires that we use the same secret key that was originally used to encrypt the message
    // 2 arguments:
    // 1 - nonce_and_ciphertext - Uint8Array with the nonce first and the cipherText second
    // 2 - key - should be the same key used to encrypt the original message
    symmetricDecrypt(nonceAndCipherText: Uint8Array, key: Uint8Array): string {
        // Note: `ciphertext` refers to the encrypted message
        // first, ensure the length of the nonce_and_ciphertext is more than 32 (the NONCEBYTES (24) + MACBYTES (16))
        if (nonceAndCipherText.length < _sodium.crypto_secretbox_NONCEBYTES + _sodium.crypto_secretbox_MACBYTES) {
            throw new Error('nonce_and_ciphertext is invalid. The length must be at least 32.');
        }

        // next, extract the nonce from the start of the `nonce_and_ciphertext`
        let nonce: Uint8Array = nonceAndCipherText.slice(0, _sodium.crypto_secretbox_NONCEBYTES);

        // next, extract the cipherText from the end of the `nonce_and_ciphertext`
        let ciphertext: Uint8Array = nonceAndCipherText.slice(_sodium.crypto_secretbox_NONCEBYTES);

        // next, open the ciphertext using the original secret key (this is a Uint8Array)
        let extractedText: Uint8Array = _sodium.crypto_secretbox_open_easy(ciphertext, nonce, key);

        // finally, convert the Uint8Array to a human-readable string
        return _sodium.to_string(extractedText);
    }
    // sodium helper functions:
    // `from_string` converts a string to a Uint8Array object
    // `to_string` converts a Uint8Array object to a string

    private base64ToArrayBuffer(base64: string): Uint8Array {
        var binaryString = window.atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
}

interface ServerPublicKeyResponse {
    data: {
        key: string;
    }
}

interface EncryptedOutput {
    data: string;
    nonce: string;
    clientPublicKey: string;
}
