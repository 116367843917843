import {Component, ViewChild} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {IonContent, NavController} from '@ionic/angular';
import {ModalService} from '@hrs/providers';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {OverlayRef} from '../../hrs-overlay';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {WELCH_BP_SCALE_PAIRING_PASS_KEY, WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY} from 'src/app/services/device/welch/welch.service';
import {HRSStorage} from 'src/app/services/storage/storage';
import {DeviceService} from 'src/app/services/device/device.service';
import {getLogger} from '@hrs/logging';

declare var bluetoothSerial: any;

@Component({
    selector: 'app-devices-disconnect',
    templateUrl: './disconnect-device.page.html',
    styleUrls: ['./disconnect-device.page.scss'],
})
export class DisconnectDevicePage {
    private readonly logger = getLogger('DisconnectDevicePage');
    public device: any;
    public imageUrl: string;
    public peripheralType: string;
    public isActivityPage: boolean;
    @ViewChild(IonContent, {}) content: IonContent;

    constructor(
        private taskTrackingService: TaskTrackingService,
        public navCtrl: NavController,
        private modalService: ModalService,
        private overlayRef: OverlayRef,
        private ble: BLE,
        private bluetoothSerial: BluetoothSerial,
        private storage: HRSStorage,
        private deviceService: DeviceService
    ) {
        this.modalService.setModalStatus('DisconnectDevicePage', true);
    }

    ngOnInit() {
        this.isActivityPage = this.peripheralType === 'activity';
        this.taskTrackingService.startTracking(`disconnect-bluetooth-device`, `Opened disconnect bluetooth device page`);
    }

    ngOnDestroy() {
        this.taskTrackingService.stopTracking();
    }

    public disconnectPairedDevice(): void {
        if (!this.isActivityPage) {
            this.logger.phic.debug('Will disconnect the device from disconnect device page ', this.device);
            if (BluetoothUtils.isClassicDevice(this.device)) {
                // Bluetooth Classic doesn't disconnect a specific device, but stops the socket and stops receiving updates from that devic
                this.bluetoothSerial.disconnect();
                this.unPairBluetoothSerialDevice(this.device); // unpair it also
            } else {
                this.clearWelchAllynDevicePasswords(this.device);
                this.clearPairedDevice(this.device);
                // BLE disconnects a specific device because of autoconnect
                this.ble.disconnect(this.device.id).then(
                    () => {
                        this.logger.phic.info('Disconnected from device', this.device);
                    },
                    () => this.logger.phic.error('ERROR disconnecting ', this.device)
                );
            }
        }
        this.dismiss(true);
    }

    /**
     * This method will clear the paired device from storage once user has disconnected it.
     * @param device
     */
    private clearPairedDevice(device: any): void {
        this.logger.phic.log('With disconnect, removing paired device from storage also');
        let peripheralType = this.deviceService.getPeripheralType(device.name);
        let BTKey = this.deviceService.checkForBluetoothKey(peripheralType);
        this.storage.removeItem(BTKey).then(
            () => {
                this.logger.phic.log('Removed paired device with BT key ' + BTKey + ' after disconnecting and unpairing it.');
            },
            () => this.logger.phic.error('ERROR removing paired device ')
        );
    }

    public dismiss(didDisconnect: boolean = false): void {
        this.modalService.setModalStatus('DisconnectDevicePage', false);
        this.overlayRef.dismiss({data: {device: this.device, didDisconnect: didDisconnect}});
    }

    public unPairBluetoothSerialDevice(device: any): void {
        bluetoothSerial.unPairDevice(device.address);
    }

    private clearWelchAllynDevicePasswords(device: any): void {
        // If the BLE device disconnected & unpaired is Welch Allyn BP, we need to remove the pairing password also
        if (device.name.includes('BP100')) {
            this.storage.removeItem(WELCH_BP_SCALE_PAIRING_PASS_KEY).then(
                () => {
                    this.logger.phic.log('Removed welch BP password after disconnecting and unpairing it');
                },
                () => this.logger.phic.error('ERROR removing welch BP password ')
            );
        }
        // If the BLE device disconnected & unpaired is Welch Allyn Scale, we need to remove the pairing password also
        if (device.name.includes('SC100')) {
            this.storage.removeItem(WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY).then(
                () => {
                    this.logger.phic.log('Removed welch scale password after disconnecting and unpairing it');
                },
                () => this.logger.phic.error('ERROR removing welch scale password ')
            );
        }
    }
}
