import {EventService} from '../events/event.service';
import {FirebaseNotifications} from '../firebase/firebase';
import {HRSStorage} from '../storage/storage';
import {OverlayService} from '../overlay/overlay.service';
import {Settings} from '../settings/settings.service';
import {User} from './user.service';
import {CommunicationService, GatewayApi, TokenService} from '@hrs/providers';
import {GatewayService} from '@hrs/gateway';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {AudioService} from '../audio/audio.service';
import {EncryptionService} from '../encryption/encryption.service';
import {HRSSecureCache} from '../storage/cache';
import {DeviceProfileService} from '../../hrs-tablet/device-profile/device-profile.service';
import {TabletDeviceIdService} from '../tablet-device-id/tablet-device-id';
import {take, lastValueFrom} from 'rxjs';
import {EnvironmentService} from '@patient/providers';
import {PCMTCredentials} from '../../../../../../libs/providers/api/gateway-api';
import * as jwtDecode from 'jwt-decode';
import {getLogger} from '@hrs/logging';

@Injectable()
export class TabletUserService extends User {
    private readonly logger = getLogger('TabletUserService');

    constructor(
        audio: AudioService,
        cache: HRSSecureCache,
        communication: CommunicationService,
        deviceProfile: DeviceProfileService,
        encryptionService: EncryptionService,
        eventService: EventService,
        firebase: FirebaseNotifications,
        gatewayApi: GatewayApi,
        gatewayService: GatewayService,
        menuCtrl: MenuController,
        navCtrl: NavController,
        overlay: OverlayService,
        platform: Platform,
        settings: Settings,
        storage: HRSStorage,
        tokenService: TokenService,
        private tabletDeviceIDService: TabletDeviceIdService,
        private environmentService: EnvironmentService
    ) {
        super(
            audio,
            cache,
            communication,
            deviceProfile,
            encryptionService,
            eventService,
            gatewayApi,
            gatewayService,
            menuCtrl,
            overlay,
            platform,
            settings,
            storage,
            tokenService,
            firebase,
            navCtrl
        );
        this.subscribeToPatientChangeNotifications();
    }

    public async login(): Promise<void> {
        await lastValueFrom(this.requestLogin(await this.getRequestData())).catch((err) => {
            this.logger.phic.error(`requestLogin error`, err);
        });
        await this.tabletDeviceIDService.subscribeIMEIIntent();
        if (this.isLoggedIn()) {
            this.tabletDeviceIDService.setLicenseKey();
        }
    }

    public async checkCurrentUser(): Promise<boolean> {
        try {
            const requestData = await this.getRequestData();
            const token = await lastValueFrom(this.gatewayApi.getGatewayToken(requestData));
            const decodedToken = jwtDecode(token.data.attributes.token);
            const tokenType = decodedToken['type'];
            const tokenID = decodedToken['sub'];
            // if the user hasn't changed, store the new token
            if (tokenID === this.id && tokenType === 'patient') {
                await this.gatewayApi.deleteBackendToken();
                this.tokenService.storeTokens(token.data);
                await this.settings.setValue('token', token.data.attributes.token);
                await this.settings.setValue('refresh', token.data.attributes.refresh);
                return true;
            }

            return false;
        } catch (e) {
            this.logger.phic.error(`TabletUserService: Error checking if user ${this.id} in env ${this.environment} is still assigned`, e);
            return true; // in the case that this request fails, keep the current user signed in
        }
    }

    private async getRequestData(): Promise<PCMTCredentials> {
        const imei = await this.tabletDeviceIDService.getImei();
        this.gatewayApi.imei = imei;
        this.gatewayService.imei = imei;
        const time = new Date().getTime().toString();
        const key = await TabletUserService.getKey(imei, time);

        return {
            data: {
                type: 'device',
                device: imei,
                time: time,
                key: key
            }};
    }

    // the key is made up a hash of the imei,
    // salted by hash of the imei that has been salted with the current time + a fragment of the current time
    private static async getKey(imei: string, time: string): Promise<string> {
        return await TabletUserService.createPassword(
            imei,
            await TabletUserService.createPassword(imei, time) + time.substring(time.length - 8, time.length)
        );
    }

    // takes imei and encodes it using the current time using SHA-1 as required by the backend
    private static async createPassword(imei: string, salt: string): Promise<string> {
        const msgUint8 = new TextEncoder().encode(imei + salt);
        const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        let hexString = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        return hexString.substring(0, 16);
    }

    private subscribeToPatientChangeNotifications(): void {
        this.eventService.addPatient.subscribe(async () => {
            this.logger.phic.debug(`TabletUserService.subscribeToPatientChangeNotifications addPatient Listener | Logging ${this.id} in`);
            await this.login();
            const status = await this.getUserData().then(() => this.status);
            if (this.isPatient() && status !== 'deactivated') {
                this.environmentService.getEnvironment(this.environment);
                this.environmentService.environmentDataLoading.pipe(take(1)).subscribe(() => {
                    this.navCtrl.navigateRoot(['home']);
                });
            } else if (status === 'deactivated') {
                this.logger.phic.error(`TabletUserService.subscribeToPatientChangeNotifications addPatient Listener | Patient is deactivated. Logging ${this.id} out`);
                await this.logout();
            }
        });

        this.eventService.removePatient.subscribe(async () => {
            this.logger.phic.debug(`TabletUserService.subscribeToPatientChangeNotifications removePatient Listener | Logging ${this.id} out`);
            await this.logout();
        });
    }
}

