export enum TaskType {
    Activity = 'activity',
    BloodPressure = 'bloodpressure',
    Glucose = 'glucose',
    Medication = 'medication',
    PulseOx = 'pulseox',
    ModuleReminder = 'module-reminders',
    Steps = 'steps',
    // Stethoscope = 'stethoscope',
    Survey = 'survey',
    Temperature = 'temperature',
    Weight = 'weight',
    WoundImaging = 'woundimaging',
    Tracking = 'tracking'
}
