import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {LanguageService} from '../language/language.service';
import {OverlayService} from '../overlay/overlay.service';
import {EventService} from '../events/event.service';
import {TranslateService} from '@ngx-translate/core';
import {MedicationTask, Task, TaskType} from '../tasks';
import {Observable, Subject} from 'rxjs';
import {ObjectUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';
import {CareplanChangeAction, RouteMap} from '../../enums';
import {OverlayComponent} from '../../hrs-overlay';
import {EducationFile} from '../../education';
import {Quiz} from '../../education/quizzes/education-quiz.model';

export type CareplanState = Partial<Record<TaskType, CareplanChangeAction>>;
export enum EducationChange {
    ADDED = 'ADDED',
    UPDATED = 'UPDATED',
    REMOVED = 'REMOVED',
    NO_CHANGE = ''
}
enum EducationType {
    FILES = 'FILES',
    QUIZZES = 'QUIZZES',
    VIDEOS = 'VIDEOS'
}

@Injectable({
    providedIn: 'root',
})
export class CareplanChangeService {
    private readonly logger = getLogger('CareplanChangeService');
    static MEDICATION_UPDATED = 'medication_updated';

    public emitTasks = new Subject();
    public emitEducation = new Subject();

    public careplanState: CareplanState = {};
    private educationFiles: EducationFile[];
    private educationVideos: EducationFile[];
    private educationQuizzes: Quiz[];
    private medication: Partial<MedicationTask>;
    private languageChanged: boolean = false;
    private careplanStateSubject = new Subject<CareplanState>();

    public readonly careplanState$: Observable<CareplanState>;

    constructor(
        private eventService: EventService,
        private language: LanguageService,
        private navCtrl: NavController,
        private overlay: OverlayService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.careplanState$ = this.careplanStateSubject.asObservable();
    }

    public emitCurrentState(): void {
        const state = this.careplanState;
        this.logger.debug(`emitCurrentState()`, state);
        this.careplanStateSubject.next(state);
    }

    /*
           Service will check old care plan state against new care plan state when triggered by firebase notification or platform resumed event
           IF care plan state has been updated - the user will be notified and new tasks will be passed to home component - reducing additional fetch from home component
     */

    initListeners(): void {
        this.emitTasks.subscribe({
            next: (tasks: Task[]) => {
                if (Object.keys(this.careplanState).length === 0) {
                    this.setCurrentCarePlanState(tasks);
                } else {
                    this.handleNewTasksReturned(tasks);
                }
            },
            error: (err) => {
                this.logger.phic.error(`initListeners -> emitTasks error`, err);
            }
        });

        this.emitEducation.subscribe({
            next: ({files, quizzes, videos}: {files: EducationFile[], quizzes: Quiz[], videos: EducationFile[]}) => {
                if (this.educationFiles === undefined && this.educationQuizzes === undefined && this.educationVideos === undefined) {
                    this.setCurrentEducationState(files, quizzes, videos);
                } else {
                    this.handleNewEducationReturned(files, quizzes, videos);
                }
            },
            error: (err) => {
                this.logger.phic.error(`initListeners -> emitTasks error`, err);
            }
        });

        this.language.languageChange.subscribe(() => this.languageChanged = true);

        this.eventService.loginStateChanged.subscribe((hasLoggedIn: boolean) => {
            if (!hasLoggedIn) {
                this.careplanState = {};
                this.educationFiles = undefined;
                this.educationQuizzes = undefined;
                this.educationVideos = undefined;
            }
        });
    }

    setCurrentCarePlanState(tasks: Task[]): void {
        // Follows a pessimistic pattern where each task is default set to 'removed'
        // and their state is updated if they are present in fresh data
        this.careplanState = {};
        this.medication = undefined;
        tasks.forEach((task) => {
            if (task.type === TaskType.Medication) this.medication = task;
            this.careplanState[task.type] = CareplanChangeAction.REMOVED;
        });
    }

    setCurrentEducationState(files: EducationFile[], quizzes: Quiz[], videos: EducationFile[]): void {
        this.educationFiles = files;
        this.educationQuizzes = quizzes;
        this.educationVideos = videos;
    }

    setNewCarePlanState(tasks: Task[]) {
        let didAddModules = false;
        tasks.forEach((task) => {
            const updatedValue = this.careplanState[task.type] ? CareplanChangeAction.NO_CHANGE : CareplanChangeAction.ADDED;
            this.careplanState[task.type] = updatedValue;
            if (updatedValue === CareplanChangeAction.ADDED) {
                didAddModules = true;
            }
        });

        if (didAddModules) {
            this.emitCurrentState();
        }
    }

    private handleNewTasksReturned(tasks: Task[]): void {
        this.setNewCarePlanState(tasks);
        // JIR-8648: do not await this promise, it will cause the careplan state to get out of sync and display unnecessary notifications
        this.checkForChanges(tasks);
        this.setCurrentCarePlanState(tasks);
    }

    private handleNewEducationReturned(files: EducationFile[], quizzes: Quiz[], videos: EducationFile[]): void {
        this.checkForEducationChanges(files, quizzes, videos);
        this.setCurrentEducationState(files, quizzes, videos);
    }

    private async checkForChanges(tasks: Task[]): Promise<void> {
        this.checkForMedicationUpdated(tasks);
        const careplanHasChangedMessage = this.createMessageIfChanges();
        if (careplanHasChangedMessage) await this.careplanHasChanged(careplanHasChangedMessage);
    }

    private async checkForEducationChanges(files: EducationFile[], quizzes: Quiz[], videos: EducationFile[]): Promise<void> {
        const filesUpdated = this.checkEducationUpdates(files, this.educationFiles);
        const quizzesUpdated = this.checkEducationUpdates(quizzes, this.educationQuizzes);
        const videosUpdated = this.checkEducationUpdates(videos, this.educationVideos);
        const educationHasChangedMessage = this.getEducationMessages(filesUpdated, quizzesUpdated, videosUpdated);
        if (educationHasChangedMessage.length) await this.careplanHasChanged(educationHasChangedMessage);
    }

    checkForMedicationUpdated(tasks: Task[]): void {
        if (this.languageChanged) {
            // if the task fetch/compare lifecycle was initialized by a language change then the user will be alerted
            // that medication has changed, even tho it technically has not. this prevents that alert from showing.
            this.languageChanged = false;
            return;
        }

        let medication = tasks.filter((task) => task.type === TaskType.Medication)[0];
        if (medication) this.compareMedication(medication as MedicationTask);
    }

    private compareMedication(newTask: MedicationTask): void {
        // keys to ignore represent values which are controlled by users input, or unique ids
        // but that dont represent actual changes to what user can expect and dont need to be considered when messaging to user
        const ignoreKeys = ['fromCache', 'isTaskReset', 'lastCompleted', 'lastCompletedButOffline', 'medicationId', 'saving'];

        if (newTask && this.medication) {
            // first we need to sort the subTasks (i.e. individual medications) the same way, so that object comparison works
            newTask.subTasks.sort((a, b) => {
                return this.getSortKey(a as MedicationTask).localeCompare(this.getSortKey(b as MedicationTask));
            });
            this.medication.subTasks.sort((a, b) => {
                return this.getSortKey(a as MedicationTask).localeCompare(this.getSortKey(b as MedicationTask));
            });
            if (!ObjectUtility.objectsAreTheSame(newTask, this.medication, false, ignoreKeys)) {
                const change = newTask.subTasks.length < this.medication.subTasks.length ? CareplanChangeAction.REMOVED : CareplanChangeAction.ADDED;
                this.careplanState[CareplanChangeService.MEDICATION_UPDATED] = change;
            }
        }
    }

    private getSortKey(medication: MedicationTask): string {
        const scheduledTime: moment.Moment = medication.getScheduledTime();
        if (scheduledTime.isValid()) {
            // 24 hour time for correct sorting, and alphabetically for any meds that have the exact same time
            return scheduledTime.format('HH:mm') + medication.name;
        }
        // Sort any PRN (aka "As Needed") at the end after any scheduled medications, and then alphabetically
        return medication.name;
    }

    private async careplanHasChanged(careplanHasChangedMessage: string[]): Promise<void> {
        this.logger.debug(`careplanHasChanged()`, {careplanHasChangedMessage});
        this.emitCurrentState();
        await this.notifyCarePlanChanges(careplanHasChangedMessage);
        await this.checkRouteAndTriggerViewChange();
    }

    /*
        Enforcing view changes if user is on view which is no longer supported by their careplan
     */
    private async checkRouteAndTriggerViewChange(): Promise<void> {
        this.logger.debug(`checkRouteAndTriggerViewChange()`);
        // Route back to Home if current page is on a metric that was removed.
        let currentPage;
        try {
            const currentURL = this.router.url;
            const cutoffIndex = currentURL.indexOf('?') !== -1 ? currentURL.indexOf('?') : currentURL.length;
            currentPage = currentURL.substring(1, cutoffIndex);
        } catch (err) {
            this.logger.phic.info('Could not get the current page.', err);
        }

        const routeRemoved = !!currentPage && this.careplanState[RouteMap[currentPage]] === CareplanChangeAction.REMOVED;
        this.logger.debug(`processing route state ->`, {currentPage, routeRemoved});

        if (routeRemoved) {
            await this.navCtrl.navigateRoot(['home']);
        }

        // Close the current modal if it is on a metric that was removed.
        const activeModal = this.overlay.getTop('modal');
        let modalTask;
        if (activeModal instanceof OverlayComponent) {
            if (activeModal.childComponentRef.instance.task) {
                modalTask = activeModal.childComponentRef.instance.task.type;
            }

            const modalRemoved = !!modalTask && this.careplanState[modalTask] === CareplanChangeAction.REMOVED;
            this.logger.debug(`processing modal state ->`, {modalTask, modalRemoved});

            if (modalRemoved) {
                // Dismiss the open modal if the modal is a module that was removed.
                await activeModal.dismiss();
            }
        }
    }

    private async notifyCarePlanChanges(messaging: string[]): Promise<void> {
        const topAlert = this.overlay.getTop('alert');
        // close existing alerts before making new alert
        if (topAlert instanceof OverlayComponent) {
            await topAlert.dismiss();
        }

        await this.overlay.openAlert({
            header: this.translate.instant('NOTIFICATION_CARE_PLAN_HEADER'),
            message: [this.translate.instant('NOTIFICATION_CARE_PLAN_SUBHEADER')],
            details: messaging,
            buttons: [{
                text: this.translate.instant('OK_BUTTON')
            }],
            qa: 'careplan_change_alert'
        });
    }

    private createMessageIfChanges(): string[] {
        const added = [];
        const stepsAdded = [];
        const removed = [];

        // eslint-disable-next-line guard-for-in
        for (let task in this.careplanState) {
            const medUpdate = task === CareplanChangeService.MEDICATION_UPDATED;
            const title = this.translate.instant(`TASK_TITLE_${task.toUpperCase()}`);
            if (this.careplanState[task] === CareplanChangeAction.ADDED && task !== TaskType.Steps) {
                const action = medUpdate ? 'UPDATED' : 'ADDED';
                const translation = this.translate.instant(`NOTIFICATION_TASK_${action}`, {task: title});
                added.push(translation);
            } else if (this.careplanState[task] === CareplanChangeAction.ADDED && task === TaskType.Steps) {
                stepsAdded.push(this.translate.instant('NOTIFICATION_STEPS_ADDED'));
            } else if (this.careplanState[task] === CareplanChangeAction.REMOVED) {
                const action = medUpdate ? 'UPDATED' : 'REMOVED';
                const translation = this.translate.instant(`NOTIFICATION_TASK_${action}`, {task: title});
                removed.push(translation);
            }
        }

        if (added.length || stepsAdded.length || removed.length) return [...stepsAdded, ...added, ...removed];
    }

    private getEducationMessages(filesUpdated: EducationChange, quizzesUpdated: EducationChange, videosUpdated: EducationChange): string[] {
        const messages = [];
        const makeMessage = (changeString: EducationChange, type: EducationType) => {
            const title = this.translate.instant(`EDUCATION_LIST.` + type);
            return this.translate.instant(`NOTIFICATION_EDUCATION_` + changeString, {contentType: title});
        };

        if (filesUpdated) messages.push(makeMessage(filesUpdated, EducationType.FILES));
        if (videosUpdated) messages.push(makeMessage(videosUpdated, EducationType.VIDEOS));
        if (quizzesUpdated) messages.push(makeMessage(quizzesUpdated, EducationType.QUIZZES));

        return messages;
    }

    private checkEducationUpdates(newContent: EducationFile[] | Quiz[], currentContent: EducationFile[] | Quiz[]): EducationChange {
        const differenceById = (arr1, arr2) => {
            return arr1.filter((obj1) => !arr2.some((obj2) => obj2.id === obj1.id));
        };
        const added = differenceById(newContent, currentContent);
        const removed = differenceById(currentContent, newContent);
        if (added.length !== 0 && added.length === newContent.length && newContent.length !== 0) return EducationChange.ADDED;
        if (removed.length !== 0 && removed.length === currentContent.length && currentContent.length !== 0) return EducationChange.REMOVED;
        if (added.length || removed.length) return EducationChange.UPDATED;
        return EducationChange.NO_CHANGE;
    }
}
