import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HistoricalData} from './historical-data.interface';
import {GatewayApi} from '@hrs/providers';
import {User} from '../user/user.service';
import {switchMap} from 'rxjs/operators';
import moment from 'moment/moment';
import {TaskType} from '../tasks';

@Injectable({
    providedIn: 'root'
})
export class HistoricalDataService {
    private data: { [key:string]:HistoricalData[] } = {};

    constructor(
        private gatewayApi: GatewayApi,
        private user: User
    ) {
        this.user.authenticationState.subscribe((isLoggedin: boolean) => {
            if (!isLoggedin) this.data = {};
        });
    }

    public getOfflineHistoricalData(metric: TaskType): HistoricalData[] {
        if (this.data[metric]) return this.data[metric];
        return [];
    }

    public getHistoricalData(metric: string): Observable<HistoricalData[]> {
        const endpoint = metric === TaskType.Activity ? 'latest-activity-readings' : 'metrics';
        return this.user.getStartOfEpisodeOfCare().pipe(
            switchMap((after: string) => {
                after = moment(after).locale('en').format('YYYY-MM-DD');
                return this.gatewayApi.get(`${endpoint}?filter[metric][]=${metric}&filter[hrsid]=${this.user.id}&filter[after]=${after}`);
            }),
            map((data: {data: HistoricalData[]}) => {
                if (data.data) {
                    this.data[metric] = data.data;
                    return data.data;
                }
            })
        );
    }
}
