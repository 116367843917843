import {LoadingController, LoadingOptions} from '@ionic/angular';

/**
 * Any intrinsic defaults we want to provide to all spinners
 */
const defaultLoadingOptions: LoadingOptions = {
};

/**
 * Performs the given action within the confines of
 * a new ionic loading spinner instance.
 *
 * This guarantees that the loading spinner will
 * get dismissed, regardless of any errors thrown
 * by calling `action`.
 */
export async function spinnerAction<T>(
    controller: LoadingController,
    action: () => Promise<T>,
    options: LoadingOptions = {}
): Promise<T> {
    let result: T;
    let error: any = null;
    let didFail: boolean = false;

    const spinner = await controller.create({
        ...defaultLoadingOptions,
        ...options
    });

    await spinner.present();

    try {
        result = await action();
    } catch (e) {
        error = e;
        didFail = true;
    }

    await spinner.dismiss();

    if (didFail) {
        return Promise.reject(error);
    }

    return result;
}
