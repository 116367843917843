import {Injectable, OnDestroy} from '@angular/core';
import {CommunicationService, VideoCallType, VoiceCallStatus} from '@hrs/providers';
import {Observable, Subscription, concatMap, distinctUntilChanged, map, merge, skipWhile, tap} from 'rxjs';
import {BuildUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';
import {AudioService} from './audio.service';

@Injectable({
    providedIn: 'root'
})
export class AudioCommunicationService implements OnDestroy {
    private readonly logger = getLogger('AudioCommunicationService');
    private audioChangeSubscription: Subscription = null;
    private mListenersEnabled: boolean = false;
    private isTablet: boolean = BuildUtility.isHRSTab();

    private readonly activeVideoTypes: VideoCallType[] = [
        VideoCallType.OPENTOK,
        VideoCallType.ZOOM
    ];

    private readonly activeVoiceTypes: VoiceCallStatus[] = [
        VoiceCallStatus.CONNECTING,
        VoiceCallStatus.CALL_CONNECTED
    ];

    constructor(
        private readonly audio: AudioService,
        private readonly communication: CommunicationService
    ) {
    }

    public get listenersEnabled(): boolean {
        return this.mListenersEnabled;
    }

    public ngOnDestroy(): void {
        this.disableListeners();
    }

    public enableListeners(): void {
        const alreadyEnabled = this.mListenersEnabled;
        this.logger.debug(`enableListeners() :: alreadyEnabled = ${alreadyEnabled}`);

        if (alreadyEnabled) {
            return;
        }

        if (!this.isTablet) {
            this.logger.debug(`skipping listener setup for non-tablet device`);
            return;
        }

        this.mListenersEnabled = true;

        const videoStateChanges$: Observable<boolean> = this.communication.activeVideoCallChange$.pipe(
            tap((change: VideoCallType) => this.logger.debug(`activeVideoCallChange$ -> ${change}`)),
            map((change: VideoCallType) => this.activeVideoTypes.includes(change))
        );

        const audioStateChanges$: Observable<boolean> = this.communication.activeVoiceCallChange$.pipe(
            tap((change: VoiceCallStatus) => this.logger.debug(`activeVoiceCallChange$ -> ${change}`)),
            map((change: VoiceCallStatus) => this.activeVoiceTypes.includes(change))
        );

        this.audioChangeSubscription = merge(
            videoStateChanges$,
            audioStateChanges$
        ).pipe(
            // Ignore `false` emissions during initialization, and wait for
            // an active state before we actually start messing with the volume
            skipWhile((active: boolean) => !active),
            // Ignore same-value multi-fires (e.g. voice connecting -> connected would emit `true` twice)
            distinctUntilChanged(),
            // prevent native call overlap by ensuring each operation resolves before we move onto the next one
            concatMap((isCallActive: boolean) => this.onActiveCallStateChange(isCallActive))
        ).subscribe();
    }

    public disableListeners(): void {
        const alreadyDisabled = !this.mListenersEnabled;
        this.logger.debug(`disableListeners() :: alreadyDisabled = ${alreadyDisabled}`);

        if (alreadyDisabled) {
            return;
        }

        this.mListenersEnabled = false;

        if (this.audioChangeSubscription) {
            this.audioChangeSubscription.unsubscribe();
            this.audioChangeSubscription = null;
        }
    }

    private async onActiveCallStateChange(isCallActive: boolean): Promise<void> {
        this.logger.debug(`onActiveCallStateChange() isCallActive = ${isCallActive}`);
        try {
            if (isCallActive) {
                await this.audio.overrideVolumeWithMax();
                this.logger.debug(`onActiveCallStateChange() -> overrideVolumeWithMax() done!`);
            } else {
                const didUndoChange = await this.audio.undoVolumeOverride();
                this.logger.debug(`onActiveCallStateChange() didUndoChange = ${didUndoChange}`);
            }
        } catch (err) {
            this.logger.error(`onActiveCallStateChange() error = ${err}`, err);
        }
    }
}
