import {Injectable} from '@angular/core';
import {Zoom} from '@ionic-native/zoom/ngx';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';
import {Subject, Observable, Subscription} from 'rxjs';
import {DeviceService} from '../../services/device/device.service';
import {CommunicationService, VideoCallType} from '@hrs/providers';
import {Settings} from '../settings/settings.service';
import {VideoNotification} from '../firebase/firebase-data-interface';

declare var cordova: any;

export enum ZoomEventType {
    NOTIFICATION_SERVICE_STATUS_CHANGED = 'notificationServiceStatusChanged',
    SHARED_MEETING_CHAT_STATUS_CHANGED = 'sharedMeetingChatStatusChanged',
    SDK_INITIALIZE_RESULT = 'sdkInitializeResult',
    SDK_INITIALIZE_AUTH_IDENTITY_EXPIRED = 'sdkInitializeAuthIdentityExpired',
    AUTH_IDENTITY_EXPIRED = 'authIdentityExpired',
    IDENTITY_EXPIRED = 'identityExpired',
    LOGIN_STATUS_UPDATE = 'loginStatusUpdate',
    JOIN_MEETING_RESULT = 'joinMeetingResult',
    START_MEETING_RESULT = 'startMeetingResult',
    START_INSTANT_MEETING_RESULT = 'startInstantMeetingResult',
    SDK_LOGIN_RESULT = 'sdkLoginResult',
    SDK_LOGOUT_RESULT = 'sdkLogoutResult',
    MEETING_STATUS_CHANGED = 'meetingStatusChanged',
    MEETING_PARAMETER_NOTIFICATION = 'meetingParameterNotification',
    MEETING_LEAVE_COMPLETE = 'meetingLeaveComplete',
    MEETING_NEEDS_PASSWORD_OR_DISPLAY_NAME = 'meetingNeedsPasswordOrDisplayName',
    WEBINAR_NEEDS_REGISTER = 'webinarNeedsRegister',
    WEBINAR_NEEDS_USER_NAME_AND_EMAIL = 'webinarNeedsUserNameAndEmail',
    MEETING_NEEDS_TO_CLOSE_OTHER_MEETING = 'meetingNeedsToCloseOtherMeeting',
    MEETING_FAIL = 'meetingFail',
    MEETING_USER_JOIN = 'meetingUserJoin',
    MEETING_USER_LEAVE = 'meetingUserLeave',
    MEETING_USER_UPDATED = 'meetingUserUpdated',
    IN_MEETING_USER_AVATAR_PATH_UPDATED = 'inMeetingUserAvatarPathUpdated',
    MEETING_HOST_CHANGED = 'meetingHostChanged',
    MEETING_CO_HOST_CHANGED = 'meetingCoHostChanged',
    ACTIVE_VIDEO_USER_CHANGED = 'activeVideoUserChanged',
    ACTIVE_SPEAKER_VIDEO_USER_CHANGED = 'activeSpeakerVideoUserChanged',
    HOST_VIDEO_ORDER_UPDATED = 'hostVideoOrderUpdated',
    FOLLOW_HOST_VIDEO_ORDER_CHANGED = 'followHostVideoOrderChanged',
    SPOTLIGHT_VIDEO_CHANGED = 'spotlightVideoChanged',
    USER_VIDEO_STATUS_CHANGED = 'userVideoStatusChanged',
    MICROPHONE_STATUS_ERROR = 'microphoneStatusError',
    USER_AUDIO_STATUS_CHANGED = 'userAudioStatusChanged',
    USER_AUDIO_TYPE_CHANGED = 'userAudioTypeChanged',
    MY_AUDIO_SOURCE_TYPE_CHANGED = 'myAudioSourceTypeChanged',
    LOW_OR_RAISE_HAND_STATUS_CHANGED = 'lowOrRaiseHandStatusChanged',
    CHAT_MESSAGE_RECEIVED = 'chatMessageReceived',
    CHAT_MESSAGE_DELETE_NOTIFICATION = 'chatMessageDeleteNotification',
    USER_NETWORK_QUALITY_CHANGED = 'userNetworkQualityChanged',
    SINK_MEETING_VIDEO_QUALITY_CHANGED = 'sinkMeetingVideoQualityChanged',
    HOST_ASK_UN_MUTE = 'hostAskUnMute',
    HOST_ASK_START_VIDEO = 'hostAskStartVideo',
    SILENT_MODE_CHANGED = 'silentModeChanged',
    FREE_MEETING_REMINDER = 'freeMeetingReminder',
    MEETING_ACTIVE_VIDEO = 'meetingActiveVideo',
    SINK_ATTENDEE_CHAT_PRIVILEGE_CHANGED = 'sinkAttendeeChatPrivilegeChanged',
    SINK_ALLOW_ATTENDEE_CHAT_NOTIFICATION = 'sinkAllowAttendeeChatNotification',
    SINK_PANELIST_CHAT_PRIVILEGE_CHANGED = 'sinkPanelistChatPrivilegeChanged',
    USER_NAME_CHANGED = 'userNameChanged',
    USER_NAMES_CHANGED = 'userNamesChanged',
    FREE_MEETING_NEED_TO_UPGRADE = 'freeMeetingNeedToUpgrade',
    FREE_MEETING_UPGRADE_TO_GIFT_FREE_TRIAL_START = 'freeMeetingUpgradeToGiftFreeTrialStart',
    FREE_MEETING_UPGRADE_TO_GIFT_FREE_TRIAL_STOP = 'freeMeetingUpgradeToGiftFreeTrialStop',
    FREE_MEETING_UPGRADE_TO_PRO_MEETING = 'freeMeetingUpgradeToProMeeting',
    CLOSED_CAPTION_RECEIVED = 'closedCaptionReceived',
    RECORDING_STATUS = 'recordingStatus',
    LOCAL_RECORDING_STATUS = 'localRecordingStatus',
    INVALID_RECLAIM_HOST_KEY = 'invalidReclaimHostKey',
    PERMISSION_REQUESTED = 'permissionRequested',
    ALL_HANDS_LOWERED = 'allHandsLowered',
    LOCAL_VIDEO_ORDER_UPDATED = 'localVideoOrderUpdated',
    LOCAL_RECORDING_PRIVILEGE_REQUESTED = 'localRecordingPrivilegeRequested',
    SUSPEND_PARTICIPANTS_ACTIVITIES = 'suspendParticipantsActivities',
    ALLOW_PARTICIPANTS_START_VIDEO_NOTIFICATION = 'allowParticipantsStartVideoNotification',
    ALLOW_PARTICIPANTS_RENAME_NOTIFICATION = 'allowParticipantsRenameNotification',
    ALLOW_PARTICIPANTS_UN_MUTE_SELF_NOTIFICATION = 'allowParticipantsUnMuteSelfNotification',
    ALLOW_PARTICIPANTS_SHARE_WHITE_BOARD_NOTIFICATION = 'allowParticipantsShareWhiteBoardNotification',
    MEETING_LOCK_STATUS = 'meetingLockStatus',
    REQUEST_LOCAL_RECORDING_PRIVILEGE_CHANGED = 'requestLocalRecordingPrivilegeChanged'
}

export interface ZoomEventData {
    value?: any;
    status?: string;
    enabled?: boolean;
    meetingStatus?: string;
    start?: boolean;
    success?: boolean;
    type?: string;
    error?: string;
    errorCode?: number;
    internalErrorCode?: number;
    isLoggedIn?: boolean;
    message?: string;
    authErrorMessage?: string;
    result?: any;
    meetingHost?: number;
    meetingNumber?: string;
    meetingTopic?: string;
    meetingType?: string;
    isAutoRecordingCloud?: boolean;
    isAutoRecordingLocal?: boolean;
    isViewOnly?: boolean;
    needsPassword?: boolean;
    needsDisplayName?: boolean;
    currentUserList?: number[];
    changedUserList?: number[];
    userId?: number;
    videoQuality?: string;
    inSilentMode?: boolean;
    isOriginalHost?: boolean;
    canUpgrade?: boolean;
    isFirstGift?: boolean;
    privilege?: number;
    privilegeStatus?: string;
    recordingStatus?: string;
    permissions?: string[];
    locked?: boolean;
    [key: string]: any;
}

export interface ZoomEvent {
    type: ZoomEventType;
    data: ZoomEventData;
}

const HIGH_PRIORITY_EVENTS: Set<ZoomEventType> = new Set([
    ZoomEventType.SDK_INITIALIZE_RESULT,
    ZoomEventType.SDK_INITIALIZE_AUTH_IDENTITY_EXPIRED,
    ZoomEventType.AUTH_IDENTITY_EXPIRED,
    ZoomEventType.IDENTITY_EXPIRED,
    ZoomEventType.LOGIN_STATUS_UPDATE,
    ZoomEventType.JOIN_MEETING_RESULT,
    ZoomEventType.START_MEETING_RESULT,
    ZoomEventType.START_INSTANT_MEETING_RESULT,
    ZoomEventType.SDK_LOGIN_RESULT,
    ZoomEventType.SDK_LOGOUT_RESULT,
    ZoomEventType.MEETING_STATUS_CHANGED,
    ZoomEventType.MEETING_LEAVE_COMPLETE,
    ZoomEventType.MEETING_NEEDS_TO_CLOSE_OTHER_MEETING,
    ZoomEventType.MEETING_FAIL,
    ZoomEventType.MEETING_USER_JOIN,
    ZoomEventType.MEETING_USER_LEAVE,
    ZoomEventType.MEETING_USER_UPDATED,
    ZoomEventType.MEETING_HOST_CHANGED,
    ZoomEventType.MEETING_CO_HOST_CHANGED,
    ZoomEventType.USER_VIDEO_STATUS_CHANGED,
    ZoomEventType.MICROPHONE_STATUS_ERROR,
    ZoomEventType.USER_AUDIO_STATUS_CHANGED,
    ZoomEventType.USER_AUDIO_TYPE_CHANGED,
    ZoomEventType.MY_AUDIO_SOURCE_TYPE_CHANGED,
    ZoomEventType.USER_NETWORK_QUALITY_CHANGED,
    ZoomEventType.SINK_MEETING_VIDEO_QUALITY_CHANGED,
    ZoomEventType.HOST_ASK_UN_MUTE,
    ZoomEventType.HOST_ASK_START_VIDEO,
    ZoomEventType.SILENT_MODE_CHANGED,
    ZoomEventType.FREE_MEETING_REMINDER,
    ZoomEventType.SINK_ATTENDEE_CHAT_PRIVILEGE_CHANGED,
    ZoomEventType.SINK_ALLOW_ATTENDEE_CHAT_NOTIFICATION,
    ZoomEventType.SINK_PANELIST_CHAT_PRIVILEGE_CHANGED,
    ZoomEventType.FREE_MEETING_NEED_TO_UPGRADE,
    ZoomEventType.FREE_MEETING_UPGRADE_TO_GIFT_FREE_TRIAL_START,
    ZoomEventType.FREE_MEETING_UPGRADE_TO_GIFT_FREE_TRIAL_STOP,
    ZoomEventType.FREE_MEETING_UPGRADE_TO_PRO_MEETING,
    ZoomEventType.RECORDING_STATUS,
    ZoomEventType.LOCAL_RECORDING_STATUS,
    ZoomEventType.INVALID_RECLAIM_HOST_KEY,
    ZoomEventType.PERMISSION_REQUESTED,
    ZoomEventType.LOCAL_RECORDING_PRIVILEGE_REQUESTED,
    ZoomEventType.SUSPEND_PARTICIPANTS_ACTIVITIES,
    ZoomEventType.ALLOW_PARTICIPANTS_START_VIDEO_NOTIFICATION,
    ZoomEventType.ALLOW_PARTICIPANTS_RENAME_NOTIFICATION,
    ZoomEventType.ALLOW_PARTICIPANTS_UN_MUTE_SELF_NOTIFICATION,
    ZoomEventType.MEETING_LOCK_STATUS,
    ZoomEventType.REQUEST_LOCAL_RECORDING_PRIVILEGE_CHANGED
]);

@Injectable({
    providedIn: 'root',
})
export class ZoomService {
    private readonly logger = getLogger('ZoomService');

    private readonly nativeEventSubject = new Subject<ZoomEvent>();
    private readonly nativeEventDelegateProxy: (ev: ZoomEvent) => void;
    private readonly nativeEventDelegateErrorProxy: (error: any) => void;
    private delegateInitializeRetryCount: number = 0;
    public endCallEvent: Subscription;
    private meetingId: string;
    public readonly nativeEvents$: Observable<ZoomEvent>;

    constructor(
        private communication: CommunicationService,
        private zoom: Zoom,
        private platform: Platform,
        private settings: Settings,
        private deviceService: DeviceService
    ) {
        this.nativeEvents$ = this.nativeEventSubject.asObservable();
        this.nativeEventDelegateProxy = this.onNativeEvent.bind(this);
        this.nativeEventDelegateErrorProxy = this.onNativeEventDelegateError.bind(this);
        this.initNotificationListeners();
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    private onNativeEvent(ev: ZoomEvent): void {
        const message = `onNativeEvent`;
        if (HIGH_PRIORITY_EVENTS.has(ev?.type)) {
            this.logger.debug(message, ev);
        } else {
            this.logger.trace(message, ev);
        }
        this.nativeEventSubject.next(ev);
    }

    private onNativeEventDelegateError(error: any): void {
        this.logger.error(`onNativeEventDelegateError -> ${error}`);
        const maxAttempts = 3;

        // prevent infinite loops from potential exceptions
        if (this.delegateInitializeRetryCount > maxAttempts) {
            this.logger.warn(`max retry attempts exceeded! (${maxAttempts})`);
            return;
        }

        this.delegateInitializeRetryCount++;
        this.logger.error(`retry = ${this.delegateInitializeRetryCount} out of ${maxAttempts})`);
        this.initializeNativeEventDelegateAsync();
    }

    private initializeNativeEventDelegateAsync(): void {
        this.logger.trace(`initializeNativeEventDelegateAsync()`);
        this.initializeNativeEventDelegate().catch((err) => {
            this.logger.error(`initializeNativeEventDelegate() failed! -> ${err}`);
        });
    }

    private async initializeNativeEventDelegate(): Promise<void> {
        this.logger.trace(`initializeNativeEventDelegate()`);
        await this.platform.ready();

        if (!this.platform.is('android')) {
            return;
        }

        this.logger.trace(`initializeNativeEventDelegate() registering shared event listener...`);
        cordova.plugins.Zoom.setSharedEventListener(
            this.nativeEventDelegateProxy,
            this.nativeEventDelegateErrorProxy
        );
    }

    private initNotificationListeners(): void {
        this.initializeNativeEventDelegateAsync();
        // End current call and join incoming call
        // event listener, call in progress, call pending in background
        this.communication.exitVideoCallEnterNew$.subscribe(() => {
            this.logger.debug(`initNotificationListeners() End current call and join incoming call`);
            // need to tell the current call to end
            if (this.communication.videoCallType() === VideoCallType.ZOOM) { // Zoom call in progress
                // to test w/ DEV-11830 - fix incoming call notifications
                // cordova.plugins.Zoom.logout();
            }
        });

        // This event handling in zoom is needed as we close the video modal once zoom is launched. To handle the call decline event, subscribing to that
        this.endCallEvent = this.communication.endVideoCall$.subscribe((data: VideoNotification) => {
            this.logger.debug(`initNotificationListeners() the person we're calling missed, ignored or declined the call`);
            if (data && data.type.includes('video-zoom')) { // Zoom call
                this.logger.debug('Call declined by participant' + data.callId);
                cordova.plugins.Zoom.notifyCallStatus(data.action, this.meetingId); // send this info to plugin
            }
        });
    }

    /**
     * Method to check permisisons and other required things for zoom calls
     * @param callback
     */
    public preInit(callback?: () => void): void {
        if (this.platform.is('android') && this.deviceService.getDeviceVersion() > 11 && !this.deviceService.hasAndroidPermission){
            // For android 12, zoom plugin does not currently handle Nearby devices permissions and
            // if the permission asked is denied and still sdk is initialized, the app crashes
            // thus, to handle this currently we inform the user again that permission for nearby devices is needed
            this.deviceService.checkAndroidPermissions();
        }
    }

    /**
     * Method to initialize Zoom SDK. This needs a JWT token which is associated with a meeting number.
     * Thus before joining a call, we init zoom with this token.
     * @param jwtToken
     */
    public async initWithJWTToken(jwtToken: string): Promise<boolean> {
        if (this.platform.is('android') && this.deviceService.getDeviceVersion() > 11 && !this.deviceService.hasAndroidPermission){
            // For android 12, zoom plugin does not currently handle Nearby devices permissions and
            // if the permission asked is denied and still sdk is initialized, the app crashes
            // thus, to handle this currently we inform the user again that permission for nearby devices is needed
            this.deviceService.checkAndroidPermissions();
            return false;
        }

        if (!this.isNativePlatform || !jwtToken) {
            this.logger.debug('JWT token not present, unable to init zoom');
            return false;
        }

        try {
            await cordova.plugins.Zoom.initializeWithJWT(jwtToken);
            this.zoom.setLocale(this.settings.getValue('language') || 'en');
            this.logger.debug('Initialized zoom sdk with jwt token');
            return true;
        } catch (err) {
            this.logger.phic.error('Error initializing Zoom SDK', err);
            return false;
        }
    }

    public joinMeeting(meetingNumber: string, meetingPassword: string, displayName: string): Promise<any> {
        return this.zoom.joinMeeting(meetingNumber, meetingPassword, displayName, {
            'no_dial_in_via_phone': true,
            'no_dial_out_to_phone': true,
            'participant_id': ''
        }).then(
            () => {
                this.logger.debug(`joinMeeting() meeting joined`);
                this.communication.setVideoCallActive(VideoCallType.ZOOM);
                this.initZoomMeetingListeners();
                this.communication.updateParticipantStatus('active');
            },
            (err) => {
                this.logger.phic.error(`joinMeeting() ERROR`, err);
            }
        );
    }

    public leaveMeeting(): void {
        this.logger.debug(`leaveMeeting()`);
        cordova.plugins.Zoom.logout();
    }

    private initZoomMeetingListeners(): void {
        this.logger.debug(`initZoomMeetingListeners()`);
        cordova.plugins.Zoom.addMeetingLeaveListener(this.onZoomCallLeft.bind(this), this);
    }

    // callback for event listener, user has left meeting successfully
    private onZoomCallLeft(): void {
        this.logger.debug(`onZoomCallLeft()`);
        this.communication.setVideoCallInactive();
        this.communication.updateParticipantStatus('left');
        cordova.plugins.Zoom.removeMeetingLeaveListener();
    }

    // Setter method for meetingId
    public setZoomMeetingId(meetingId: string): void {
        this.meetingId = meetingId;
    }
}
