import {Component} from '@angular/core';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private readonly logger = getLogger('AppComponent');

    constructor() {
        this.logger.trace(`[[ constructor ]]`);
    }
}
