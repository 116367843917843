import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RangePipe} from './range.pipe';

const pipes = [
    RangePipe
];

@NgModule({
    declarations: pipes,
    exports: pipes,
    imports: [
        CommonModule
    ]
})
export class SharedPipesModule {
}
