import {SurveyChoiceType} from './survey-choice-type.enum';

export class SurveyChoice {
    id: number;
    type: SurveyChoiceType;
    text: string;
    selectOnly: boolean;
    answer: any;

    constructor(data?: Partial<SurveyChoice>) {
        this.set(data);
    }

    set(data: Partial<SurveyChoice>) {
        Object.assign(this, data);
    }

    /**
     * Text for display only, because as of now we still have to submit the original text to the metrics service when recording the selected
     * answer choice.
     */
    getDisplayText(): string {
        if (!this.text) {
            // To make sure we don't show an empty answer choice if there's no text, fallback to the ID which should be an integer like 0 - 5
            return this.id.toString();
        }

        // Most of the survey answer choices in Global Settings are defined as a string following by a number in parentheses, which is
        // useful for some of the surveys to clarify to the user which choice is bad, good better, best, etc, but is not needed for Yes/No
        // and looks silly for them. Also, for some reason there's no space before the parenthesis, which also looks a little bad.

        // If Yes(#) or No(#) then trim the (#)
        let yesNoRegex = /^(Yes|No)(\([0-9]+\))$/gm;
        let matches = yesNoRegex.exec(this.text);
        if (matches && matches.length === 3) {
            // index 0 is the original string, 1 is the first group (ie. Yes or No) and 2 is the second group
            return matches[1];
        }

        // If it's like Something(3) then just add a space between the text and the value: Something (3)
        let otherScaleRegex = /^(.+)(\([0-9]+\))$/gm;
        matches = otherScaleRegex.exec(this.text);
        if (matches && matches.length === 3) {
            return matches[1] + ' ' + matches[2];
        }

        return this.text;
    }
}
