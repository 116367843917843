import {Injectable, NgZone} from '@angular/core';
import {HRSStorage} from '../../storage/storage';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {AnDClassicService} from './andclassic.service';
import {ModalService} from '@hrs/providers';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {HRSFirebaseAnalytics} from '../../analytics/firebaseanalytics.service';
import {HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

/**
 * This class is the service class for AnD Classic Scales 351, 355. It inherits AnD Classic base service for all the
 * common features and specializes some behavior related to scale.
 */
@Injectable({
    providedIn: 'root',
})
export class AnDClassicScaleService extends AnDClassicService {
    private readonly logger = getLogger('AnDClassicScaleService');

    constructor(
        bluetoothSerial: BluetoothSerial,
        storage: HRSStorage,
        ngZone: NgZone,
        fbanalytics: HRSFirebaseAnalytics,
        modalService: ModalService,
    ) {
        super(
            bluetoothSerial,
            storage,
            ngZone,
            fbanalytics,
            modalService
        );
    }

    /**
     * Returns the reading data size for AnD classic Scales 351 and 355
     * @returns readingData size
     */
    public getDataSize(): number {
        return 74;
    }

    /**
     * Process the data obtained from AnD Device
     * @param rxPacket
     * @param data
     * @param peripheral
     */
    public processData(rxPacket: ArrayBuffer, peripheral: any): void {
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'weight');
        // Check whether it is a recent reading or an old one
        this.isRecentReading = this.isRecentReadingRecieved(rxPacket, peripheral);

        // Process data header section
        this.processDataHeaderSection(rxPacket);

        // Process data reading section
        let scaleData = BluetoothUtils.getStringValueFromByteArray(rxPacket, 60, 74); // from 60th byte we get the scale data. Data is of lenght 14
        let measurementType = scaleData.substring(1, 2);
        this.logger.phic.debug('AnD Classic Scale ' + peripheral.name + ': Is measurement valid ' + measurementType);
        let readingval = scaleData.substring(4, 10);
        let readingtype = scaleData.substring(10, 12);
        this.logger.phic.debug('AnD Classic Scale ' + peripheral.name + ': Reading Value ' + readingval);
        this.logger.phic.debug('AnD Classic Scale ' + peripheral.name + ': Reading Type ' + readingtype);

        // force lb as reading type
        if (readingtype.includes('lb')) {// pounds
            this.weight = Number(readingval);
            this.submitWeight(peripheral);
        } else if (readingtype.includes('kg')) {// kilograms
            // convert kg to lbs
            this.weight = Number(readingval);
            this.weight = BluetoothUtils.convertKgWeightToPound(this.weight);
            this.submitWeight(peripheral);
        } else {
            this.logger.phic.error('AnD Classic Scale ' + peripheral.name + ' Incorrect weight data type');
            this.readingErrorOccurred = true;
            this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.INCORRECT_DEVICE_DATA});
        }
    }

    /**
     * Submit the weight to CC2 via metric update callback
     * @param peripheral
     */
    private submitWeight(peripheral: any) {
        if (this.isRecentReading && this.weight > 0) {
            this.logger.phic.debug('AnD Classic Scale ' + peripheral.name + ' Weight Obtained ' + this.weight + ' lbs');
            this.metricUpdateCallback(this.weight, peripheral);
            this.gotRecentReading = true;
        } else {
            this.historicalReadingObtained = true;
        }
    }

    /**
    * Callback function when metrics are updated for scale
    */
    public metricUpdateCallback: (weight: number, peripheral: any) => void;

    /**
     * Registers the metric update callback and handles the peripheral connected call.
     */
    public onConnected(peripheral: any, onMetricUpdateCallback: (weight: number, peripheral: any) => void): void {
        this.metricUpdateCallback = onMetricUpdateCallback;
        this.onPeripheralConnected(peripheral);
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        // cleanup
        this.gotRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }
}
