import {TaskType} from '../services/tasks';

export enum RouteMap {
    'medication' = TaskType.Medication,
    'survey' = TaskType.Survey,
    'wound-imaging' = TaskType.WoundImaging,
    'activity-monitors' = TaskType.Steps,
    'glucometers' = TaskType.Glucose,
    'oximeters' = TaskType.PulseOx,
    'bpmonitors' = TaskType.BloodPressure,
    'thermometers' = TaskType.Temperature,
    'scales' = TaskType.Weight,
    // 'stethoscope' = TaskType.Stethoscope
}
