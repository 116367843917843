<hrs-wizard [hasNoHeaderButton]="true" [config]="wizardConfig">
  <hrs-content background="white" qa="consent_content">
    <ng-container *ngIf="!isOnSignaturePage()">
      <ng-container *ngFor="let element of currentPage(); index as i;">
        <hrs-text font="b1-regular" color="gray-9" [qa]="'consent--text-' + i">
          {{ element.text }}
        </hrs-text>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isOnSignaturePage()">
      <hrs-text font="h2-semibold" qa="consent_signature_label">{{ signatureLabel }}</hrs-text>
      <app-signature-pad (endStroke)="onSignatureComplete()" data-qa="consent_form--signature"></app-signature-pad>
    </ng-container>
  </hrs-content>

  <hrs-footer variant="two-button">
    <hrs-button
      type="button"
      variant="secondary"
      data-qa="consent_form--btn-previous"
      icon="previous"
      (click)="navigate('back')"
      [disabled]="currentPageNumber == 1"
    ></hrs-button>
    <hrs-button
      *ngIf="!isOnSignaturePage()"
      type="submit"
      variant="primary"
      data-qa="consent_form--btn-submit"
      icon="next"
      text="{{ 'NEXT_BUTTON' | translate }}"
      (click)="navigate('forward')"
    ></hrs-button>
    <hrs-button
      *ngIf="isOnSignaturePage()"
      type="submit"
      variant="primary"
      data-qa="consent_form--btn-submit"
      text="{{ 'SUBMIT_BUTTON' | translate }}"
      (click)="save()"
      [loading]="saving"
      [disabled]="!signature || saving"
    ></hrs-button>
  </hrs-footer>
</hrs-wizard>
