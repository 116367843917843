import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {
    OverlayComponent,
    ModalTemplateComponent,
    AlertTemplateComponent,
    ToastTemplateComponent,
    WizardTemplateComponent
} from './';
import {CaregiverPinPage} from '../caregiver-pin/caregiver-pin.page';
import {ChatPage} from '../communication/chat/chat.page';
import {ConsentFormPage} from '../consent-form/consent-form.page';
import {DisconnectDevicePage} from '../devices/disconnect/disconnect-device.page';
import {EducationQuizzesPage} from '../education';
import {GenericMetricPage} from '../generic-metric/generic-metric.page';
import {LanguageSelectionPage} from '../hrs-tablet/language-selection/language-selection.page';
import {MedicationModal} from '../medication/medication.modal';
import {PdfComponent} from '../components/pdf/pdf.component';
import {SurveyPage} from '../survey/survey.page';
import {VideoPage} from '../communication/video/video.page';
import {VoicePage} from '../communication/voice/voice.page';
import {WoundImagingPage} from '../wound-imaging/wound-imaging.page';
import {BuildUtility} from '@hrs/utility';
import {SkeletonContentComponent} from '../components/skeleton-content/skeleton-content.component';
import {SharedPipesModule} from '../pipes/shared-pipes.module';

/** **********************************************************
 ************ ADD MODAL CHILD COMPONENTS HERE (A-Z) *********
 ************************************************************/
const hrsTabImports = [
    LanguageSelectionPage
];

let modalChildComponents: any[] = [
    CaregiverPinPage,
    ChatPage,
    ConsentFormPage,
    DisconnectDevicePage,
    EducationQuizzesPage,
    GenericMetricPage,
    MedicationModal,
    SkeletonContentComponent,
    PdfComponent,
    SurveyPage,
    VideoPage,
    VoicePage,
    WoundImagingPage
];

const requiredOverlayComponents = [
    AlertTemplateComponent,
    ModalTemplateComponent,
    ToastTemplateComponent,
    WizardTemplateComponent,
    OverlayComponent
];

if (BuildUtility.isHRSTab()) {
    modalChildComponents = [...modalChildComponents, ...hrsTabImports];
}

@NgModule({
    imports: [
        CommonModule,
        SharedDirectivesModule,
        SharedPipesModule,
    ],
    declarations: requiredOverlayComponents,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OverlayModule { }
