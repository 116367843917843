/**
 * This is a utility file which can contain commonly used helper methods related to bluetooth operations
 */
export default class BluetoothUtils {
    /**
     * The bluetooth classic serial plugin discovers classic as well as BLE devices which are ready to pair
     * We need to filter out classic devices and add only them to the classic devices list
     * This list is based on https://healthrecoverysolutions.atlassian.net/wiki/spaces/HS1/pages/1290076161/Bluetooth+Compatibility+List
     * @param device
     */
    public static isClassicDevice(device: any): boolean {
        if (device.name && (device.name.includes('Nonin_Medical') ||
            device.name.includes('TEST-N-GO') ||
            device.name.includes('UA-767') ||
            device.name.includes('Taidoc-Device') ||
            device.name.includes('UC-351') ||
            device.name.includes('UC-355'))
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
    * Converts the weight obtained in Kgs to Pounds
    * @param weightInKgs in kgs
    * @returns weight in pounds
    */
    public static convertKgWeightToPound(weightInKgs: number): number {
        let weightInPound = this.round(weightInKgs * 2.20462, 1);
        if (weightInPound < 220) {
            weightInPound = (this.round(weightInKgs * 1.1023, 1)) * 2;
        } else {
            let weightStep1 = (this.round(weightInKgs * 2.2046, 1)) / 5;
            // Keep only one decimal place without rounding for the next step
            weightInPound = this.truncateUpToOneDecimal(weightStep1) * 5;
        }
        return weightInPound;
    }

    /**
     * Rounds of the number to the precision
     */
    public static round(value: number, precision: number): number {
        let scale: number = Math.pow(10, precision);
        return Math.round(value * scale) / scale;
    }

    /**
     * Truncate up to one decimal place
     */
    private static truncateUpToOneDecimal(value: number): number {
        return Math.floor(value * 10) / 10;
    }

    /**
     * Convert bytes obtained to string representation
     * @param bytes
     * @returns string
     */
    public static bytesToString = (bytes) => {
        let decoder = new TextDecoder('UTF-8');
        const array = new Uint8Array(bytes);
        return decoder.decode(array);
    };

    /**
     * Convert string to byte array
     * @param str
     * @returns ArrayBuffer
     */
    public static convertStringToUTF8ByteArray(str): ArrayBuffer {
        let binaryArray = new Uint8Array(str.length);
        Array.prototype.forEach.call(binaryArray, function(el, idx, arr) {
            arr[idx] = str.charCodeAt(idx);
        });
        return binaryArray;
    }

    /**
     * Get string representation from the sliced array buffer
     * @param rxPacket
     * @param offset
     * @param len
     * @returns string
     */
    public static getStringValueFromByteArray(rxPacket: ArrayBuffer, offset: number, len: number) {
        let slicedArray = rxPacket.slice(offset, offset + len);
        let slicedArrayString = this.bytesToString(slicedArray);
        return slicedArrayString;
    }

    /**
    * Helper utility method to convert array buffer to hex
    * Provide reverseString boolean to reverse the resultant string (might be needed for some outputs such as Mac address)
    */
    public static buf2hex(buffer: ArrayBuffer, reverseString?: boolean): string {
        const byteArray = new Uint8Array(buffer);
        const hexParts = [];
        for (let i = 0; i < byteArray.length; i++) {
            const hex = byteArray[i].toString(16);
            const paddedHex = ('00' + hex).slice(-2);
            hexParts.push(paddedHex);
        }
        if (typeof reverseString !== 'undefined' && reverseString) {
            hexParts.reverse();
        }
        // join all the hex values of the elements into a single string
        return hexParts.join(' ');
    }

    /**
     * Checks whether k-th bit is set for the provided byte value
     * @param byteValue
     * @param k
     * @returns
     */
    public static iskthBitSet(byteValue: number, k: number): boolean {
        if (byteValue) {
            return ((byteValue & (1 << k)) > 0);
        } else {
            return false;
        }
    }

    /**
     * Utility method to convert SFloat data to Float string.
     * Example: Nonin 3230 gives SFloat (SFLOAT is a 16-bit data comprising a signed 4-bit integer exponent followed by a signed 12-bit Mantissa)
     */
    public static hexToSFloat(str: string) {
        var sfloat = parseInt(str, 16);
        const SFLOAT_MANTISSA_MASK = 0x0FFF;
        const SFLOAT_EXPONENT_MASK = 0xF000;
        const SFLOAT_EXPONENT_SHIFT = 12;

        // Extract exponent and mantissa
        let mantissa = sfloat & SFLOAT_MANTISSA_MASK;
        let exponent = (sfloat & SFLOAT_EXPONENT_MASK) >> SFLOAT_EXPONENT_SHIFT;

        // Sign-extend mantissa if necessary
        if (mantissa >= 0x0800) {
            mantissa = -(0x1000 - mantissa);
        }

        // Adjust exponent
        if (exponent >= 0x08) {
            exponent = -(0x10 - exponent);
        }

        // Calculate the floating-point value
        const value = mantissa * Math.pow(10, exponent);
        return value;
    }

    /**
     * While pairing and making connections, we need to set date and time for UC-352
     * This time is then adjusted on the device and send along with the further readings
     * @returns Uint8Array
     */
    public static getTimeStampForAndBLEDevice(): Uint8Array {
        const currentTime = new Date();
        const year = currentTime.getFullYear();
        const month = currentTime.getMonth() + 1;
        const day = currentTime.getDate();
        const hour = currentTime.getHours();
        const min = currentTime.getMinutes();
        const sec = currentTime.getSeconds();

        const bytes = new Uint8Array(7);
        bytes[0] = (year & 0x0FF);
        bytes[1] = (year >> 8);
        bytes[2] = (month);
        bytes[3] = (day);
        bytes[4] = (hour);
        bytes[5] = (min);
        bytes[6] = (sec);
        return bytes;
    }

    /**
    * This method parses the date and time information which is present in the scale
    * measurement data.
    * @param buffer
    * @param yearOffset
    * @returns
    */
    public static fetchReadingTimestampForAnDBLEDevice(buffer: Uint8Array, yearOffset: number): Date {
        // Time Stamp example 2013/8/26 9:10:20→0xDD 0x07 0x08 0x1A 0x09 0x0A 0x14
        let receivedDate = new Date();

        const yearByteFirst = buffer[yearOffset];
        yearOffset++;

        const yearByteSecond = buffer[yearOffset];
        const year = (yearByteFirst & 0x0FF) | (yearByteSecond << 8);
        receivedDate.setFullYear(year);
        yearOffset++;

        const month = buffer[yearOffset];
        receivedDate.setMonth(month - 1);
        yearOffset++;

        const date = buffer[yearOffset];
        receivedDate.setDate(date);
        yearOffset++;

        const hours = buffer[yearOffset];
        receivedDate.setHours(hours);
        yearOffset++;

        const minutes = buffer[yearOffset];
        receivedDate.setMinutes(minutes);
        yearOffset++;

        const seconds = buffer[yearOffset];
        receivedDate.setSeconds(seconds);
        yearOffset++;

        return receivedDate;
    }

    /**
    * This method checks whether the reading is and old reading based on the date
    * obtained with the reading
    * @param date : obtained with the scale reading
    * @returns boolean
    */
    public static isAnOldReadingForAnDDevice(date: Date, maxTimeDifference: number): boolean {
        const deltaTimeInSeconds = this.getReadingTimeDifference(date);
        return (deltaTimeInSeconds > maxTimeDifference);
    }

    public static getReadingTimeDifference(date: Date): number {
        const currentTime = Date.now();
        const deltaTimeInSeconds = (currentTime - date.getTime()) / 1000;
        return deltaTimeInSeconds;
    }
}
