import {GatewayApi} from '@hrs/providers';
import {HttpClient} from '@angular/common/http';
import {AdminTokenService} from './admin-token.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AdminGatewayApiService extends GatewayApi {
    constructor(http: HttpClient, adminTokenService: AdminTokenService) {
        super(http, adminTokenService);
    }
}
