<hrs-wizard [config]="wizardConfig" variant="default" data-qa="qa-historical_survey--wizard">
    <hrs-content variant="default" class="app-survey-historical-data--content" data-qa="qa-historical_survey--list">
        <div *ngIf="listConfig" class="app-survey-historical-data--list-container">
            <hrs-list [list]="listConfig"></hrs-list>
        </div>
        <div *ngIf="!listConfig" class="app-survey-historical-data--message-container">
            <div class="app-survey-historical-data--message">
                <hrs-text *ngIf="listInitialLoad" font="h1-semibold">{{ 'LOADING_ELLIPSIS' | translate }}</hrs-text>
                <hrs-text *ngIf="!listInitialLoad" font="h1-semibold">{{ 'GENERIC_METRIC.HISTORICAL.NO_READINGS' | translate }}</hrs-text>
            </div>
        </div>
    </hrs-content>
    <hrs-footer [variant]="isHRSTab ? 'two-button' : 'one-button'">
        <hrs-button
                *ngIf="isHRSTab"
                class="historical_survey--btn-speak"
                type="button"
                variant="secondary"
                data-qa="qa-historical_survey--btn-speak"
                icon="audio"
                (click)="readAllContent()"
        ></hrs-button>
        <hrs-button
                type="submit"
                variant="primary"
                data-qa="qa-historical_survey--btn-close"
                text="{{ 'CLOSE_BUTTON' | translate }}"
                [expand]="!isHRSTab"
                (click)="dismiss()"
        ></hrs-button>
    </hrs-footer>
</hrs-wizard>
