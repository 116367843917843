import {Injectable} from '@angular/core';
import {PatientTabletSettings} from '@hrs/interfaces';
import {GatewayResourceService, GatewayResponse} from '@hrs/gateway';
import {AudioService} from '../audio/audio.service';
import {LanguageService} from '../language/language.service';
import {User} from '../user/user.service';
import {ISOValuesFromLanguage} from '../../enums';
import {lastValueFrom} from 'rxjs';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})

export class TabletSettingsService {
    private readonly logger = getLogger('TabletSettingsService');
    public settingsFetchInProgress: Promise<any>;

    private resolver: (..._args: any[]) => void;
    private tabletSettings: PatientTabletSettings = {};

    constructor(
        private audio: AudioService,
        private gateway: GatewayResourceService,
        private language: LanguageService,
        private user: User
    ) {}

    public getTabletSettings(constructPromise?: boolean): void {
        this.logger.debug(`getTabletSettings()`);
        if (constructPromise) this.constructSettingsFetchPromise();

        this.gateway.get({endpoint: `v1/tablet-settings/${this.user.id}`})
            .subscribe({
                next: (res) => {
                    if (res.data && res.data['attributes']) {
                        this.logger.debug(`getTabletSettings() -> got updated tablet settings data!`);
                        this.tabletSettings = res.data['attributes'];
                    }
                },
                error: (error) => {
                    this.logger.phic.error('ERROR getting tablet settings: ', error);
                    this.resolveSettingsFetchPromise();
                },
                complete: () => this.handleSettingsResponse()
            });
    }

    public putTabletSettings(updates: PatientTabletSettings): Promise<GatewayResponse<{}> | void> | Promise<void> {
        this.logger.debug(`putTabletSettings() updates = `, updates);
        let shouldUpdate = false;
        for (let update in updates) {
            if (updates[update] !== this.tabletSettings[update]) {
                shouldUpdate = true;
            }
        }

        this.logger.debug(`putTabletSettings() shouldUpdate = ${shouldUpdate}`);

        if (shouldUpdate) {
            this.tabletSettings = {...this.tabletSettings, ...updates};
            this.logger.debug(`sending tablet settings update request = `, this.tabletSettings);
            return lastValueFrom(this.gateway.put({endpoint: `v1/tablet-settings/${this.user.id}`}, this.tabletSettings))
                .catch((err)=> this.logger.error(`putTabletSettings() failed! -> `, err));
        } else {
            return Promise.resolve();
        }
    }

    /**
     * The settingsFetchInProgress promise will exist if the home screen refresher triggers a tablet settings fetch
     * The promise will prevent a language change event from firing an additional getTasks event
     * And allow HomePage.pullToRefresh handle getTasks one time
     */
    public constructSettingsFetchPromise(): void {
        this.settingsFetchInProgress = new Promise((resolve) => {
            this.resolver = resolve;
        });
    }

    public resolveSettingsFetchPromise(): void {
        if (this.settingsFetchInProgress) {
            this.resolver();
            this.settingsFetchInProgress = undefined;
            this.resolver = undefined;
        }
    }

    public async handleSettingsResponse(): Promise<void> {
        this.logger.debug(`handleSettingsResponse()`);
        let changeVolume: boolean = false;
        let volume: number;
        const language = this.tabletSettings.language && ISOValuesFromLanguage[this.tabletSettings.language];
        const audioReminders = this.tabletSettings.audioreminders;
        const localVolume = this.audio.volume;

        if (audioReminders && !this.audio.audioReminders.includes(audioReminders)) {
            this.logger.debug(`update audioReminders = ${audioReminders}`);
            this.audio.audioReminders = audioReminders;
            changeVolume = true;
        }

        if (isNaN(this.tabletSettings.volume)) {
            this.tabletSettings.volume = localVolume;
            volume = localVolume;
        } else {
            volume = this.tabletSettings.volume;
        }

        if (localVolume !== volume) {
            changeVolume = true;
        }

        this.logger.debug(`localVolume = ${localVolume}` +
            `, requestedVolume = ${volume}` +
            `, changeVolume = ${changeVolume}`);

        if (changeVolume) {
            this.audio.requestVolumeChange(volume)
                .catch((err) => this.logger.warn(`handleSettingsResponse() failed to change volume -> `, err));
        }

        if (language && this.language.getUserLanguage() !== language) {
            await this.language.setTranslation(language);
            this.resolveSettingsFetchPromise();
        } else {
            this.resolveSettingsFetchPromise();
        }
    }

    /**
      *  CC2 displays 100 volume steps, samsung has 15 volume steps
     */
    public static convertVolume(level: number, toSamsung?: boolean): number {
        const cc2Steps = 100;
        const samsungSteps = 15;

        if (toSamsung) {
            return Math.round((level * 0.01) * samsungSteps);
        } else {
            return Math.round((level / samsungSteps) * cc2Steps);
        }
    }
}
