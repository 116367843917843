/* eslint hrs-lint/require-space-between-class-methods: off */
import {InjectFlags, InjectionToken, Injector, Type} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AlertButtonOptions} from '@hrsui/core/dist/types/components/alert/alert.interface';
import {textAlignment} from '@hrsui/core/dist/types/components/text/text.interface';
import {
    WizardConfigDetail,
    wizardVariant
} from '@hrsui/core/dist/types/components/wizard/wizard.interface';

export interface OverlayRefCloseOptions {
    isAffirmativeChoice?: boolean;
    submittedMeds?: string[];
}

export interface OverlayRefDismissReason extends Record<string, any> {
    message?: string;
    data?: any;
}

export interface OverlayRefDismissResult extends Record<string, any> {
    overlayComponentRefId: number;
    overlayDismissed?: boolean;
    isAffirmativeChoice?: boolean;
    reason?: OverlayRefDismissReason;
}

/**
 * Reference class for controlling and passing data between Child Component, Overlay, and Parent Component
 * @param: overlayComponentRefId - required to reference Overlay Instance in OverlayService
 * @usage: pass `private overlay: OverlayRef` in constructor of Overlay Child Component to expose close/dismiss
 */
export class OverlayRef {
    private readonly _result = new Subject<OverlayRefDismissResult>();
    public readonly result$: Observable<OverlayRefDismissResult>;

    constructor(public overlayComponentRefId: number) {
        this.result$ = this._result.asObservable();
    }

    public close(data: OverlayRefCloseOptions = {}): void {
        const closeResult = {...data, overlayComponentRefId: this.overlayComponentRefId};
        this._result.next(closeResult);
    }

    /**
     * Use when no action is taken
     * @param reason - optional string explaining dismissal
     */
    public dismiss(reason?: {message?: string, data?: any}): void {
        const overlayDismissed = true;
        const overlayComponentRefId = this.overlayComponentRefId;
        this._result.next({overlayComponentRefId, overlayDismissed, reason});
    }
}

/**
 * Allows us to add custom objects to child component via dependency injection
 * Normally, child components only search the parent Injector for constructor tokens
 * Here we bypass those gets to also search our _additionalTokens map at the time of Component creation
 * @usage: see OverlayService.createOverlay for implementation
 */
export class OverlayInjector implements Injector {
    constructor(
        private _parentInjector: Injector,
        private _additionalTokens: WeakMap<any, any>
    ) {}

    get<T>(
        token: Type<T> | InjectionToken<T>,
        notFoundValue?: T,
        flags?: InjectFlags
    ): T;
    get(token: any, notFoundValue?: any);
    get(token: any, notFoundValue?: any, flags?: any) {
        const value = this._additionalTokens.get(token);
        if (value) return value;
        return this._parentInjector.get<any>(token, notFoundValue);
    }
}

export type overlayType = 'alert' | 'modal' | 'toast' | 'wizard';

export interface OverlayOpts {

    /**
     * Component to render within Modal body
     */
    component?: Type<any>;

    /**
     * Overlay Configuration options
     * @see: OverlayConfig class definition below
     */
    config: OverlayConfig;

    /**
     * Type of overlay to render
     */
    type: overlayType;
}

/**
 * Class holding Overlay configuration settings. Please use caution and consideration when adding new settings.
 * Any added settings should add long-term value to either dev experience or the product itself.
 * Please do not add component-specific or otherwise single-use settings.
 */
export class OverlayConfig<D = any> {
    /**
     * If `true`, modal header displays a "minimize" icon button and support minimization functionality
     */
    canMinimize?: boolean = false;

    /**
     * How many milliseconds to wait before hiding toast
     */
    duration?: number;

    /**
     * Type of wizard to use
     */
    wizardVariant?: wizardVariant;

    /**
     * Wizard configuration settings
     */
    wizardConfig?: WizardConfigDetail;

    /**
     * Alert variant to render.
     */
    variant?: 'default' | 'success' | 'error' | 'multi-button' = 'default';

    /**
     * If `true`, alert will be dismissed when user taps on `hrs-backdrop`
     */
    backdropDismiss?: boolean;

    /**
     * Custom CSS class to apply to the overlay
     */
    cssClass?: string;

    /**
     * Alert header.
     */
    header?: string;

    /**
     * Alert includes a spinner at the top of content
     */
    hasSpinner?: boolean;

    /**
     * Alert message.
     * Text to be displayed at the top of the alert's content.
     */
    message?: string[];

    /**
     * Alignment for message text (i.e. left, right, center, etc.)
     */
    messageAlign?: textAlignment;

    /**
     * Spacing to be used between messages
     */
    messageSpacing?: boolean;

    /**
     * Array of objects detailing button configuration options
     */
    buttons?: AlertButtonOptions[];

    /**
     * Alert details.
     * Text to be displayed under the message in bullet points.
     */
    details?: string[];

    /**
     * Primary button text.
     * @default: 'Confirm'.
     */
    primaryButtonText?: string;

    /**
     * Secondary button text.
     * @default: 'Cancel'.
     */
    secondaryButtonText?: string;

    /** *************************************************************************************** /

     /**
     * Component to render within Modal body
     */
    component?: Type<any>;

    /**
     * Optional data payload for any data that would not make sense to include in result Observable
     */
    data?: D;

    /**
     * Option to keep or remove standard overlay footer.
     * @default: false (footer included)
     */
    hasNoFooter?: boolean;

    /**
     * Option to keep or remove standard button in the header.
     * @default: false (button included)
     */
    hasNoHeaderButton?: boolean;

    /**
     * Option to add dynamic variables to child component.
     */
    inputs?: any;

    /**
     * Custom callback function for primary button (click)
     * @default: dismiss();
     */
    primaryBtnCallback?: Function | string;

    /**
     * Members that will be resolved and passed to the controller as locals; it is equivalent of the `resolve` property for AngularJS routes
     * If property value is an array, it must be in Inline Array Annotation format for injection (strings followed by factory method)
     */
    resolve?: { [key: string]: string | Function | Array<string | Function> | Object };

    /**
     * Custom callback function for secondary button (click).
     * @default: dismiss();
     */
    secondaryBtnCallback?: Function;

    /**
     * Text to display in header
     */
    title?: string;

    /**
     * Provides the suffix for a `data-qa` data attribute to be used as a QA Locator.
     * @example <hrs-button qa="profile--button"> => <hrs-button data-qa="profile--button">
     */
    qa: string;
}

export interface ToastOverlayConfig extends OverlayConfig {

    /**
     * Text for toast link
     */
    redirectText?: string;

    /**
     * Allows toast link
     */
    allowRedirect?: boolean;

    /**
     * Handles toast link click
     */
    handler?: () => void;
}
