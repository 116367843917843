import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {MedicationPage} from './medication.page';
import {ComponentLibraryModule} from '@hrsui/angular';

const routes: Routes = [
    {
        path: '',
        component: MedicationPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild(),
        RouterModule.forChild(routes)
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [MedicationPage]
})
export class MedicationPageModule {}
