import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {ComponentLibraryModule} from '@hrsui/angular';
import {PinDialogPage} from './pin-dialog.page';

const routes: Routes = [
    {
        path: '',
        component: PinDialogPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentLibraryModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [PinDialogPage],
})
export class PinDialogPageModule {}
