import {SurveyChoiceType} from './survey-choice-type.enum';
import {SurveyChoice} from './survey-choice.model';
import {Task} from './task.model';
import {User} from '../user/user.service';
import * as moment from 'moment';

export class SurveyTask extends Task {
    private SUPPORTED_CHOICE_TYPES = [SurveyChoiceType.Normal, SurveyChoiceType.Numeric];

    surveyId: string;
    question: string;
    answered: boolean;
    onetime: boolean;
    discharge: boolean;
    private _choices: SurveyChoice[];

    set(data: Partial<SurveyTask>) {
        Object.assign(this, data);
    }

    get choices() {
        return this._choices;
    }

    set choices(value) {
        let choices = [];
        if (value) {
            // Looping instead of just assigning the array all at once so that we call the constructor of the child class
            for (let i = 0; i < value.length; i++) {
                choices.push(new SurveyChoice(value[i]));
            }
        }
        this._choices = choices.filter((choice) => {
            return this.SUPPORTED_CHOICE_TYPES.indexOf(choice.type) > -1;
        });
    }

    isValid() {
        // "onetime" surveys should be invalidated if it's been answered before:
        // checking "answered" if we've made a new call for fresh data
        // checking "lastCompleted" if we are looking at cache data
        const onetimeCompleted = (this.answered || this.lastCompleted) && this.onetime;
        const isValidQuestion = this.surveyId && this.question && this.choices && this.choices.length && !onetimeCompleted && super.isValid();

        if (User._data) {
            switch (User._data.status) {
                case 'paused':
                case 'deactivated':
                    // "paused" and "deactivated" patients should not be shown any surveys at all
                    return false;
                case 'predeactivated':
                    // "predeactivated" patients should be shown all questions including
                    //  "discharge" surveys starting on the day that the clinician has chosen
                    const isEndOfEnrollment = moment().isSameOrAfter(User._data.deactivated_at);
                    return isValidQuestion && (!this.discharge || (this.discharge && isEndOfEnrollment));
            }
        }

        // "activated" and "preactivated" patients should be shown all questions except "discharge"
        return isValidQuestion && !this.discharge;
    }
}
