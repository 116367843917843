import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {ModalService} from '@hrs/providers';
import {HRSStorage} from '../storage/storage';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

/**
 * Creating constant for TNG glucometer.
 * Peripheral name of TNG glucometer is 'TNG'
 * and devices are being idetified with their names
 * everywhere in the code using includes method as TNG is common in other
 * TNG devices ex: TNG spo2 and TNG scale
 * We are using === to indetify TNG glucometer
 * In future we will do device identification on the basis of UUID
 */
export let TNG_BLE_GLUCOMETER = 'TNG';
export let LAST_GLUCOSE_TIME_MEASUREMENT_KEY = 'LAST_GLUCOSE_TIME_MEASUREMENT_KEY';

@Injectable({
    providedIn: 'root',
})
export class GlucoseDeviceService {
    private readonly logger = getLogger('GlucoseDeviceService');

    public glucose: number;
    public LAST_GLUCOSE_READING_KEY = 'LAST_GLUCOSE_READING';
    NIPRO_BYTE = 0x06;
    NIPRO_NEW_BYTE = 0x01;
    NIPROBGM_CONTROL_SERVICE = '00A215BD-9B02-4E5A-9A65-98F1095F4755';
    NIPROBGM_CONTROL_CHARACTERISTIC = '448AF2D8-36A2-42A1-86B5-F51207C36760';
    NIPROBGM_SERVICE = '1808';
    NIPROBGM_CHARACTERISTIC = '2A18';
    NIPROBGM_GLUCOSE_MEASUREMENT_CONTEXT = '2A34';
    NIPROBGM_DATA_CHARACTERISTIC = '2A52';

    FORACARE_SERVICE = '00001523-1212-EFDE-1523-785FEABCD123';
    FORACARE_CHARACTERISTIC = '00001524-1212-EFDE-1523-785FEABCD123';

    metricChange = new Subject();
    bluetoothMetricChange$: Observable<any>;
    currentReadingTimestamp: Date;

    isRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    // Foracare Byte Commands and Request Messages
    CMD_ACK = 0x51;
    CMD_START = 81;
    CMD_END = 163;
    CMD_COMMS = 0x54; // 84
    CMD_DATA_COUNT = 0x2B; // 43
    CMD_DATA_TIME = 0x25; // 37
    CMD_DATA = 0x26; // 38
    CMD_DATA_SCALE = 0x71;
    CMD_DATA_CLEAR = 0x52;
    CMD_TAKE_MEASUREMENT = 0x41; // 65
    CMD_POWER_OFF = 0x50;
    CMD_DATA_POINTS = 0x2;

    constructor(
        private ble: BLE,
        private ngZone: NgZone,
        private modalService: ModalService,
        private bluetoothSerial: BluetoothSerial,
        private fbAnalytics: HRSFirebaseAnalytics,
        protected storage: HRSStorage
    ) {
        this.bluetoothMetricChange$ = this.metricChange.asObservable();
    }

    onGlucoseMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, peripheralType);
        if (
            peripheralType == 'glucose' &&
            (peripheralName.includes('Nipro') || peripheralName.includes('TRUEAIR')) &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(async () => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug(peripheralName + ' Hex Data: ', BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer[0] == 7 && arrayBuffer[1] == 6) {
                    let data = new Uint8Array([this.NIPRO_NEW_BYTE, this.NIPRO_BYTE]);
                    if (peripheralName.includes('TRUEAIR')) { // DEV-15782 TrueAir didnt transmit readings at times without this delay
                        setTimeout(() => {
                            this.logger.phic.debug('writeGlucometerNiproCharacteristic()');
                            this.writeGlucometerNiproCharacteristic(peripheral, data);
                        }, 50);
                    } else {
                        this.writeGlucometerNiproCharacteristic(peripheral, data);
                    }
                } else if (arrayBuffer[0] == 18) {
                    let yearStart = arrayBuffer[3];
                    let yearEnd = arrayBuffer[4];
                    let year = yearEnd * 256 + yearStart;
                    let month = arrayBuffer[5];
                    let day = arrayBuffer[6];
                    let hours = arrayBuffer[7];
                    let minutes;
                    if (arrayBuffer[8] < 10) {
                        minutes = ('0' + arrayBuffer[8]).slice(-2);
                    } else {
                        minutes = arrayBuffer[8];
                    }
                    // Needs to be set in order to check for measurements > 256
                    const byte1 = arrayBuffer[10] & 0xFF;
                    const byte2 = arrayBuffer[11] & 0x01;
                    this.logger.phic.trace('Array Buff LSB ' + byte1 + ' MSB ' + byte2);
                    const newReading = (byte2 << 8) | byte1;
                    this.logger.phic.debug('Array Buffer combined reading', newReading);
                    const broadcastNewReading = async (dateStringISO: string) => {
                        this.glucose = newReading;
                        this.logger.phic.debug('Broadcasting new reading for BGM ' + peripheralName + ': Reading ' + newReading );
                        if (this.glucose > 0) {
                            this.logger.phic.debug(peripheralName + ' Got latest reading' + this.glucose + ' will show on UI');
                            this.metricChange.next(peripheralName);
                            this.isRecentReading = true;
                            await this.storage.set(LAST_GLUCOSE_TIME_MEASUREMENT_KEY, dateStringISO);
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.READING_OFFSET]: this.getReadingTimeDifference(readingDate)});
                        }
                    };
                    const lastGlucoseTimestampISO = await this.storage.get(LAST_GLUCOSE_TIME_MEASUREMENT_KEY);
                    this.logger.phic.debug(peripheral.name + ' reading :: Meassure cal = Y ' + year + ' M ' + (month) + ' D ' + day + ' HH ' + hours + ' MM ' + minutes + ' ');
                    let readingDate = new Date(year, (month - 1), day, hours, minutes, 0);
                    this.logger.phic.debug(peripheral.name + 'Reading date -> ' + readingDate);
                    const dateStringISO = (month + '/' + day + '/' + year + ' ' + hours + ':' + minutes).toString();
                    const deviceReadingDate = moment(dateStringISO).locale('en').format('M/DD/YYYY HH:mm');
                    const isRecentReading = this.isRecentReadingForGlucometer(readingDate);
                    this.logger.phic.debug(' Glucose reading timestamp parameters: ' + peripheralName + ' isRecentReading ' + isRecentReading +
                    ' deviceReadingDate ' + deviceReadingDate + ' lastGlucoseTimestampISO' + lastGlucoseTimestampISO +
                    + ' dateStringISO ' + dateStringISO);

                    if (isRecentReading && (!lastGlucoseTimestampISO || (lastGlucoseTimestampISO !== dateStringISO)) ){
                        // We allow recent reading (taken within last 15 minutes) which has not been transmitted yet
                        await broadcastNewReading(dateStringISO);
                    } else {
                        this.historicalReadingObtained = true;
                        this.logger.phic.debug(peripheralName + ': Not broadcasting new reading.' );
                    }
                } else if (arrayBuffer.length >= 3 && arrayBuffer[0] == 6 && arrayBuffer[3] == 128) {
                    // Retried to fetch readings when glucometer responded with error bytes [6, 0, 1, 128]
                    this.logger.phic.debug('Error bytes recieved, retrying to fetch readings for BGM');
                    let data = new Uint8Array([this.NIPRO_NEW_BYTE, this.NIPRO_BYTE]);
                    this.writeGlucometerNiproCharacteristic(peripheral, data);
                }
            });
        } else if (
            peripheralType == 'glucose' &&
            peripheralName === TNG_BLE_GLUCOMETER &&
            !this.modalService.getModalStatus('GenericMetricPage')
        ) {
            this.ngZone.run(() => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug('onGlucoseMetricMeasurementChange arrayBuffer data', JSON.stringify(arrayBuffer) + 'hex values' + BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer.length == 2 && (arrayBuffer[0] == 1) && arrayBuffer[1] == 0) {
                    this.logger.phic.debug('Writing data count command for ' + peripheralName);
                    let dataCount = this.addCheckSum([81, this.CMD_DATA_COUNT, 0, 0, 0, 0, 163]);
                    this.writeForacareGlucometerBLECharacteristic(peripheral, dataCount, false);
                } else if (arrayBuffer.length == 8) {
                    if (arrayBuffer[1] == this.CMD_DATA_COUNT) {
                        this.logger.phic.debug('Writing date time command for ' + peripheralName);
                        let dataDateTime = this.addCheckSum([81, this.CMD_DATA_TIME, 0, 0, 0, 0, 163]);
                        this.writeForacareGlucometerBLECharacteristic(peripheral, dataDateTime, false);
                    } else if (arrayBuffer[1] == this.CMD_DATA_TIME) {
                        if (arrayBuffer[2] == 0 && arrayBuffer[3] == 0 && arrayBuffer[4] == 0){
                            /**
                             * Invalid readings: With correct reading TNG glucomter is giving few readings with 0 day,
                             * 0 month and 0 year which does not exist. So not considering those invalid readings.
                             */
                            this.logger.phic.error('Invalid readings for ' + peripheralName);
                            this.readingErrorOccurred = true;
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.VALIDATION_FAILURE});
                            return;
                        }
                        this.currentReadingTimestamp = this.parseTNGDateTime(arrayBuffer);
                        this.logger.phic.debug(peripheralName + ' TNG BLE Glucometer Current reading timestamp ' + this.currentReadingTimestamp);
                        if (this.isRecentReadingForGlucometer(this.currentReadingTimestamp)) {
                            this.logger.phic.debug('Got a valid recent reading from FORA TNG ' + peripheralName + ' will show on UI for submission');
                            let dataMeasurement = this.addCheckSum([81, this.CMD_DATA, 0, 0, 0, 0, 163]);
                            this.writeForacareGlucometerBLECharacteristic(peripheral, dataMeasurement, false);
                        } else {
                            this.logger.phic.debug('Not submitting an old stored reading from Glucometer ' + peripheralName);
                            this.historicalReadingObtained = true;
                            let turnOffDevice = this.addCheckSum([81, this.CMD_POWER_OFF, 0, 0, 0, 0, 163]);
                            this.writeForacareGlucometerBLECharacteristic(peripheral, turnOffDevice, true);
                        }
                    } else if (arrayBuffer[1] == this.CMD_DATA && arrayBuffer[2] > 0) {
                        this.glucose = (arrayBuffer[3] << 8) | arrayBuffer[2];
                        if (this.glucose > 0) {
                            this.logger.phic.debug('Got recent reading for Glucometer ' + peripheralName + ' will show on UI for submission');
                            this.metricChange.next(peripheralName);
                            this.isRecentReading = true;
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.READING_OFFSET]: this.getReadingTimeDifference(this.currentReadingTimestamp)});
                            this.logger.phic.debug('Requesting power off Glucometer ' + peripheralName);
                            let requestPowerOff = this.addCheckSum([81, this.CMD_POWER_OFF, 0, 0, 0, 0, 163]);
                            this.writeForacareGlucometerBLECharacteristic(peripheral, requestPowerOff, true);
                        }
                    }
                }
            });
        } else if (
            peripheralType == 'glucose' &&
            peripheralName.includes('TEST-N-GO') &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                this.bluetoothSerial.subscribeRawData().subscribe(
                    {
                        next: (data) => {
                            let arrayBuffer = new Uint8Array(data);
                            this.logger.phic.debug('Got data from Glucometer ' + peripheralName + ' ' + BluetoothUtils.buf2hex(arrayBuffer));
                            if (arrayBuffer[1] == this.CMD_COMMS) {
                                this.logger.phic.debug(peripheralName + ' Glucometer writing data count command');
                                let dataCount = this.addCheckSum([81, this.CMD_DATA_COUNT, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataCount);
                                this.onGlucoseMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[1] == this.CMD_DATA_COUNT) {
                                this.logger.phic.debug(peripheralName + ' Glucometer writing date time command');
                                let dataDateTime = this.addCheckSum([81, this.CMD_DATA_TIME, 0, 0, 0, 0, 163]);
                                this.writeForacareDataPacket(dataDateTime);
                                this.onGlucoseMetricMeasurementChange(null, peripheralType, peripheral);
                            } else if (arrayBuffer[1] == this.CMD_DATA_TIME) {
                                let dataMeasurement = this.addCheckSum([81, this.CMD_DATA, 0, 0, 0, 0, 163]);
                                // Data_1 + Data_0 : Year (7 bit) + Month (4 bit) + Day (5 bit)
                                // Data_2 : Minute (6 bit), Data_3 : Hour(5 bit)
                                this.currentReadingTimestamp = this.parseTNGDateTime(arrayBuffer);
                                this.logger.phic.debug(peripheralName + ' Glucometer Current reading timestamp ' + this.currentReadingTimestamp);
                                if (this.isRecentReadingForGlucometer(this.currentReadingTimestamp)) {
                                    this.logger.phic.debug('Got recent reading from Glucometer ' + peripheralName + ' will show on metric reading page for submission');
                                    this.writeForacareDataPacket(dataMeasurement);
                                    this.onGlucoseMetricMeasurementChange(null, peripheralType, peripheral);
                                } else {
                                    this.logger.phic.debug('Not Submitting an old stored reading from Glucometer ' + peripheralName);
                                    this.historicalReadingObtained = true;
                                    let turnOffDevice = this.addCheckSum([81, this.CMD_POWER_OFF, 0, 0, 0, 0, 163]);
                                    this.writeForacareDataPacket(turnOffDevice);
                                }
                            } else if (arrayBuffer[1] == this.CMD_DATA && arrayBuffer[2] > 0) {
                                this.logger.phic.debug('Parse and submit reading for FORA Test-N-Go BG');
                                this.parseandSubmitTnGGlucoseReading(arrayBuffer, peripheralName, peripheralDisplayName, peripheral.rssi, peripheralType, this.currentReadingTimestamp);
                            }
                        },
                        error: (error) => {
                            this.logger.phic.error('Error reading raw data for Glucometer ' + peripheralName, JSON.stringify(error));
                            this.readingErrorOccurred = true;
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.ERROR_READING_DATA,
                                    [HRSFirebaseParams.META_DATA]: error});
                        }
                    }
                );
            });
        }
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (!isPairingFlow){
            let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'glucose');
            if (!this.isRecentReading && !this.readingErrorOccurred && !peripheralDisplayName.includes('TEST-N-GO')) {
                let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'glucose',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
            }
        }
        // cleanup
        this.isRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }

    async parseandSubmitTnGGlucoseReading(arrayBuffer: Uint8Array, peripheralName: string, peripheralDisplayName: any, rssi:string, peripheralType: string, currentReadingTimestamp: Date ) {
        const newReading = (arrayBuffer[3] << 8) | arrayBuffer[2];
        this.glucose = newReading;
        if (this.glucose > 0) {
            this.logger.phic.debug('Submitting recent reading from FORA Test-N-Go BG ' + this.glucose);
            this.metricChange.next(peripheralName);
            this.isRecentReading = true;
            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                    [HRSFirebaseParams.RSSI]: rssi, [HRSFirebaseParams.READING_OFFSET]: this.getReadingTimeDifference(currentReadingTimestamp)});
            this.logger.phic.debug('Turning off FORA Test-N-Go BG ');
            let turnOffDevice = this.addCheckSum([81, this.CMD_POWER_OFF, 0, 0, 0, 0, 163]);
            this.writeForacareDataPacket(turnOffDevice);
        }
    }

    /**
     * This method compares the reading time stamp with current date and checks whether
     * reading is a recent one. Reading is considered latest if reading time difference is less than 15 minutes.
     * @param readingTimestamp
     * @returns boolean
     */
    private isRecentReadingForGlucometer(readingTimestamp: Date): boolean {
        const fifteenMinutes = 900000; // This is a clinical & product recommended limit for blood glucometers
        let readingTimeDiff = this.getReadingTimeDifference(readingTimestamp);
        return readingTimeDiff < fifteenMinutes;
    }

    private getReadingTimeDifference(readingTimestamp: Date): number {
        const currentDate = Date.now();
        this.logger.phic.log('Current device date ' + currentDate);
        let readingTimeDiff = currentDate - readingTimestamp.getTime();
        return readingTimeDiff;
    }

    /**
     * This method will parse date time in Date format from fetched device information
     * @param arrayBuffer
     * @returns Date
     */
    private parseTNGDateTime(arrayBuffer: ArrayBuffer): Date {
        let day = arrayBuffer[2];
        let dayBitMask = 0x1f; // bitmask 00011111
        let extractedDay = day & dayBitMask;
        let monthLB = arrayBuffer[2] >>> 5;
        let monthHB = (arrayBuffer[3] & 0x01) << 3;// bitmask 00000001
        let month = monthLB | (monthHB);
        let year = arrayBuffer[3];
        year = (year >>> 1);
        year = 2000 + year;
        let minutes = arrayBuffer[4] & 0x3F; // bitmask 00111111
        let hours = arrayBuffer[5] & 0x3F; // bitmask 00111111
        this.logger.phic.debug('FORA TnG reading :: Meassure cal = Y ' + year + ' M ' + (month) + ' D ' + extractedDay + ' HH ' + hours + ' MM ' + minutes + ' ');
        return new Date(year, (month - 1), extractedDay, hours, minutes, 0);
    }

    writeForacareDataPacket(bytes: ArrayBuffer): void {
        this.bluetoothSerial.write(bytes).then(
            (success) => {
                this.logger.phic.debug('Successful writing of data packet for classic device bluetooth ', success);
            }, (error) => {
                this.logger.phic.error('Error writing data packet for classic device', error);
            }
        );
    }

    public onACLConnect(peripheral: any): void {
        let dataCount = this.addCheckSum([81, this.CMD_DATA_COUNT, 0, 0, 0, 0, 163]);
        this.writeForacareGlucometerBLECharacteristic(peripheral, dataCount, false);
    }

    onNiproDescriptorWrite(peripheral: any): void {
        // Service and Data Characteristic
        this.logger.phic.debug('onNiproDescriptorWrite()');
        this.ble.startNotification(peripheral.id, this.NIPROBGM_SERVICE, this.NIPROBGM_DATA_CHARACTERISTIC).subscribe(
            {
                next: (data) => {
                    this.logger.phic.debug('Response for char:' + this.NIPROBGM_DATA_CHARACTERISTIC);
                    this.onGlucoseMetricMeasurementChange(data[0], 'glucose', peripheral);
                },
                error: (error) => {
                    this.logger.phic.error('Notification failure for char ' + this.NIPROBGM_DATA_CHARACTERISTIC + ' for ' + peripheral.name, error);
                }
            }
        );
    }

    onNiproEnableNotification(peripheral: any): void {
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'glucose');
        // Service and Data Characteristic
        this.logger.phic.debug('onNiproEnableNotification()');
        this.ble.startNotification(peripheral.id, this.NIPROBGM_SERVICE, this.NIPROBGM_GLUCOSE_MEASUREMENT_CONTEXT).subscribe(
            {
                next: (data) => {
                    this.logger.phic.debug('Response for char:' + this.NIPROBGM_GLUCOSE_MEASUREMENT_CONTEXT);
                    this.onGlucoseMetricMeasurementChange(data[0], 'glucose', peripheral);
                },
                error: (error) => {
                    this.logger.phic.error('Notification failure for char ' + this.NIPROBGM_GLUCOSE_MEASUREMENT_CONTEXT + ' from ' + peripheral.name, error);
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'glucose',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                            [HRSFirebaseParams.META_DATA]: error});
                }
            }
        );
    }

    onNiproEnableIndication(peripheral: any): void {
        let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, 'glucose');
        // Service and Data Characteristic
        this.logger.phic.debug('onNiproEnableIndication()');
        this.ble.startNotification(peripheral.id, this.NIPROBGM_CONTROL_SERVICE, this.NIPROBGM_CONTROL_CHARACTERISTIC).subscribe(
            {
                next: (data) => {
                    this.logger.phic.debug('Response for char:' + this.NIPROBGM_CONTROL_CHARACTERISTIC);
                    this.onGlucoseMetricMeasurementChange(data[0], 'glucose', peripheral);
                },
                error: (error) => {
                    this.logger.phic.error('Notification failure for char ' + this.NIPROBGM_CONTROL_CHARACTERISTIC + 'from ' + peripheral.name, error);
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'glucose',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                            [HRSFirebaseParams.META_DATA]: error});
                }
            }
        );

        let timeout = (peripheral?.name?.includes('TRUEAIR')) ? 50 : 0;
        setTimeout(() => {
            let arrayBuffer = new Uint8Array([this.NIPRO_BYTE]);
            this.logger.phic.debug('Writing Char ' + this.NIPROBGM_CONTROL_CHARACTERISTIC + ' with data ', BluetoothUtils.buf2hex(arrayBuffer));
            this.ble.write(peripheral.id, this.NIPROBGM_CONTROL_SERVICE, this.NIPROBGM_CONTROL_CHARACTERISTIC, arrayBuffer.buffer).then(
                (res) => {
                    this.logger.phic.debug('Write Response for char ' + this.NIPROBGM_CONTROL_CHARACTERISTIC);
                    this.onGlucoseMetricMeasurementChange(arrayBuffer, 'glucose', peripheral);
                },
                (error) => {
                    this.logger.phic.error('Failed to write characteristic from Nipro device' + peripheral.name, error);
                });
        }, timeout);
    }

    public writeGlucometerNiproCharacteristic(peripheral: any, data: any): void {
        let peripheralName = peripheral.name;
        let peripheralId = peripheral.id;
        this.logger.phic.debug('writeGlucometerNiproCharacteristic()');
        this.ble.write(peripheralId, this.NIPROBGM_SERVICE, this.NIPROBGM_DATA_CHARACTERISTIC, data.buffer).then(
            (res) => {
                this.onGlucoseMetricMeasurementChange(data, 'glucose', peripheral);
            },
            (error) => {
                this.logger.phic.error('Failed to write characteristic from glucose device ' + peripheralName, error);
            });
    }

    public writeForacareGlucometerBLECharacteristic(peripheral: any, data: any, isClearData: boolean): void {
        let peripheralName = peripheral.name;
        let peripheralId = peripheral.id;
        this.ble.write(peripheralId, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, data.buffer).then(
            (res) => {
                if (!isClearData) {
                    this.onGlucoseMetricMeasurementChange(data.buffer, 'glucose', peripheral);
                }
            },
            (error) => {
                this.logger.phic.error('Failed to write characteristic from Nipro device ' + peripheralName, error);
            });
    }

    /**
     * Foracare documentation requires a check sum [1...n-1]. Checksum appends as the last byte of the transmission
     * you get n bytes, sum of the first n-1 bytes, see if the answer is the same as the last byte, etc
     * This is so that we get our full 8 byte check.
     */
    addCheckSum(bytes: number[]): ArrayBuffer {
        let out = new Uint8Array(bytes.length + 1);
        let checksum = 0;
        for (let i = 0; i < bytes.length; i++) {
            checksum += (bytes[i] & 0xFF);
            out[i] = bytes[i];
        }
        out[out.length - 1] = checksum;
        return out;
    }
}
