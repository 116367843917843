import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {Device} from '@ionic-native/device/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {WifiWizard2} from '@ionic-native/wifi-wizard-2/ngx';

import {WifiNetwork} from './wifi-network.interface';
import {OverlayService} from '@patient/providers';

@Component({
    selector: 'wifi-settings',
    templateUrl: './wifi-settings.component.html',
    styleUrls: ['./wifi-settings.component.scss'],
})
export class WifiSettingsComponent implements OnInit {
    static readonly TOGGLE: string = 'TOGGLE';
    static readonly CONNECT: string = 'CONNECT';

    availableNetworks: WifiNetwork[];
    connectedNetwork: string;
    isConnected: boolean;
    isScanning: boolean;
    isWifiOn: boolean;
    hasNoNetworks: boolean = false;
    hasScanError: boolean = false;
    isToggling: boolean = false;
    isAndroidTenOrGreater: boolean = false;
    unsubscribeResumeEvent: Subscription;

    constructor(
        private alertCtrl: AlertController,
        private changeDetectorRef: ChangeDetectorRef,
        private device: Device,
        private openNativeSettings: OpenNativeSettings,
        private overlay: OverlayService,
        private platform: Platform,
        private translate: TranslateService,
        private wifiWizard: WifiWizard2,
    ) {}

    ngOnInit(): void {
        this.isWifiEnabled();
        this.isAndroidTenOrGreater = +this.device.version >= 10;
        this.unsubscribeResumeEvent = this.platform.resume.subscribe(() => {
            this.isWifiEnabled().then(() => this.changeDetectorRef.detectChanges());
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeResumeEvent.unsubscribe();
    }

    async isWifiEnabled(): Promise<void> {
        return await this.wifiWizard.isWifiEnabled().then((isWifiOn: boolean) => {
            this.isWifiOn = isWifiOn;
            this.initWifi();
        });
    }

    /**
     * Turn wifi on/off
     */
    toggleWifi(): void {
        this.isToggling = true;
        if (!this.isWifiOn) this.isScanning = true;
        if (this.isAndroidTenOrGreater) {
            this.openNativeWifiPanelAlert(WifiSettingsComponent.TOGGLE);
        } else {
            this.toggleWifiAndroidNineMinus();
        }
    }

    /**
     * Due to breaking changes to the exposed android.wifiManager apis starting in android 10
     * programmatically turning wifi on/off via android.wifiManager.setEnabled is deprecated
     * ~ AND ~
     * WifiWizard apis for connecting to a network have not been updated to work with android 10 yet
     * this function alerts the user and opens the native wifi settings panel
     */
    async openNativeWifiPanelAlert(requestAction: string): Promise<void> {
        const action = requestAction === WifiSettingsComponent.TOGGLE ? 'ADMIN_WIFI_PANEL_ON_OFF' : 'ADMIN_WIFI_PANEL_CONNECT';
        await this.overlay.openAlert({
            header: this.translate.instant('ADMIN_WIFI_OPEN_PANEL'),
            message: [this.translate.instant(
                'ADMIN_WIFI_OPEN_MESSAGE',
                {action: this.translate.instant(action)}
            )],
            buttons: [{
                text: this.translate.instant('CANCEL_BUTTON'),
            }, {
                text: this.translate.instant('OPEN_BUTTON'),
                handler: () => {
                    this.openNativeSettings.open('wifi');
                    this.isToggling = false;
                }
            }],
            qa: 'native_wifi_alert'
        });
    }

    /**
     * For Android 9 and older this programmatically toggles wifi
     * wifiWizard.setWifiEnabled which hits android.wifiManager.setWifiEnabled
     * is still supported despite being deprecated for newer android versions
     */
    toggleWifiAndroidNineMinus(): void {
        this.wifiWizard.setWifiEnabled(!this.isWifiOn)
            .then((success: string) => {
                if (success === 'OK') {
                    this.isWifiOn = !this.isWifiOn;
                }
            }).finally(() => {
            // if wifi was just enabled this timeout relieves some blocking that prevents template from updating view
                setTimeout(() => this.initWifi(), 10);
            });
    }

    initWifi(): void {
        if (this.isWifiOn) {
            this.isConnectedToInternet();
            this.scan();
        } else {
            this.isConnected = false;
            this.isToggling = false;
            this.connectedNetwork = undefined;
        }
    }

    isConnectedToInternet(): void {
        let counter = 0;

        const checkConnection = () => this.wifiWizard.isConnectedToInternet().then(
            (isConnected: string) => {
                this.isConnected = isConnected === 'IS_CONNECTED_TO_INTERNET';
            })
            .catch((error) => {
                // when toggling wifi back on the function takes a long time to register if a connection is available
                // recursively call the function up to 10 times to get the connection state if it becomes available
                this.isConnected = false;
                if (counter < 10) setTimeout(() => checkConnection(), 500);
            })
            .finally(() => {
                counter += 1;
                if (this.isConnected) this.getConnectedSSID();
            });

        checkConnection();
    }

    scan(): void {
        this.isScanning = true;
        this.wifiWizard.scan().then(
            (networks: WifiNetwork[]) => {
                this.hasNoNetworks = networks.length === 0;
                this.hasScanError = false;
                if (!this.hasNoNetworks) this.filterByMaxStrength(networks);
            },
            (error) => this.hasScanError = true)
            .finally(() => {
                this.isScanning = false;
                this.isToggling = false;
            });
    }

    getConnectedSSID(): void {
        this.wifiWizard.getConnectedSSID().then(
            (networkSSID: string) => {
                this.connectedNetwork = networkSSID;
            })
            .catch( (error) => {
                this.connectedNetwork = undefined;
                // there is often a timeout error when loading
                // if we are connected to wifi we recursively call till we get the network name
                setTimeout(() => this.isConnected && this.getConnectedSSID(), 200);
            })
            .finally(() => {
                if (!this.changeDetectorRef['destroyed']) this.changeDetectorRef.detectChanges();
            });
    }

    /**
     * WifiWizard2 returns multiple instances of a each network,
     * here we filter to only show one instance of each network by signal strength
     * SSID = network name;
     * level = strength;
     * @param networks
     */
    filterByMaxStrength(networks: WifiNetwork[]): void {
        let allNetworks = {};
        networks.map((network) => {
            const hasGreaterLevel = (network) => network.level > allNetworks[network.SSID].level;

            if (network.SSID && (!allNetworks[network.SSID] || hasGreaterLevel(network))) {
                allNetworks[network.SSID] = network;
            }
        });
        this.availableNetworks = Object.values(allNetworks);
    }

    canDisplayList(): boolean {
        return this.isWifiOn && !this.isScanning && !this.hasNoNetworks && !this.hasScanError;
    }

    connectToNetwork(network: WifiNetwork): void {
        if (this.connectedNetwork && network.SSID === this.connectedNetwork) {
            this.isConnectedAlert(network);
        } else if (this.isAndroidTenOrGreater) {
            this.openNativeWifiPanelAlert(WifiSettingsComponent.CONNECT);
        } else {
            this.isNotConnectedAlert(network);
        }
    }

    async isConnectedAlert(network: WifiNetwork): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('ADMIN_WIFI_CONNECTED_TO_NETWORK'),
            message: [this.translate.instant('ADMIN_WIFI_IS_CONNECTED', {network: network.SSID})],
            buttons: [
                {
                    text: this.translate.instant('CLOSE_BUTTON'),
                }
            ],
            qa: 'wifi_connected_alert'
        });
    }

    async isNotConnectedAlert(network: WifiNetwork): Promise<void> {
        let statusAdded;
        let connectionStatus = document.createElement('p');

        const formatMessage = (isConnected: boolean): void => {
            connectionStatus.innerHTML = '';
            connectionStatus.innerHTML = isConnected ?
                this.translate.instant('ADMIN_WIFI_CONNECTION_SUCCESS') :
                this.translate.instant('ADMIN_WIFI_CONNECTION_ERROR');
            connectionStatus.className = '';
            connectionStatus.className = `c_admin_wifi--password ${isConnected ? 'success' : 'error'}`;
        };

        const formatSpinner = (passwordAlert: HTMLIonAlertElement): void => {
            const connecting = this.translate.instant('ADMIN_WIFI_CONNECTING');
            let messageSibling = passwordAlert.children[2].children[1];
            connectionStatus.innerHTML = '';
            connectionStatus.innerHTML = `${connecting} <ion-spinner name="lines-small"></ion-spinner>`;
            connectionStatus.className = '';
            connectionStatus.className = 'c_admin_wifi--password';

            if (!statusAdded) {
                messageSibling.after(connectionStatus);
                statusAdded = true;
            }
        };

        const connect = (password: string): void => {
            const algorithm = network.capabilities.includes('WEP') ? 'WEP' : 'WPA';
            this.wifiWizard.connect(network.SSID, true, password, algorithm).then(
                () => {
                    this.isConnected = true;
                    this.getConnectedSSID();
                    formatMessage(true);
                    setTimeout(() => {
                        this.alertCtrl.getTop().then((alert) => {
                            if (alert) this.alertCtrl.dismiss();
                        });
                    }, 3000);
                }).catch(() => {
                formatMessage(false);
            });
        };

        const alert = await this.alertCtrl.create({
            header: this.translate.instant('ADMIN_WIFI_CONNECT_TO'),
            message: network.SSID,
            inputs: [{
                name: 'networkPassword',
                type: 'password',
                placeholder: this.translate.instant('ADMIN_WIFI_PASSWORD')
            }],
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    handler: () => {}
                },
                {
                    text: this.translate.instant('ADMIN_WIFI_CONNECT'),
                    handler: (alertData) => {
                        this.alertCtrl.getTop().then((passwordAlert) => {
                            formatSpinner(passwordAlert);
                            connect(alertData.networkPassword);
                        });
                        return false;
                    }
                }
            ],
            cssClass: 'c_admin_wifi--password_alert'
        });

        return alert.present();
    }
}
