import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Task, TaskType} from '../tasks';
import {TaskService} from '../tasks/task.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class TaskTrackingService {
    private readonly logger = getLogger('TaskTrackingService');
    private currentAction = '';
    private currentActionInfo = '';
    private currentActionStart = 0;
    private task: Task;

    constructor(
        private taskService: TaskService,
    ) {
        this.task = new Task({type: TaskType.Tracking});
    }

    public startTracking(action: string, info: string): void {
        if (this.currentAction !== '' && action !== this.currentAction) {
            this.submitTracking(this.currentAction, this.currentActionInfo);
        }
        this.setActionState(action, info, moment().unix());
    }

    public stopTracking(): void {
        if (this.currentAction !== '') {
            this.submitTracking(this.currentAction, this.currentActionInfo);
        }

        this.setActionState();
    }

    public submitTracking(action: string, info: string): void {
        const data = {
            action: action,
            info: info,
            time: this.getActionDuration()
        };

        const metadata = {
            recordedDate: this.getRecordedDate()
        };

        this.taskService.submitTask(this.task, data, metadata).subscribe({
            next: () => {
                this.logger.phic.info('Successfully submitted tracking', data);
            },
            error: (err) => {
                this.logger.phic.error('Failed to submit tracking', err);
            }
        });
    }

    private setActionState(action = '', info = '', time = 0): void {
        this.currentAction = action;
        this.currentActionInfo = info;
        this.currentActionStart = time;
    }

    private getActionDuration(): number {
        return this.currentActionStart === 0 ? 0 : moment().unix() - this.currentActionStart;
    }

    private getRecordedDate() {
        return moment().locale('en').format();
    }
}

