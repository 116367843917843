import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {Platform} from '@ionic/angular';
import {Observable, defer, switchMap} from 'rxjs';
import {getLogger} from '@hrs/logging';

export abstract class KnoxIntentService {
    private readonly baseLogger = getLogger('KnoxIntentService');
    private static KNOX_PACKAGE = 'com.hrs.patientconnectknoxservice';
    private static FLAG_INCLUDE_STOPPED_PACKAGES = 32;

    protected constructor(
        private broadcaster: Broadcaster,
        protected readonly platform: Platform
    ) {}

    protected abstract registerListeners(): void;

    protected registerListener(listener: string, isGlobal: boolean): Observable<any> {
        return defer(() => this.platform.ready()).pipe(
            switchMap(() => this.broadcaster.addEventListener(listener, isGlobal))
        );
    }

    protected async sendIntent(intent: string, isGlobal: boolean = true, extras?: {[key: string]: string}): Promise<boolean> {
        let data = {
            packageName: KnoxIntentService.KNOX_PACKAGE,
            flags: KnoxIntentService.FLAG_INCLUDE_STOPPED_PACKAGES,
        };
        if (extras) data['extras'] = extras;
        await this.platform.ready();
        this.baseLogger.debug(`sendIntent()`, {intent, isGlobal, extras});
        return this.broadcaster.fireNativeEvent(intent, isGlobal, data).then(() => {
            this.baseLogger.phic.debug('Plugin successfully sent intent: ' + intent);
            return true;
        }).catch((e) => {
            this.baseLogger.phic.error('Error sending intent: ' + intent);
            return false;
        });
    }

    protected handleResult(event: any, name: string): void {
        if (event.result) {
            this.baseLogger.debug(name + ' SUCCESS', event.result);
        } else {
            this.baseLogger.phic.error(name + ' FAIL', event);
        }
    }
}
