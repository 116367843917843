<ion-content class="pin-entry-content c_splash--page" scroll="false">
    <div class="c_splash--wrapper">
        <div class="c_splash--logo_container">
            <div class="c_splash--logo_bg"></div>
            <div class="c_splash--logo"></div>
        </div>
        <div class="c_splash--card_container is-login is-setup">
            <hrs-card variant="splash">
                <hrs-card-header text="{{ title | translate }}"></hrs-card-header>
                <hrs-card-content>
                    <div>
                        <span class="c_splash--contact" *ngIf="prompt">
                            {{ prompt | translate }}
                        </span>
                        <span class="c_splash--contact">
                            {{ text | translate }}
                        </span>
                    </div>
                    <form class="c_splash--form c_form"
                            [formGroup]="pinForm"
                            (ngSubmit)="enterPin()">
                        <hrs-form-item class="c_form--field-group">
                            <hrs-input class="c_form--field c_form--field-basic"
                                        autocapitalize="none"
                                        maxlength="4"
                                        type="text"
                                        placeholder="####"
                                        formControlName="pinInput">
                            </hrs-input>
                        </hrs-form-item>
                        <div class="c_card--buttons">
                            <hrs-button class="c_button c_button-center login"
                                        type="submit"
                                        [disabled]="!pinInput.valid">
                                {{ 'SAVE_BUTTON' | translate }}
                            </hrs-button>
                        </div>
                    </form>
                    <hrs-card-footer class="c_splash--card_footer c_splash--contact">
                        <hrs-icon icon="voice-call"
                                  data-qa="welcome--btn-support"></hrs-icon>
                        <div class="c_splash--contact_text">
                            <p>{{ 'WELCOME_CONTACT_SUPPORT' | translate }}</p>
                            <a href="#" (click)="initSupportCall()">
                                {{ 'WELCOME_SUPPORT_NUMBER' | translate }}
                            </a>
                        </div>
                    </hrs-card-footer>
                </hrs-card-content>
            </hrs-card>
        </div>
    </div>
</ion-content>
