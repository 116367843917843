import {Component, HostListener, OnInit} from '@angular/core';
import {AudioService, OverlayService, TabletSettingsService} from '@patient/providers';
import {getLogger} from '@hrs/logging';
import {TaskTrackingService} from '../services/task-tracking/task-tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageSelectionPage} from '../hrs-tablet/language-selection/language-selection.page';
import {OverlayRef} from '../hrs-overlay';
import {RestartDeviceIntentService} from '../hrs-tablet/knox-service-intents/restart-device-intent.service';
import {KnoxManageService} from '../services/knox/knox-manage.service';

@Component({
    selector: 'tablet-settings',
    templateUrl: './tablet-settings.page.html',
    styleUrls: ['./tablet-settings.page.scss'],
})
export class TabletSettingsPage implements OnInit {
    private readonly logger = getLogger('TabletSettingsPage');

    public readonly DISPLAY_VOLUME_MIN: number = 0;
    public readonly DISPLAY_VOLUME_MAX: number = 15;
    public audioReminders: boolean = true;
    public volume: number;
    public percentageVolume: number;

    @HostListener('hrsRadioChanged', ['$event'])
    handleRadioClicked({detail: {radio}}) {
        if (radio) {
            this.audioReminders = radio === AudioService.ENABLE_ALERTS;
            this.audioRemindersChange();
        }
    }

    constructor(
        private audio: AudioService,
        private taskTrackingService: TaskTrackingService,
        private overlay: OverlayService,
        private restartDeviceIntent: RestartDeviceIntentService,
        private tablet: TabletSettingsService,
        private translate: TranslateService,
        private readonly knox: KnoxManageService
    ) {}

    ngOnInit() {
        this.audioReminders = this.audio.audioReminders === AudioService.ENABLE_ALERTS;
        this.loadVolumeState();
        this.taskTrackingService.startTracking('tablet-settings', 'Opened tablet settings page');
    }

    async ngOnDestroy() {
        this.taskTrackingService.stopTracking();
    }

    public get knoxEnabled(): boolean {
        return this.knox.enabled;
    }

    private audioRemindersChange(): void {
        const reminder = this.audioReminders ? AudioService.ENABLE_ALERTS : AudioService.DISABLE_ALERTS;
        this.audio.audioReminders = reminder;
        this.tablet.putTabletSettings({
            audioreminders: this.audioReminders ? AudioService.ENABLE_ALERTS : AudioService.DISABLE_ALERTS
        });
    }

    public volumeChange(changeType: 'decrement' | 'increment'): void {
        const previousVolume = this.volume;
        if ((changeType === 'decrement') && (this.volume > this.DISPLAY_VOLUME_MIN)) this.volume--;
        if ((changeType === 'increment') && (this.volume < this.DISPLAY_VOLUME_MAX)) this.volume++;
        const updatedVolume = this.volume;
        this.logger.debug(`volumeChange() changeType = ${changeType}, change: ${previousVolume} -> ${updatedVolume}`);
        this.storeVolume();
    }

    private async storeVolume(): Promise<void> {
        this.logger.debug(`storeVolume() -> ${this.volume}`);

        try {
            this.percentageVolume = TabletSettingsService.convertVolume(this.volume);
            const success = await this.audio.volumeChange(this.percentageVolume);

            if (success) {
                await this.tablet.putTabletSettings({volume: this.percentageVolume});
                return;
            }
        } catch (err) {
            this.logger.error(`storeVolume() failed! -> ${err}`, err);
        }

        // Failed to set one or more audio streams to a new volume level, revert to previous volume
        this.loadVolumeState();
        this.logger.warn(`failed to set volume, reverting to ${this.volume} (${this.percentageVolume}%)`);
    }

    private loadVolumeState(): void {
        this.percentageVolume = this.audio.volume;
        this.volume = TabletSettingsService.convertVolume(this.percentageVolume, true);
    }

    public async openLanguageModal(): Promise<void> {
        await this.overlay.openModal({
            component: LanguageSelectionPage,
            title: this.translate.instant('LANGUAGE_SELECTION.HEADER'),
            qa: 'language_modal'
        });
    }

    public async restartTablet(): Promise<OverlayRef> {
        return await this.overlay.openAlert({
            header: this.translate.instant('TABLET_SETTINGS.RESTART').toUpperCase(),
            message: [this.translate.instant('WHITE_SCREEN.RESTART_MESSAGE')],
            buttons: [
                {
                    text: this.translate.instant('YES_BUTTON'),
                    handler: () => {
                        this.restartDeviceIntent.restartDevice();
                    }
                },
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                }
            ],
            qa: 'restart_tablet_alert'
        });
    }

    async openWifiSettings(): Promise<void> {
        await this.knox.openWifiSettings();
    }
}
