import {EventService} from '../events/event.service';
import {FirebaseNotifications} from '../firebase/firebase';
import {HRSStorage} from '../storage/storage';
import {OverlayService} from '../overlay/overlay.service';
import {Settings} from '../settings/settings.service';
import {User} from '../user/user.service';
import {CommunicationService, GatewayApi, TokenService} from '@hrs/providers';
import {GatewayService} from '@hrs/gateway';
import {NavController, Platform, MenuController} from '@ionic/angular';
import {Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {AudioService} from '../audio/audio.service';
import {EncryptionService} from '../encryption/encryption.service';
import {HRSSecureCache} from '../storage/cache';
import {DeviceProfileService} from '../../hrs-tablet/device-profile/device-profile.service';

@Injectable()
export class MobileUserService extends User {
    constructor(
        audio: AudioService,
        cache: HRSSecureCache,
        communication: CommunicationService,
        encryptionService: EncryptionService,
        eventService: EventService,
        firebase: FirebaseNotifications,
        gatewayApi: GatewayApi,
        gatewayService: GatewayService,
        menuCtrl: MenuController,
        navCtrl: NavController,
        overlay: OverlayService,
        platform: Platform,
        settings: Settings,
        storage: HRSStorage,
        tokenService: TokenService,
        @Optional() deviceProfile: DeviceProfileService
    ) {
        super(
            audio,
            cache,
            communication,
            deviceProfile,
            encryptionService,
            eventService,
            gatewayApi,
            gatewayService,
            menuCtrl,
            overlay,
            platform,
            settings,
            storage,
            tokenService,
            firebase,
            navCtrl
        );
    }

    public login(username: string, code: string): Observable<void> {
        const credentials = {
            data: {
                type: 'one-time-login',
                code: code,
                username: username,
                source: this.sourceApp
            }
        };

        return super.requestLogin(credentials);
    }

    public checkCurrentUser(): Promise<boolean> {
        return Promise.resolve(true);
    }
}

