import {Component, ChangeDetectionStrategy, ChangeDetectorRef, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {finalize, take, tap, first, filter} from 'rxjs/operators';
import {MedicationTask, Task, TaskType} from '../services/tasks';
import {TaskMetaData} from '../services/tasks/task-metadata.interface';
import {CareplanChangeService, OverlayService, TaskService, TaskTrackingService} from '@patient/providers';
import {EncryptionService} from '../services/encryption/encryption.service';
import {ContentDetail, ContentGridItem} from '@hrsui/core/dist/types/components/content/content.interface';
import {ListItem} from '@hrsui/core/dist/types/components/list/list.interface';
import {tileStatus} from '@hrsui/core/dist/types/components/tile/tile.interface';
import {MedicationModal} from './medication.modal';
import {OverlayRef} from '../hrs-overlay';
import {concat, EMPTY, catchError, Subscription} from 'rxjs';
import {CareplanChangeAction} from '../enums';
import {getLogger} from '@hrs/logging';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-medication',
    templateUrl: './medication.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationPage {
    public content: ContentDetail[];
    private readonly logger = getLogger('MedicationPage');

    private alertShown: boolean;
    private medications: MedicationTask[] = [];
    private medicationsToSave: MedicationTask[] = [];
    private PRNTimeSlot: string;
    private statusCheckInterval;
    private task: Task;
    private STATUS_CHECK_TIMER: number = 60000;
    private taskRemovedSubscription: Subscription;

    @HostListener('hrsTileClick', ['$event'])
    handleTileClick({detail: {event, mainTitle}}) {
        this.showMedicationModal(mainTitle);
    }

    constructor(
        private changeRef: ChangeDetectorRef,
        private encryptionService: EncryptionService,
        private overlay: OverlayService,
        private taskService: TaskService,
        private taskTrackingService: TaskTrackingService,
        private translate: TranslateService,
        private careplanChangeService: CareplanChangeService,
        private navCtrl: NavController
    ) {}

    ngOnInit() {
        this.PRNTimeSlot = this.translate.instant('MEDICATION_SCHEDULE_AS_NEEDED');
        this.loadMedications();
        this.statusCheckInterval = setInterval(() => {
            this.checkScheduleStatus();
        }, this.STATUS_CHECK_TIMER);
        this.taskRemovedSubscription = this.careplanChangeService.careplanState$.pipe(
            tap((careplanState) => this.logger.debug(`received careplan state update ->`, careplanState, this.task)),
            filter((careplanState) => this.task && careplanState[this.task.type] === CareplanChangeAction.REMOVED),
            first()
        ).subscribe(() => {
            this.logger.info(`returning to home page after module remove!`);
            this.returnToHomePage();
        });
    }

    ngOnDestroy() {
        clearInterval(this.statusCheckInterval);
        if (this.taskRemovedSubscription) {
            this.taskRemovedSubscription.unsubscribe();
            this.taskRemovedSubscription = null;
        }
    }

    private loadMedications(): void {
        this.task = this.taskService.getTask(TaskType.Medication);

        if (!this.task || !this.task.subTasks) {
            return;
        }

        for (let i = 0; i < this.task.subTasks.length; i++) {
            const medication = this.task.subTasks[i] as MedicationTask;

            if (!medication.isScheduled() && !this.isPRN(medication)) {
                this.logger.phic.debug('Excluding medication ' + i + ': not scheduled for today');
                continue;
            }

            this.medications.push(medication);
        }

        // this.medications will always be sorted alphabetically within scheduled time, with PRN meds (aka "As Needed") at the end
        this.medications.sort((a, b) => {
            return this.getSortKey(a).localeCompare(this.getSortKey(b));
        });

        this.mapMedsToPageContent();
    }

    private getSortKey(medication: MedicationTask): string {
        const scheduledTime: moment.Moment = medication.getScheduledTime();
        if (scheduledTime.isValid()) {
            // 24 hour time for correct sorting, and alphabetically for any meds that have the exact same time
            return scheduledTime.format('HH:mm') + medication.name;
        }
        // Sort any PRN (aka "As Needed") at the end after any scheduled medications, and then alphabetically
        return medication.name;
    }

    private mapMedsToPageContent(): void {
        const scheduledMedsTiles: ContentGridItem[] = this.getTileDetails();
        const fullMedsListItems: ListItem[] = this.getListDetails();

        this.content = [
            {
                tab: {
                    id: 'MEDICATION_SCHEDULED',
                    title: this.translate.instant('MEDICATION_SCHEDULED')
                },
                items: scheduledMedsTiles
            },
            {
                tab: {
                    id: 'MEDICATION_FULL_LIST',
                    title: this.translate.instant('MEDICATION_FULL_LIST')
                },
                lists: [{
                    items: fullMedsListItems
                }]
            }
        ];
    }

    private updateUIDetails(medication: MedicationTask): void {
        const medicationTime: string = medication.getScheduledTimeString(this.PRNTimeSlot);

        if (medicationTime === this.PRNTimeSlot) {
            // update the full list details
            this.content[1].lists[0].items = this.getListDetails();
        } else {
            // update the reminder status on the tile
            const gridItems: ContentGridItem[] = this.content[0].items;
            const medicationTile: ContentGridItem = gridItems.find((gridItem) => gridItem.mainTitle === medicationTime);

            if (medicationTile) {
                medicationTile.currentStatus = this.getReminderStatus(medicationTime);
                this.content = [...this.content];
                this.changeRef.detectChanges();
            }
        }
    }

    private getTileDetails(): ContentGridItem[] {
        let gridItems: ContentGridItem[] = [];
        let currentTime: string = '';
        let currentCount: number = 0;

        this.medications.forEach((med) => {
            const medAt: string = med.getScheduledTimeString(this.PRNTimeSlot);
            if (medAt === currentTime) {
                currentCount++;
            } else {
                if (currentTime && currentCount > 0) {
                    const gridItem: ContentGridItem = this.getGridItem(currentTime, currentCount);
                    gridItems.push(gridItem);
                    currentCount = 0;
                }
                currentTime = medAt;
                currentCount++;
            }
        });

        // push the last grouping's details
        if (currentTime && currentCount > 0) {
            const gridItem: ContentGridItem = this.getGridItem(currentTime, currentCount);
            gridItems.push(gridItem);
        }

        return gridItems;
    }

    private getGridItem(time: string, count: number): ContentGridItem {
        let subtitle: string;

        if (count > 1) {
            subtitle = this.translate.instant('MEDICATION_COUNT_MULTIPLE', {number: count});
        } else {
            subtitle = this.translate.instant('MEDICATION_COUNT_SINGLE');
        }

        const gridItem: ContentGridItem = {
            variant: 'medication',
            mainTitle: time,
            subtitle: subtitle
        };
        const tileStatus = this.getReminderStatus(time);
        if (tileStatus) gridItem.currentStatus = tileStatus;
        return gridItem;
    }

    private getReminderStatus(time: string): tileStatus {
        if (time === this.PRNTimeSlot) return; // no status for PRN ('As Needed') medications

        const scheduledMeds: MedicationTask[] = this.medications.filter((med) => med.getScheduledTimeString(this.PRNTimeSlot) === time);
        let missedCount = 0;
        let dueCount = 0;
        let completedCount = 0;
        let offlineCount = 0;

        scheduledMeds.forEach((med) => {
            if (med.isDue()) {
                dueCount++;
            } if (med.isMissed()) {
                missedCount++;
            } else if (med.isCompleted() && med.isCompletedButOffline()) {
                offlineCount++;
            } else if (med.isCompleted()) {
                completedCount++;
            }
        });

        if (dueCount) return 'due';
        if (missedCount) return 'missing';
        if (offlineCount) return 'submitted';
        if (completedCount == scheduledMeds.length) return 'done';
    }

    private checkScheduleStatus(): void {
        if (this.content && this.content[0] && this.content[0].items) {
            let statusChanged: boolean = false;
            this.content[0].items.forEach((gridItem) => {
                const status: tileStatus = this.getReminderStatus(gridItem.mainTitle);
                if (gridItem.currentStatus != status) {
                    statusChanged = true;
                    gridItem.currentStatus = status;
                }
            });
            // only update UI if we've updated at least one module's status, otherwise
            // we re-render the page on the check status interval
            if (statusChanged) {
                this.content = [...this.content];
                this.changeRef.detectChanges();
            }
        }
    }

    private getListDetails(): ListItem[] {
        let listMedications: MedicationTask[] = this.getFullListSortedMedications();

        const listItems: ListItem[] = [];
        let combinedSchedule: string[] = [];

        /* iterate over the sorted medication list and generate a list item description for each medication
           the list contains an entry for each medication for each scheduled time, so here we
           aggregate the schedule for each unique medication before generating the list item description
        */
        for (let i = 0; i < listMedications.length; i++ ) {
            const thisMedication = listMedications[i];
            const nextMedication = listMedications[i + 1];
            const isPRNMed = thisMedication.getScheduledTimeString(this.PRNTimeSlot) === this.PRNTimeSlot;

            // medications have a single scheduled time
            if (thisMedication.schedule && thisMedication.schedule[0] && thisMedication.schedule[0].at) {
                combinedSchedule.push(thisMedication.getScheduledTimeString(this.PRNTimeSlot));
            }

            if (nextMedication && nextMedication.name === thisMedication.name) continue;

            const subtitles = [this.taskService.getMedicationMethod(thisMedication)];

            if (thisMedication.instruction) {
                subtitles.push(`${this.translate.instant('MEDICATION_SPECIAL_INSTRUCTIONS')} ${thisMedication.instruction}`);
            }

            if (combinedSchedule.length) {
                subtitles.push(combinedSchedule.join(', '));
                combinedSchedule = [];
            }

            if (isPRNMed) {
                if (thisMedication.lastCompletedButOffline) {
                    subtitles.push(`${this.translate.instant('MEDICATION_LAST_TAKEN_AT.COMPLETED_BUT_OFFLINE', {date: thisMedication.getLastCompletedDate(), time: thisMedication.getLastCompletedTime()})}`);
                } else if (thisMedication.lastCompleted) {
                    subtitles.push(`${this.translate.instant('MEDICATION_LAST_TAKEN_AT', {date: thisMedication.getLastCompletedDate(), time: thisMedication.getLastCompletedTime()})}`);
                }
            }

            const listItem: ListItem = {
                mainTitle: this.taskService.getMedicationDescription(thisMedication),
                subtitles: subtitles,
                itemId: thisMedication.id
            };

            listItems.push(listItem);
        }

        return listItems;
    }

    private getFullListSortedMedications(): MedicationTask[] {
        let sortedMedications: MedicationTask[] = [...this.medications];

        // medications will be sorted as scheduled medications followed by PRN medications and alphabetically within each category
        const getFullListSortKey = (medication: MedicationTask): string => {
            const scheduledTime: moment.Moment = medication.getScheduledTime();
            if (scheduledTime.isValid()) {
            // 24 hour time for correct sorting, and sorted by time within alphabetical list of medications
                return medication.name + scheduledTime.format('HH:mm');
            }
            // ensure PRN ("As Needed") meds are at the end after any scheduled medications, and then alphabetically
            return 'ZZZZZ' + medication.name;
        };

        sortedMedications.sort((a, b) => {
            return getFullListSortKey(a).localeCompare(getFullListSortKey(b));
        });

        return sortedMedications;
    }

    private async showMedicationModal(timeSlot: string): Promise<void> {
        let visibleMedications: MedicationTask[];
        let modalTitle: string;
        const isPRN: boolean = (timeSlot === this.PRNTimeSlot);

        if (isPRN) {
            modalTitle = timeSlot;
            visibleMedications = this.medications.filter((med) => this.isPRN(med));
        } else {
            modalTitle = this.translate.instant('MEDICATION_TIME_TITLE', {time: timeSlot});
            visibleMedications = this.medications.filter((med) => med.getScheduledTimeString(this.PRNTimeSlot) === timeSlot);
        }

        const overlay = await this.overlay.openModal({
            component: MedicationModal,
            title: modalTitle,
            inputs: {
                isPRN: isPRN,
                medications: visibleMedications
            },
            qa: 'medication_modal'
        });

        this.handleOverlayResult(overlay);
    }

    private handleOverlayResult(overlay: OverlayRef): void {
        overlay.result$.pipe(take(1)).subscribe((result) => {
            if (result && result.submittedMeds) {
                result.submittedMeds.forEach((medID) => {
                    let medicationTaken = this.medications.find((medication) => medication.id === medID);
                    if (medicationTaken) this.medicationsToSave.push(medicationTaken);
                });
                this.save();
            }
        });
    }

    private isPRN(medication: MedicationTask): boolean {
        return medication.getScheduledTimeString(this.PRNTimeSlot) === this.PRNTimeSlot;
    }

    private isCompleted(medication: MedicationTask): boolean {
        if (!this.isPRN(medication)) {
            // Non-PRN meds can only be taken once per task each day, unless the medication task has been reset.
            return medication.isLastCompletedToday() && !medication.isTaskReset;
        } else {
            // PRN meds can be taken infinite times (probably isn't healthy to do so though)
            return false;
        }
    }

    private save(): void {
        let taskSubmissions = [];

        this.medicationsToSave.forEach((medication) => {
            if (medication.saving || this.isCompleted(medication)) {
                return;
            }
            medication.saving = true;

            /* [language locale] for non-western languages moment converts the date string to a different character-set that
               the server doesn't like so setting locale('en') to english to ensure we get a date string in western characters
               (only an issue for non-western languages like arabic and hindi)
            */
            const metadata: TaskMetaData = {
                recordedDate: moment().locale('en').format(), // get the date this metric was recorded in case the user is offline when they upload. also, see below note on "language locale",
            };

            taskSubmissions.push(
                this.taskService.submitTask(medication, {
                    medication: medication.name,
                    dosage: medication.dosage
                }, metadata).pipe(
                    tap(() => this.taskTrackingService.submitTracking('submit-medication', 'success submitting ' + this.task.type)),
                    catchError((err) => {
                        this.logger.phic.error('Error submitting medication to server', err);
                        if (!this.alertShown) {
                            this.alertShown = true;
                            this.medicationErrorAlert(err, medication);
                        }
                        this.taskTrackingService.submitTracking('submit-medication', 'failed submitting ' + this.task.type);
                        return EMPTY;
                    }),
                    finalize(() => {
                        medication.saving = false;
                        this.updateUIDetails(medication);
                        this.taskService.markTaskUpdated(this.task); // updates TaskService Subject so anything needing to know the Medication Task has been updated can be notified
                    })
                )
            );
        });

        concat(...taskSubmissions).subscribe();
    }

    private medicationErrorAlert(error: Error, medication: MedicationTask): void {
        this.logger.phic.error('Error submitting task to server', error);
        const taskTitle: string = this.task && this.task.title ? this.task.title.toLowerCase() : '';
        const hasServerPublicKey: boolean = !!this.encryptionService.serverPublicKey;
        if (hasServerPublicKey) {
            /* we've got a serverPublicKey, which means we encrypted and saved the metric on the patient's device.
               provide an alert explaining that we'll be uploading their metric shortly.
            */
            this.notSavedWillRetryAlert(taskTitle);
            this.markMedsAsStoredOffline(medication);
        } else {
            /* no serverPublicKey, which means we couldn't encrypt their data, which means we couldn't store the data on their device's storage
               thus, provide a generic error toast explaining their data was not saved.
            */
            this.notSavedCantRetryToast(taskTitle);
        }
    }

    private markMedsAsStoredOffline(medication: MedicationTask): void {
        medication.lastCompletedButOffline = medication.lastCompleted;
    }

    private async notSavedWillRetryAlert(taskTitle: string): Promise<void> {
        const alert = await this.overlay.openAlert({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.HEADER'),
            message: [
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.1', {metric: taskTitle}),
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.2')
            ],
            buttons: [{
                text: this.translate.instant('OK_BUTTON'),
                role: 'cancel'
            }],
            qa: 'medication_save_alert'
        });
        alert.result$.subscribe(() => {
            this.alertShown = false;
        });
    }

    private async notSavedCantRetryToast(taskTitle: string): Promise<OverlayRef> {
        return await this.overlay.openToast({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.HEADER'),
            message: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.BODY', {metric: taskTitle}),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'medication_toast'
        });
    }

    private returnToHomePage(): void {
        this.logger.debug(`returnToHomePage()`);
        // close the metric page/modal
        this.navCtrl.navigateRoot(['home']);
    }
}
