import {
    ComponentRef,
    Injectable,
    Injector
} from '@angular/core';
import {DomService} from './dom.service';
import {OverlayComponent} from 'src/app/hrs-overlay/overlay.component';
import {AlertTemplateComponent} from 'src/app/hrs-overlay/overlay-templates/alert-template.component';
import {ModalTemplateComponent, ModalTemplateOptions} from 'src/app/hrs-overlay/overlay-templates/modal-template.component';
import {ToastTemplateComponent} from 'src/app/hrs-overlay/overlay-templates/toast-template.component';
import {WizardTemplateComponent} from 'src/app/hrs-overlay/overlay-templates/wizard-template.component';
import {OverlayConfig, OverlayInjector, OverlayOpts, OverlayRef, OverlayRefDismissResult, ToastOverlayConfig} from 'src/app/hrs-overlay/overlay';
import {first, lastValueFrom} from 'rxjs';
import {isNumber} from 'lodash';
import {getLogger} from '@hrs/logging';
import {EventService} from '../events/event.service';

// Disallow the 'buttons' property specifically for confirmation dialogs.
// If `buttons` is defined, the pattern library will not emit the `hrsUserSelection` event,
// which will cause `OverlayRef.close()` to never get called,
// which will cause `isAffirmativeChoice` to never be true on the close event data.
export type ConfirmationAlertOverlayConfig = Omit<OverlayConfig, 'buttons'>;
export const OVERLAY_DURATION_INFINITE = -1;

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    private readonly logger = getLogger('OverlayService');
    private readonly openOverlaysRef: {[id: number]: ComponentRef<OverlayComponent>} = {};
    private overlayTypeRefs = {
        alert: AlertTemplateComponent,
        modal: ModalTemplateComponent,
        toast: ToastTemplateComponent,
        wizard: WizardTemplateComponent
    };
    public currentToast: OverlayRef;

    constructor(
        private domService: DomService,
        private event: EventService,
        private injector: Injector,
    ) {}

    /**
     * Used to launch `hrs-alert` as `OverlayComponent`s. Returns an `OverlayRef` which can be subscribed to as `OverlayRef.result$`
     * @param config
     */
    public async openAlert(config: OverlayConfig): Promise<OverlayRef> {
        const overlayOpts: OverlayOpts = {
            type: 'alert',
            config
        };
        return await this.open(overlayOpts);
    }

    /**
     * Convenience for unwrapping confirm/cancel selection from an alert dialog.
     */
    public async openConfirmationAlert(config: ConfirmationAlertOverlayConfig): Promise<boolean> {
        const overlayRef = await this.openAlert(config);
        this.logger.debug(`openConfirmationAlert() opened overlay with ID ${overlayRef.overlayComponentRefId}`);
        const overlayResultStream = overlayRef.result$.pipe(first());
        const overlayResult: OverlayRefDismissResult = await lastValueFrom(overlayResultStream).catch(() => null);
        this.logger.debug(`openConfirmationAlert() overlay ${overlayRef.overlayComponentRefId} result -> `, overlayResult);
        return !!(overlayResult?.isAffirmativeChoice);
    }

    /**
     * Used to launch `hrs-toast` as `OverlayComponent`s. Returns an `OverlayRef` which can be subscribed to as `OverlayRef.result$`
     * @param config
     */
    public async openToast(customConfig: ToastOverlayConfig): Promise<OverlayRef> {
        const defaultConfig: Partial<ToastOverlayConfig> = {
            duration: 5000, // 5 seconds is the standard toast duration
        };
        const config: ToastOverlayConfig = {
            ...defaultConfig,
            ...customConfig
        };
        const overlayOpts: OverlayOpts = {
            type: 'toast',
            config
        };
        return await this.open(overlayOpts);
    }

    /**
     * Used to launch `hrs-page` (`modal` variant) as `OverlayComponent` with initialized ComponentRef child specified
     * as `OverlayConfig.component`. Returns an `OverlayRef` which can be subscribed to as `OverlayRef.result$`
     * @param config
     */
    public async openModal(config: OverlayConfig): Promise<OverlayRef> {
        const overlayOpts: OverlayOpts = {
            type: 'modal',
            config,
            component: config.component
        };
        return await this.open(overlayOpts);
    }

    /**
     * Used to launch `hrs-wizard` as `OverlayComponent` with initialized ComponentRef child specified
     * as `OverlayConfig.component`. Returns an `OverlayRef` which can be subscribed to as `OverlayRef.result$`
     * @param config
     */
    public async openWizard(config: OverlayConfig): Promise<OverlayRef> {
        const overlayOpts: OverlayOpts = {
            type: 'wizard',
            config,
            component: config.component
        };
        return await this.open(overlayOpts);
    }

    /**
     * Used to dismiss, remove, and destroy overlay instance and references for most recently created overlay
     * NB: This will ONLY target the MOST RECENT overlay created unless a target reference ID is passed in
     */
    public async dismiss(refId?: number): Promise<boolean> {
        const targetOverlayId = isNumber(refId) ? refId : overlayId - 1;
        const targetOverlay = this.openOverlaysRef[targetOverlayId];
        if (targetOverlay) {
            await targetOverlay.instance.dismiss();
            return true;
        }
        return false;
    }

    /**
     * @param overlayOpts - mostly optional configuration settings
     *      @requires: title
     *      @see: ./overlay.ts#OverlayConfig for full list of config options
     *
     * @returns OverlayRef to expose result Observable to Parent Component
     *
     * Call from Parent Component to open overlay.
     * @example:
     * exampleFunction(): void {
     *     const overlay = this.overlay.open({
     *         component: ExampleComponent,
     *         title: 'Example Overlay Title'
     *     });
     *     overlay.result$.subscribe((result) => {
     *         // code to execute on close //
     *     };
     * }
     */
    private async open(overlayOpts: OverlayOpts): Promise<OverlayRef> {
        this.logger.info('Overlay Service | opening overlay', overlayOpts);
        this.event.overlayOpened.next(overlayOpts.type);
        const {overlayComponentRef, overlayComponentRefId, overlayRef} = await this.createOverlay(overlayOpts);
        // Store new OverlayComponent for easy access, if needed
        this.openOverlaysRef[overlayComponentRefId] = overlayComponentRef;
        // duration <= 0 will leave the overlay open until user dismisses
        if (overlayOpts?.config?.duration > 0) {
            setTimeout(() => {
                this.dismiss(overlayComponentRefId);
                this.currentToast = undefined;
            }, overlayOpts.config.duration);
        }
        return overlayRef;
    }

    /**
     * Creates Overlay, applies config, and appends Overlay to document body
     * @param opts
     */
    private async createOverlay(opts: OverlayOpts): Promise<overlayRefObject> {
        const config = opts.config;
        const overlayComponentRefId = overlayId++;
        const map = new WeakMap().set(OverlayConfig, config);
        const overlayRef = new OverlayRef(overlayComponentRefId);
        const overlayTypeRef = this.overlayTypeRefs[opts.type];

        map.set(OverlayRef, overlayRef);
        this.subscribeToOverlayResult(overlayRef);

        let overlayComponentRef = await this.domService.createComponentRefOf(overlayTypeRef, config, new OverlayInjector(this.injector, map));
        overlayComponentRef.instance.overlayType = opts.type;
        await overlayComponentRef.instance.applyOverlayOptions();
        if (/modal|wizard/.test(opts.type)) {
            await overlayComponentRef.instance.loadChildComponent(opts.component);
        }

        await this.domService.appendComponentTo('body', overlayComponentRef);
        return {overlayComponentRef, overlayRef, overlayComponentRefId};
    }

    /**
     * Updates config properties on the ModalTemplateComponent that are bound to hrs-modal
     */
    public updateModal(overlayRef: OverlayRef, configUpdates: ModalTemplateOptions): void {
        const targetOverlay = this.openOverlaysRef[overlayRef['overlayComponentRefId']];
        const modalComponent: ModalTemplateComponent = targetOverlay.instance as ModalTemplateComponent;

        if (modalComponent) {
            for (let key in configUpdates) {
                if ({}.hasOwnProperty.call(configUpdates, key)) {
                    modalComponent[key] = configUpdates[key] && configUpdates[key];
                }
            }
        }
    }

    /**
     * Removes Overlay and Child from ng component tree
     * @param overlayComponentRefId
     */
    private async removeOverlay(overlayComponentRefId: number): Promise<void> {
        const overlayComponent = this.openOverlaysRef[overlayComponentRefId];
        const overlayBody = overlayComponent.instance.overlayBody();
        if (overlayBody) overlayBody.classList.add('is-closing');
        setTimeout(() => {
            // Modal overlays have children which also need to be removed from the DOM
            if (overlayComponent.instance.childComponentRef) {
                this.domService.removeComponent(overlayComponent.instance.childComponentRef);
            }
            this.domService.removeComponent(overlayComponent);
            delete this.openOverlaysRef[overlayComponentRefId];
        }, 300);
    }

    /**
     * Returns the ComponentRef instance of the topmost currently-rendered overlay of specified type
     */
    public getTop(overlayType: string): OverlayComponent | boolean {
        const typeRegex = new RegExp(overlayType);
        const activeOverlays = Object.entries(this.openOverlaysRef).filter((entry) => {
            return typeRegex.test(entry[1].instance.overlayType);
        })[0];
        return activeOverlays ? activeOverlays[1].instance : false;
    }

    private subscribeToOverlayResult(overlayRef: OverlayRef) {
        let sub = overlayRef.result$.subscribe(({overlayComponentRefId}) => {
            this.removeOverlay(overlayComponentRefId);
            sub.unsubscribe();
        });
    }
}

let overlayId: number = 0;

interface overlayRefObject {
    overlayComponentRef: ComponentRef<OverlayComponent>,
    overlayComponentRefId: number,
    overlayRef: OverlayRef
}
