import moment from 'moment';
import {Task} from './task.model';

export class MedicationTask extends Task {
    medicationId: string;
    name: string;
    strength: number;
    units: string;
    count: number;
    route: string;
    // dosage is a combination of strength, units, count, route because the combined string is how the backend currently stores medication.
    // Should be able to remove it from the frontend app once the backend ticket SHRS-2683 is done, but for now we need it to save readings.
    dosage: string;
    instruction?: string;
    saving: boolean;

    set(data: Partial<MedicationTask>) {
        Object.assign(this, data);
    }

    // each medication only has a single time scheduled
    public getScheduledTime(): moment.Moment {
        if (this.schedule && this.schedule[0] && this.schedule[0].at) {
            return moment(this.schedule[0].at, moment.HTML5_FMT.TIME_SECONDS);
        }
        return moment.invalid();
    }

    public getScheduledTimeString(asNeeded: string): string {
        const scheduledTime = this.getScheduledTime();
        if (scheduledTime.isValid()) {
            return scheduledTime.format('h:mm A');
        }
        return asNeeded;
    }
}
