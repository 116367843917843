import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2
} from '@angular/core';
import {OverlayComponent} from '../overlay.component';
import {DomService} from '../../services/overlay/dom.service';
import {
    OverlayConfig,
    OverlayRef
} from '../overlay';
import {
    WizardConfigDetail,
    wizardVariant
} from '@hrsui/core/dist/types/components/wizard/wizard.interface';

@Component({
    selector: 'wizard-overlay',
    template: `
<!--        <hrs-wizard-->
<!--            [config]="wizardConfig"-->
<!--            [variant]="wizardVariant"-->
<!--            [hasNoHeaderButton]="hasNoHeaderButton"-->
<!--        >-->
<!--        </hrs-wizard>-->
        <ng-template appInsertion></ng-template>
    `
})
export class WizardTemplateComponent extends OverlayComponent {
    public wizardConfig: WizardConfigDetail;
    public hasNoHeaderButton: boolean;
    public wizardVariant: wizardVariant;

    constructor(
        cd: ChangeDetectorRef,
        dom: DomService,
        el: ElementRef,
        renderer: Renderer2,
        config: OverlayConfig,
        ref: OverlayRef,
    ) {
        super(cd, dom, el, renderer, config, ref);
    }
}
