import {Injectable, inject} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {Router, RouterEvent} from '@angular/router';
import {Subscription, filter, map} from 'rxjs';
import {templateDevices} from '../../devices/generic-device/types/template-devices';
import {FIREBASE_ANALYTICS_PLUGIN} from '@app/native-plugins';

export enum HRSFirebaseEvents {
    BT_READING_TRANSMISSION_SUCCESS = 'BT_READING_TRANSMISSION_SUCCESS', // shows readings were transmitted from peripheral and we got them in our app
    VITAL_SUBMISSION = 'VITAL_SUBMISSION', // shows vital was submitted to CC
    BT_READING_ERROR= 'BT_READING_ERROR', // shows any reading error
    BT_NOTIFICATION_ERROR= 'BT_NOTIFICATION_ERROR', // shows any notification error
    BT_PAIRING_SUCCESS = 'BT_PAIRING_SUCCESS', // shows pairing success
    BT_PAIRING_FAILURE = 'BT_PAIRING_FAILURE', // shows pairing failure
    CALL_PENDING = 'CALL_PENDING',
    CALL_START = 'CALL_START',
    CALL_PROGRESS = 'CALL_PROGRESS',
    CALL_END = 'CALL_END',
    CALL_DECLINED = 'CALL_DECLINED',
    CALL_ERROR = 'CALL_ERROR',
    CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED',
    CHAT_MESSAGE_SENT = 'CHAT_MESSAGE_SENT',
    CHAT_MESSAGE_SEND_ERROR = 'CHAT_MESSAGE_SEND_ERROR',
}

// NOTE:
// Google Analytics has a MAX of 25 parameters,
// so add to this with caution.
export enum HRSFirebaseParams {
    DEVICE_NAME = 'DEVICE_NAME',
    RSSI = 'BT_RSSI',
    PERIPHERAL_TYPE = 'PERIPHERAL_TYPE',
    SCREEN = 'SCREEN',
    STATE = 'STATE',
    READING_OFFSET = 'READING_OFFSET',
    REASON = 'REASON',
    META_DATA = 'META_DATA',
    PROVIDER = 'PROVIDER',
    INITIATOR = 'INITIATOR',
    TERMINATOR = 'TERMINATOR', // I'll be Bach
    NETWORK_TYPE = 'NETWORK_TYPE',
    SIGNAL_STRENGTH = 'SIGNAL_STRENGTH',
    SIGNAL_LEVEL = 'SIGNAL_LEVEL',
    DURATION = 'DURATION',
    CALL_QUALITY = 'CALL_QUALITY',
    ID = 'ID'
}

export enum HRSFirebaseErrorReason {
    PARSING_ERROR = 'PARSING_ERROR', // any parsing error
    DISCONNECTION_ERROR = 'DISCONNECTION_ERROR', // disconnection error
    NO_RECENT_READING = 'NO_RECENT_READING', // when we dont get any vitals
    HISTORICAL_READING_ONLY = 'HISTORICAL_READING_ONLY', // when we dont get recent vitals but got only historical ones
    DEVICE_NOT_PAIRED = 'DEVICE_NOT_PAIRED', // device is not paired
    INCORRECT_DEVICE_DATA = 'INCORRECT_DEVICE_DATA', // incorrect data obtained from peripheral
    VALIDATION_FAILURE = 'VALIDATION_FAILURE', // validation failures
    ERROR_READING_DATA = 'ERROR_READING_DATA', // could not read data
    NOTIFICATION_ERROR = 'NOTIFICATION_ERROR', // notification error
    VITAL_DISPLAY_FAILURE = 'VITAL_DISPLAY_FAILURE', // error during display of vital
    AND_651_BP_ERR_ERRCUFF_2047 = 'AND_651_BP_ERR_ERRCUFF_2047', // shows AnD 651 error cuff and err
    AND_651_BP_ERR_NO_PULSE_2048 = 'AND_651_BP_ERR_NO_PULSE_2048', // shows AnD 651 E error
    AND_651_BP_ERR_2047_BODY_MOVEMENT_DETECTED = 'AND_651_BP_ERR_2047_BODY_MOVEMENT_DETECTED', // shows ERR 2047 along with flag set for body movement
    AND_651_BP_ERR_2047_CUFF_TOO_LOOSE = 'AND_651_BP_ERR_2047_CUFF_TOO_LOOSE', // shows ERR 2047 along with flag set for cuff too loose
    AND_651_BP_ERR_2047_IMPROPER_MEASUREMENT_POSITION = 'AND_651_BP_ERR_2047_IMPROPER_MEASUREMENT_POSITION' // shows ERR 2047 along with flag set for improper measurement position
}

export interface HRSFirebaseEventData extends Record<string, any> {
}

@Injectable({
    providedIn: 'root'
})
export class HRSFirebaseAnalytics {
    private readonly logger = getLogger('HRSFirebaseAnalytics');
    private currentScreen: string = undefined;
    private subscription: Subscription = undefined;

    private readonly analytics = inject(FIREBASE_ANALYTICS_PLUGIN);

    constructor(private readonly router: Router) {
        this.subscription = this.router.events.pipe(
            filter((e) => e instanceof RouterEvent),
            map((e) => e as RouterEvent)
        ).subscribe((e: RouterEvent) => {
            this.currentScreen = e.url;
            if (this.currentScreen) {
                this.currentScreen = this.currentScreen.split('?')[0]; // truncate after ? as this is a URL
            }
        });
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    public logEvent(name: string, params: HRSFirebaseEventData = {}): void {
        Promise.resolve()
            .then(() => {
                if (this.currentScreen) {
                    return this.analytics.setCurrentScreen({screenName: this.currentScreen});
                }
            })
            .then(() => {
                return this.analytics.logEvent({name, params});
            })
            .catch((e) => {
                this.logger.error(`logEvent ERROR -> ${e}`);
            });
    }

    /**
     * Get the peripheral display name which will be logged with the firebase events
     */
    public getDisplayName(peripheral: any, peripheralType: string): string {
        try {
            let peripheralName;
            if (peripheral) {
                peripheralName = peripheral.name;
            }
            if (peripheralName && peripheralType) {
                const devices = templateDevices[peripheralType];
                for (let i = 0; i < devices.length; i++) {
                    if (peripheralName.includes(devices[i].name)) {
                        return devices[i].display;
                    }
                }
            } else {
                this.logger.error('Firebase display name couldnt find peripheral ' + peripheralName);
            }
            return peripheralName ? peripheralName : 'NA';
        } catch (e) {
            this.logger.error('Firebase display name couldnt find peripheral ', e);
            return 'NA';
        }
    }
}
