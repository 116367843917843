import {Injectable} from '@angular/core';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {TaskTrackingService} from '../task-tracking/task-tracking.service';
import {CommunicationService, ModalService} from '@hrs/providers';
import {BuildUtility} from '@hrs/utility';
import {VoicePage} from '../../communication/voice/voice.page';
import {OverlayService} from '../overlay/overlay.service';
import {Subject} from 'rxjs';
import {OverlayRef} from '../../hrs-overlay';
import {take} from 'rxjs/operators';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class SupportCallService {
    private readonly logger = getLogger('SupportCallService');
    static DISPLAY_NUMBER: string = '(551) 203-0011';
    static SUPPORT_PHONE_NUMBER: string = '551-203-0011';
    public supportCallStatus: Subject<'open' | 'closed'> = new Subject<'open' | 'closed'>();

    constructor(
        private callNumber: CallNumber,
        private communicationService: CommunicationService,
        private modalService: ModalService,
        private overlay: OverlayService,
        private platform: Platform,
        private taskTrackingService: TaskTrackingService,
        private translate: TranslateService,
    ) {}

    /**
     * Start support call
     */
    public async initSupportCall(shouldTrack = true): Promise<void> {
        // if a video or voice call is already active, show a message to user
        // otherwise, initiate the call to support
        const callModalIsOpen: boolean = this.modalService.getModalStatus('VideoPage') || this.modalService.getModalStatus('VoicePage');
        const videoCallIsActive: boolean = this.communicationService.hasActiveVideoCall();

        if (callModalIsOpen || videoCallIsActive) {
            await this.overlay.openToast({
                message: this.translate.instant('LAUNCH_CALL_BLOCKED'),
                duration: 5000, // 5 seconds is the standard toast duration
                qa: 'app_active_call_error'
            });
            return;
        }

        this.emitOpenStatus();
        if (shouldTrack) {
            this.taskTrackingService.startTracking('support-call', 'Starting support call with patients phone service');
        }

        if (BuildUtility.isHRSTab()) {
            await this.startTwilio();
        } else {
            await this.startNative();
        }
    }

    public async startTwilio() {
        const overlay = await this.overlay.openModal({
            component: VoicePage,
            title: this.translate.instant('VOICE_PANEL'),
            inputs: {
                isSupportCall: true
            },
            qa: 'support_voice_modal'
        });

        this.handleOverlayResult(overlay);
    }

    public startNative() {
        if (this.platform.is('ios')) {
            // ios shows a prompt natively
            this.dialPhoneNumber();
        } else {
            // android: add a prompt to prevent accidental calling
            return this.showSupportCallPrompt();
        }
    }

    /**
     * Display prompt to start call on android
     */
    private async showSupportCallPrompt(): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('CALL_SUPPORT.HEADER'),
            message: [SupportCallService.DISPLAY_NUMBER],
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    handler: () => this.emitClosedStatus()
                },
                {
                    text: this.translate.instant('CALL_BUTTON'),
                    handler: () => {
                        this.dialPhoneNumber();
                        this.emitClosedStatus();
                    }
                },
            ],
            qa: 'support_call_alert'
        });
    }

    /**
     * Dial support
     */
    public dialPhoneNumber(): void {
        this.callNumber.callNumber(SupportCallService.SUPPORT_PHONE_NUMBER, true)
            .then((res) => {
                this.logger.phic.info(this.translate.instant('CALL_SUPPORT_SUCCESS'), JSON.stringify(res));
                // maybe we should log when people call support
                this.taskTrackingService.stopTracking();
            })
            .catch((err) => {
                this.dialPhoneNumberFailToast();
            })
            .finally(() => this.emitClosedStatus());
    }

    private async dialPhoneNumberFailToast(): Promise<OverlayRef> {
        let dialSuccessToast = await this.overlay.openToast({
            message: this.translate.instant('CALL_SUPPORT_FAIL'),
            duration: 5000, // 5 seconds is the standard toast duration
            variant: 'error',
            qa: 'support_fail_toast'
        });
        this.taskTrackingService.stopTracking();
        return dialSuccessToast;
    }

    /**
     * Emit Open Status for Main Page to Update Active Footer Tab.
     */
    public emitOpenStatus(): void {
        this.supportCallStatus.next('open');
    }

    /**
     * Emit Closed Status for Main Page to Update Active Footer Tab.
     */
    public emitClosedStatus(): void {
        this.supportCallStatus.next('closed');
    }

    private handleOverlayResult(overlay: OverlayRef) {
        overlay.result$
            .pipe(take(1))
            .subscribe(() => this.emitClosedStatus());
    }
}
