import {BuildUtility} from '@hrs/utility';
import {Subject} from 'rxjs';

/**
 * MainPageDetails specifies the page attributes needed for MainPage
 *
 * Keys are based on the route passed as part of the Router Event that performs navigation
 * corresponding config object may contain any of the following attributes:
 *      hasBackButton:  boolean - true = display back button, false or absent = display menu hamburger
 *      hideFab:        boolean - true = hide Fab, false or absent = display Fab
 *      hideFooter:     boolean - true = hide app footer, false or absent = display app footer
 */

const isHRSTab = BuildUtility.isHRSTab();

export enum handlerType {
    HOME = 'home',
    BACK = 'back',
    EVENT = 'event'
}

export type buttonHandlerType = handlerType.HOME | handlerType.BACK | handlerType.EVENT;

export interface FooterClickDetails {page: string, buttonIndex: number}

export const FooterEvent: { clicked: Subject<FooterClickDetails> } = {clicked: new Subject()};

export const MainPageDetails = {
    home: {
        footerVariant: 'nav-tabs',
        tabDetail: {id: 'MENU_HOME_TITLE', icon: 'home-outline'},
        titleKey: 'HOME_TITLE',
        hideTitle: true
    },
    activity_monitors: {
        hideFooter: false,
        footerVariant: 'two-button',
        footerButtons: [
            {
                variant: 'secondary',
                icon: 'home-solid',
                handler: handlerType.HOME
            },
            {
                variant: 'primary',
                icon: 'refresh',
                text: 'REFRESH_BUTTON',
                handler: handlerType.EVENT
            }
        ]
    },
    education: {
        footerVariant: 'nav-tabs',
        tabDetail: {id: 'MENU_EDUCATION', icon: 'education-outline'},
        titleKey: 'EDUCATION_TITLE',
        hideTitle: true
    },
    education_video: {
        hasBackButton: true,
        hideFooter: true,
        hideFab: true
    },
    education_condition: {
        hasBackButton: true,
        footerVariant: 'one-button',
        footerButtons: [{
            variant: 'primary',
            expand: true,
            text: 'CLOSE_BUTTON',
            handler: handlerType.BACK
        }]
    },
    generic_device: {
        hideFooter: false,
        footerVariant: 'two-button',
        footerButtons: [
            {
                variant: 'secondary',
                icon: 'home-solid',
                handler: handlerType.HOME
            },
            {
                variant: 'primary',
                icon: 'refresh',
                text: 'REFRESH_BUTTON',
                handler: handlerType.EVENT
            }
        ]
    },
    generic_metric: {
        hasBackButton: true,
        hideFooter: false,
        footerVariant: 'one-button',
        footerButtons: [
            {
                variant: 'primary',
                expand: true,
                text: 'MANUAL_INPUT_BUTTON',
                handler: handlerType.EVENT
            }
        ]
    },
    pairing_instructions: {
        hasBackButton: true,
        hideFooter: false,
        footerVariant: 'one-button',
        footerButtons: [
            {
                variant: 'primary',
                expand: true,
                text: 'CLOSE_BUTTON',
                handler: handlerType.BACK
            }
        ]
    },
    usage_instructions: {
        hasBackButton: true,
        hideFooter: false,
        footerVariant: isHRSTab ? 'two-button' : 'one-button',
        footerButtons: isHRSTab ?
            [
                {
                    variant: 'secondary',
                    icon: 'volume',
                    handler: handlerType.EVENT
                },
                {
                    variant: 'primary',
                    text: 'MANUAL_INPUT_BUTTON',
                    handler: handlerType.EVENT
                }
            ] : [
                {
                    variant: 'primary',
                    expand: true,
                    text: 'MANUAL_INPUT_BUTTON',
                    handler: handlerType.EVENT
                }
            ]
    },
    medication: {
        hasBackButton: true,
        hideFooter: true
    },
    settings: {
        tabDetail: {id: 'MENU_SETTINGS', icon: 'settings-outline'},
        footerVariant: 'nav-tabs',
        titleKey: 'SETTINGS_TITLE',
        hideTitle: true
    }
};

/**
 * MainPageConfig specifies the page attributes that are bound to MainPage's template
 *
 * title:  is set in MainPage based on queryParameters on the route
 * hasBackButton:  is set in MainPage based on the MainPageDetails defined above
 * hideFab:                             "
 * hideFooter:                          "
 */

export interface MainPageConfig {
    pageType?: string;
    title?: string;
    hideTitle?: boolean;
    hasBackButton?: boolean;
    hideFab?: boolean;
    hideFooter?: boolean;
    footerVariant?: string;
    footerButtons?: {
        variant?: string;
        expand?: boolean;
        icon?: string;
        text?: string;
        handler?: buttonHandlerType;
    }[];
}

export enum MainPageTypes {
    HOME = 'home',
    ACTIVITY_MONITORS = 'activity_monitors',
    EDUCATION_LIST = 'education_list',
    EDUCATION_CONDITION = 'education_condition',
    GENERIC_DEVICE = 'generic_device',
    GENERIC_METRIC = 'generic_metric',
    PAIRING_INSTRUCTIONS = 'pairing_instructions',
    USAGE_INSTRUCTIONS = 'usage_instructions'
}
