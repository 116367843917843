import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Setting} from '../../admin/admin-settings.interface';
import {AdminSettings} from '../../enums';
import {BuildUtility} from '@hrs/utility';
import {HrsMenu} from '@hrsui/angular';
import {User} from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    static PASSCODE: string = 'HRSADMIN123!@#';
    adminSettings = AdminSettings;
    settings: Setting[];
    private totalButtonClicks: number = 0;
    private openAdminTimeout: ReturnType<typeof setTimeout>;

    constructor(
        private router: Router,
        private readonly user: User
    ) {
        // each 'Setting' represents a nav item for the admin area
        this.settings = BuildUtility.isHRSTab() ? [
            {
                fullLabel: 'ADMIN_WIFI_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_WIFI_SETTINGS_LABEL_TAB',
                icon: 'wifi-outline',
                enum: this.adminSettings.WIFI
            },
            {
                fullLabel: 'ADMIN_KIOSK_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_KIOSK_SETTINGS_LABEL_TAB',
                icon: 'lock-closed-outline',
                enum: this.adminSettings.KIOSK
            },
            {
                fullLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_TAB',
                icon: 'earth-outline',
                enum: this.adminSettings.DOMAIN
            },
            {
                fullLabel: 'ADMIN_DEVICE_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_DEVICE_SETTINGS_LABEL_TAB',
                icon: 'tablet-landscape-outline',
                enum: this.adminSettings.DEVICE
            },
            {
                fullLabel: 'ADMIN_REGISTRATION_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_REGISTRATION_SETTINGS_LABEL_TAB',
                icon: 'clipboard-outline',
                enum: this.adminSettings.REGISTRATION
            },
            {
                fullLabel: 'ADMIN_SOFTWAREUPDATE_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_SOFTWAREUPDATE_SETTINGS_LABEL_TAB',
                icon: 'cloud-download-outline',
                enum: this.adminSettings.SOFTWAREUPDATE
            }
        ] : [
            {
                fullLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_TAB',
                icon: 'earth-outline',
                enum: this.adminSettings.DOMAIN
            }
        ];
    }

    /**
     * If the device used is a tablet - access to the admin is granted thru a 'secret' button
     * located in the menu at the version number - 5 clicks are required to open admin
     * if 2 seconds passes between a click the counter is reset
     */
    openAdmin(menuEl?: HrsMenu): void {
        this.totalButtonClicks += 1;
        clearTimeout(this.openAdminTimeout);
        if (this.totalButtonClicks === 5) {
            this.totalButtonClicks = 0;
            this.router.navigate(['admin']);
            if (menuEl) menuEl.toggle();
        } else {
            this.openAdminTimeout = setTimeout(() => this.totalButtonClicks = 0, 2000);
        }
    }

    rerouteHome(): void {
        const route = this.user.isDevice() ? 'tablet-white-screen' : 'home';
        this.router.navigate([route]);
    }
}
