<ion-label class="c_admin_wifi--setting" for="connection_signal">
    <span>{{ 'ADMIN_WIFI_CONNECTION_STATUS' | translate }}</span>
    <ion-icon
            id="connection_signal"
            class="c_admin_wifi--icon_signal"
            [ngClass]="isConnected ? 'connected' : 'disconnected'"
            name="wifi"
    ></ion-icon>
</ion-label>
<ion-label class="c_admin_wifi--setting">
    <span>{{ 'ADMIN_WIFI_WIFI_IS' | translate }}</span>
    <span class="wifi_state" [ngClass]="isWifiOn ? 'connected' : 'disconnected'">
  {{ (isWifiOn ? 'ADMIN_WIFI_ON' : 'ADMIN_WIFI_OFF') | translate }}
  </span>
    <hrs-button class="c_admin_wifi--button_power" icon="power" (click)="toggleWifi()" [disabled]="isToggling" [loading]="isToggling"></hrs-button>
</ion-label> 
<ion-label class="c_admin_wifi--setting">
    {{ 'ADMIN_WIFI_AVAILABLE_NETWORKS' | translate }}
    <hrs-button class="c_admin_wifi--button_refresh" icon="refresh" (click)="initWifi()" [disabled]="!isWifiOn" [loading]="isScanning"></hrs-button>
</ion-label>
<div *ngIf="isWifiOn" class="c_admin_wifi--networks">
    <ng-container>
        <ion-spinner *ngIf="isScanning"></ion-spinner>
        <p *ngIf="hasNoNetworks">{{ 'ADMIN_WIFI_NO_NETWORKS' | translate }}</p>
        <p *ngIf="hasScanError"></p>
        <ion-list *ngIf="canDisplayList()" id="available_networks" class="c_admin_wifi--networks_list">
            <ion-item *ngFor="let network of availableNetworks" (click)="connectToNetwork(network)">
                <div class="icon_placeholder">
                    <hrs-icon
                        *ngIf="connectedNetwork && network.SSID == connectedNetwork"
                        icon="success" 
                    ></hrs-icon>
                </div>
                <ion-label>{{network.SSID}}</ion-label>
                <div class="strength_placeholder">
                    <span>{{ 'ADMIN_WIFI_SIGNAL_STRENGTH' | translate }} </span>
                    <span [ngClass]="network.level > -45 ? 'strong' : network.level > -65 ? 'fair' : 'weak'">
        {{ (network.level > -45 ? 'ADMIN_WIFI_STRONG' : network.level > -65 ? 'ADMIN_WIFI_FAIR' : 'ADMIN_WIFI_WEAK') | translate }}</span>
                </div>
            </ion-item>
        </ion-list>
    </ng-container>
</div>
