import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/**
 * Use DomService for DOM interactions like creating dynamic Components
 */
@Injectable()
export class DomService {
    private mAppRef: ApplicationRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) { }

    // Injecting this directly in the constructor may cause
    // circular dependency errors on startup, so lazy-load it instead.
    private get appRef(): ApplicationRef {
        if (!this.mAppRef) this.mAppRef = this.injector.get(ApplicationRef);
        return this.mAppRef;
    }

    /**
     * Create Component Reference object
     * @param component - the Component Type you wish to reference
     * @param config - either a componentConfig object for i/o settings. Leave as {} if using a custom Injector
     * @param customInjector - custom Injector class for adding custom DI objects at Component creation
     */
    public async createComponentRefOf(component: any, config?: any, customInjector?: Injector): Promise<ComponentRef<any>> {
        const injector = customInjector ? customInjector : this.injector;
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(injector);

        config && this.attachConfig(config, componentRef);
        return componentRef;
    }

    /**
     * Dynamically load ViewChild on parent from child Component Type
     * @param child - child component to display within overlay
     * @param parent - Parent component's `this`
     */
    public async createComponent(child: Type<any>, parent: any): Promise<any> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(child);
        const viewContainerRef = parent.insertionPoint.viewContainerRef;
        // ensure no existing children in view
        viewContainerRef.clear();
        // Create ViewChild and mark for change detection
        return viewContainerRef.createComponent(componentFactory);
    }

    /**
     * Appends child Component to parent
     * @param parentId - #id set on parent Node (alt: 'body' to append to document.body)
     * @param child - child ComponentRef to be appended
     */
    public async appendComponentTo(parentId: string, child: ComponentRef<any>): Promise<void> {
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(child.hostView);
        // Append DOM element to the parent element (parentId)
        if (parentId === 'body') {
            const {nativeElement} = child.location;
            this.document.body.appendChild(nativeElement);
        } else {
            // Get DOM element from component
            const childDomElem = (child.hostView as EmbeddedViewRef<any>)
                .rootNodes[0] as HTMLElement;
            document.getElementById(parentId).appendChild(childDomElem);
        }
    }

    /**
     * Remove Component from ng component tree and destroy Component reference
     * @param componentRef - ComponentRef to be removed/destroyed
     */
    public async removeComponent(componentRef: ComponentRef<any>): Promise<void> {
        // Remove component from the ng component tree
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }

    /**
     * Attach Input/Output configuration values to ComponentRef dynamically
     * @param config - custom i/o configuration to be set
     * @param componentRef - Component host for settings
     */
    private attachConfig(config: componentConfig, componentRef: ComponentRef<any>): void {
        const inputs = config.inputs;
        const outputs = config.outputs;
        for (let key in inputs) {
            if ({}.hasOwnProperty.call(inputs, key)) componentRef.instance[key] = inputs[key];
        }
        for (let key in outputs) {
            if ({}.hasOwnProperty.call(outputs, key)) componentRef.instance[key] = outputs[key];
        }
    }
}

interface componentConfig {
    inputs: object;
    outputs: object;
}
