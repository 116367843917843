import {Injectable} from '@angular/core';
import {TrackingDataRequest} from '@hrs/interfaces';
import {getLogger} from '@hrs/logging';
import {PDFSource} from 'ng2-pdf-viewer';
import {EducationFile} from '../../education';
import {EducationTrackingService} from './education-tracking.service';
import {OverlayService} from '../overlay/overlay.service';
import {User} from '../user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {OverlayRef} from '../../hrs-overlay';
import {PdfComponent} from '../../components/pdf/pdf.component';

@Injectable()
export class EducationFileService {
    private readonly logger = getLogger('EducationFileService');
    public attachedListener: () => void;
    private overlayRef: OverlayRef;

    constructor(
        private educationTracking: EducationTrackingService,
        private overlay: OverlayService,
        private translate: TranslateService,
        private user: User
    ) {
    }

    public async downloadAndOpenFile(file: EducationFile): Promise<any> {
        this.logger.trace(`downloadAndOpenFile()`, file);
        let downloadUrl = file.attributes.url as string;
        const src: PDFSource = {
            url: downloadUrl,
            httpHeaders: {'Authorization': 'Bearer ' + this.user.token}
        };
        this.overlayRef = await this.overlay.openModal({
            component: PdfComponent,
            title: file.attributes.name,
            inputs: {
                src: src,
                showErrorAlert: (error) => this.handleFileError(file, error),
                startTracking: () => this.educationTracking.trackingStartTime(),
                submitTracking: () => this.submitFileTracking(file)
            },
            qa: 'education_file_modal'
        });
    }

    private submitFileTracking(content: EducationFile): void {
        let trackingData = this.setTrackingData(content.id);
        this.educationTracking.postTrackingData(trackingData);
    }

    private setTrackingData(contentID: string): TrackingDataRequest {
        return {
            path: 'content-viewed-tracking',
            body: {
                id: contentID,
                attributes: {
                    hrsid: this.user.id,
                    type: 'custom',
                    category: 'education',
                    time: this.educationTracking.trackingTimeElapsed(),
                    ftime: this.educationTracking.trackingFinishTime(),
                    source: EducationTrackingService.TRACKING_SOURCE
                }
            }
        };
    }

    private async handleFileError(content: EducationFile, error: ErrorEvent): Promise<OverlayRef> {
        this.logger.phic.error('Failed to open PDF', error);
        let message = 'EDUCATION_LIST.FILE-ERROR';
        return await this.overlay.openAlert({
            variant: 'error',
            header: this.translate.instant('ERROR_TITLE'),
            message: [this.translate.instant(message)],
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    role: 'cancel',
                    handler: () => this.overlayRef.dismiss()
                },
                {
                    text: this.translate.instant('RETRY_BUTTON'),
                    handler: async () => {
                        await this.overlayRef.dismiss();
                        await this.downloadAndOpenFile(content);
                    }
                }
            ],
            qa: 'education_file_alert'
        });
    }
}
