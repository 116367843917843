<hrs-content background="white">
  <div class="text-wrapper">
    <hrs-text *ngIf="!error" font="h1-semibold" qa="caregiver_pin--code">{{code}}</hrs-text>
    <hrs-text font="b1-regular" qa="caregiver_pin--instructions">{{body}}</hrs-text>
  </div>
</hrs-content>
<hrs-footer variant="one-button">
  <hrs-button
          expand
          variant="primary"
          text="{{'CLOSE_BUTTON' | translate}}"
          qa="caregiver_pin--btn"
          (click)="dismiss()"
  ></hrs-button>
</hrs-footer>
