import {
    ChangeDetectionStrategy,
    Component,
    HostListener
} from '@angular/core';
import {
    Answer,
    Question,
    Quiz
} from './education-quiz.model';
import {
    EducationService,
    EducationTrackingService,
    OverlayService,
    User
} from '@patient/providers';
import {TrackingDataRequest} from '@hrs/interfaces';
import {TranslateService} from '@ngx-translate/core';
import {OverlayRef} from '../../hrs-overlay';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {
    statusType,
    WizardConfigDetail,
    WizardSubheaderDetail
} from '@hrsui/core/dist/types/components/wizard/wizard.interface';

@Component({
    selector: 'app-education-quizzes',
    templateUrl: './education-quizzes.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EducationQuizzesPage {
    @HostListener('hrsListItemSelected', ['$event'])
    handleListItemSelected({detail: {itemId, selected}}): void {
        // example itemId: "3j4A6vZoBslcfA9C-q0-a1" ("[QUIZ_ID]-q[QUESTION_INDEX]-a[ANSWER_INDEX]")
        const quizId = itemId.split('-')[0];
        const questionIndex = parseInt(itemId.split('-q')[1]);
        const answerIndex = parseInt(itemId.split('-a')[1]);
        if (quizId === this.quizId && questionIndex === this.getQuestionIndex() && answerIndex < this.checkedAnswer.length) {
            this.checkedAnswer[answerIndex] = !!selected;
            this.isAnswered = this.checkedAnswer.some((ans) => ans);
        }
    }

    private correctAnswersText: string[] = [];
    private questions: Question[] = [];
    private quizId: string;
    private selectedAnswersText: string[] = [];

    public answers: Answer[];
    public checkedAnswer: boolean[] = [];
    public content: ContentDetail[];
    public correctAnswerSelected: boolean;
    public correctAnswers: Answer[];
    public currentQuestion: Question;
    public currentStep: number = 1;
    public hasAnswerButton: boolean = true;
    public headerText: string;
    public isAnswered: boolean = false;
    public isStatusCard: boolean = false;
    public isQuizComplete: boolean = false;
    public numberOfSteps: number;
    public quiz: Quiz;
    public showAnswer: boolean = false;
    public showCompleted: boolean = false;
    public wizardConfig: WizardConfigDetail;

    constructor(
        private education: EducationService,
        private educationTracking: EducationTrackingService,
        private overlay: OverlayService,
        private ref: OverlayRef,
        private translate: TranslateService,
        private user: User
    ) {}

    ngOnInit() {
        this.loadQuiz();
    }

    private loadQuiz(): void {
        this.quiz = this.education.getQuizById(this.quizId)[0];
        // the backend checks correct answers by array index, this sorting is to ensure that FE arrays match BE arrays order
        this.quiz.questions.sort((a, b) => {
            if (a && b) {
                return a.text.localeCompare(b.text);
            }
        });

        this.headerText = this.translate.instant('REVIEW_QUIZZES.QUIZ-NAME', {name: this.quiz.name});
        this.questions = [...this.quiz.questions];
        this.numberOfSteps = this.questions.length * 2; // Account for answer review StatusCards after each question
        this.showQuestionContent();
        this.updateWizard();
    }

    private getQuestionIndex(): number {
        // Even-numbered steps are StatusCards, so round up odd results to get correct indices
        return Math.ceil(this.currentStep / 2) - 1;
    }

    private updateWizard(): void {
        const isComplete = this.isQuizComplete;
        const updatedConfig = {
            ...this.wizardConfig,
            ...{
                headerText: this.headerText,
                totalSteps: this.numberOfSteps / 2,
                subheader: this.buildSubheader(isComplete)
            }
        };
        this.wizardConfig = updatedConfig;
    }

    private buildSubheader(isComplete: boolean): WizardSubheaderDetail {
        const statusType: statusType = isComplete ? 'complete' : 'correct';
        const statusTitle = isComplete ? 'REVIEW_QUIZZES.CONGRATULATIONS' : 'REVIEW_QUIZZES.CORRECT';
        return {
            serializationText: this.translate.instant('REVIEW_QUIZZES.SERIALIZATION', {index: (this.getQuestionIndex() + 1), total: this.questions.length}),
            isStatus: this.isStatusCard,
            type: this.correctAnswerSelected || isComplete ? statusType : 'wrong',
            title: this.translate.instant(this.correctAnswerSelected || isComplete ? statusTitle : 'REVIEW_QUIZZES.INCORRECT'),
            subtitle: this.translate.instant(`${this.correctAnswerSelected || isComplete ? statusTitle : 'REVIEW_QUIZZES.INCORRECT'}-SUBTITLE`),
        };
    }

    private isQuestionMultiChoice(): boolean {
        return this.correctAnswers.length > 1;
    }

    private checkAnswer(): void {
        this.selectedAnswersText = [];
        let isCorrect = true;
        this.answers.forEach((answer, i) => {
            if (this.checkedAnswer[i]) {
                this.selectedAnswersText.push(answer.text);
                if (!answer.correct) isCorrect = false;
            }
        });
        this.correctAnswerSelected = isCorrect && (this.selectedAnswersText.length === this.correctAnswersText.length);
    }

    public moveSeries(isForward: boolean): void {
        let currentViewAction;
        const isStatusCard = (): boolean => this.currentStep % 2 === 0;

        if (isForward && this.currentStep <= this.numberOfSteps) {
            this.currentStep++;
        }

        if (!isForward && this.currentStep > 1) {
            this.currentStep--;
            if (isStatusCard()) this.currentStep--;
        }

        if (isStatusCard()) {
            this.checkAnswer();
            currentViewAction = 'Answer';
        } else {
            currentViewAction = 'Question';
        }

        this.isQuizComplete = this.currentStep > this.numberOfSteps;
        this.isStatusCard = this.isQuizComplete || isStatusCard();

        this[`show${this.isQuizComplete ? 'Completed' : currentViewAction}Content`]();

        this.updateWizard();
    }

    private showQuestionContent(): void {
        this.unsetQuestionDetails();
        this.currentQuestion = this.questions[this.getQuestionIndex()];
        this.answers = [...this.currentQuestion.answers];
        for (let _ of this.answers) this.checkedAnswer.push(false);
        this.correctAnswers = this.currentQuestion.answers.filter((answer, index) => {
            if (answer.correct) {
                this.correctAnswersText.push(answer.text);
                return answer;
            }
        });
        this.content = this.createContentDetail();
    }

    public showAnswerContent(): void {
        const trackingData = this.setTrackingData('answer');
        this.educationTracking.postTrackingData(trackingData);
        this.showAnswer = true;
        this.hasAnswerButton = false;
        this.content = [] as ContentDetail[];
    }

    public showCompletedContent(): void {
        const trackingData = this.setTrackingData('quiz');
        this.educationTracking.postTrackingData(trackingData);
        this.showCompleted = true;
    }

    private unsetQuestionDetails(): void {
        this.answers = [];
        this.checkedAnswer = [];
        this.isAnswered = false;
        this.selectedAnswersText = [];
        this.correctAnswersText = [];
        this.currentQuestion = undefined;
        this.showAnswer = false;
        this.hasAnswerButton = true;
    }

    private createContentDetail(): ContentDetail[] {
        if (this.isStatusCard) return;
        const detail: ContentDetail = {
            lists: [{
                variant: this.isQuestionMultiChoice() ? 'checkbox' : 'radio',
                items: [],
                clearSelections: true
            }]
        };
        this.answers.forEach((ans, i) => {
            detail.lists[0].items[i] = {
                itemId: `${this.quiz.id}-q${this.getQuestionIndex()}-a${i}`,
                mainTitle: ans.text
            };
        });
        return [detail];
    }

    public handleModalClose(): void {
        if (this.isQuizComplete || this.questions.length === 1) {
            this.ref.dismiss();
        } else {
            this.notifyUser();
        }
    }

    private async notifyUser(): Promise<OverlayRef> {
        const alert = await this.overlay.openAlert({
            header: this.translate.instant('REVIEW_QUIZZES.ALERT-HEADER'),
            message: [this.translate.instant('REVIEW_QUIZZES.ALERT-MESSAGE')],
            variant: 'error',
            buttons: [
                {
                    text: this.translate.instant('REVIEW_QUIZZES.ALERT-LEAVE'),
                    handler: () => this.ref.dismiss()
                },
                {
                    text: this.translate.instant('REVIEW_QUIZZES.ALERT-CANCEL'),
                    handler: () => alert.dismiss()
                }
            ],
            qa: 'review_quizzes_alert'
        });
        return alert;
    }

    private setTrackingData(trackingType: string): TrackingDataRequest {
        if (trackingType === 'quiz') {
            return {
                path: 'quiz-tracking',
                body: {
                    id: this.user.id,
                    attributes: {
                        quiz: this.quiz.id,
                        ftime: this.educationTracking.trackingFinishTime(),
                        source: EducationTrackingService.TRACKING_SOURCE
                    }
                }
            };
        } else if (trackingType === 'answer') {
            return {
                path: 'quiz-answer-tracking',
                body: {
                    id: this.quiz.id,
                    attributes: {
                        hrsid: this.user.id,
                        question: this.currentQuestion.text,
                        questionnumber: this.getQuestionIndex() + 1,
                        answer: {answerinfo: {correct: this.correctAnswersText, selected: this.selectedAnswersText}},
                        ftime: this.educationTracking.trackingFinishTime(),
                        source: EducationTrackingService.TRACKING_SOURCE
                    }
                }
            };
        }
    }
}

