import {Injectable} from '@angular/core';
import {KnoxIntentService} from './knox-intent.service';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {BuildUtility} from '@hrs/utility';
import {Subject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class KioskIntentService extends KnoxIntentService {
    private readonly logger = getLogger('KioskIntentService');

    private static ACTION_DISABLE_KIOSK_MODE = 'com.hrs.intent.action.DISABLE_KIOSK_MODE';
    private static ACTION_ENABLE_KIOSK_MODE = 'com.hrs.intent.action.ENABLE_KIOSK_MODE';
    private static ACTION_KIOSK_IS_ENABLED = 'com.hrs.intent.action.KIOSK_IS_ENABLED';
    private static ACTION_DISABLE_KIOSK_MODE_RESULT = 'com.hrs.intent.action.DISABLE_KIOSK_MODE_RESULT';
    private static ACTION_ENABLE_KIOSK_MODE_RESULT = 'com.hrs.intent.action.ENABLE_KIOSK_MODE_RESULT';
    private static ACTION_KIOSK_IS_ENABLED_RESULT = 'com.hrs.intent.action.KIOSK_IS_ENABLED_RESULT';

    public isEnabledSubject: Subject<any> = new Subject();

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected registerListeners(): void {
        if (!BuildUtility.isHRSTab()) return;
        super.registerListener(KioskIntentService.ACTION_ENABLE_KIOSK_MODE_RESULT, true).subscribe((event) => {
            super.handleResult(event, KioskIntentService.ACTION_ENABLE_KIOSK_MODE_RESULT);
            this.isEnabledSubject.next(true);
        });

        super.registerListener(KioskIntentService.ACTION_DISABLE_KIOSK_MODE_RESULT, true).subscribe((event) => {
            super.handleResult(event, KioskIntentService.ACTION_DISABLE_KIOSK_MODE_RESULT);
            this.isEnabledSubject.next(false);
        });

        super.registerListener(KioskIntentService.ACTION_KIOSK_IS_ENABLED_RESULT, true).subscribe((event) => {
            super.handleResult(event, KioskIntentService.ACTION_KIOSK_IS_ENABLED_RESULT);
            this.isEnabledSubject.next(event.isEnabled);
        });
    }

    public async enableKioskMode(): Promise<boolean> {
        let intent = KioskIntentService.ACTION_ENABLE_KIOSK_MODE;
        this.logger.phic.info('Enabling kiosk mode');
        return super.sendIntent(intent);
    }

    public async disableKioskMode(): Promise<boolean> {
        let intent = KioskIntentService.ACTION_DISABLE_KIOSK_MODE;
        this.logger.phic.info('Disabling kiosk mode');
        return super.sendIntent(intent);
    }

    public async isKioskModeEnabled(): Promise<boolean> {
        let intent = KioskIntentService.ACTION_KIOSK_IS_ENABLED;
        this.logger.phic.info('Checking if kiosk mode is enabled');
        return super.sendIntent(intent);
    }
}
