import {Injectable, NgZone} from '@angular/core';
import {WelchService, WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY} from './welch.service';
import {BLE} from '@ionic-native/ble/ngx';
import {HRSStorage} from '../../storage/storage';
import {ModalService} from '@hrs/providers';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

/**
 * This class is the service class for Welch Allyn Scale. It inherits Welch base service for all the
 * common features and specializes some behavior related to scale.
 */
@Injectable({
    providedIn: 'root',
})
export class WelchWeightService extends WelchService {
    private readonly logger = getLogger('WelchWeightService');

    public SERVICE_WELCH_ALLYN_WEIGHT: string = '7802';
    public CHAR_WEIGHT_MEASUREMENT: string = '8a21';
    public CHAR_BATTERY_STATUS: string = '8a22';
    public DEVICE_NAME: string = 'SC100';
    public DEVICE_PAIRING_NAME: string = '1SC100';
    public weightInPounds: number = 0;

    constructor(
        protected ble: BLE,
        protected storage: HRSStorage,
        protected ngZone: NgZone,
        protected modalService: ModalService,
        private fbanalytics: HRSFirebaseAnalytics
    ) {
        super(ble, storage, ngZone, modalService, fbanalytics);
    }

    /**
     * Initializes the base service parameters for scale
     */
    public setBluetoothParams(): void {
        this.BASE_WELCH_SERVICE = this.SERVICE_WELCH_ALLYN_WEIGHT;
        this.BASE_WELCH_METRIC_MEASUREMENT_CHAR = this.CHAR_WEIGHT_MEASUREMENT;
        this.BASE_DEVICE_NAME = this.DEVICE_NAME;
        this.BASE_DEVICE_PAIRING_NAME = this.DEVICE_PAIRING_NAME;
        this.BASE_WELCH_PASSWORD_KEY = WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY;
    }

    /**
     * The enable characteristics message enables the device characteristics that the application wants to receive.
     * @param peripheral
     */
    public enableCharacteristics(peripheral: any): void {
        this.enableUploadDataTransferChar(peripheral);
        this.enableWeightCharacteristics(peripheral);
        this.enableBatteryCharacteristics(peripheral);
    }

    /**
     * Enable weight measurement transfer characteristic on scale
     * @param peripheral
     */
    public enableWeightCharacteristics(peripheral: any): void {
        this.logger.phic.debug('Enable weight characteristics for welch scale');
        this.ble.startNotification(peripheral.id, this.SERVICE_WELCH_ALLYN_WEIGHT, this.CHAR_WEIGHT_MEASUREMENT).subscribe(
            {
                next: (data) => {
                    this.logger.phic.debug('Got weight data from Welch Scale');
                    this.parseWeightData(data[0], peripheral);
                },
                error: (error) => {
                    let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'weight');
                    this.logger.phic.error('Error in starting notification for welch weight scale ' + error);
                    this.fbanalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                            [HRSFirebaseParams.META_DATA]: error});
                }
            }
        );
    }

    /**
     * Enables Battery status transfer characteristic on scale.
     * @param peripheral
     */
    public enableBatteryCharacteristics(peripheral: any): void {
        this.ble.startNotification(peripheral.id, this.SERVICE_WELCH_ALLYN_WEIGHT, this.CHAR_BATTERY_STATUS).subscribe(
            {
                next: (data) => {
                    this.logger.phic.debug('Battery char enabled ' + peripheral.name);
                    this.parseBatteryCharacteristicsData(data[0]);
                },
                error: (error) => {
                    this.logger.phic.error('Error in starting notification for battery char ' + peripheral.name, error);
                }
            }
        );
    }

    /**
     * Parses the weight data obtained from scale.
     * @param arrayBuffer
     * @param peripheral
     */
    public parseWeightData(arrayBuffer: ArrayBuffer, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheralName, 'weight');
        if (arrayBuffer.byteLength != 11) {
            this.logger.phic.debug('Invalid data size for scale weight measurements ' + peripheral.name);
            this.weightInPounds = 0; // invalid weight case as correct data not received from scale
            this.readingErrorOccurred = true;
            this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.INCORRECT_DEVICE_DATA,
                    [HRSFirebaseParams.META_DATA]: 'Receieved byte length ' + arrayBuffer.byteLength});
        } else {
            let data = new Int8Array(arrayBuffer);
            this.ngZone.run(() => {
                this.logger.phic.debug('Got data from Welch ' + peripheral.name + BluetoothUtils.buf2hex(arrayBuffer));
                // Extract units
                var units = 'unknown';
                if ((data[0] & 0x60) == 0x00) {
                    units = 'kgs';
                } else if ((data[0] & 0x60) == 0x20) {
                    units = 'lbs';
                } else if ((data[0] & 0x60) == 0x40) {
                    units = 'st';
                }
                this.logger.phic.debug('Welch ' + peripheral.name + ' Unit set to ' + units);

                let weightInKgs: number = ((((data[3] & 0xff) << 16) | (data[2] & 0xff) << 8) | (data[1] & 0xff)) * Math.pow(10.0, -((~data[4]) + 1));
                this.logger.phic.debug('Welch ' + peripheral.name + ' Weight in kgs: ' + weightInKgs);

                this.weightInPounds = BluetoothUtils.convertKgWeightToPound(weightInKgs);
                this.logger.phic.debug('Welch ' + peripheral.name + ' Weight in lbs: ' + this.weightInPounds);

                // Extract weight status
                if ((data[10] & 0x0e) == 0x08) {
                    this.logger.phic.debug('Welch ' + peripheral.name + ' Weight measurement complete');
                } else {
                    this.logger.phic.warn('Welch ' + peripheral.name + ' Invalid status received');
                }

                // Extract timestamp
                var timeStamp = ((data[8] & 0xff) << 24) | ((data[7] & 0xff) << 16) |
                ((data[6] & 0xff) << 8) | (data[5] & 0xff);
                this.logger.phic.debug('Received weight timestamp: ' + timeStamp);

                // The scale device will attempt to send all historical measurements as soon as the device is powered on.
                // We will take the most recent reading for our use
                if (this.isMostRecentReading(this.getNormalizedTime(timeStamp), 60)) {
                // All recordings are represented in pounds (lbs).
                // If a reading is taken while the scale is set to kilograms (kg) instead of pounds (lbs),
                // PCM displays the converted weight in pounds.
                // The weight in pounds (lbs) is what subsequently gets loaded to ClinicianConnect.
                    this.metricUpdateCallback(this.weightInPounds, peripheral);
                    this.gotRecentReading = true;
                    this.logger.phic.debug('Got recent reading from Welch Scale ' + this.weightInPounds + ' and will show on metric reading page for submission');
                    // app needs to send ACK disconnect after proper reading is received
                    this.sendDisconnect(peripheral);
                } else {
                    this.logger.phic.debug('Welch Scale : Old reading with historical data for weight as: ' + this.weightInPounds + ' lbs');
                    this.historicalReadingObtained = true;
                }
            });
        }
    }

    /**
     * Parses the battery status data obtained from scale.
     */
    public parseBatteryCharacteristicsData(arrayBuffer: ArrayBuffer): void {
        let data = new Int8Array(arrayBuffer);
        if ((data[0] & 0xff) != 0x81) {
            this.logger.phic.info('Battery Status Message is not properly formatted');
        }

        // Extract timestamp
        var timeStamp = ((data[4] & 0xff) << 24) | ((data[3] & 0xff) << 16) |
                ((data[2] & 0xff) << 8) | (data[1] & 0xff);
        this.logger.phic.info('Received Battery Status timestamp: ' + timeStamp);

        // Extract battery status
        var batteryStatus = (data[6] & 0x01) == 0x01 ? 'ok' : 'low power';
        this.logger.phic.info('Battery is: ' + batteryStatus);
    }

    /**
    * Callback function when metrics are updated for scale
    */
    public metricUpdateCallback: (weight: number, peripheral: any) => void;

    /**
     * Registers the metric update callback and handles the peripheral connected call.
     */
    public onConnected(peripheral: any, onMetricUpdateCallback: (weight: number, peripheral: any) => void): void {
        this.metricUpdateCallback = onMetricUpdateCallback;
        this.onPeripheralConnected(peripheral);
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (!isPairingFlow) {
            let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'weight');
            if (!this.gotRecentReading && !this.readingErrorOccurred) {
                let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
            }
        }
        // cleanup
        this.gotRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }
}
