<div class="c_white_screen">
    <div class="c_white_screen--top">
        <div class="c_white_screen--top_button_container">
            <hrs-button class="c_white_screen--top-upload-logs-button" (click)="submitAppLogs()" [text]="'TABLET_SETTINGS.SUBMIT_APP_LOGS' | translate"></hrs-button>
            <hrs-button class="c_white_screen--top-wifi-settings-button"  *ngIf="knoxEnabled" (click)="openWifiSettings()" [text]="'TABLET_SETTINGS.WIFI_SETTINGS' | translate"></hrs-button>
        </div>
        <div class="c_white_screen--top_button_container">
            <hrs-button class="c_white_screen--top-restart-device-button" (click)="restartDevicePrompt()" [text]="'TABLET_SETTINGS.RESTART' | translate"></hrs-button>
        </div>
    </div>
    <div class="c_white_screen--logo_container"  (click)="openAdmin()">
        <img src="/../../../assets/img/logo-blue.svg" alt="HRS Logo" class="c_white_screen--logo" data-qa="welcome--logo" />
    </div>
    <div class="c_white_screen--bottom">
        <hrs-text font="h2-semibold" (click)="openAdmin()">
            <div>{{ 'WHITE_SCREEN.BATTERY' | translate }} {{ batteryPercentage || "-" }}%</div>
            <div>v{{ versionNumber }}</div>
        </hrs-text>
        <hrs-text font="h2-semibold">
            {{ 'WHITE_SCREEN.ASSIGN_PATIENT' | translate }}
        </hrs-text>
    </div>
</div>
