import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {ContentDetail, ContentGridItem} from '@hrsui/core/dist/types/components/content/content.interface';
import {ActivatedRoute} from '@angular/router';
import {EducationService} from '@patient/providers';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {NavController} from '@ionic/angular';
import {TabDetail} from '@hrsui/core/dist/types/components/tabs/tabs.interface';
import {EducationRailTabs} from './education-rail-tabs.enum';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-education',
    templateUrl: './education.page.html',
    styleUrls: ['./education.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EducationPage implements OnInit {
    private readonly logger = getLogger('EducationPage');
    public content: ContentDetail[] = [];
    public selectedTab: TabDetail;
    private queryParamsSubscription: Subscription;

    constructor(
        private education: EducationService,
        private navCtrl: NavController,
        private ref: ChangeDetectorRef,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    @HostListener('hrsNavTabSelected', ['$event'])
    protected handleNavTabSelected({detail: {tab}}) {
        this.selectedTab = tab;
        this.education.educationRailTab = tab.id;
    }

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
            // Store the active rail tab so we can show the same state when we return to the Education page.
            if (params.type) this.education.educationRailTab = params.type;
            this.selectedTab = {id: this.education.educationRailTab, title: this.translate.instant(this.education.educationRailTab)};
            this.ref.detectChanges();
        });

        this.getEducationContent();
    }

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe();
    }

    private getEducationContent(): void {
        const educationTabs = this.getEducationContentTypes();
        this.content = educationTabs.map((tab) => {
            let gridItems: ContentGridItem[] = this.getTabContent(tab);

            // sort grid tiles alphabetically by title
            gridItems.sort((a, b) => {
                return a.mainTitle.localeCompare(b.mainTitle);
            });

            return {
                tab: {
                    id: tab,
                    title: this.translate.instant(tab)
                },
                items: gridItems
            };
        });
        this.ref.detectChanges();
    }

    private getTabContent(tab: string): ContentGridItem[] {
        if (tab === EducationRailTabs.VIDEOS) {
            return this.education.videoConditions.map((condition) => this.getGridItem(condition));
        }

        if (tab === EducationRailTabs.QUIZZES) {
            return this.education.quizConditions.map((condition) => this.getGridItem(condition));
        }

        if (tab === EducationRailTabs.FILES) {
            return this.education.fileConditions.map((condition) => this.getGridItem(condition));
        }
    }

    public getGridItem(condition: string): ContentGridItem {
        return {
            mainTitle: condition,
            variant: 'generic',
            handler: () => {
                this.openConditionPage(condition);
            }
        };
    }

    private async openConditionPage(condition: string): Promise<void> {
        const navigationExtras = {
            queryParams: {
                condition: condition,
                educationType: this.selectedTab.id
            }};

        try {
            await this.navCtrl.navigateForward('/education/condition', navigationExtras);
        } catch (e) {
            this.logger.phic.error(`Failed to open condition page: ${condition}`, e);
        }
    }

    private getEducationContentTypes(): string[] {
        let types = [];
        if (this.education.hasVideos()) types.push(EducationRailTabs.VIDEOS);
        if (this.education.hasQuizzes()) types.push(EducationRailTabs.QUIZZES);
        if (this.education.hasFiles()) types.push(EducationRailTabs.FILES);
        return types;
    }
}
