<div class="c_admin_kiosk">
    <ion-list>
        <ion-radio-group (ionChange)="toggleLock($event)">
            
            <ion-list-header>
                <ion-label>{{'ADMIN_KIOSK_SETTINGS_LABEL_TAB' | translate}}</ion-label>
            </ion-list-header>

            <ion-item>
                <ion-label>{{'ADMIN_KIOSK_LOCKED' | translate}}</ion-label>
                <ion-radio color="success" slot="start" value="locked"></ion-radio>
            </ion-item>

            <ion-item>
                <ion-label>{{'ADMIN_KIOSK_UNLOCKED' | translate}}</ion-label>
                <ion-radio color="danger" slot="start" value="unlocked"></ion-radio>
            </ion-item>

        </ion-radio-group>
    </ion-list>
</div>