<ng-template #metrics>
    <div class="generic_metric--wrapper">
        <form
            *ngIf="formGroup"
            [formGroup]="formGroup">
            <ng-container *ngFor="let control of schema">
                <hrs-form-item *ngIf="control.type === 'INPUT'">
                    <hrs-label *ngIf="control.uiText.label !== null"
                            [text]="control.uiText.label"
                            [subText]="control.uiText.placeholder !== null ? '(' + control.uiText.placeholder + ')' : ''"
                            [qa]="control.uiText.qaLabel + '-label'"
                    ></hrs-label>
                    <hrs-input #hrsInput
                            [appSubmitOnEnterKey]
                            (enterSubmit)="trySave()"
                            [formControlName]="control.id"
                            [id]="control.id"
                            [max]="control.max"
                            [min]="control.min"
                            [placeholder]="placeholderText"
                            [step]="control.step"
                            [type]="control.inputType"
                            [qa]="control.uiText.qaLabel + '-input'"
                    ></hrs-input>
                </hrs-form-item>
            </ng-container>

            <hrs-text *ngIf="shouldAutosubmit" qa="autosubmit-text">
                {{ autosubmitText | async }}
            </hrs-text>

            <div *ngIf="formGroup.errors?.hasDecimal" [ngClass]="{'generic_metric--errors': true, 'generic_metric--errors-autosubmit' : shouldAutosubmit}">
                <hrs-text font="h3-regular" color="error" align="center">{{ 'GENERIC_METRIC_ERROR' | translate }}</hrs-text>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #buttons>
    <hrs-button *ngIf="isHRSTablet" type="button" variant="secondary" appThrottleClick (throttledClick)="readContent()" [disabled]="isReading" icon="volume" qa="metric--btn-read_content"></hrs-button>
    <hrs-button id="submitButton" [expand]="isModal && !isHRSTablet" type="button" variant="primary" appThrottleClick (throttledClick)="save()" [disabled]="!canSave" [loading]="saving" qa="set_metrics--btn-save">
        {{ 'SUBMIT_BUTTON' | translate }}
    </hrs-button>
</ng-template>

<ng-container *ngIf="isModal">
    <hrs-content class="generic-metric--container">
        <ng-container *ngTemplateOutlet="metrics"></ng-container>
    </hrs-content>
    <hrs-footer *ngIf="formGroup" [attr.variant]="isHRSTablet ? 'two-button' : 'one-button'">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </hrs-footer>
</ng-container>

<ng-container *ngIf="!isModal">
    <div class="generic-metric-page">
        <div class="generic-metric-page--card-landing-area"></div>
        <ion-content class="generic-metric--content">
            <ion-refresher *ngIf="historicalDataEnabled" slot="fixed" data-qa="biometric--refresher" (ionRefresh)="handlePullToRefresh($event)">
                <ion-refresher-content
                    pullingIcon="arrow-down-circle-outline"
                    refreshingSpinner="circles">
                </ion-refresher-content>
            </ion-refresher>
            <hrs-content variant="card" class="generic-metric--card-container">
                <hrs-card *ngIf="hasPairedDevice" appThrottleClick (throttledClick)="showUsage()">
                    <div class="device-paired-card--container">
                        <hrs-image variant="large-thumbnail" [imageUrl]="imageUrl"></hrs-image>
                        <span class="device-paired-details">
                            <hrs-text font="h1-bold">{{deviceDetails.type}}</hrs-text>
                            <hrs-chip variant="success" [toggle]="true">{{'BLUETOOTH.DEVICE_PAIRED' | translate}}</hrs-chip>
                            <span class="device-instructions-link">
                                <hrs-text font="h2-semibold" color="gray-10">{{'BLUETOOTH.DEVICE_INSTRUCTIONS_TITLE' | translate}}</hrs-text><hrs-icon icon="next"></hrs-icon>
                            </span>
                        </span>
                    </div>
                </hrs-card>
                <hrs-card *ngIf="!hasPairedDevice && formGroup">
                    <div class="device-unpaired-card--container">
                        <ng-container *ngTemplateOutlet="metrics"></ng-container>
                        <div [ngClass]="{'button-container': true, 'button-container--two': isHRSTablet, 'button-container--error-text-adjust': formGroup.errors?.hasDecimal}">
                            <ng-container *ngTemplateOutlet="buttons"></ng-container>
                        </div>
                    </div>
                </hrs-card>
                <hrs-card *ngIf="historicalDataEnabled" class="historical_data">
                    <div class="historical_data--header">
                        <span class="historical_data--title">
                            <hrs-text font="h1-semibold">{{'GENERIC_METRIC.HISTORICAL.TITLE' | translate}}</hrs-text>
                            <hrs-button
                                *ngIf="isHRSTablet"
                                class="historical_data--btn-speak"
                                type="button"
                                variant="secondary"
                                data-qa="qa-historical_data--btn-speak"
                                icon="audio"
                                appThrottleClick (throttledClick)="readAllHistoricalMetrics()"
                            ></hrs-button>
                        </span>
                        <hrs-text font="h2-semibold">{{'GENERIC_METRIC.HISTORICAL.DATE' | translate}}: {{currentDay}}</hrs-text>
                    </div>
                    <hrs-list *ngFor="let list of listConfig" [list]="list"></hrs-list>
                    <div *ngIf="!listConfig || listConfig.length === 0" class="historical_data--no_readings">
                        <hrs-text *ngIf="listInitialLoad" font="h2-semibold">{{ 'LOADING_ELLIPSIS' | translate }}</hrs-text>
                        <hrs-text *ngIf="!listInitialLoad" font="h2-semibold">{{ 'GENERIC_METRIC.HISTORICAL.NO_READINGS' | translate }}</hrs-text>
                    </div>
                </hrs-card>
            </hrs-content>
        </ion-content>
    </div>
</ng-container>
