import {MonoTypeOperatorFunction, throttleTime} from 'rxjs';

export const THROTTLE_CLICK_TIME_MS_DEFAULT = 250;

/**
 * Wrapper for rxjs throttle operator that includes
 * default values for click event throttling specifically
 */
export function throttleClick<T>(
    throttleTimeMs: number = THROTTLE_CLICK_TIME_MS_DEFAULT
): MonoTypeOperatorFunction<T> {
    return throttleTime(throttleTimeMs);
}
