import {Injectable} from '@angular/core';
import {GatewayApi} from '@hrs/providers';
import {Subject} from 'rxjs';
import {HRSSecureCache} from '../storage/cache';
import {getLogger} from '@hrs/logging';

/**
 *Environment provider.
 */
@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    private readonly logger = getLogger('EnvironmentService');
    public data: any;
    public environmentDataLoading: Subject<void> = new Subject();

    constructor(
        private cache: HRSSecureCache,
        private gatewayApi: GatewayApi
    ) {}

    /**
     * Send a GET request to our environment settings endpoint on login.
     */
    public getEnvironment(environment: string) {
        let endpoint = 'v1/environment-settings?filter[environment]=' + environment;
        return this.cache.loadFromDelayedObservable(
            HRSSecureCache.ENVIRONMENT_SETTINGS_CACHE_KEY + this.cache.userID,
            this.gatewayApi.get(endpoint),
            undefined,
            undefined,
            'all',
            'meta'
        ).subscribe({
            next: (data: any) => {
                this.data = data.data;
                this.environmentDataLoading.next();
            },
            error: (err) => {
                this.logger.phic.error(`getEnvironment error`, err);
            }
        });
    }

    public isClinicianCommunicationDisabled(): boolean {
        return this.hasEnvironmentSetting('SYSTEM_DISABLECLINICIANCALLING');
    }

    public getEnvironmentName(): string {
        let environmentName;
        if (this.data) {
            for (let i = 0; i < this.data.length; i++) {
                if (
                    this.data[i].attributes.name === 'SYSTEM_ENVIRONMENTNAME' && !environmentName ||
                    this.data[i].attributes.name === 'SYSTEM_FRIENDLY_ENVIRONMENTNAME'
                ) {
                    environmentName = this.data[i].attributes.value;
                }
            }
        }
        return environmentName;
    }

    public getEnhancedSecurityFlag(): string {
        const setting = this.getEnvironmentSetting('SYSTEM_MOBILE_SECURITY_OPTIONAL');
        if (setting) return setting.value;
    }

    public getClientLogoURL(): string {
        const logo = this.getEnvironmentSetting('SYSTEM_PCMTLOGO');
        if (logo) {
            return logo.url;
        }

        return '';
    }

    public getEnvironmentSetting(flag: string): {name: string; value?: any; url?: string} {
        let setting;
        if (this.data) {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].attributes.name === flag) {
                    setting = this.data[i].attributes;
                }
            }
        }
        return setting;
    }

    public hasSystemMultipleReadingsFlag(): boolean {
        return this.hasEnvironmentSetting('SYSTEM_MULTIPLEREADINGS');
    }

    public hasMultipleReminders(): boolean {
        return this.hasEnvironmentSetting('SYSTEM_MULTIPLEREMINDERS');
    }

    public hasHistoricalData(): boolean {
        return this.hasEnvironmentSetting('SYSTEM_HISTORICALDATA_PCMT');
    }

    public hasEnvironmentSetting(flag: string): boolean {
        let hasFlag = false;
        if (this.data) {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].attributes.name === flag) {
                    hasFlag = true;
                    break;
                }
            }
        }

        return hasFlag;
    }
}
