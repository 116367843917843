import {Injectable} from '@angular/core';
import {KnoxIntentService} from './knox-intent.service';
import {Broadcaster} from '@ionic-native/broadcaster/ngx';
import {BuildUtility} from '@hrs/utility';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class APKIntentService extends KnoxIntentService {
    public static ACTION_INSTALL_APK = 'com.hrs.intent.action.INSTALL_APK';
    public static ACTION_INSTALL_APK_RESULT = 'com.hrs.intent.action.INSTALL_APK_RESULT';

    constructor(
        broadcaster: Broadcaster,
        platform: Platform
    ) {
        super(broadcaster, platform);
        this.registerListeners();
    }

    protected registerListeners(): void {
        if (!BuildUtility.isHRSTab()) return;
        super.registerListener(APKIntentService.ACTION_INSTALL_APK_RESULT, true).subscribe((event) => {
            this.handleResult(event, APKIntentService.ACTION_INSTALL_APK_RESULT);
        });
    }

    public sendInstallIntent(filepath: string, packageName: string, launchActivity: string): Promise<boolean> {
        const extras = {
            path: filepath,
            package: packageName,
            activity: launchActivity
        };

        return super.sendIntent(APKIntentService.ACTION_INSTALL_APK, true, extras);
    }
}
