<ion-header class="c_admin--header">
    <ion-toolbar>
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button
                class="c_admin--home_button"
                (click)="exitAdmin()"
            >
                <hrs-icon icon="home-solid"></hrs-icon>
                <span class="sr-only">{{ 'MENU_HOME_TITLE' | translate }}</span>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ 'ADMIN_TITLE' | translate }}
            </span>
            <ion-label
                    *ngIf="hasCorrectAccess"
                    for="settingsPanel"
            >| {{ selectedSetting.fullLabel | translate}}</ion-label>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="c_admin--content">

    <ng-container *ngIf="hasCorrectAccess; else passwordEntry">
        <ion-tab-bar class="c_admin_settings--tabs">
            <ion-tab-button
                *ngFor="let setting of settings"
                (click)="selectSetting(setting)"
                [ngClass]="{'tab-selected': setting.enum === selectedSetting.enum, 'tab-hidden': tabGuard(setting.enum)}">
                <ion-icon name="{{setting.icon}}"></ion-icon>
                <ion-label>{{setting.tabLabel | translate}}</ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    
        <div class="c_admin_settings">
            <div [ngClass]="{'c_admin_settings--panel': true, 'c_admin_settings--panel-device': selectedSetting.enum == adminSettings.DEVICE}">
                <ng-container
                    id="settingsPanel"
                    [ngSwitch]="selectedSetting.enum">
                    <wifi-settings  *ngSwitchCase="adminSettings.WIFI"></wifi-settings>
                    <kiosk-settings *ngSwitchCase="adminSettings.KIOSK"></kiosk-settings>
                    <domain-settings *ngSwitchCase="adminSettings.DOMAIN"></domain-settings>
                    <device-settings *ngSwitchCase="adminSettings.DEVICE"></device-settings>
                    <registration *ngSwitchCase="adminSettings.REGISTRATION"></registration>
                    <software-update *ngSwitchCase="adminSettings.SOFTWAREUPDATE"></software-update>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #passwordEntry>
        <hrs-admin-access
            (hasCorrectPassword)="hasCorrectPassword($event)"
        ></hrs-admin-access>
    </ng-template>
    
</ion-content>

