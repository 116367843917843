import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {Subject, Observable} from 'rxjs';
import {ModalService} from '@hrs/providers';
import BluetoothUtils from 'src/app/bluetooth-utils';
import moment from 'moment';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})

export class PulseOxDeviceService {
    private readonly logger = getLogger('PulseOxDeviceService');

    // Metrics
    heartRate: number;
    spo2: number;

    isRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    metricChange = new Subject();
    bluetoothMetricChange$: Observable<any>;

    // Services and Characteristics to connect to device
    FORACARE_SERVICE = '00001523-1212-EFDE-1523-785FEABCD123';
    FORACARE_CHARACTERISTIC = '00001524-1212-EFDE-1523-785FEABCD123';
    isDeviceConnected: boolean = false;

    // Foracare Byte Commands and Request Messages
    CMD_ACK = 0x51;
    CMD_START = 81;
    CMD_END = 163;
    CMD_COMMS = 0x54;
    CMD_DATA_COUNT = 0x2B;
    CMD_DATA_TIME = 0x25;
    CMD_DATA = 0x26;
    CMD_DATA_SCALE = 0x71;
    CMD_DATA_CLEAR = 0x52;
    CMD_TAKE_MEASUREMENT = 0x41;
    CMD_POWER_OFF = 0x50;
    CMD_DATA_POINTS = 0x2;

    // Taidoc 8255 commands and control variables
    CMD_START_HEX = 0x51;
    CMD_STOP_HEX = 0xa3;
    CMD_WRITE_DATE = 0x33;
    CMD_DATA_ACK = 0xa5;
    retryForTaidoc8255Data = false;

    // Nonin 3230 commands
    private NONIN_CONTROL_PT_MEASURE_COMPLETE = '1447af80-0d60-11e2-88b6-0002a5d5c51b';
    private NONIN_3230_SERVICE = '46A970E0-0D5F-11E2-8B5E-0002A5D5C51B';
    private NONIN_MEASUREMENT_COMPLETE_CMD = 0x62;

    private NONIN_3230_OXIMETRY_CHAR = '0AAD7EA0-0D60-11E2-8E3C-0002A5D5C51B'; // continous oximetry reading
    private NONIN_3230_SPOTCHECK_SERVICE = '1822'; // spot check service
    private NONIN_3230_SPOTCHECK_CHAR = '2A5E'; // spot check characteristic

    constructor(
        private ble: BLE,
        private ngZone: NgZone,
        private modalService: ModalService,
        private bluetoothSerial: BluetoothSerial,
        private fbanalytics: HRSFirebaseAnalytics
    ) {
        this.bluetoothMetricChange$ = this.metricChange.asObservable();
    }

    /**
     * Foracare (TNG) is specific in that it needs to be written to in order to continue notifications
     * The addCheckSum ensures that the buffer array includes the correct bytes. We stop writing on clear.
     * This may be used for the other Foracare devices, but currently unsure. I will adjust and cleanup functions
     * if that is the case.
     */
    onPulseOxMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralId = peripheral.id;
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, peripheralType);
        this.logger.phic.debug('onPulseOxMetricMeasurementChange ' + peripheralName);
        if (peripheralType == 'pulseox' && peripheralName.includes('Nonin3230') && !this.modalService.getModalStatus('GenericMetricPage')) {
            let arrayBuffer = new Uint8Array(buffer);
            this.logger.phic.debug('Nonin 3230: Got data: ' + BluetoothUtils.buf2hex(arrayBuffer));
            this.ngZone.run(() => {
                let parsedHeartRate;
                let parsedspo2;
                let isSpotCheckMode = (buffer != null && buffer.byteLength == 19);
                let isContinuousMode = (buffer != null && buffer.byteLength == 10);
                let isSmartPointReading: boolean = false;
                // spot check data 19 bytes length
                if (isSpotCheckMode) {
                    this.logger.phic.debug('Nonin 3230 : Spot check Reading mode ');
                    let spo2RawValue = arrayBuffer[2].toString(16) + arrayBuffer[1].toString(16); // MSO + LSO
                    parsedspo2 = BluetoothUtils.hexToSFloat(spo2RawValue);
                    let heartRateRawVal = arrayBuffer[4].toString(16) + arrayBuffer[3].toString(16); // MSO + LSO
                    parsedHeartRate = BluetoothUtils.hexToSFloat(heartRateRawVal);
                    isSmartPointReading = true;
                } else if (isContinuousMode){ // continous reading data 10 bytes length
                    this.logger.phic.debug('Nonin 3230 : Continuous Reading mode ' );
                    // Utlizing Nonin's smart point characteristic reading to ensure that we are getting the most accurate reading possible
                    if (this.isSmartPointReading(arrayBuffer)) {
                        this.logger.phic.debug('Nonin 3230: Smart point reading');
                        parsedHeartRate = arrayBuffer[9];
                        parsedspo2 = arrayBuffer[7] + arrayBuffer[8];
                        isSmartPointReading = true;
                    }
                } else {
                    this.logger.phic.error('Unknown data from Nonin 3230');
                }

                if (isSmartPointReading) {
                    this.logger.debug('Smart point : Parsed Reading: Heart rate ' + parsedHeartRate + ' SP02 ' + parsedspo2 );

                    const isValidHeartRate = parsedHeartRate > 0 && parsedHeartRate !== 255;
                    const isValidSpO2 = parsedspo2 > 0 && parsedspo2 !== 128;
                    // Prevent submitting Nonin 3230 default readings of 128/255 that transmits when the device can not get a proper reading.
                    if (isValidHeartRate && isValidSpO2) {
                        this.heartRate = parsedHeartRate;
                        this.spo2 = parsedspo2;
                        this.logger.phic.debug('Nonin 3230: Obtained valid readings: HR: ' + this.heartRate + ' sp02 ' + this.spo2);
                        this.metricChange.next(peripheralName);
                        this.isRecentReading = true;
                        this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi});

                        this.logger.phic.debug('Nonin 3230: Will write Control Point Measurement Complete Procedure as submission is done');
                        var measurementComplete = new Uint8Array([this.NONIN_MEASUREMENT_COMPLETE_CMD, 0x4E, 0x4D, 0x49]);
                        this.ble.write(peripheralId, this.NONIN_3230_SERVICE, this.NONIN_CONTROL_PT_MEASURE_COMPLETE, measurementComplete.buffer).then(
                            (res) => {
                                this.logger.phic.debug('Nonin 3230: Response receieved: Measurement Complete ' + res);
                            },
                            (error) => {
                                this.logger.phic.error('Failed to write Nonin 3230 control point measurement complete procedure ', error);
                            });
                    } else {
                        this.logger.phic.debug('Nonin 3230: Obtained InValid readings! HR: ' + parsedHeartRate + ' sp02 ' + parsedspo2);
                        this.readingErrorOccurred = true;
                        this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.VALIDATION_FAILURE});
                    }
                }
            });
        } else if ((peripheralType == 'pulseox' && peripheralName.includes('TNG SPO2') && !this.modalService.getModalStatus('GenericMetricPage'))) {
            this.ngZone.run(() => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug('TNG SP02: Got data: ' + BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer.length == 2 && (arrayBuffer[0] == 1) && arrayBuffer[1] == 0) {
                    this.logger.phic.debug('TNG SP02: Send data count command');
                    let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
                    this.writeForacarePulseOxCharacteristic(peripheral, dataCount, false);
                } else if (arrayBuffer.length == 8) {
                    if (arrayBuffer[1] == this.CMD_DATA_COUNT) {
                        this.logger.phic.debug('TNG SP02: Send date time command');
                        let dataDateTime = this.addCheckSum([81, 0x25, 0, 0, 0, 0, 163]);
                        this.writeForacarePulseOxCharacteristic(peripheral, dataDateTime, false);
                    } else if (arrayBuffer[1] == this.CMD_DATA_TIME) {
                        this.logger.phic.debug('TNG SP02: Send data measurement command');
                        let dataMeasurement = this.addCheckSum([81, 0x26, 0, 0, 0, 0, 163]);
                        this.writeForacarePulseOxCharacteristic(peripheral, dataMeasurement, false);
                    } else if (arrayBuffer[1] == this.CMD_DATA) {
                        if (arrayBuffer[5] > 0 && arrayBuffer[2] > 0) {
                            this.spo2 = arrayBuffer[2];
                            this.heartRate = arrayBuffer[5];
                            this.logger.phic.debug('TNG SP02: Valid readings: Heartrate:' + this.heartRate + ' SP02:' + this.spo2);
                            setTimeout(() => {
                                this.logger.phic.debug('TNG SP02: Send to metric reading page');
                                this.metricChange.next(peripheralName);
                            }, 5000);
                            this.isRecentReading = true;
                            this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi});
                            this.logger.phic.debug('TNG SP02: Send data clear device command');
                            let recordData = this.addCheckSum([81, 0x52, 0, 0, 0, 0, 163]);
                            this.writeForacarePulseOxCharacteristic(peripheral, recordData, false);
                        }
                    } else if (arrayBuffer[1] == this.CMD_DATA_CLEAR) {
                        this.logger.phic.debug('TNG SP02: Send turn off device command');
                        let clearData = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                        this.writeForacarePulseOxCharacteristic(peripheral, clearData, true);
                    }
                }
            });
        } else if ((peripheralType == 'pulseox' && peripheralName.includes('TAIDOC TD8255') && !this.modalService.getModalStatus('GenericMetricPage'))) {
            this.ngZone.run(() => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug('Taidoc 8255: Got data ' + BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer.length == 8) {
                    if (arrayBuffer[1] == this.CMD_DATA_COUNT && arrayBuffer[6] == this.CMD_DATA_ACK) { // callback for data count receievd twice one for cmd and one for ack
                        this.logger.phic.debug('Taidoc 8255 Command DATA COUNT result ' + BluetoothUtils.buf2hex(arrayBuffer));

                        // JIR-10793 : For Taidoc 8255, as soon as user starts taking the reading and device is connected,
                        // device might be still measuring them and thus if latest readings are not available (stored at 0th index)
                        // we we will have to : Retry fetching the data till it is available and discard any historical data
                        if (arrayBuffer[2] == 0 && arrayBuffer[3] == 0 ) { // Device is still measuring
                            this.logger.phic.debug('Retrying to measure for Taidoc 8255 as device is still measuring');
                            this.retryFetchingTaidoc8255Data(peripheral);
                        } else {
                            this.logger.phic.debug('Got the stored data from Taidoc 8255 and thus executing the next steps');
                            this.retryForTaidoc8255Data = false;
                            let dataDateTime = this.addCheckSum([81, 0x25, 0, 0, 0, 0, 163]);
                            this.writeForacarePulseOxCharacteristic(peripheral, dataDateTime, false);
                        }
                    } else if (arrayBuffer[1] == this.CMD_DATA_TIME && arrayBuffer[3] != 0) { // year is not zero
                        this.logger.phic.debug('Taidoc 8255 Command DATA TIME result buf to hex ' + BluetoothUtils.buf2hex(arrayBuffer));
                        let currentReadingTimestamp = this.parseDateTimeTaidoc8255(arrayBuffer);
                        if (this.isRecentReadingForTaidoc8255(currentReadingTimestamp)) {
                            this.isRecentReading = true;
                            this.logger.phic.debug('Got recent reading from Taidoc 8255');
                            let dataMeasurement = this.addCheckSum([81, 0x26, 0, 0, 0, 0, 163]);
                            this.writeForacarePulseOxCharacteristic(peripheral, dataMeasurement, false);
                        } else {
                            this.logger.phic.log('Not Submitting an old stored reading from Taidoc 8255. Will retry for a newer one');
                            this.historicalReadingObtained = true;
                            this.retryFetchingTaidoc8255Data(peripheral);
                        }
                    } else if (arrayBuffer[1] == this.CMD_DATA) {
                        this.logger.phic.debug('Got DATA ' + BluetoothUtils.buf2hex(arrayBuffer));
                        if (arrayBuffer[5] > 0 && arrayBuffer[2] > 0) {
                            this.spo2 = (arrayBuffer[3] << 8) | arrayBuffer[2]; //  MSB (array [3]) LSB (array [2])
                            this.heartRate = arrayBuffer[5];
                            setTimeout(() => {
                                this.logger.phic.debug('Got recent reading from Taidoc 8255 ' + ' heart rate ' + this.heartRate + ' sp02' + this.spo2 + ' will show on metric reading page for submission');
                                this.metricChange.next(peripheralName);
                            }, 500);
                            this.isRecentReading = true;
                            this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi});
                            this.logger.phic.debug('Taidoc 8255: Send data clear device command');
                            let clearData = this.addCheckSum([81, 0x52, 0, 0, 0, 0, 163]);
                            this.writeForacarePulseOxCharacteristic(peripheral, clearData, false);
                        }
                    } else if (arrayBuffer[1] == this.CMD_DATA_CLEAR) {
                        this.logger.phic.debug('Taidoc 8255: Send turn off device command');
                        let turnoffDevice = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                        this.writeForacarePulseOxCharacteristic(peripheral, turnoffDevice, true);
                    }
                }
            });
        } else if (
            peripheralType == 'pulseox' &&
            peripheralName.includes('Nonin_Medical') &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.bluetoothSerial.subscribeRawData().subscribe(
                {
                    next: (data) => {
                        let arrayBuffer = new Uint8Array(data);
                        this.logger.phic.debug('Nonin_Medical 9560: Got data ' + BluetoothUtils.buf2hex(arrayBuffer));
                        this.ngZone.run(() => {
                            this.logger.phic.debug('Nonin_Medical 9560 Data obtained with lenght ' + arrayBuffer.length);
                            if (arrayBuffer.length === 22 && arrayBuffer[19] > 0) {
                                this.spo2 = arrayBuffer[19];
                                this.heartRate = arrayBuffer[17];

                                // We are using Data format 13 and it attempts to send only one measurement when a SmartPoint measurement is available or after 40 seconds from power-on.
                                // High Quality SmartPoint Measurement bit value will be set for Spot check measurement which is the latest one
                                let statusMSB = arrayBuffer[14];
                                this.logger.phic.info('Nonin_Medical 9560 Is a SPA (Spot Check high quality measurement) recorded ?' + BluetoothUtils.iskthBitSet(statusMSB, 1));

                                // LSB will have MEM bit (at position 4th) set to 1 if it is a stored reading (Stored measurement from memory)
                                let statusLSB = arrayBuffer[15];
                                this.logger.phic.debug('Nonin_Medical 9560 Is this a Stored measurement from memory ? ' + BluetoothUtils.iskthBitSet(statusLSB, 4));

                                // LSB will have LOW BAT bit (at position 0th) set to 1 if it is a low battery device
                                this.logger.phic.debug('Nonin_Medical 9650 Is the device Low battery ? ' + BluetoothUtils.iskthBitSet(statusLSB, 0));

                                let isAStoredReading = BluetoothUtils.iskthBitSet(statusLSB, 4);

                                // Discard if it is stored reading
                                if (!isAStoredReading) {
                                    this.logger.phic.info('Obtained latest reading from Nonin 9560 thus will show on metric reading page for submission');
                                    setTimeout(() => {
                                        this.metricChange.next(peripheralName);
                                    }, 5000);
                                    this.isRecentReading = true;
                                    this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                            [HRSFirebaseParams.RSSI]: peripheral.rssi});
                                } else {
                                    this.logger.phic.info('Obtained Stored reading from Nonin 9560 thus discarding it');
                                    this.historicalReadingObtained = true;
                                }
                            }
                        });
                    },
                    error: (error) => {
                        this.logger.phic.error('Error reading raw data', JSON.stringify(error));
                        this.readingErrorOccurred = true;
                        this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.ERROR_READING_DATA,
                                [HRSFirebaseParams.META_DATA]: error});
                    }
                }
            );
        }
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (!isPairingFlow) {
            let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'pulseox');
            if (!this.isRecentReading && !this.readingErrorOccurred && !peripheralDisplayName.includes('Nonin_Medical')) {
                let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'pulseox',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
            }
        }
        // cleanup
        this.isRecentReading = false;
        this.historicalReadingObtained = false;
        this.readingErrorOccurred = false;
    }

    writeForacarePulseOxCharacteristic(peripheral: any, data: any, isClearData: boolean): void {
        let peripheralId = peripheral.id;
        this.ble.write(peripheralId, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, data.buffer).then(
            (res) => {
                if (!isClearData) {
                    this.onPulseOxMetricMeasurementChange(data.buffer, 'pulseox', peripheral);
                }
            },
            (error) => {
                this.logger.phic.error('Failed to write characteristic from device', error);
            });
    }

    /**
     * This method retries fetching the stored data count on Taidoc 8255
     * @param peripheralId
     * @param peripheralName
     */
    private retryFetchingTaidoc8255Data(peripheral: any): void {
        if (!this.retryForTaidoc8255Data) {
            this.retryForTaidoc8255Data = true;
            setTimeout(() => {
                let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
                this.writeForacarePulseOxCharacteristic(peripheral, dataCount, false);
                this.retryForTaidoc8255Data = false;
            }, 2000);
        }
    }

    /**
     * Write current date and time on Taidoc 8255 pulse ox
     * @param peripheral
     */
    public configureDevice(peripheral: any): void {
        if (peripheral.name.includes('TAIDOC TD8255')) {
            var today = new Date();
            const year = today.getFullYear() - 2000;
            const month = today.getMonth() + 1;
            const day = today.getDate();
            this.logger.phic.debug('Writing date time config to Taidoc 8255 :: Y ' + year + ' M ' + (month) + ' D ' + day + ' HH ' + today.getHours() + ' MM ' + today.getMinutes() + ' ');
            let writeSystemClock = this.addCheckSum([this.CMD_START_HEX, this.CMD_WRITE_DATE, ((month & 7) << 5) + day, (year << 1) + (month >> 3),
                today.getMinutes(), today.getHours(), this.CMD_STOP_HEX]);
            this.writeForacarePulseOxCharacteristic(peripheral, writeSystemClock, true);
        } else if (peripheral.name.includes('Nonin3230')) {
            this.logger.phic.debug('Starting notification for SpotCheck on Nonin 3230');
            this.ble.startNotification(peripheral.id, this.NONIN_3230_SPOTCHECK_SERVICE, this.NONIN_3230_SPOTCHECK_CHAR).subscribe(
                {
                    next: (data) => {
                        this.onPulseOxMetricMeasurementChange(data[0], 'pulseox', peripheral);
                    },
                    error: (error) => {
                        // Error starting or listening to SPOT check mode, thus enable characteristics for continuous reading mode
                        this.logger.phic.error('Failed to notify spot check char from ' + peripheral.name + ' , will start notification for continuous reading');
                        this.ble.startNotification(peripheral.id, this.NONIN_3230_SERVICE, this.NONIN_3230_OXIMETRY_CHAR).subscribe(
                            {
                                next: (data) => {
                                    this.onPulseOxMetricMeasurementChange(data[0], 'pulseox', peripheral);
                                },
                                error: (error) => {
                                    this.logger.phic.error('configureDevice: Failed to notify oximetry characteristic from ' + peripheral.name);
                                }
                            }
                        );
                    }
                }
            );
        }
    }

    /**
     * Parse date and tim obtained from Taidoc 8255 for the readings
     * @param arrayBuffer
     * @returns
     */
    private parseDateTimeTaidoc8255(arrayBuffer: Uint8Array): Date {
        let day = arrayBuffer[2];
        let dayBitMask = 0x1f; // bitmask 00011111
        let extractedDay = day & dayBitMask;
        let monthLB = arrayBuffer[2] >>> 5;
        let monthHB = (arrayBuffer[3] & 0x01) << 3;// bitmask 00000001
        let month = monthLB | (monthHB);
        let year = arrayBuffer[3];
        year = (year >>> 1);
        year = 2000 + year;
        let minutes = arrayBuffer[4] & 0x3F; // bitmask 00111111
        let hours = arrayBuffer[5] & 0x3F; // bitmask 00111111
        this.logger.phic.debug('Taidoc 8255 reading :: Meassure cal = Y ' + year + ' M ' + (month) + ' D ' + extractedDay + ' HH ' + hours + ' MM ' + minutes + ' ');
        let currentReadingTimestamp = new Date(year, (month - 1), extractedDay, hours, minutes, 0);
        this.logger.phic.debug('Current reading timestamp ' + currentReadingTimestamp);
        return currentReadingTimestamp;
    }

    /**
     * This method compares the reading time stamp with current date and checks whether
     * reading is a recent one. Reading is considered latest if reading time difference is less than 2 minutes.
     * @param readingTimestamp
     * @returns boolean
     */
    private isRecentReadingForTaidoc8255(readingTimestamp: Date): boolean {
        const maxTimeDiffInSeconds = 120;
        const readingCaptureMoment = moment(readingTimestamp);
        const currentTime = moment();
        const deltaTimeInSeconds = ((currentTime.valueOf() - readingCaptureMoment.valueOf()) / 1000);
        return (deltaTimeInSeconds < maxTimeDiffInSeconds);
    }

    public onACLConnect(peripheral: any): void {
        if (peripheral.name.includes('TAIDOC TD8255')) {
            this.retryForTaidoc8255Data = false;
            // Taidoc 8255 does not start transmitting right away but takes a while thus will query for data after 2 seconds
            setTimeout(() => {
                let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
                this.writeForacarePulseOxCharacteristic(peripheral, dataCount, false);
                this.retryForTaidoc8255Data = false;
            }, 2000);
        } else if (peripheral.name.includes('TNG SPO2')) {
            let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
            this.writeForacarePulseOxCharacteristic(peripheral, dataCount, false);
        }
    }

    /**
     * Foracare documentation requires a check sum [1...n-1]. Checksum appends as the last byte of the transmission
     * you get n bytes, sum of the first n-1 bytes, see if the answer is the same as the last byte, etc
     * This is so that we get our full 8 byte check.
     */
    addCheckSum(bytes: number[]): ArrayBuffer {
        let out = new Uint8Array(bytes.length + 1);
        let checksum = 0;
        for (let i = 0; i < bytes.length; i++) {
            checksum += (bytes[i] & 0xFF);
            out[i] = bytes[i];
        }
        out[out.length - 1] = checksum;
        return out;
    }

    getBit(bytes: any, position: number): any {
        // Bitwise Operators
        return ((bytes >> position) & 1);
    }

    // Parsers - we need to include all of these to run through the process and get the smart point reading
    isSmartPointReading(arrayBuffer: ArrayBuffer): boolean {
        // let displaySync = this.getBit(arrayBuffer[1], 0);
        // let weakSignal = this.getBit(arrayBuffer[1], 1);
        let smartPoint = this.getBit(arrayBuffer[1], 2);
        // let searching = this.getBit(arrayBuffer[1], 3);
        // let correctCheck = this.getBit(arrayBuffer[1], 4);
        // let lowBattery = this.getBit(arrayBuffer[1], 5);
        // let encryption = this.getBit(arrayBuffer[1], 6);
        // let reserved = this.getBit(arrayBuffer[1], 7);

        if (smartPoint === 1) {
            return true;
        } else {
            return false;
        }
    }
}
