import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {IonRouterOutlet, LoadingController, NavController, Platform} from '@ionic/angular';
import {EducationTrackingService, OverlayService, User} from '@patient/providers';
import {TranslateService} from '@ngx-translate/core';
import {GatewayService} from '@hrs/gateway';
import {TrackingDataRequest} from '@hrs/interfaces';
import {ScreenOrientationService} from '../../services/screen-orientation/screen-orientation.service';
import {ActivatedRoute} from '@angular/router';
import {EducationVideoFile} from '../education-file.interface';
import {OverlayRef} from '../../hrs-overlay';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-education-videos',
    templateUrl: './education-videos.page.html',
    styleUrls: ['./education-videos.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EducationVideosPage implements OnInit, AfterViewInit, OnDestroy {
    private readonly logger = getLogger('EducationVideosPage');
    public content: EducationVideoFile;
    public video: HTMLVideoElement;
    private timer: number;
    public hideControls: boolean = false;
    private loading: HTMLIonLoadingElement;
    private currentIndex: number = -1; // index will be incremented before first use, so it needs to start at -1
    private header: Element;
    private contentDiv: Element;
    private videoFormatOrder = ['.webm', '.mpeg4', '.ogg', '.vdat'];

    constructor(
        private educationTracking: EducationTrackingService,
        private gateway: GatewayService,
        private loadingCtrl: LoadingController,
        private route: ActivatedRoute,
        private overlay: OverlayService,
        private navCtrl: NavController,
        private platform: Platform,
        private screenOrientation: ScreenOrientationService,
        private translateService: TranslateService,
        private user: User,
        private routerOutlet: IonRouterOutlet,
        private ref: ChangeDetectorRef
    ) {}

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    ngOnInit(): void {
        this.routerOutlet.swipeGesture = false;
        this.route.queryParams.subscribe((params) => {
            this.content = params.data;
            this.setUpFullScreen();
            this.getVideo();
        });
    }

    ngAfterViewInit(): void {
        if (this.isNativePlatform) {
            this.screenOrientation.lockLandscape();
        }
    }

    ionViewWillLeave(): void {
        this.submitTracking();
        this.routerOutlet.swipeGesture = true;
        this.unsetFullScreen();
        if (this.isNativePlatform) {
            this.screenOrientation.setDefault();
        }

        // clicking the back button in the header was passing the click event to the onerror handler for video
        // resetting video event handlers to prevent code from running when we leave the video page, JIR-8560
        if (this.video) {
            this.video.onplaying = null;
            this.video.onerror = null;
        }
    }

    ngOnDestroy(): void {
        this.video = null;
        clearTimeout(this.timer);
        this.timer = null;
    }

    private async downloadVideo(url: string): Promise<void> {
        if (url) {
            if (!this.loading) {
                await this.showLoading();
            }

            // grab only the path from full URL so we can use the current build domain.
            const path = url.substring(url.indexOf('content-files/'), url.length);
            this.gateway.get({
                path: path,
                responseType: 'blob'
            }).subscribe(
                {
                    next: (res: Blob) => {
                        if (res) {
                            this.video = document.querySelector('video');
                            this.setVideoCallBacks();
                            this.video.src = window.URL.createObjectURL(res);
                            this.resetTimer();
                        } else {
                            this.logger.phic.error('Error getting blob response');
                            this.getVideo();
                        }
                    },
                    error: (err) => {
                        this.logger.phic.error('Error downloading file', err);
                        this.getVideo();
                    }
                }
            );
        } else {
            this.logger.phic.error('Video content does not have URL.');
            await this.getVideo();
        }
    }

    private setVideoCallBacks(): void {
        this.video.onplaying = async () => {
            this.ref.detectChanges();
            this.educationTracking.trackingStartTime();
            await this.hideLoading();
        };
        this.video.onerror = async () => {
            this.logger.phic.error('Error playing video');
            await this.getVideo();
        };
    }

    public async showLoading(): Promise<any> {
        if (this.loading) return;
        this.loading = await this.loadingCtrl.create({message: this.translateService.instant('EDUCATION_LIST.DOWNLOADING')});
        return this.loading.present();
    }

    private async hideLoading(): Promise<void> {
        if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
        }
    }

    public togglePausePlay(): void {
        if (this.video && this.video.paused) {
            this.video.play();
        } else if (this.video && !this.video.paused) {
            this.video.pause();
        }
        this.toggleControls();
    }

    // rewind 10 seconds
    public rewind(): void {
        const subtractTen = this.video.currentTime - 10;
        this.video.currentTime = subtractTen > 0 ? subtractTen : 0;
        this.toggleControls();
    }

    // fastforward 10 seconds
    public fastForward(): void {
        const addTen = this.video.currentTime + 10;
        this.video.currentTime = addTen < this.video.duration ? addTen : this.video.duration;
        this.toggleControls();
    }

    public submitTracking(): void {
        let trackingData = this.setTrackingData();
        if (trackingData) this.educationTracking.postTrackingData(trackingData);
    }

    // tries each video format in order webm -> mpeg4 -> ogg -> vdat (last resort)
    // https://www.computer.org/publications/tech-news/trends/8-best-video-file-formats-for-2020
    public async getVideo(): Promise<void> {
        if (this.video && this.video.error) {
            this.logger.phic.error('Video element error', this.video.error);
        }

        // if there are more sources, try next source
        this.currentIndex++;
        if (this.currentIndex < this.videoFormatOrder.length) {
            this.downloadVideo(this.getCurrentVideoUrl(this.videoFormatOrder[this.currentIndex]));
        } else {
            await this.showVideoErrorAlert();
        }
    }

    private async showVideoErrorAlert(): Promise<OverlayRef> {
        // if there are no more sources, present alert
        await this.hideLoading();
        const alert = await this.overlay.openAlert({
            header: this.translateService.instant('ERROR_TITLE'),
            message: [this.translateService.instant('EDUCATION_VIDEO.ERROR')],
            backdropDismiss: true,
            variant: 'error',
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    handler: () => {
                        alert.dismiss();
                        this.navCtrl.pop();
                    }
                }, {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.handleRetry();
                    }
                }
            ],
            qa: 'education_video_alert'
        });

        return alert;
    }

    public handleRetry(): void {
        this.currentIndex = 0;
        this.downloadVideo(this.getCurrentVideoUrl(this.videoFormatOrder[this.currentIndex]));
    }

    public toggleControls(): void {
        this.hideControls = !this.hideControls;
        this.toggleHeader();
        this.resetTimer();
    }

    public resetTimer(): void {
        clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            if (!this.hideControls) {
                this.hideControls = true;
                this.toggleHeader();
                this.ref.detectChanges();
            }
        }, 5000);
    }

    private setTrackingData(): TrackingDataRequest {
        if (!this.video) return;

        const videoViewedTime = this.educationTracking.trackingTimeElapsed();
        const totalTime = Math.round(this.video.duration);
        const time = videoViewedTime > totalTime ? totalTime : videoViewedTime;

        return {
            path: 'video-status-tracking',
            body: {
                id: this.content.id,
                attributes: {
                    hrsid: this.user.id,
                    time: time.toString(),
                    totaltime: totalTime.toString(),
                    ftime: this.educationTracking.trackingFinishTime(),
                    source: EducationTrackingService.TRACKING_SOURCE
                }
            }
        };
    }

    // edits default styles for the header and content div located in main.page.html allowing the education video to be rendered fullscreen
    private setUpFullScreen(): void {
        this.header = document.querySelector(`[data-js-hook="education-video"]`);
        this.contentDiv = document.querySelector(`[data-js-hook="education-video-content"]`);
        this.header.classList.add('video-header');
        this.header.classList.add('header-transition');
        // unsets position relative on the page--content div which lives in main.page.html allowing the video
        // to extend beyond the usual bounds created by hrs-page for content rendering
        this.contentDiv.classList.add('content-fullscreen');
    }

    // used to ensure styles are returned to their default state when leaving the education video page
    private unsetFullScreen(): void {
        this.header.classList.remove('header-transition');
        this.header.classList.remove('hide-header');
        this.contentDiv.classList.remove('content-fullscreen');
    }

    // allows the header in main.page.html to be hidden and unhidden
    private toggleHeader(): void {
        this.header.classList.toggle('hide-header');
    }

    private getCurrentVideoUrl(videoFormat: string): string {
        if (this.content.attributes.url) { // query params comes back with url === empty string, not empty array
            return this.content.attributes.url.find((url) => {
                if (url.includes(videoFormat)) return true;
            });
        } else {
            return undefined;
        }
    }
}
