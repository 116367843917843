import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {HRSLogger, getLogger, primaryTransport, globalLogConfig} from '@hrs/logging';
import {LogEvent, broadcastLogEvent, LogEventSerializer, ConsoleLike, EventEmitterDelegate} from '@obsidize/rx-console';
import {SecureLogLevel, SecureLogger, enableWebviewListener, disableWebviewListener} from 'cordova-plugin-secure-logger';
import {environment} from '@app/env';
import {Device} from '@ionic-native/device/ngx';
import {pick} from 'lodash';
import {EventService} from '../events/event.service';
import {Subscription} from 'rxjs';

globalLogConfig.stringifyMaxLength = 2000; // chars

// Wire up the primary rx-console transport with secure logger webview proxy.
enableWebviewListener(primaryTransport);

@Injectable({
    providedIn: 'root'
})
export class NativeLoggerService {
    private readonly logger: HRSLogger = getLogger('NativeLoggerService');
    private readonly customBroadcastProxy: EventEmitterDelegate<any>;
    private initialized: boolean = false;
    private deviceRestartSubscription: Subscription;

    constructor(
        private readonly cordovaDevice: Device,
        private readonly platform: Platform,
        private readonly eventService: EventService
    ) {
        this.customBroadcastProxy = this.performCustomBroadcast.bind(this);
    }

    public initialize(): void {
        if (this.initialized) {
            return;
        }

        this.initialized = true;
        primaryTransport.addListener(this.customBroadcastProxy);
        this.logger.trace('initialize()');

        // shouldn't ever happen but... paranoia
        if (this.deviceRestartSubscription) {
            try {
                this.deviceRestartSubscription.unsubscribe();
            } catch {}
        }

        this.deviceRestartSubscription = this.eventService.restartDevice.subscribe(() => {
            this.logger.warn(`device restart notification received, closing active log stream`);
            SecureLogger.flushAndCloseActiveStream()
                .then(() => this.logger.info(`eventService.restartDevice -> active stream flushed and closed`))
                .catch((err) => this.logger.info(`eventService.restartDevice -> active stream close error: `, err));
        });
    }

    public isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    public performCustomBroadcast(ev: LogEvent, serialize?: LogEventSerializer, target?: ConsoleLike): void {
        broadcastLogEvent(ev, serialize, target);
    }

    public async tryRegisterNativeLogging(): Promise<void> {
        this.logger.trace('tryRegisterNativeLogging()');

        if (!this.isNativePlatform()) {
            this.logger.warn('ignoring register call for non-native env');
            disableWebviewListener(primaryTransport);
            return;
        }

        // run this before any async blocks so
        // it can show up as early as possible
        this.dumpAppInfoBlock();

        await SecureLogger.configure({
            minLevel: SecureLogLevel.DEBUG,
        });
    }

    private getCordovaDeviceInfo(): any {
        return pick(this.cordovaDevice, [
            'cordova',
            'model',
            'platform',
            'uuid',
            'version',
            'manufacturer',
            'isVirtual',
            'serial'
        ]);
    }

    private dumpAppInfoBlock(): void {
        this.logger.warn(`========================================================================`);
        this.logger.warn(`>>>>>>>>>>>>>>>>>>>>>>>> APPLICATION START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<`);
        this.logger.warn(`------------------------------------------------------------------------`);
        this.logger.warn(`>>> Environment: ${JSON.stringify(environment, null, '\t')}`);
        this.logger.warn(`------------------------------------------------------------------------`);
        this.logger.warn(`>>> Device: ${JSON.stringify(this.getCordovaDeviceInfo(), null, '\t')}`);
        this.logger.warn(`------------------------------------------------------------------------`);
    }
}
