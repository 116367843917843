import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {BuildUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root'
})
export class ScreenOrientationService {
    private readonly logger = getLogger('ScreenOrientationService');

    constructor(
        private platform: Platform,
        private screenOrientation: ScreenOrientation
    ) {}

    public setDefault(): Promise<void> {
        if (BuildUtility.isHRSTab()) {
            return this.lockLandscape();
        }
        let width = this.platform.width();
        // if width is more than height the device is landscape
        if (width > this.platform.height()) width = this.platform.height();
        if (width < BuildUtility.MIN_TABLET_SCREEN_SIZE) {
            return this.lockPortrait();
        } else {
            return this.lockLandscape();
        }
    }

    public getCurrentOrientation() {
        return this.screenOrientation.type;
    }

    public lockPortrait(): Promise<void> {
        return this.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }

    public lockLandscape(): Promise<void> {
        return this.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
    }

    public lock(orientation: string): Promise<void> {
        return this.screenOrientation.lock(orientation).then(() => {
            this.logger.phic.debug('Screen successfully locked: ' + orientation);
        }, (err) => {
            this.logger.phic.error('Screen failed to lock', err);
        });
    }

    public unlock(): void {
        this.screenOrientation.unlock();
    }
}
