import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EducationPageRoutingModule} from './education-routing.module';
import {EducationPage} from './education.page';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {EducationConditionPage} from './entry-pages/education-condition.page';
import {EducationFileService, EducationTrackingService} from '@patient/providers';
import {EducationVideosPage} from './videos/education-videos.page';
import {PdfComponent} from '../components/pdf/pdf.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {SkeletonContentComponent} from '../components/skeleton-content/skeleton-content.component';
import {SharedPipesModule} from '../pipes/shared-pipes.module';

@NgModule({
    declarations: [
        EducationPage,
        EducationConditionPage,
        EducationVideosPage,
        SkeletonContentComponent,
        PdfComponent
    ],
    imports: [
        CommonModule,
        EducationPageRoutingModule,
        FormsModule,
        IonicModule,
        PdfViewerModule,
        SharedPipesModule,
        TranslateModule.forChild(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        EducationFileService,
        EducationTrackingService
    ]
})
export class EducationPageModule {}
