import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InsertionDirective} from './dom-insertion.directive';
import {ThrottleClickDirective} from './throttle-click.directive';
import {SubmitOnEnterKeyDirective} from './submit-on-enter-key.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        InsertionDirective,
        ThrottleClickDirective,
        SubmitOnEnterKeyDirective
    ],
    exports: [
        InsertionDirective,
        ThrottleClickDirective,
        SubmitOnEnterKeyDirective
    ]
})
export class SharedDirectivesModule {
}
