import {RemoteDiagnosticsData} from './remote-diagnostics.interface';

export class RemoteDiagnosticsEvent {
    static STOP = 'stop';
    static START = 'start';
    static TOUCH = 'touch';
    static KEY = 'key';
    public action: string;
    public data: RemoteDiagnosticsData;
    public time: number;

    constructor(data: RemoteDiagnosticsData, time: number) {
        this.setAction(data);
        this.data = data;
        this.time = time;
    }

    public isStop(): boolean {
        return this.action === RemoteDiagnosticsEvent.STOP;
    }

    public isStart(): boolean {
        return this.action === RemoteDiagnosticsEvent.START;
    }

    public isTouch(): boolean {
        return this.action === RemoteDiagnosticsEvent.TOUCH;
    }

    public isKey(): boolean {
        return this.action === RemoteDiagnosticsEvent.KEY;
    }

    /**
     * If we don't receive any activity from the remote user before a timeout elapses, then we'll
     * stop transmitting because they might have stopped listening but we didn't receive a cancel command.
     */
    public isTimedOut(now): boolean {
        const timeout = 600;
        return now >= this.time + timeout;
    }

    private setAction(data) : void {
        if (data.type === 'data') {
            if (data.touchevent) {
                this.action = RemoteDiagnosticsEvent.TOUCH;
            } else if (data.keyevent) {
                this.action = RemoteDiagnosticsEvent.KEY;
            }
        } else {
            this.action = data.type;
        }
    }
}
