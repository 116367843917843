import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {ConsentForm} from './consent-form';
import {GatewayApi} from '@hrs/providers';
import {User} from '../user/user.service';
import {HRSSecureCache} from '../storage/cache';
import {take} from 'rxjs/operators';
import {getLogger} from '@hrs/logging';

interface ConsentFormResponse {
    content: {
        pages: [
            {
                elements: [
                    {
                        type: string;
                        class: string;
                        text: string;
                        position: string;
                        top: number;
                        width: number;
                        height: number;
                        src: string;
                    }
                ]
            }
        ]
    };
}

@Injectable({
    providedIn: 'root',
})
export class ConsentFormService {
    private readonly logger = getLogger('ConsentFormService');
    public consentForm: ConsentForm;

    constructor(
        private cache: HRSSecureCache,
        private gatewayApi: GatewayApi,
        private user: User
    ) {}

    /**
     * Check whether the user needs to sign the consent form.
     * We make this check every so often just in case the back-end might tell us we need to re-sign the consent form (if there was some
     * back-end error when it was previously signed, or perhaps if the consent form content has been updated, etc).
     */
    getIsConsentFormRequired(bypassCache: boolean = false): Observable<ConsentForm> {
        return this.cache.loadFromDelayedObservable(
            HRSSecureCache.CONSENT_REQUIRED_CACHE_KEY + this.user.id,
            this.gatewayApi.get('consent-required/' + this.user.id),
            undefined,
            undefined,
            bypassCache ? 'all' : 'expired'
        ).pipe(
            take(1),
            switchMap((res: any) => {
                if (typeof res === 'string') {
                    try {
                        res = JSON.parse(res);
                    } catch (err) {
                        this.logger.phic.error(`getIsConsentFormRequired error`, err);
                    }
                }

                if (res.data && res.data.required) {
                    return this.getConsentForm();
                } else {
                    return of(undefined);
                }
            })
        );
    }

    getConsentForm(): Observable<ConsentForm> {
        return this.gatewayApi.get('consent/' + this.user.environment).pipe(
            map((res: any) => {
                this.consentForm = this.createConsentFormModel(res.data);
                return this.consentForm;
            })
        );
    }

    createConsentFormModel(data: ConsentFormResponse): ConsentForm {
        let consentForm = new ConsentForm();
        consentForm.pages = [];
        if (data && data.content && data.content.pages) {
            for (let page of data.content.pages) {
                let elements = page.elements.filter((element) => {
                    // Imitating what the tablet (APIv1 actually, before it sends consent form to the tablet) does -
                    // ignoring headers, images, and the signature line
                    return element.type === 'text' && element.class === 'content';
                });
                if (elements.length) {
                    consentForm.pages.push({elements: elements});
                }
            }
        }
        return consentForm;
    }

    submitSignature(signature: string): Observable<Object> {
        return this.gatewayApi.post('signed-consent', {
            data: {
                hrsid: this.user.id,
                content: signature
            }
        }).pipe(
            tap(() => {
                // Update the cache to mark the consent form as having been signed, so that it won't be shown again the next time.
                this.cache.saveItem(
                    HRSSecureCache.CONSENT_REQUIRED_CACHE_KEY + this.user.id,
                    {
                        'data': {
                            'hrsid': this.user.id,
                            'required': false
                        }
                    }
                );
            })
        );
    }
}
